import React, { useState , useEffect} from "react";
import { Modal, Row, Checkbox } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentSms } from "@fortawesome/free-solid-svg-icons";
import { get } from "lodash-es";
import { useSelector } from "react-redux";
import { decodeString, isValidPhoneNumber, isPositive } from "../../util/misc";
import { useTranslation } from "react-i18next";
const AddGroups = ({ getNumbers }) => {


    const { db }                                = useSelector(({ auth }) => auth);
    const [modal1Visible, setModal1Visible]     = useState(false);
    const [selectedGroups, setSelectedGroups]   = useState({});
    const [groups, setGroups]                   = useState([]);
    const { t }                                 = useTranslation();
    //this will setup the component to show all groups currently
    useEffect(() => {
        const data  =   [];
        let groups  =   get(db, ["GROUPS"], {});

        for (let key in groups) {
            const groupsDetails = groups[key];

            data.push({
                key : key,
                ...groupsDetails,
            });
        }

        setGroups(data);
    }, [db]);
    


    const processNumbersFromOneGroup = (item) => {

        let studentPhone        =   [];
        let parent1             =   [];
        let parent2             =   [];
        let customNumbers       =   [];

        for (let student in item.selectedStudents) {
            let studentData = item.selectedStudents[student];
            if (studentData.customNumber === true){
                customNumbers.push(studentData.Phone);
            }
            else {
                studentData.key = student;
                studentPhone.push(studentData.Phone);
                if(studentData?.parent1phone){
                    parent1.push(studentData.parent1phone);
                }
                if(studentData?.parent2phone){
                    parent2.push(studentData.parent2phone);
                }
            }
        }

        let data = {
            type                : 'Groups',
            studentPhone        : studentPhone,
            parent1             : parent1,
            parent2             : parent2,
            customNumbers       : customNumbers,
            groupName           : item.groupName,
            key                 : item.key
        }

        return data;
    }

    const takeEveryNumberfromGroup = (groups) => {

        let output = [];

        for (let oneGroupKey in groups){

            if (groups[oneGroupKey] === undefined) continue;

            let processedGroup = processNumbersFromOneGroup(groups[oneGroupKey]);

            if ((processedGroup.studentPhone).length !== 0){

                let validatedSArr    =   [];

                for (let onePhone of (processedGroup.studentPhone)){
                    if (isValidPhoneNumber(onePhone)) validatedSArr.push(onePhone);
                }

                output.push({
                    groupName   : `${processedGroup.groupName} - All Students`, 
                    numbers     : validatedSArr
                })
            }

            if ((processedGroup.parent1).length > 0){

                let validatedP1Arr    =   [];

                for (let onePhone of (processedGroup.parent1)){
                    if (isValidPhoneNumber(onePhone)) validatedP1Arr.push(onePhone);
                }

                output.push({
                    groupName: `${processedGroup.groupName} - All Parents 1`, 
                    numbers: validatedP1Arr
                })
            }

            if ((processedGroup.parent2).length > 0){

                let validatedP2Arr    =   [];

                for (let onePhone of (processedGroup.parent2)){
                    if (isValidPhoneNumber(onePhone)) validatedP2Arr.push(onePhone);
                }

                output.push({
                    groupName: `${processedGroup.groupName} - All Parents 2`, 
                    numbers: validatedP2Arr
                })
            }

            if ((processedGroup.customNumbers).length > 0){

                let validatedCustomArr    =   [];

                for (let onePhone of (processedGroup.customNumbers)){
                    if (isValidPhoneNumber(onePhone)) validatedCustomArr.push(onePhone);
                }

                output.push({
                    groupName: `${processedGroup.groupName} - Custom Numbers`, 
                    numbers: validatedCustomArr
                })
            }
        }

        getNumbers(output);
    }


    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                }}
                onClick={() => setModal1Visible(true)}
            >
                <FontAwesomeIcon icon={faCommentSms} />

                <span className="gx-ml-4">{t("From SMS Groups")}</span>
            </div>
            <Modal
                title= {t("Import SMS Groups As Recipients")}
                style={{
                    maxWidth: "400px",
                }}
                visible={modal1Visible}
                onOk={() => {
                    setModal1Visible(false);
                    takeEveryNumberfromGroup(selectedGroups);
                }}
                onCancel={() => setModal1Visible(false)}
            >
                <Row className="gx-mx-4">
                    {groups.map((item) => (
                        <div
                            key         =   {item.key}
                            style       =   {{
                                                border      : "1px solid #d9d9d9",
                                                display     : "flex",
                                                width       : "100%",
                                                height      : "40px",
                                                alignItems  : "center",
                                                paddingLeft : "10px",
                                            }}
                        >
                            <Checkbox
                                initialValue    =   {false}
                                onChange        =   {(e) => {

                                                        if (e.target.checked) {
                                                            setSelectedGroups(currentSelected => {
                                                                currentSelected[item.key] = item;
                                                                return currentSelected
                                                            });
                                                        } 
                                                        else {
                                                            setSelectedGroups(currentSelected => {
                                                                currentSelected[item.key] = undefined;
                                                                return currentSelected
                                                            });
                                                        }

                                                    }}
                            >
                                {decodeString(item.groupName)}
                            </Checkbox>
                        </div>
                    ))}
                </Row>
            </Modal>
        </div>
    );
};

export default AddGroups;
