import { useEffect, useState } from 'react';
import { Row, Col, Button, Skeleton, Card } from 'antd';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import Widget from "../Widget"
import ProfileCard from './ProfileCard';

const ButtonGroup = Button.Group;


const UsersCard = ({ studentData, isBatch, loading, addOneStudentHandler, bulkAddStudentHandler, selectOnStudentHandler, showStdProfileDrawer, onCloseStdProfileDrawer  }) => {

    const { t }                                             = useTranslation();
    const { db }                                            = useSelector(({ auth }) => auth);
    const [studentDataArray, setStudentDataArray]           = useState([])


    //this will return skeleton student cards loading based on number supplied to it
    const returnLoadingStudentCardsSkeleton = (numberOfStudentCards) => {
        let loadingCardsJSX = [];
        for (let index = 0; index < numberOfStudentCards; index++) {
            loadingCardsJSX.push(
                <Col span={8} key={index}>
                    <Card>
                        <Skeleton avatar active paragraph={{ rows: 1 }} />
                    </Card>
                </Col>

            )
        }
        return loadingCardsJSX
    }


    useEffect(()=> {
        setStudentDataArray(studentData)
    },[db, studentData])

    return (
    
        <div className='gx-pt-4 gx-inside-content-wrapper'>

            <Row className='gx-row-m-0' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify={studentData.length > 0 ? 'left':'center'} align="middle" style={{paddingTop: '2%'}}>

                {/* if loading is true then just show loading components */}
                {loading === true ? returnLoadingStudentCardsSkeleton(20) : 
                
                    // else show student cards
                    // but two scenarios to consider
                    // if no student cards and this is a Batch then show empty state to add students / bulk onboard
                    // if no student cards and this isnt event a Batch then show empty state to create batch here
                    <>
                        {(isBatch === false) ? //widget to propmt user to create a batch
                            <Col span={8} style={{marginTop: '4%'}}>

                                <Widget styleName="gx-card-full">

                                    {loading === true ? 
                                    <Skeleton avatar active paragraph={{ rows: 3 }} /> :
                                        
                                    <>
                                        <img style={{paddingBottom: 0, width: '100%'}} className="gx-slider-img" alt="example" src={"/assets/images/emptyState/emptySchool.jpeg"}/>
                                    
                                        <Row align='center' justify='middle'>
                                            <Col span={22}>
                                                <h4 className="gx-text-primary gx-text-center gx-mb-0 gx-mt-3 gx-mb-sm-2">{t("A Batch hasn't been added here yet!")}</h4>
                                            </Col>

                                        </Row>

                                        <div className="gx-p-2 gx-pb-3 ant-row-flex" style={{justifyContent: 'center', alignItems: 'center'}}>
                                            <p style={{textAlign: 'center'}}>{t("You can add a Batch here from the Batches section you can see to your left")}</p>
                                        </div>
                                    </>
                                    
                                    }
                                    
                                </Widget>
                            </Col>   
                            :
                            <>
                                {(studentDataArray.length > 0 && studentDataArray.length > 0 && isBatch === true) ? 
                                
                                <>
                                 
                                    {
                                        studentDataArray.map((item, index)=> {
                                            return (
                                                    <Col  
                                                        md              = {{span :8 }} 
                                                        sm              = {{span :24}} 
                                                        key             = {item['UID']}
                                                    >
                                                        <ProfileCard 
                                                            key                         =   {item['UID']} 
                                                            studentInfo                 =   {item} 
                                                            selectOnStudentHandler      =    {(c, v)=> selectOnStudentHandler(c, v)}
                                                            showStdProfileDrawer        =   {showStdProfileDrawer}
                                                            onCloseStdProfileDrawer     =   {onCloseStdProfileDrawer}
                                                            
                                                        />
                                                    </Col>
                                            )
                                            
                                        })
                                    }
                                
                                </>
                                
                                
                                : //widget to propmt user to add students / bulk on-board
                                    <Col span={8} style={{marginTop: '5%'}}>

                                        <Widget styleName="gx-card-full">

                                            {loading === true ? 

                                                <Skeleton avatar active paragraph={{ rows: 3 }} />

                                                : <>
                                            
                                                <img style={{paddingBottom: 0, width: '100%'}} className="gx-slider-img" alt="example" src={"/assets/images/emptyState/emptyClassroom.jpeg"}/>
                                                
                                                    <Row align='center' justify='middle'>
                                                        <Col span={24}>
                                                            <h4 className="gx-text-primary gx-text-center gx-mb-0 gx-mt-3 gx-mb-sm-2">Looks a bit empty! <br></br> Lets add some users to keep track off..</h4>
                                                        </Col>

                                                    </Row>
                                                    
                                                    <div className="gx-p-2 gx-pb-3" style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                        <ButtonGroup>
                                                            <Button size="large" className='gx-text-white gx-mb-0 btn-modifier gx-btn-actionBlue' onClick={()=>addOneStudentHandler()}>Add New User+</Button>
                                                        </ButtonGroup>
                                                    </div>
                                            
                                                </>
                                            }

                                        </Widget>

                                    </Col>
                                }
                            </>
                        }

                    </>
                }

            </Row>
       
        </div>
    );
};

export default UsersCard;