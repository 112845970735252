import * as React from 'react';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { useDispatch } from 'react-redux';
import { THEME_TYPE_DARK, THEME_TYPE_LITE } from '../../constants/ThemeSetting';
import { useEffect } from 'react';
import { setThemeType } from '../../appRedux/actions';
import { useState } from 'react';


const DarkLightMode = () => {

  const dispatch                  = useDispatch();
  const [isDarkMode, setDarkMode] = useState(
    localStorage.getItem('themeType') === THEME_TYPE_DARK ? true : false
  );

  useEffect(() => {
    if (isDarkMode ) {
      localStorage.setItem('themeType', THEME_TYPE_DARK)
      dispatch(setThemeType(THEME_TYPE_DARK));
    }
    else {
      localStorage.setItem('themeType', THEME_TYPE_LITE)
      dispatch(setThemeType(THEME_TYPE_LITE));
    }
  
  }, [isDarkMode,dispatch])

  const toggleDarkMode = (checked) => {
     setDarkMode(checked);
  };

  return (
    <DarkModeSwitch
      checked={isDarkMode}
      onChange={toggleDarkMode}
      size={20}
    />
  );
};
export default DarkLightMode;