
import {encodeString, fixBangladeshPhoneNumbers, formatDateAMPM, isNumber, lettersToNumber, totalCharactersUSED} from "../util/misc";
import {get} from "lodash-es";

class ExcelQuery {

    excelQueryLanguage({fullString, excelFileData, allPhone}){
        
        let txt                     = fullString;
        
        const regExp                = /\{([^{]+)\}/g;
        const matchedArr            = txt.match(regExp);
        let matches                 = [];
        if (matchedArr){
            matches                 = [...txt.match(regExp)];
        }
        
        let output                  = [];
        let isDynamicRowsIncrement  = false;
    
        let phoneIndex          = 0;
        for (let onePhone of allPhone){
    
            let inputStr_Copy           = fullString;
            let doISkipThisRow          = false;
    
            for (let oneMatch of matches){

                //check to see if this matched query should be shown in end preview text or not
                let doIHideThisQueryInEndText   = false;
                if (oneMatch.includes('(HIDE)')){
                    oneMatch.replace('(HIDE)', '');
                    doIHideThisQueryInEndText = true;
                }
    
                let curlyBracketRemoved         = oneMatch.slice(1, -1);
                let fileKeyID                   = curlyBracketRemoved.split('(')[0] ? encodeString(curlyBracketRemoved.split('(')[0]) : undefined;

                if (!fileKeyID) continue;
                
                let excelRows                   = get(excelFileData, [fileKeyID, 'file'], []);
                let FULL_excelCellName          = (curlyBracketRemoved.split('(')[1])?.slice(0,-1);
                let excelCellNameDUP            = FULL_excelCellName;
                let excelCellNameDUP2           = FULL_excelCellName;
    
                let cellRowNumber               = undefined;
                let cellAlphabetCol             = undefined;
                
    
                if (FULL_excelCellName.includes('++')){
    
                    //console.log('DETECTED ++ IN MATCHED TEXT!', FULL_excelCellName);
                    //remove the ++ and add phone index place to the row number

                    if (!oneMatch.includes('INCLUDE_IF') && !oneMatch.includes('INCLUDE_IF_DATE')){
                        excelCellNameDUP = excelCellNameDUP.slice(0,-2);
                        excelCellNameDUP = excelCellNameDUP?.replace(/\D/g, "");
        
                        //console.log('excelCellNameDUP: ', excelCellNameDUP);
        
                        cellRowNumber           = parseInt(excelCellNameDUP) + phoneIndex;
                        cellAlphabetCol         = excelCellNameDUP2?.replace(/[0-9]/g, ''); 
        
                        cellAlphabetCol         = cellAlphabetCol.slice(0, -2);
        
                        // console.log('ROW: ', cellRowNumber);
                        // console.log('COL: ', cellAlphabetCol);
                    }
                    else if (oneMatch.includes('INCLUDE_IF_DATE')){
                        //FOR INCLUDE_IF_DATE
                        //IN MESSAGE COMPARATOR NEEDS TO OF FORM MM/DD/YYYY
                        //IN EXCEL IT CAN BE OF FORM DD/MM/YYYY
                        //yes a skip argument is added here 
    
                        excelCellNameDUP = excelCellNameDUP.slice(0,-2);
                        excelCellNameDUP = excelCellNameDUP?.replace(/\D/g, "");
        
                        //console.log('excelCellNameDUP: ', excelCellNameDUP);
        
                        cellRowNumber               = parseInt(excelCellNameDUP) + phoneIndex;
                        cellAlphabetCol             = excelCellNameDUP2?.replace(/[0-9]/g, ''); 
        
                        cellAlphabetCol             = cellAlphabetCol.slice(0, -2);
    
                        let toCheckValues           = undefined;
    
                        let cellColumnNumber_        = parseInt(lettersToNumber(cellAlphabetCol));    
    
                        if (isNumber(cellRowNumber) && isNumber(cellColumnNumber_)){
                            if (excelRows[cellRowNumber-1]){
                                toCheckValues    = excelRows[cellRowNumber-1][cellColumnNumber_-1];
                            }
                        }

                        let splitSKIP_IF            =  oneMatch.split('INCLUDE_IF_DATE');
                        let skipArgument            =  (splitSKIP_IF[1]).slice(0,-2);
    
                        if (!(toCheckValues instanceof Date)){
                            //the to check values inside excel is not a date obj
                            console.log('The excel value is not a DATE OBJ!!! ', {toCheckValues, skipArgument});
                        }
                        else if (skipArgument.includes('==')){
                            let compareValue            = skipArgument.split('==')[1];
                            let craftedDateCompareValue = '';
                            if (compareValue === 'NOW'){
                                craftedDateCompareValue = new Date();
                            }
                            else{
                                let monthVal = compareValue.split('/')[0];
                                if (isNumber(monthVal)){
                                    craftedDateCompareValue = new Date(compareValue);
                                }
                            }

                            craftedDateCompareValue.setHours(6); //in excel all date objects are at 6 AM

                            console.log({ ExcelValue: formatDateAMPM(toCheckValues.getTime()), ExcelValueDateFormat: toCheckValues, MessageValue: formatDateAMPM(craftedDateCompareValue.getTime())});

                            if (isNumber(craftedDateCompareValue.getTime()) && isNumber(toCheckValues.getTime())){
                                //compare as date
                                if (craftedDateCompareValue.getFullYear() === toCheckValues.getFullYear() && craftedDateCompareValue.getMonth() === toCheckValues.getMonth() && craftedDateCompareValue.getDate() === toCheckValues.getDate()){
                                    doISkipThisRow = false;
                                }
                                else{
                                    doISkipThisRow = true;
                                }
                            }
    
                        }
                        else if (skipArgument.includes('>')){
                            let compareValue                    = skipArgument.split('>')[1];
                            let craftedDateCompareValue         = '';
                            if (compareValue === 'NOW'){
                                craftedDateCompareValue = new Date();
                            }
                            else{
                                let monthVal = compareValue.split('/')[0];
                                if (isNumber(monthVal)){
                                    craftedDateCompareValue = new Date(compareValue);
                                }
                            }

                            craftedDateCompareValue.setHours(6); //in excel all date objects are at 6 AM

                            console.log({ ExcelValue: formatDateAMPM(toCheckValues.getTime()), ExcelValueDateFormat: toCheckValues, MessageValue: formatDateAMPM(craftedDateCompareValue.getTime())});

                            if (isNumber(craftedDateCompareValue.getTime()) && isNumber(toCheckValues.getTime())){
                                //compare as date
                                if (parseFloat(toCheckValues.getTime()) < parseFloat(craftedDateCompareValue.getTime())){
                                            doISkipThisRow = true;
                                }
                            }
    
                        }
                        else if (skipArgument.includes('<')){
                            let compareValue = skipArgument.split('<')[1];
                            let craftedDateCompareValue         = '';
                            if (compareValue === 'NOW'){
                                craftedDateCompareValue = new Date();
                            }
                            else{
                                let monthVal = compareValue.split('/')[0];
                                if (isNumber(monthVal)){
                                    craftedDateCompareValue = new Date(compareValue);
                                }
                            }

                            craftedDateCompareValue.setHours(6); //in excel all date objects are at 6 AM

                            console.log({ ExcelValue: formatDateAMPM(toCheckValues.getTime()), ExcelValueDateFormat: toCheckValues, MessageValue: formatDateAMPM(craftedDateCompareValue.getTime())});

                            if (isNumber(craftedDateCompareValue.getTime()) && isNumber(toCheckValues.getTime())){
                                //compare as date
                                if (parseFloat(toCheckValues.getTime()) > parseFloat(craftedDateCompareValue.getTime())){
                                            doISkipThisRow = true;
                                }
                            }
    
                        }
    
                    }
                    else if (oneMatch.includes('INCLUDE_IF')){
                        //FOR INCLUDE_IF
                        //yes a skip argument is added here 
    
                        excelCellNameDUP = excelCellNameDUP.slice(0,-2);
                        excelCellNameDUP = excelCellNameDUP?.replace(/\D/g, "");
        
                        //console.log('excelCellNameDUP: ', excelCellNameDUP);
        
                        cellRowNumber               = parseInt(excelCellNameDUP) + phoneIndex;
                        cellAlphabetCol             = excelCellNameDUP2?.replace(/[0-9]/g, ''); 
        
                        cellAlphabetCol             = cellAlphabetCol.slice(0, -2);
    
                        let toCheckValues           = undefined;
    
                        let cellColumnNumber_        = parseInt(lettersToNumber(cellAlphabetCol));    
    
                        if (isNumber(cellRowNumber) && isNumber(cellColumnNumber_)){
                            if (excelRows[cellRowNumber-1]){
                                toCheckValues    = excelRows[cellRowNumber-1][cellColumnNumber_-1];
                            }
                        }
    
                        let splitSKIP_IF            =  oneMatch.split('INCLUDE_IF');
                        let skipArgument            =  (splitSKIP_IF[1]).slice(0,-2);
    
                        if (skipArgument.includes('==')){
                            let compareValue = skipArgument.split('==')[1];
    
                            if (isNumber(compareValue) && isNumber(toCheckValues)){
                                //compare as numbers
                                if (parseFloat(toCheckValues) !== parseFloat(compareValue)){
                                    doISkipThisRow = true;
                                }
                            }
                            else{
                                //compare as strings
                                if (String(toCheckValues) !== String(compareValue)){
                                    doISkipThisRow = true;
                                }
                            }
    
                        }
                        else if (skipArgument.includes('>')){
                            let compareValue = skipArgument.split('>')[1];
    
                            if (isNumber(compareValue) && isNumber(toCheckValues)){
                                //compare as numbers
                                if (parseFloat(toCheckValues) < parseFloat(compareValue)){
                                    doISkipThisRow = true;
                                }
                            }
    
    
                        }
                        else if (skipArgument.includes('<')){
                            let compareValue = skipArgument.split('<')[1];
    
                            if (isNumber(compareValue) && isNumber(toCheckValues)){
                                //compare as numbers
                                if (parseFloat(toCheckValues) > parseFloat(compareValue)){
                                    doISkipThisRow = true;
                                }
                            }
    
                        }
    
                    }

                    isDynamicRowsIncrement = true;
                }
                else{
                    cellRowNumber           = parseInt(excelCellNameDUP?.replace(/\D/g, ""));
                    cellAlphabetCol         = excelCellNameDUP2?.replace(cellRowNumber, ''); 
                }
    
                let cellColumnNumber        = parseInt(lettersToNumber(cellAlphabetCol));    
                let textReplaceFromExcel    = undefined;
        
                if (isNumber(cellRowNumber) && isNumber(cellColumnNumber)){
                    if (excelRows[cellRowNumber-1]){
                        if (doIHideThisQueryInEndText === false){
                            textReplaceFromExcel    = excelRows[cellRowNumber-1][cellColumnNumber-1];
                        }
                        else{
                            textReplaceFromExcel    = ' ';
                        }
                    }
                }
                
                if (textReplaceFromExcel !== undefined){
                    inputStr_Copy = inputStr_Copy.replace(oneMatch, textReplaceFromExcel);
                }

                if (textReplaceFromExcel === undefined){
                    inputStr_Copy = inputStr_Copy.replace(oneMatch, '.');
                }
        
            }
    
            onePhone = fixBangladeshPhoneNumbers(onePhone);
    
            phoneIndex++;
    
            if (onePhone === null || onePhone === undefined){
                doISkipThisRow = true;
            }
    
            if (doISkipThisRow === false){

                if (!inputStr_Copy) inputStr_Copy = '';

                let smsNumberPerPerson  =   Math.ceil(totalCharactersUSED(inputStr_Copy)/134);

                var regexp      = /#\S+/g;
                inputStr_Copy   = inputStr_Copy.replace(regexp, ''); //remove any hashtags from the message string..
                //inputStr_Copy   = inputStr_Copy.replace(/[^A-Za-z0-9 \r\n@£$¥!\"#$%&amp;'\(\)*\+,_.\/:;&lt;=&gt;?^{}\\\[~\]]*/ig, '');
        
                output.push({
                    text            : inputStr_Copy,
                    phone           : onePhone,
                    totalSMSNeeded  : smsNumberPerPerson
                });

            }
    
        }

        //filter the output and remove any phone which are +880
        let filteredOutput = output.filter(function (el) {
            if (el.phone !== '+880'){
                return true
            }
            else return false
        });

        console.log({filteredOutput, isDynamicRowsIncrement});
    
        return [filteredOutput, isDynamicRowsIncrement]
    }



}


export default new ExcelQuery()