import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Input, Image , Form, message, Menu, Dropdown, Checkbox, TimePicker} from 'antd';
import { get, indexOf, pick, set } from "lodash-es";
import { useSelector } from 'react-redux';
import { decodeString, encodeString } from "../../../../util/misc";
import firebase from 'firebase';
import { useTranslation } from "react-i18next";
import moment from "moment";
import {  PlusOutlined } from "@ant-design/icons";

const database    = firebase.database();


const EditRoomTimingModal = ({ isModalVisible, handleCancel, roomKey, weekDay, roomName, timingKey, startTime, endTime, timingName }) => {

    const { db }                                     = useSelector(({ auth }) => auth);
    const { t }                                      = useTranslation();

    const [pickedStartTime, setPickedStartTime]      = useState();
    const [pickedEndTime, setPickedEndTime]          = useState();

    const [tName, setTName]                          = useState('');

    useEffect(()=>{
        if (db && isModalVisible===true && roomKey !== undefined && weekDay !== undefined && roomName !== undefined && timingKey !== undefined && startTime !== undefined && endTime !== undefined && timingName !== undefined){

            setPickedEndTime(moment(new Date(endTime)));
            setPickedStartTime(moment(new Date(startTime)));
            setTName(decodeString(timingName));
        }
    }, [db, isModalVisible,roomKey, weekDay, roomName, timingKey, endTime, startTime, timingName])

    
    const submitNewTiming = () => {

        console.log('SUBMIT NEW TIMINGS WITH VALUES: ', {
            pickedStartTime, pickedEndTime, roomKey, weekDay, roomName, tName
        });
       
        if (!pickedStartTime || !pickedEndTime || !roomKey || !weekDay || !roomName || tName === ''){
            message.error('Please choose a proper timing and fill in all fields..');
            return false;
        }

        let clonePickedEndTime = pickedEndTime;

        const areDaysAndMonthsEqual = pickedStartTime.isSame(clonePickedEndTime, 'month') && pickedStartTime.isSame(clonePickedEndTime, 'day');
     
        if (!areDaysAndMonthsEqual) {
            // If the day and month are not equal, update the end date to match the start date's day and month
            const updatedEndDate = pickedEndTime.clone().set({
              month: pickedStartTime.month(),
              date: pickedStartTime.date(),
            });
            clonePickedEndTime = updatedEndDate
         
        }

        let startTime = new Date(pickedStartTime).getTime()
        let endTime = new Date(clonePickedEndTime).getTime()

        //end time cannot be before the start time
        if (startTime > endTime){
            message.error('End Time must be later than Start Time. Please picked End Time Again.');
            return false;
        }

        let tuid, teacherName, avatarURL;


        let data = {
            tuid, 
            teacherName, 
            startTime: startTime, 
            endTime: endTime, 
            avatarURL,
            timingName: encodeString(tName)
        }

        database.ref(`USERS/${db['UID']}/access_control/${roomKey}/timings/${weekDay}/${timingKey}`).update(data)
            .then(()=>{
                message.success('Timing updated for room successfully');
                handleCancel();
            })
    }
    
    return (
        <>
            <Modal
                centered
                title       =   {`Edit Timing: ${decodeString(roomName)} for ${weekDay}`}
                visible     =   {isModalVisible}
                onOk        =   {()=>submitNewTiming()}
                onCancel    =   {handleCancel}
                bodyStyle   =   {{paddingTop: '5px', paddingBottom: '40px'}}
                width       =   {550}
                footer      =   {[
                    <Button type="primary" onClick={()=>submitNewTiming()}>
                        Update
                    </Button>
                ]}
            >


                    <label style={{marginTop: '20px', display: 'flex', marginBottom: '5px'}}>Name</label>
                    <Input 
                        style       =   {{width: '100%'}}
                        onChange    =   {(v)=>setTName(v.target.value)} 
                        value       =   {tName}
                        placeholder =   {'e.g Morning First-Hour Check-In'}
                        name        =   "timingName" 
                        type        =   "text"
                    />


                    <label style={{marginTop: '20px', display: 'flex', marginBottom: '5px'}}>Start Time</label>
                    <TimePicker 
                        style={{width: '100%'}}
                        use12Hours 
                        format      =   "h:mm A"  
                        onChange    =   {(v)=>{
                            setPickedStartTime(v)
                        }} 
                        onSelect    =   {(v)=>setPickedStartTime(v)}
                        placeholder =   {t("Start Time")}
                        name        =   "StartTime" 
                        placement   =   "bottomLeft"
                        value       =   {pickedStartTime}
                        allowClear  =   {false}
                    />

                    <label style={{marginTop: '20px', display: 'flex', marginBottom: '5px'}}>End Time</label>
                    <TimePicker 
                        style={{width: '100%'}}
                        use12Hours 
                        format      =   "h:mm A"  
                        onChange    =   {(v)=>{
                            setPickedEndTime(v)
                            
                        }} 
                        onSelect    =   {(v)=>setPickedEndTime(v)} 
                        placeholder =   {t("End Time")}
                        name        =   "EndTime" 
                        placement   =   "bottomLeft"
                        value       =   {pickedEndTime}
                        allowClear  =   {false}
                    />
            
            </Modal>
        </>
    );
};

export default EditRoomTimingModal;