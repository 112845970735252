import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import {  rollCall, } from "../../../assets/dashboardIcons";
import ButtonWidget from "../../../components/ButtonWidget";
import { useTranslation } from "react-i18next";

import Widget from "../../Widget";


const DashboardBody = ({ onClick }) => {


    return (

        <>



                <Widget>

                    <Row style={{ height: '22vh' }} align="middle" justify="center">

                        <Col md={{ span: 22 }} xs={{ span: 22 }} style={{ height: '70%' }}>


                            <ButtonWidget
                                title={'Check-In Summary'}
                                buttonStyle={{ marginBottom: '0', height: '100%' }}
                                icon={rollCall}
                                color={"#FCAE66"}
                                bodyStyle={{ height: '100%' }}
                                onClick={() => {

                                }
                                }
                            />

                        </Col>

                    </Row>

                </Widget>









        </>


    );
}
export default DashboardBody