
import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_PHONE_USER,
  SIGNIN_PHONE_USER_SMS_CODE,
  SIGNIN_PHONE_USER_SUCCESS,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNIN_DEV_USER,
  LOCAL_DB,
  UPDATE_IN_LOCAL_DB,
  REMOVE_IN_LOCAL_DB,
  TEST_DB,
  SET_ACTIVE_CHAT_KEY,
  ADD_PROGRESS_OBJECT,
  UPDATE_PROGRESS_OBJECT,
  UPDATE_MOD_TEACHER_IN_LOCAL_DB,
  UPDATE_STUDENT_IN_LOCAL_DB,
  UPDATE_ADMIN_TEACHER_IN_LOCAL_DB,
  Remove_All_PROGRESS_OBJECT,
  Remove_PROGRESS_OBJECT_BY_ID,
  SET_ACTIVE_TEACHER_UID,
  SET_ALL_TEACHERS_UID,
  SET_IS_LIVE_TYPING_ENABLED
} from "../../constants/ActionTypes";










export const userSignUp = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user,
  };
};
export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user,
  };
};
export const devUserSignIn = (user) => {
  return {
    type: SIGNIN_DEV_USER,
    payload: user,
  };
};
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER,
  };
};
export const userSignUpSuccess = (authUser) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser,
  };
};

export const userSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser,
  };
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

// progressBar actions

export const addProgressObject = (progressObj) => {
  return {
    type: ADD_PROGRESS_OBJECT,
    payload: progressObj,
  };
};

export const updateProgressObj = (updateProgressObj) => {
  return {
    type: UPDATE_PROGRESS_OBJECT,
    payload: updateProgressObj,
  };
};

export const removeAllProgressObj = () => {
  return {
    type: Remove_All_PROGRESS_OBJECT,
  };
};

export const removeProgressObjById = (id) => {
  return {
    type: Remove_PROGRESS_OBJECT_BY_ID,
    payload: id,
  };
};

// end of progressBar actions

export const userPhoneSignIn = (user) => {
  return {
    type: SIGNIN_PHONE_USER,
    payload: user,
  };
};
export const userPhoneSignInSMSCode = (confirm) => {
  return {
    type: SIGNIN_PHONE_USER_SMS_CODE,
    payload: confirm,
  };
};
export const userPhoneSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_PHONE_USER_SUCCESS,
    payload: authUser,
  };
};

export const userGoogleSignIn = (user) => {
  return {
    type: SIGNIN_GOOGLE_USER,
    payload: user,
  };
};

export const userGoogleSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser,
  };
};
export const userFacebookSignIn = () => {
  return {
    type: SIGNIN_FACEBOOK_USER,
  };
};
export const userFacebookSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_FACEBOOK_USER_SUCCESS,
    payload: authUser,
  };
};
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};
export const setLocalDatabase = (db) => {
  return {
    type: LOCAL_DB,
    payload: db,
  };
};
export const updateInLocalDatabase = (pl) => {
  return {
    type: UPDATE_IN_LOCAL_DB,
    payload: pl,
  };
};


export const updateModeratingTeacherInLocalDatabase = (pl) => {
  return {
    type: UPDATE_MOD_TEACHER_IN_LOCAL_DB,
    payload: pl,
  };
};


export const updateStudentTeacherInLocalDatabase = (pl) => {
    return {
      type: UPDATE_STUDENT_IN_LOCAL_DB,
      payload: pl,
    };
};


export const updateAdminedTeacherInLocalDatabase = (pl) => {
  return {
    type: UPDATE_ADMIN_TEACHER_IN_LOCAL_DB,
    payload: pl,
  };
};


export const removeInLocalDatabase = (pl) => {
  return {
    type: REMOVE_IN_LOCAL_DB,
    payload: pl,
  };
};
export const testDatabase = (x) => {
  return {
    type: TEST_DB,
    payload: x,
  };
};
export const userTwitterSignIn = () => {
  return {
    type: SIGNIN_TWITTER_USER,
  };
};
export const userTwitterSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_TWITTER_USER_SUCCESS,
    payload: authUser,
  };
};
export const userGithubSignIn = () => {
  return {
    type: SIGNIN_GITHUB_USER,
  };
};
export const userGithubSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GITHUB_USER_SUCCESS,
    payload: authUser,
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

export const setActiveTeacherUID = (userUID) => {
  return {
    type: SET_ACTIVE_TEACHER_UID,
    payload: {uid: userUID},
  };
};

export const setActiveChatKey = (key) => {
    return {
      type: SET_ACTIVE_CHAT_KEY,
      payload: {key: key},
    };
  };

export const setIsLiveTypingEnabled = (bool) => {
    return {
        type: SET_IS_LIVE_TYPING_ENABLED,
        payload: {bool: bool},
    };
};

export const setAllTeachersUID = (allIDs) => {
  return {
    type: SET_ALL_TEACHERS_UID,
    payload: {allUid: allIDs},
  };
};

