import React, {  useEffect, useState } from "react";
import { Modal, Upload, message, Button, Input , Tooltip, Select, InputNumber} from "antd";
import { FileAddFilled, InboxOutlined } from "@ant-design/icons";
import readXlsxFile, {readSheetNames} from 'read-excel-file'
import { decodeString, lettersToNumber} from "../../util/misc";
import * as XLSX from "xlsx";
import { encodeString } from "../../util/misc";
import { useTranslation } from "react-i18next";
import {set, get} from "lodash-es";
const { Dragger } = Upload;

const AddFromFile = ({ getNumbers, getFileData }) => {

    const [modal1Visible, setModal1Visible]             =   useState(false);
    const { t }                                         =   useTranslation();

    const [phoneColumnModal, setPhoneColumnModal]       =   useState(false);
    const [allSheetNames, setAllSheetNames]             =   useState([]);

    const [chosenColumns, setChosenColumns]             =   useState([]);
    const [allSheetRowsArr, setAllSheetRowsArr]         =   useState(undefined);
    const [fileName_,setFileName_]                      =   useState('');

    const beforeUpload = (file) => {

        const isExcel = (file.name).includes('.xls') || (file.name).includes('.xlsx');
        if (!isExcel) {
          message.error('You can only upload a Excel file!');
        }
      
        const isLt2M = file.size / 1024 / 1024 < 2;
      
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
      
        return isExcel && isLt2M;
    };

    const downloadExcelTemplate = () => {

        let createXLSLFormatObj             = [];
            
        /* XLS Rows Data */
        let xlsRows                         = [];

        /* File Name */
        let filename = `Import-Phone-Numbers-${(new Date()).getTime()}.xlsx`;

        /* Sheet Name */
        let ws_name = `Import-Phone-Numbers`;

        xlsRows.push(['Phone Numbers']);
        for (let index = 0; index < 5; index++) {
            let data = ['1700766173'];
            xlsRows.push(data);
        }

        xlsRows.forEach((value, index )=>{
            let innerRowData = [];
            value.forEach((val, ind) => {
                innerRowData.push(val);
            });
            createXLSLFormatObj.push(innerRowData);
        });


        let wb                  = XLSX.utils.book_new()
        let ws                  = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

        let colWidthDataStyle   = [ { wch: 22 }]
        for (let index = 0; index < 30; index++) {
            colWidthDataStyle.push({wch: 35})
        }
        
        ws["!cols"]             = colWidthDataStyle;

        /* Add worksheet to workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);

            
        /* Write workbook and Download */
        XLSX.writeFile(wb, filename);
    }

    const uploadExcelTemplate = (zippedFile, chosenSheetIndex) => {

        if (!chosenSheetIndex) chosenSheetIndex = 0;

        let fileName                    =       zippedFile.file.name;
        setFileName_(fileName);

        readSheetNames(zippedFile.file).then((sheetNames) => {

            let sheetArrayNames             = sheetNames;
            let promiseArr                  = [];                
            
            sheetArrayNames = sheetArrayNames.map((x)=>encodeString(x));
            setAllSheetNames(sheetArrayNames);

            let outputInputValueSheetColumns = [];

            let i = 0;
            for (let sheetQ of sheetArrayNames){
                outputInputValueSheetColumns.push({
                    [sheetQ]: i===0 ? 'A': ''
                })
                i++
            }
            setChosenColumns(outputInputValueSheetColumns)

            for (let oneSheet of sheetArrayNames){
                promiseArr.push(readXlsxFile(zippedFile.file, { sheet: decodeString(oneSheet) }))
            }

            Promise.all(promiseArr).then((rowsArr) => {
        
                setAllSheetRowsArr(rowsArr);
                let fileDataArr     =   []

                //now find and structure the actual excel file as JSON data with multiple sheets
                let i = 0;
                for (let oneRow of rowsArr){
                    fileDataArr.push({
                        id      : `${encodeString(fileName)}-${encodeString(sheetArrayNames[i])}`,
                        file    : oneRow,
                        index   : i
                    })
                    i++;
                }

                
                getFileData(fileDataArr);
                setModal1Visible(false);
                setPhoneColumnModal(true);
                message.destroy();
            })
        })

    }


    const handleFormChange = (index, event, sheetName) => {
        setChosenColumns(currentData=>{
            let newData = [...currentData];
            newData[index][sheetName] = event.target.value;
            return newData
        });
    }
    
    return (
        <div>
            <div style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
            }} onClick={() => setModal1Visible(true)}>
                <FileAddFilled />

                <span className="gx-ml-4">{t("From Excel File")}</span>
            </div>
            <Modal
                title= {t("Import Numbers from Excel File")}
                style={{
                    maxWidth: "400px",
                }}
                visible={modal1Visible}
                bodyStyle={{paddingTop: '1.5%'}}
                onOk={() => {
                    setModal1Visible(false);
                }}
                onCancel={() => setModal1Visible(false)}
                footer={[
                    <Button key="back" onClick={()=>setModal1Visible(false)}>
                        {t("Cancel")}
                    </Button>,
                    <Button
                        key="link"
                        type="danger"
                        onClick={()=>downloadExcelTemplate()}
                    >
                        {t("Download Excel Template")}
                    </Button>
                ]}
            >
                
                <Dragger 
                    multiple        =   {false}
                    showUploadList  =   {false}
                    beforeUpload    =   {beforeUpload}
                    customRequest   =   {(x)=>uploadExcelTemplate(x)} 
                    className="gx-p-4 gx-mb-2 gx-mt-3">
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                       {t("Upload the excel template here")}
                    </p>
                    <p className="ant-upload-hint">
                        Please note we discard the first row automatically due to it being header row.
                    </p>
                </Dragger>
            </Modal>

            <Modal
                title= {t("Select Phone Number Column")}
                style={{
                    maxWidth: "500px",
                }}
                bodyStyle={{paddingTop: '1.5%'}}
                visible={phoneColumnModal}
                onOk={() => {
                    setPhoneColumnModal(false);
                }}
                onCancel={() => setPhoneColumnModal(false)}
                footer={[
                    <Button type="primary" onClick={()=>{

                        if (allSheetRowsArr === undefined){
                            setPhoneColumnModal(false);
                            message.error('Could not find data in uploaded file..');
                            return false;
                        }
                        //update the selected phone numbers

                        //find all phone numbers and set it for recepients tag
                        let allPhoneNumbers                     = [];

                        console.log('chosenColumns: ', chosenColumns);

                        let i = 0;
                        for (let oneSheetObj of chosenColumns){

                            let sheetName = allSheetNames[i];

                            if (sheetName === '' || !oneSheetObj[sheetName]) continue;

                            let thisSheetInputField     = oneSheetObj[sheetName]
                            let strVal                  = thisSheetInputField;
                            if (strVal){
                                let thisSheetPhoneNumberColumns          = [];
                                if (strVal.includes(',')){
                                    let allColumns      = strVal.split(',');
                                    for (let oneAlphabet of allColumns){
                                        if (oneAlphabet){
                                            thisSheetPhoneNumberColumns.push(lettersToNumber((String(oneAlphabet)).trim()));
                                        }
                                    }
                                }
                                else{
                                    thisSheetPhoneNumberColumns.push(lettersToNumber(strVal));
                                }

                                //now find the phone numbers in this column and add it to allPhoneNumbers

                                let colNumberToSearchArr                =  thisSheetPhoneNumberColumns;
                                let rows                                =  allSheetRowsArr[allSheetNames.indexOf(sheetName)];
                                let copyRows                            = [...rows];
                                copyRows.shift(); // remove header row
        
                                for (let colNumberToSearch_ of colNumberToSearchArr){
                                    let colNumberToSearch     = parseInt(colNumberToSearch_)-1;
                                    for (let oneStudentInfo of copyRows){
                                        let studentPhone  = oneStudentInfo[colNumberToSearch];
                                        allPhoneNumbers.push(studentPhone);
                                    }
                                }
                            }

                            i++;
                        }
                        

                        if (allPhoneNumbers.includes(undefined)){
                            let uniqueAllPhone      = allPhoneNumbers.filter((v, i, a) => a.indexOf(v) === i);
                            if (uniqueAllPhone[0] === undefined){
                                message.error('Could not find any numbers to add!');
                                return false;
                            }
                        }

                        getNumbers({fileName: `${encodeString(fileName_)}`, numbers:allPhoneNumbers, id: `${encodeString(fileName_)}`});
                        setPhoneColumnModal(false);

                    }}>
                        {t("Submit")}
                    </Button>
                ]}
            >

                    <p className="ant-upload-hint gx-mt-0">
                        These are all the sheets found in your file. Please fill in the columns beside each with column containing your phone numbers. You can add multiple columns with comma.
                    </p>
                
                    {allSheetNames.map((v, i)=>{

                        console.log({
                            chosenColumns, i, v
                        });

                        return  <>
                                    <Input 
                                        className           ="gx-mt-2" 
                                        addonBefore         ={decodeString(v)} 
                                        defaultValue        ={i === 0 ? 'A': ''} 
                                        placeholder         ="A,B,C" 
                                        onChange            ={event => handleFormChange(i, event, v)}
                                        value               = {chosenColumns.length === 0 ? '': (chosenColumns[i] ? chosenColumns[i][v]: '' )}
                                    />
                                </>

                    })}


            </Modal>
        </div>
    );
};

export default AddFromFile;
