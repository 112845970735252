import React, { useEffect, useState } from "react";
// eslint-disable-next-line
import { Col, Row, Menu, Layout, Button } from "antd";
import RouteTitle from "../../../components/RouteTitle";
import ContentTopBar from "../../../components/ContentTopBar";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import General from "./General";
import History from "./History";
import Groups from "./Groups";
import Templates from "./Templates"
import Automation from "./Automation";
import AddBtn from '../../../components/AddBtn/index';


const { Content } = Layout;


const SMS_Settings = () => {

    const { t }                                 = useTranslation();
    const [rightContent, setRightContent]       = useState();

    const [sideBarContent, setSideBarContent]   = useState([]);
    const { db }                                = useSelector(({ auth }) => auth);

    //set the side-bar content for sms settings
    useEffect(()=>{

        if (db === undefined) return false;

        setSideBarContent([
            {
                title: t("General"),
                key: "1",
                },
                {
                title: t("History"),
                key: "2",
                },
                {
                title: t("Groups"),
                key: "3",
                },
                {
                title: t("Templates"),
                key: "4",
                },

        ])

    }, [db, t]);


    const [selectedKey, setSelectedKey] = useState("1");

    const onClick = (key) => {
        setSelectedKey(key);
    };

    const routing = (key) => {
        switch (key) {
        case "1":
            return <General />;
        case "2":
            return <History />;
        case "3":
            return <Groups />;
        case "4":
            return <Templates />;
        case "5":
            return <Templates />;
        default:
            return <General />;
        }
    };

    const originTargetKeys = "start from here ";
    const [targetKeys, setTargetKeys] = useState(originTargetKeys);
    const [color, setColor] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    
    const [loading, setLoading] = useState(false);
    const onChange = (nextTargetKeys) => {
        setTargetKeys(nextTargetKeys);
        console.log(targetKeys);
    };
    const onChangeChanged = (e) => {
        console.log(`checked = ${e.target.checked}`);
        //function koi use hosse dekha lagbe
    };
    const triggerDisable = (checked) => {
        setDisabled(checked);
    };

    const triggerShowSearch = (checked) => {
        setShowSearch(checked);
    };

    
    const showModal = () => {
        setIsModalVisible(true);
    };

    useEffect(() => {

        switch (selectedKey) {
        case "1":
            return setRightContent([]);
        case "2":
            return setRightContent(<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            
            {/*<Button className="gx-btn-primary gx-mb-0">Resend</Button>*/}
            </div>);
        case "3":
            return setRightContent();
        // case "4":
        //     return setRightContent(<AddBtn onClick={showModal} text={'Add Automation'} className='gx-btn-primary' />);
        case "5":
            return setRightContent();
        default:
            return setRightContent([]);
        }
    }, [selectedKey])
    
    return (
        <div className="gx-main-content-wrapper padding-0 gx-main-content-wrapper-match-background-to-theme">

            <ContentTopBar
                leftContent         =   {<RouteTitle title={t("Manage SMS")} icon={faGear} fontSize={'1.2em'}/>}
                middleContent       =   {<></>}

                rightContent        =   {rightContent}
                middleCol           =   {12}
                rightCol            =   {7}
            />


            <div className="content-wrapper-settings">
                <Row className="gx-row-m-0 content-row">
                    <Col
                        lg={5}
                        md={5}
                        sm={24}
                        xs={24}
                        className="gx-pl-0"
                    >
                        <Menu
                            className="sider-left  gx-pt-4 gx-ml-4"
                            mode="inline"
                            defaultSelectedKeys={["1"]}
                            defaultOpenKeys={["sub1"]}
                            >
                            {sideBarContent.map((item) => (
                                <Menu.Item key={item.key} onClick={() => onClick(item.key)}>
                                    <span>{item.title}</span>
                                </Menu.Item>
                            ))}
                        </Menu>
                    </Col>

                    <Col lg={19} md={19} sm={24} xs={24}>
                        <Content>
                        <div className="site-layout-background">
                            {routing(selectedKey)}
                        </div>
                        </Content>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default SMS_Settings;