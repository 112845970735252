import { useEffect, useState } from 'react';
import './Auth.css'
import logo from '../assets/images/logoLight-NoBackground.png'

import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';

import { message, Popover } from "antd";
import firebase from 'firebase';
import {  Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  auth
} from "../firebase/firebase";

import {
  hideMessage, switchLanguage
} from "../appRedux/actions";

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./Topbar/languageData";
import DarkLightMode from '../components/DarkLightMode';
import  IntlMessages  from 'util/IntlMessages';


const database          = firebase.database();


const ForgotPassword = () => {

  const dispatch = useDispatch();
  const { showMessage } = useSelector(({ auth }) => auth);

  const [email, setEmail] = useState('');
  const { locale } = useSelector(({ settings }) => settings);


  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
  });


  const onFinish = event => {

    event.preventDefault();

    auth
      .sendPasswordResetEmail(email)
      .then(function (snapshot) {
        message.success(`Reset Email sent to ${email}. You can update your password from there. If you can't find it then check your spam folder.`);
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };



  return (
    <div className="gx-app-login-wrap">

      <div className="gx-app-login-container sign-up-page-wrapper">
        <div className="sign-up-page-flex">
          <div className="gx-app-logo-content left-column">
            <Link to="/welcome" style={{textAlign: 'center'}}> 
                <div className="gx-app-logo">
                <img alt="brand" src={logo} />
                </div>
          
            </Link>
          </div>

          <div className="gx-app-login-content right-column">
            <div className="right-column-wrapper">
              <div className="forget-text">
                <h4><IntlMessages id="app.userAuth.forgotPassword" /> ?</h4>
                <><IntlMessages id="app.userAuth.forgot" /></>
              </div>
              <form className="signup-form"
                name="basic"
                onSubmit={onFinish}

              >
                <div className="uk-form-group">
                  <label className="uk-form-label"> <IntlMessages id="appModule.email" /></label>

                  <div className="uk-position-relative w-100">
                    <span className="uk-form-icon">
                      <FontAwesomeIcon className="icon" icon={faEnvelopeOpen} />
                    </span>
                    <input className="uk-input email-input" type="email" placeholder="name@example.com" value={email} onChange={v => setEmail(v.target.value)} />
                  </div>
                </div>

                <div className="btn-group">
                  <div className="uk-width-expand@s">
                    <button type="submit" className="btn ant-btn-primary signup-btn btn-block"><IntlMessages id="appModule.send" /></button>
                  </div>
                </div>

                <div className="bottom-link">
                  <div className="uk-width-1-1">
                    <p> <NavLink to="/signin" className="login-btn"><FontAwesomeIcon className="icon" icon={faArrowLeft} />&nbsp;<IntlMessages id="appModule.back" /></NavLink></p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;