import { Button, Col, Form, Input, Row , Card} from "antd";
import React, { useState, useEffect } from "react";
import Uploader from "./Uploader";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { decodeString, encodeString } from "../../../util/misc";
import firebase from 'firebase';
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash-es";
import Email from "../../../api/Email";
import { message } from "antd/lib/index";

const database    = firebase.database();

const Features = () => {
    const { t }                             = useTranslation();
    const [form]                            = Form.useForm();
    const [formLayout, setFormLayout]       = useState('vertical');
    const { db }                            = useSelector(({ auth }) => auth);


    const { TextArea } = Input;
    const formItemLayout =
        formLayout === 'horizontal'
        ? {
            labelCol: {
                span: 4,
            },
            wrapperCol: {
                span: 14,
            },
            }
        : null;



    const onFinishFailed = errorInfo => {
    };


    const onFinish = async values => {
        let {subject,details} = values;
        let data = {
            subject: encodeString(subject),
            details: encodeString(details),
        }

        database.ref(`USERS/${db['UID']}/FeatureRequest`).push(data);
        database.ref(`FeatureRequest/${db['UID']}/`).push(data);
        message.success(('Thank you for the request. We will get in touch with you soon!'));
        form.resetFields();
        Email.sendAdmin({
            message: `User ${decodeString(db['PublicInfo']['UserName'])} ${decodeString(db['PublicInfo']['UserPhone'])} ${decodeString(db['PublicInfo']['UserEmal'])} requested for feature ${(details)}`,
            subject: `Feature Request! -- ${subject}`, 
            uid: db['UID'], 
            callback: ()=>{}
        });
        
    };


    return (
        <div className="conatiner">
            <Row>
                <Col md={{span:16}} sm={{span:24}} flex={10}>
                    <h1>{t("What feature would you like to request?")}</h1>
                    <Form 
                        className="line-height-bangla"
                        {...formItemLayout}
                        layout={formLayout}
                        form={form}
                        initialValues={{ layout: formLayout }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item rules={[{ required: true, message: t('Subject') }]} label={t("Subject")} name="subject" >
                            <Input placeholder={t("Enter the subject")} />
                        </Form.Item>

                         <Form.Item rules={[{ required: true, message: t('Please enter your feature idea!') }]} label={t("Details")} name="details" >
                            <TextArea rows={8} /> 

                        </Form.Item>

                            <br />

                        <Form.Item>
                            <Button block htmlType="submit" type="primary">{t("Submit Request")}</Button>
                        </Form.Item>
                        </Form>
                </Col>
            </Row>
        </div>
    );
};

export default Features;
