import React, { useState, useEffect, useCallback } from "react";
import { Row, Button,DatePicker, Popover } from "antd";
import * as moment from 'moment';
import { useTranslation } from "react-i18next";
const { RangePicker } = DatePicker;
const ButtonGroup = Button.Group;

const CRangePicker = ({ defaultValue, onChange , containerProps , size ,style}) => {
 
    const [current, setCurrent]         = useState(false);
    const [startDate, setStartDate]     = useState(0);
    const [endDate, setEndDate]         = useState(0);
    const [customTitle, setCustomTitle] = useState("Custom");
    const { t }                         = useTranslation();
    const [active, setActive]           = useState(null);

    const handler = (i,btn) => {
        if(i === 0){
            setCurrent(!current);
        }
        else{
            setCustomTitle(t("Custom"));
            genaralFunc(btn.callBFunc);
        }
        setActive(i);
    };

    const genaralFunc = useCallback((val,mVal) => {
        if(val  === "Today"){
            let obj =   {   startDate: moment().startOf('day').valueOf(),
                            endDate: moment().valueOf()
                        }
            return onChange(obj);
        }
        if(val  === "Yestarday"){
            let obj =   {   startDate: moment().subtract(1, 'days').startOf('day').valueOf(),
                            endDate: moment().subtract(1, 'days').endOf('day').valueOf()
                        }
            return onChange(obj);
        }
        if(val  === "7Day"){
            let obj =   {   startDate: moment().subtract(7, 'days').startOf('day').valueOf(),
                            endDate: moment().endOf('day').valueOf()
                        }
            return onChange(obj);
        }
        if(val  === "30Day"){
            let obj =   {   startDate: moment().subtract(30, 'days').startOf('day').valueOf(),
                            endDate: moment().endOf('day').valueOf()
                        }
            return onChange(obj);
        }
        if(val  === "3M"){
            let obj =   {   startDate: moment().subtract(3, 'month').startOf('day').valueOf(),
                            endDate: moment().endOf('day').valueOf()
                        }
            return onChange(obj);
        }
        if(val  === "6M"){
            let obj =   {   startDate: moment().subtract(6, 'month').startOf('day').valueOf(),
                            endDate: moment().endOf('day').valueOf()
                        }
            return onChange(obj);
        }
        if(val  === "12M"){
            let obj =   {   startDate: moment().subtract(12, 'month').startOf('day').valueOf(),
                            endDate: moment().endOf('day').valueOf()
                        }
            return onChange(obj);
        }
        if(Array.isArray(val)){
            setStartDate(moment(val[0]).format("DD/MM/YY"));
            setEndDate(moment(val[1]).format("DD/MM/YY"));
            let obj =   {   startDate: val[0]?.startOf('day').valueOf(),
                            endDate: val[1]?.endOf('day').valueOf()
                        }
            setCurrent(false);
            return onChange(obj);
        }
        
        // onChange(val)
    },[onChange])

    useEffect(()=>{
       let val = defaultValue? defaultValue : "12M"  ;
        if(val){
            genaralFunc(val);
        }
        setCustomTitle(t("Custom"));
    },[defaultValue, t]);

    useEffect(()=>{

         if(startDate && endDate){
            setCustomTitle(startDate? `${startDate} - ${endDate}` : t("Custom"));
         }
     },[startDate, endDate, t]);

    const btns = [
        { id: 0, title:  customTitle , callBFunc: "Null"},
        { id: 1, title:  t("Today") , callBFunc: "Today"},
        { id: 2, title:  t("Yestarday") , callBFunc: "Yestarday"},
        { id: 3, title:  t("7D") ,  callBFunc: "7Day"},
        { id: 4, title:  t("30D") , callBFunc: "30Day"},
        { id: 5, title:  t("3M") ,  callBFunc: "3M"},
        { id: 6, title:  t("6M") ,  callBFunc: "6M"}
      ];
    
    return (
        <div style={style}>
            <div {...containerProps}>
                <Popover
                        
                        placement       =   'bottomLeft'
                        visible         =   {current}
                        trigger         =   "click"
                        onVisibleChange =   {()=>setCurrent(!current)}
                        content         =   {
                            <div>
                                <RangePicker
                                    size         =  "small"
                                    onChange     =  {genaralFunc}
                                />
                            </div>
                        }
                    >
                
                </Popover>
                <ButtonGroup>
                {btns.map((btn, i) => (
                    <Button
                        key         ={i}
                        size        ={size?size:'small'}
                        className   ={i === active ? "gx-btn-default" : "gx-btn-primary"}
                        onClick     ={() => handler(i,btn)}
                        >
                    {btn.title}
                    </Button>
                ))}

                </ButtonGroup>
                
            </div>
            
        </div>
    );

};

export default CRangePicker;
