import { Button, Col, Form, Input, Row } from "antd";
import React, { useState, useEffect } from "react";
import Uploader from "./Uploader";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { decodeString, encodeString, isValidPhoneNumber, isNumber } from "../../../util/misc";
import firebase from 'firebase';
import { useSelector } from "react-redux";
import { get } from "lodash-es";
import { message } from "antd/lib/index";
import { BankOutlined, HeatMapOutlined, HomeOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { auth } from "../../../firebase/firebase";
import LogoUploader from "./LogoUploader";

const database    = firebase.database();


const EditProfile = () => {

    const { t }                             = useTranslation();
    const [form]                            = Form.useForm();
    const [formLayout, setFormLayout]       = useState('vertical');
    const { db }                            = useSelector(({ auth }) => auth);
    const [email, setEmail]                 = useState('');
    const [dbEmail, setDbEmail]             = useState('');

    const formItemLayout =
        formLayout === 'horizontal'
        ? {
            labelCol: {
                span: 4,
            },
            wrapperCol: {
                span: 14,
            },
            }
        : null;

    //set default name and other fields
    useEffect(() => {

        let {UserName, UserInstitution, UserPhone, UserEmail, pwd, UserLocation}  = get(db, ['PublicInfo'], '');

        if (UserLocation){
            if (UserLocation.includes('unset')){
                UserLocation = '';
            }
        }

        form.setFieldsValue({
            email: decodeString(UserEmail), 
            Password: decodeString(pwd), 
            name: decodeString(UserName), 
            institution: decodeString(UserInstitution),
            phone: decodeString(UserPhone),
            address: UserLocation ? decodeString(UserLocation) : ''
        });

        setEmail(decodeString(UserEmail));
        setDbEmail(decodeString(UserEmail));

    }, [db, form]);

    const onFinishFailed = errorInfo => {
    };
    
    const onFinish = async values => {
        console.log('On finish form values: ', values);
        
        let {name, phone, institution, address} = values;

        if (!(String(phone).length === 10 && isNumber(phone))){
            message.error('Invalid phone number');
            return false;
        } 

        let data = {
            UserName: encodeString(name),
            UserPhone: encodeString(phone),
            UserInstitution: encodeString(institution),
            UserLocation: encodeString(address)
        }

        let hide = message.loading('', 0);

        await database.ref(`USERS/${db['UID']}/PublicInfo`).update(data);
        hide();
        message.success(('Profile updated successfully'));
    };


    const passwordRequest = () => {
        auth
            .sendPasswordResetEmail(dbEmail)
            .then(function (snapshot) {
                message.success(`Reset Email sent to ${dbEmail}. You can update your password from there. If you can't find it then check your spam folder.`);
            })
            .catch(function (error) {
                message.error(error.message);
            });
    }

    const updateEmail = () => {

        if (email && email.includes('@')){
            auth.currentUser.updateEmail(email)
                .then(() => {
                    message.success(`Your email has been updated successfully!`);
                    database.ref(`USERS/${db['UID']}/PublicInfo`).update({UserEmail: encodeString(email)})
                })
              .catch((error) => {
                message.error(`${error.code}  - ${error.message}`);
                if (error.code === 'auth/requires-recent-login'){
                    let h = message.error('You need to log-out and then log back in recently to use this feature!', 0);
                    setTimeout(()=>{
                        h();
                    }, 10000);
                }
              });
        }
        else{
            message.error('Please enter a proper email!');
        }
    }


    return (
        <div className="conatiner">
            <Row>
                <Col xs={{span:24}} md={{span:12 }}>

                    <h1>{t("Account Email & Password")}: </h1>

                    <Input prefix={<MailOutlined className="site-form-item-icon gx-mr-1" />} placeholder={t("Enter your email address")} value={email} onChange={(v)=>setEmail(v.target.value)}/>

                    <Button block type="primary" className="gx-mt-3" onClick={()=>updateEmail()}>{t("Update Email")}</Button>
                    <Button block type="danger" onClick={()=>passwordRequest()}>{t("Request Password Reset")}</Button>


                    <h1 className="gx-mt-4">{t("Personal Information")}: </h1>
                    <Form 
                        className="line-height-bangla"
                        {...formItemLayout}
                        layout={formLayout}
                        form={form}
                        initialValues={{ layout: formLayout }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >

                        <Form.Item rules={[{ required: true, message: t('Please enter your Full Name') }]} label={t("User Name")} name="name" >
                            <Input prefix={<UserOutlined className="site-form-item-icon gx-mr-1" />} placeholder={t("Enter your full name")} />
                        </Form.Item>
                        <Form.Item rules={[{ required: true, message: t('Please enter your Institution') }]} label={t("Institution")} name="institution" >
                            <Input prefix={<BankOutlined className="site-form-item-icon gx-mr-1" />} placeholder={t("Enter your institution name")} />
                        </Form.Item>
                        <Form.Item rules={[{ required: true, message: t('Please enter your location / address') }]} label={t("Address")} name="address" >
                            <Input prefix={<HomeOutlined className="site-form-item-icon gx-mr-1" />} placeholder={t("Enter your institution address")} />
                        </Form.Item>
                        <Form.Item rules={[{ required: true, message: t('Please enter your Phone') }]} label={t("Phone number")} name="phone">
                            <Input 
                                prefix      =   "+880"
                                placeholder={t("Enter your phone number")} />
                        </Form.Item>

                        <Form.Item>
                            <Button block htmlType="submit" type="primary">{t("Update Information")}</Button>
                        </Form.Item>

                    </Form>
                </Col>


                <Col span={10} className="gx-ml-3">
                        <Uploader />
                </Col>


            </Row>
        </div>
    );
};

export default EditProfile;
