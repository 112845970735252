import { Button, Col, Table, Input, message, Row } from "antd";
import React, { useState, useEffect } from "react";

import "../../../i18n";
import { useTranslation } from "react-i18next";
import { copyToClipboard, decodeString, formatDateAMPM } from "../../../util/misc";
import firebase from 'firebase';
import { useSelector } from "react-redux";
import { get } from "lodash-es";

const database    = firebase.database();

const Referrals = () => {

    const { t }                             = useTranslation();
    const { db }                            = useSelector(({ auth }) => auth);

    const [refLink, setRefLink]             = useState('');
    const [tableData, setTableData]         = useState([]);

    //setup the table data and the ref link
    useEffect(()=>{
        if (db){
            setRefLink(`https://edutechs.app/signup?ref=${db['UID']}`);

            let referrals       = get(db, ['PublicInfo', 'Referrals'], {});
            let promiseArr      = [];
            let dataReferrals   = [];
            let tableDataJSX    = [];

            for (let tuid in referrals){
                let {referralID, timeStamp, Bonus} = referrals[tuid];
                promiseArr.push(database.ref(`USERS/${referralID}/PublicInfo/UserEmail`).once('value'));
                dataReferrals.push({
                    referralID, timeStamp, Bonus
                })
            }

            Promise.all(promiseArr)
                .then(snapshotArr=>{

                    for (let index = 0; index < snapshotArr.length; index++) {

                        let userEmail = (snapshotArr[index]).val();

                        tableDataJSX.push({
                            key             : dataReferrals[index]['referralID'],
                            email           : decodeString(userEmail),
                            dateReferred    : formatDateAMPM(dataReferrals[index]['timeStamp']),
                            extraSMS        : get(dataReferrals[index]['Bonus'], ['extraSMSAmount'], 0),
                            dateReceived    : get(dataReferrals[index]['Bonus'], ['timeStamp'], undefined) ? formatDateAMPM(dataReferrals[index]['Bonus']['timeStamp']) : ' ',
                            ...dataReferrals[index],
                        })
                    }

                    //need to see if self user has bought in which case they will have a bonus as well
                    let thisAccountWasreferredBy  = get(db, ['ReferredBy', 'SelfBonus'], undefined);
                    if (thisAccountWasreferredBy){

                        let {extraSMSAmount, timeStamp} = thisAccountWasreferredBy;

                        tableDataJSX.push({
                            key             : db['UID'],
                            email           : `${decodeString(db['PublicInfo']['UserEmail'])} (You)`,
                            dateReferred    : formatDateAMPM(timeStamp),
                            extraSMS        : extraSMSAmount,
                            dateReceived    : formatDateAMPM(timeStamp),
                        })

                    }

                    setTableData(tableDataJSX)
                })

        }
    }, [db, t]);

    const columns = [
        {
          title: t('Referred Account'),
          dataIndex: 'email',
          key: 'email'
        },
        {
            title: t('Date Referred'),
            dataIndex: 'dateReferred',
            key: 'dateReferred'
          },
        {
          title: t('Received Extra SMS Amount'),
          dataIndex: 'extraSMS',
          key: 'extraSMS'
        },
        {
          title: t('Date Received Extra SMS'),
          dataIndex: 'dateReceived',
          key: 'dateReceived',
        },
    ];

    return (
        <div className="conatiner">
            <Row>
                <Col md={{span:20}} sm={{span:24}} flex={10}>
                    <h1>{t("Refer to earn Free SMS")}</h1>
                    <p>{t("Copy the following link")}</p>

                    <Input disabled={false} value={refLink} style={{width: '60%'}}/>
                    <Button className="gx-mb-0 gx-ml-3" type="primary" onClick={()=>{
                        copyToClipboard(refLink);
                        message.info(('Copied to your Clipboard!'));
                    }}>{t("Copy Link")}</Button>

                    <Row justify="middle" align="center" style={{marginTop: '8%'}}>

                        <Col span={24}>

                            <div style={{borderLeft: '5px solid lightblue', paddingLeft: '15px'}}>
                                <h3 style={{color: 'purple'}}>{t("You get")}</h3>
                                <p className="gx-mt-1">{t("Extra 25% of the SMS your friend buys for the first time")}</p>
                            </div>

                        </Col>
                    </Row>

                    <h1 style={{marginTop: '5%'}}>{t("Referral History")}</h1>
                    <Table
                        className       =   "table-striped-rows custom-fix-pagination"
                        columns         =   {columns}
                        dataSource      =   {tableData}
                        style           =   {{marginTop: '1em'}}
                        pagination      =   {{pageSize: 12}}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default Referrals;
