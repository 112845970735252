import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Input, Form, message} from 'antd';
import { get } from "lodash-es";
import { useSelector } from 'react-redux';
import { decodeString, encodeString } from "../../../../util/misc";
import firebase from 'firebase';
import { useTranslation } from "react-i18next";
const database    = firebase.database();

const { TextArea } = Input;


const EditTemplateModal = ({ isModalVisible, handleOk, handleCancel, onItemSelect ,data }) => {
    const [form]                            = Form.useForm();
    const { db }                            = useSelector(({ auth }) => auth);
    const [items, setItems]                 = useState([]);
    const [loading, setLoading]             = useState(false);
    const { t }                             = useTranslation();


    const onFinish = async (values) => {
        setLoading(true);
        const {msg, description} = values;
        if(msg){
            const data = {
                message : encodeString(msg),
            }

            if (description && description !== ''){
                data['description'] = encodeString(description);
            }

            let dbAddress =  `USERS/${db['UID']}/SMS-Templates/${onItemSelect}`;
            await database.ref(dbAddress).update(data)
            .then(res => {
                message.success('Update successfully!');
                //form.resetFields();
                setLoading(false);
                handleOk();
            })
            .catch(e=>{
                message.error(e)
            })
        }else{
            message.error('Please give some values');
            setLoading(false);
        }
    };


    useEffect (()=>{
      const data        = [];
      let smsTemplates  = get(db, ["SMS-Templates"], {});
      for (let key in smsTemplates) {
        const templates = smsTemplates[key];
        let description = templates.description ?  decodeString(templates.description) : '';
        console.log(key);
        data.push({
          key : key,
          ...templates,
          message: decodeString(templates.message),
          description_: description
        });
      }
      setItems(data);
    },[db]);


    useEffect (()=>{
        let result = items?.filter((el)=>{
            return el.key === onItemSelect
            
        });

        if(result){
            form.setFieldsValue({msg: result[0]?.message});

            if (result[0]?.description){
                form.setFieldsValue({description: decodeString(result[0]?.description)});
            }

        }
        else{
            message.error('Error Message Already deleted');
        }
        
    },[items,onItemSelect,db,form]);


    return (
        <>
            <Modal
                centered
                title={t("Edit SMS Template")}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={500}
                footer={[
                    <Button loading={loading} form='myFormSmsTemplatesEdit' type="primary" htmlType="submit">
                        {t("Save Changes")}
                    </Button>
                ]}
            >
                <div className="content-insideModal-smsSetting-groups">
                    <Form
                        id="myFormSmsTemplatesEdit"
                        initialValues   =   {{ remember: true }}
                        name            =   "basic"
                        form            =   {form}
                        onFinish        =   {onFinish}
                        layout          =   "vertical"
                        className       =   "gx-force-two-cols gx-form-row0"
                    >
                        <Row>
                        
                            <Col span={24}>
                                <Form.Item
                                    name="description"
                                    label='Description'
                                    rules={[{ required: false}]}
                                >
                                <Input
                                    name="description"
                                    placeholder={t("[Optional] Enter a description of this template")}
                                />
                                </Form.Item>

                                <Form.Item
                                    name="msg"
                                    label='Message'
                                    rules={[{ required: true, message: 'Empty message not accepted!' }]}
                                >
                                <TextArea
                                    name="msg"
                                    placeholder={t("Enter Your template text")}
                                    style={{
                                    height: 120,
                                    }}
                                />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

export default EditTemplateModal;