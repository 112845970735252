

import Rollbar from 'rollbar';


type USER = {
    uid: string;
    proff: string;
    email: string;
    name: string;
};
  

class RollbarEdutechs {

    rollbar:any = '';

    init(){
        const _rollbarConfig = {
            accessToken: "8fd2af798e2943d7bbb67f76fb2b12a5",
            captureUncaught: true,
            captureUnhandledRejections: true,
            payload: {
                environment: (window.location.href).includes('localhost') ? "development" : "production"
            }
        };

        const rollbar = new Rollbar(_rollbarConfig);
        
        this.rollbar = rollbar;
        return rollbar
    }

    

    setUserData(user: USER){
        this.rollbar.configure({
            payload: {
              person: {uid:user.uid, proff: user.proff, email: user.email, name: user.name, id:user.uid, username: user.name}
            }
          });
    }


}


export default new RollbarEdutechs()