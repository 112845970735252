import React, { useState, useEffect } from 'react';
import './Auth.css'
import firebase from "firebase";
import { auth } from "../firebase/firebase";
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import SweetAlert from "react-bootstrap-sweetalert";
import IntlMessages from "util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import logo from '../assets/images/logoLight-NoBackground.png'
import {
  hideMessage,
  userPhoneSignInSMSCode, switchLanguage
} from "../appRedux/actions";

import {
  Input, Form, Select, Button, message, Popover
} from 'antd';

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./Topbar/languageData";
import DarkLightMode from '../components/DarkLightMode';


const { Option } = Select;

const database    = firebase.database();


const PhoneLogin = () => {

  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState('vertical');
  const [showCodeAcceptor, setshowCodeAcceptor] = useState(false);
  const [accountType, setAccountType] = useState('Student');
  const [recaptcha, setRecaptcha] = useState(undefined);

  const dispatch = useDispatch();
  const history = useHistory();
  const { showMessage, authUser } = useSelector(({ auth }) => auth);

  const [confirm, setConfirm] = useState(undefined);
  const [phone, setPhone] = useState('');
  const { locale } = useSelector(({ settings }) => settings);
  const [localLoading, setLocalLoading]                   = useState(false);     



  useEffect(() => {

    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }

    //if there is existing user then redirect to his dashboard
    if (authUser !== null) {
        setLocalLoading(true);
        console.log('authUser: ', authUser);
        history.push(`/main/dashboard`);
    }
  });

  const formItemLayout =
    formLayout === 'horizontal'
      ? {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 6 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 14 },
        },
      }
      : null;
  const buttonItemLayout =
    formLayout === 'horizontal'
      ? {
        wrapperCol: {
          span: 14,
          offset: 4,
        },
      }
      : null;

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 100, }}>
        <Option value="+880">+880</Option>
      </Select>
    </Form.Item>
  );

  useEffect(() => {

    setTimeout(() => {

      if (!recaptcha) {
        const reCaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha', {
          callback: function (response) {
            console.log('Not a robot');
          },
        });
        reCaptchaVerifier.render();

        setRecaptcha(reCaptchaVerifier);
      }

    }, 200);


  }, []);

  const onFinish = values => {

    console.log(values);

    if (!recaptcha) {
      message.error('Please complete the Recaptcha');
      return false;
    }

    let phone = `${values.prefix}${values.phone}`;

    auth.signInWithPhoneNumber(phone, recaptcha)
      .then(confirmation => {
        console.log(confirmation);
        if (confirmation) {
          setshowCodeAcceptor(true);
          setConfirm(confirmation);
          setPhone(phone);
        }
        else {
          message.error('Failed to get Confirmation to send SMS Code');
        }
      })
      .catch(e => {
        message.error(`${e.code} - ${e.message}`)
      })
  };

  const onConfirmSms = (v) => {
    console.log(v);
    dispatch(userPhoneSignInSMSCode({
      phone, accountType, confirmation: confirm, code: v
    }));
    setshowCodeAcceptor(false);
    let hide = (message.loading('Please wait while we try to setup your account and log you in..', 0));
    setTimeout(hide, 2500);
  }


  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={() => {
            console.log('Switch to language: ', language);
            dispatch(switchLanguage(language))
          }

          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>);

  return (
    <div className='gx-app-login-wrap'>

      <ul className="gx-header-notifications gx-ml-auto" style={{ position: 'absolute', top: '5px', right: '25px'}}>
        <li className="gx-language">
          <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={languageMenu()}
            trigger="click">
            <span className="gx-pointer gx-flex-row gx-align-items-center">
              <i className={`flag flag-24 flag-${locale.icon}`} />
              <span className="gx-pl-2 gx-language-name">{locale.name}</span>
              <i className="icon icon-chevron-down gx-pl-2" />
            </span>
          </Popover>
        </li>
        <li style={{marginTop:'3px'}}>
          <DarkLightMode />
        </li>
      </ul>

      <div className="gx-app-login-container sign-up-page-wrapper">
        <div className="gx-app-login-main-content sign-up-page-flex">
          <div className="gx-app-logo-content left-column">
            <div className="gx-app-logo">
              <img alt="brand" src={logo} />
            </div>
          </div>

          <div className="gx-app-login-content right-column">
            <div className="right-column-wrapper">
              <div className="welcome-text">
                <h3><IntlMessages id="appModule.welcome" /></h3>
                <p><IntlMessages id="appModule.loginText" /></p>
              </div>

              <Form
                {...formItemLayout}
                layout={formLayout}
                form={form}
                name="register"
                onFinish={onFinish}
                initialValues={{
                  prefix: '+880',
                }}
                scrollToFirstError
              >


                <label className="uk-form-label"><IntlMessages id="appModule.chooseAccountType" /></label>
                <div className="uk-position-relative">

                  <Select className="uk-input uk-select"
                    rules={[{ required: true, message: 'Account Type is needed!' }]}
                    labelInValue
                    defaultValue={accountType}
                    style={{ color: '#999' }}
                    dropdownStyle={{ outline: 'none', border: 'none' }}
                    onChange={(v) => setAccountType(v.value)}
                  >
                    <Select.Option value='Student'>Student</Select.Option>
                    <Select.Option value='Teacher'>Teacher</Select.Option>
                    <Select.Option value='Parent'>Parent</Select.Option>
                    <Select.Option value='Admin'>Coaching Admin</Select.Option>
                  </Select>
                </div>

                <Form.Item
                  name="phone"
                  label={<IntlMessages id="appModule.phoneNumber" />}
                  rules={[{ required: true, message: 'Please input your phone number!' }]}
                >
                  <Input addonBefore={prefixSelector} style={{ width: '100%' }}  placeholder="1700766173" />
                </Form.Item>

                <div id="recaptcha">


                </div>


                <Form.Item {...buttonItemLayout}>
                  <Button loading={localLoading} type="primary" id="getSMSCode" htmlType="submit" block style={{ marginTop: 12 }}>
                  <IntlMessages id="app.userAuth.getSmsCode" />
                  </Button>
                </Form.Item>

              </Form>

              <div className="bottom-link">
                <div className="uk-width-1-1">
                  <p> <NavLink to="/signin" className="login-btn"><FontAwesomeIcon className="icon" icon={faArrowLeft} />&nbsp;<IntlMessages id="appModule.back" /></NavLink></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SweetAlert show={showCodeAcceptor}
        customClass="gx-sweet-alert-top-space"
        input
        required
        inputType="text"
        title={<IntlMessages id="sweetAlerts.enterSMScode" />}
        validationMsg="You must enter the SMS Code!"
        onConfirm={onConfirmSms}
      />
    </div>
  );
};

export default PhoneLogin;