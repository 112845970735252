import React, { useState, useCallback, useEffect } from "react";
import { AutoComplete, message, Avatar, Input } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { decodeString } from "../../../util/misc";
import {get} from "lodash-es";
import UserProfileDrawer from "../UserProfileDrawer";
import EditUserDrawer from "../EditUserDrawer";


//TODO -- UPDATE THIS COMPONENT TO USE USEEFFECT AND FIX THE DEFAULT PROP BUG WHERE IT DOESNT UPDATE IF DEFAULT CHANGED!

//whichever it is pass the values in searchWithinArr as hierachiel array format --> [grade, subject, batchKey];
//the defaultValue if passed in needs to be of string format: `${decodeString(STUDENTNAME)} - #${STUDENTCUSTOMID} - ${STUDENTPHONE}`
const SearchAutoCompleteUsers = ({placeholder, width, defaultValue, onSelect,  openProfileWhenClicked}) => {

    const { db }                                    = useSelector(({ auth }) => auth);

    const [stdProfileVisible, setStdProfileVisible] = useState(false);
    const [stdEditVisible, setStdEditVisible]       = useState(false);
    const [oneStdInfo, setOneStdInfo]               = useState({})
    const [stdUID, setStdUID]                       = useState('');
    const { t }                                     = useTranslation();
    const [autoCompleteJSX, setAutoCompleteJSX]     = useState(<></>);
    const [searchText, setSearchText]                = useState('');

    const [avatar, setAvatar]                       = useState('https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png');


    useEffect(()=>{

        if (db === undefined) return false;

        let searchOptions       = [];
        let allUsersJSON        = get(db, ['AddedUsers'], undefined);

        if (allUsersJSON !== undefined){
            if (searchText){

                //match this search text with ID and with name to keep in the array
                let newSearchText           = (searchText.toLowerCase()).trim();
                for (let oneUserUID in allUsersJSON){

                    let oneUser = allUsersJSON[oneUserUID];

                    if (!oneUser['Name']) continue;

                    let thisStudentName     = (oneUser['Name']).toLowerCase();
                    let thisStudentID       = (oneUser['customID'] ? oneUser['customID'] : 'NO ID').toLowerCase();
                    let thisStudentUID      =  oneUser['UID'];
                    let thisStudentPhone    =  oneUser['Phone'];

                    if (thisStudentName.includes(newSearchText) || thisStudentID.includes(newSearchText) || thisStudentPhone.includes(newSearchText)){
                        searchOptions.push({
                            value   :   `${decodeString(oneUser['Name'])} - #${thisStudentID.toUpperCase()} ${decodeString(thisStudentPhone)}`,
                            uid     :   thisStudentUID
                        }); 
                    }
                }
            }
        }

    
        const onSearchSelect = (data, option) => {
            onSelect(option['uid']);
            setStdUID(option['uid']);

            let thisStudentINFO = get(db, ['AddedUsers',  option['uid']], '');
    
            if (thisStudentINFO === ''){
                message.error('Invalid User. Could not find in your Account!')
                return false;
            }
            else{
                setAvatar(get(thisStudentINFO, ['avatarURL'], 'https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png'));
                if (openProfileWhenClicked === true){
                    setOneStdInfo(thisStudentINFO);
                    setStdProfileVisible(true);
                }
            }
    
        };


        setAutoCompleteJSX(                        
            <AutoComplete
                options                     =   {searchOptions}
                style                       =   {{
                    width: width ? width : 400,
                    paddingLeft: 20,
                    paddingRight: 40,
                }}
                onSelect                    =   {onSearchSelect}
                placeholder             ={placeholder}
                onSearch                    =   {(v)=>setSearchText(v)}
                defaultActiveFirstOption    =   {defaultValue ? true : false}
                defaultValue                =   {defaultValue}
                notFoundContent             =   {t('ID, Name, Phone can be used')}
            />

        )

    }, [defaultValue, t, placeholder, db, searchText, onSelect, openProfileWhenClicked, width]);




    const onCloseStdProfileDrawer = () => {
        setStdProfileVisible(false);
    };

    const showStdEditDrawer = useCallback(()=>{
        
        let thisStudentINFO = get(db, ['AddedUsers', stdUID], '');

        if (thisStudentINFO === ''){
            message.error('Invalid User. Could not find in your Account!')
            return false;
        }
        else{
            setOneStdInfo(thisStudentINFO);
            setStdEditVisible(true);
        }
     
    },[db, stdUID])


    const onCloseStdEditDrawer = () => {
        setStdEditVisible(false);
    };

    return (
        <>
            <div className={`gx-search-bar gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg`}

                style =   {{
                    width: width ? width : 400,
                }}
            
            >
                    
                    <div className="gx-form-group darkmode-search-color-adjust">

                        <span className="gx-search-icon gx-pointer" style={{lineHeight: "40px"}}><i className="icon icon-search"/></span>
                        
                    
                        {autoCompleteJSX}
                        

                        {!avatar.includes('DefaultAvatar') ? 
                        
                        <Avatar
                            size={18}
                            src={decodeString(avatar)}
                            style={{
                                right: 10,
                                top: 7,
                                position: 'absolute'
                            }}
                        />: <></>}
                        
                    </div>
            </div>

            <UserProfileDrawer 
                name                    =   "User Profile" 
                visible                 =   {stdProfileVisible} 
                size                    =   {'large'} 
                onClose                 =   {onCloseStdProfileDrawer} 
                oneStdInfo              =   {oneStdInfo}
                studentUID              =   {stdUID}
                active                  =   {['placeholder','placeholder','placeholder']}
                showStdEditDrawer       =   {showStdEditDrawer}
            />

            <EditUserDrawer 
                name                    =   "Edit User Profile" 
                visible                 =   {stdEditVisible} 
                size                    =   {'large'} 
                onClose                 =   {onCloseStdEditDrawer} 
                oneStdInfo              =   {oneStdInfo}
                studentUID              =   {stdUID}
                active                  =   {['placeholder','placeholder','placeholder']}
                
            />
        
        </>
    )
};
export default SearchAutoCompleteUsers;

