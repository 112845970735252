import React from "react";
import {Card} from "antd";
import PropTypes from "prop-types";

const Widget = ({title, children, styleName, cover, extra, actions, backgroundColor, cardStyle, bodyStyle, onClick}) => {

    if (backgroundColor){
        return (
            <Card onClick={onClick ? ()=>onClick() : ()=>{}} bodyStyle={bodyStyle} style={{...{backgroundColor: backgroundColor}, ...cardStyle?cardStyle:{}}} title={title} actions={actions} cover={cover} className={`gx-card-widget ${styleName}`} extra={extra}>
              {children}
            </Card>
          )

    }
  return (
    <Card onClick={onClick ? ()=>onClick() : ()=>{}} title={title} actions={actions} cover={cover} className={`gx-card-widget ${styleName}`} extra={extra}>
      {children}
    </Card>
  )
};

export default Widget;
Widget.defaultProps = {
  styleName: '',
};

Widget.propTypes = {
  title: PropTypes.node,
  extra: PropTypes.node,
  cover: PropTypes.node,
  actions: PropTypes.node,
  backgroundColor: PropTypes.node, // this line is added Extra for Widget Error (Shahed) i will remove it if any error occurs in js File. 
  children: PropTypes.node.isRequired,
  cardStyle: PropTypes.node,
  bodyStyle: PropTypes.node,
  onClick: PropTypes.node,
};
