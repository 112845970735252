import React, { useState, useEffect } from 'react'
import {Image, Button, Result, message, Progress, Row, Col} from "antd";
import IntlMessages from "util/IntlMessages";
import { decodeString, getUrlVars } from '../../../util/misc';
import firebase from "firebase";
import {get} from "lodash-es";
import EdutechCloudServer from '../../../EdutechCloudServer';
import axios from "axios";
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { Buffer } from "buffer";

const database    = firebase.database();


const TakePicture = () => {

    const [picture, setPicture]                 = useState('');
    const [cameraSwitch, setCameraSwitch]       = useState(FACING_MODES.USER);
    const [studentUID, setStudentUID]           = useState(undefined);
    const [studentName, setStudentName]         = useState('Student-Name');
    const [studentID, setStudentID]             = useState('ID');

    const [showProgress, setShowProgress]       = useState(false);
    const [progressPercent, setProgressPercent] = useState(0);

    const [success, setSuccess]                 = useState(false);

    const [loading, setLoading]                 = useState(true);


    const uploadPicture = async (localURL) => {

        if (localURL === '' || studentUID === undefined){
            message.error('Invalid details, cannot upload photo..');
            return false;
        }

        let fixedbase64         = localURL.replace('data:image/jpeg;base64,', '');
        let bufferObj           = Buffer.from(fixedbase64, "base64");

        database.ref(`USERS/${studentUID}`).once('value')
            .then(snapshot=>{

                if (!snapshot.exists()){
                    message.error('Invalid user UID, cannot find in database..');
                    return false;
                }

                let studentJSON                 = snapshot.val();
                let acceptedBatches             = get(studentJSON, ['AcceptedByOwner'], {});

                EdutechCloudServer.postRequest('upload-avatar', {}, async (res) => {
                    if (res.msg === 'success'){

                        setShowProgress(true);
                        setProgressPercent(0);
        
                        let uploadUrl                           = res.uploadUrl;
                        let uploadAuthorizationToken            = res.uploadAuthorizationToken;
                        let filename                            = `${studentUID}-avatar.png`;
                    
                        await axios.post( uploadUrl, bufferObj, {
                        withCredentials: true,
                        headers: {
                            Authorization: uploadAuthorizationToken,
                            'Content-Type': 'b2/x-auto',
                            'X-Bz-File-Name': `${filename}`,
                            'X-Bz-Content-Sha1': 'do_not_verify',
                        },
                        onUploadProgress: ({ loaded, total }) => {
                            const totalProgress = parseInt((loaded / total) * 100);
                            setProgressPercent(totalProgress);
                        }
                        } )
                    
                        let friendlyURL = `https://f002.backblazeb2.com/file/Edutech-Avatars/${filename}`;

                        console.log('friendlyURL: ', friendlyURL);
        
        
                        database.ref(`USERS/${studentUID}/PublicInfo`).update({
                            'avatarURL': friendlyURL
                        })
                        .then(()=>{

                            //need to update inside all accepted owners..

                            let promiseArr = [];
                            for (let thisTeacherUID in acceptedBatches){

                                let studentData_TeacherSide = {
                                    avatarURL       : friendlyURL,
                                };

                                promiseArr.push(database.ref(`USERS/${thisTeacherUID}/AddedUsers/${studentUID}`).update(studentData_TeacherSide));
                            
                            }

                            Promise.all(promiseArr)
                                .then(()=>{
                                    message.success('Photo Uploaded Successfully!');
                                    setSuccess(true);
                                })

                        })
                    }
        
                    else{
                        message.error('Image Upload Failed. Server could not provide valid upload token');
                    }
                });


            });
    }

    useEffect(() => {
        let urlParams = getUrlVars(window.location.href);

        if (urlParams['key']){

            database.ref(`LivePhotoKeys/${urlParams['key']}`).once('value')
                .then(snapshot=>{

                    if (!snapshot.exists()){
                        setStudentUID(undefined);
                        setStudentID('ID');
                        setStudentName('Student-Name');
                        setLoading(false);
                        return false;
                    }

                    let {suid, name, customID} = snapshot.val();

                    setStudentUID(suid);
                    setStudentID(decodeString(customID));
                    setStudentName(decodeString(name));
                    setLoading(false);
                })

        }
        else{
            setStudentUID(undefined);
            setStudentID('ID');
            setStudentName('User-Name');
            setLoading(false);
        }

    }, []);

    return (
        <>
            {loading === true ? <p>Loading..</p>: 
        
        
            <div className="gx-app-login-container sign-up-page-wrapper">

                {success === true ? 
                            <Result
                                status      =   "success"
                                title       =   {'Photo Uploaded Successfully'}
                                subTitle    =   {<p>
                                    You can close this window now!
                                </p>}
                            >
                            </Result>

                            :

                            <>
                                {studentUID === undefined ? 
                                
                                    <Result
                                        status      =   "error"
                                        title       =   {<IntlMessages id="InvalidLink"/>}
                                        subTitle    =   {<p>
                                            <IntlMessages id="PleaseGenerate"/>
                                        </p>}
                                    >
                    
                                    </Result>
                    
                                    :
                        
                    
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                            <div>
                                                {picture === '' ? (
                                                    <Camera
                                                        onTakePhoto = { (dataUri) => {
                                                            setPicture(dataUri);
                                                        }}
                                                        onCameraError = { (error) => { message.error(error)} }
                                                        idealFacingMode = {cameraSwitch}
                                                        idealResolution = {{width: 340, height: 280}}
                                                        imageType = {IMAGE_TYPES.JPG}
                                                        imageCompression = {0.97}
                                                        isMaxResolution = {false}
                                                        isImageMirror = {false}
                                                        isSilentMode = {false}
                                                        isDisplayStartCameraError = {true}
                                                        isFullscreen = {false}
                                                        sizeFactor = {1}
                                                    />
                                                ) : (
                                                <Image src={picture} style={{marginBottom: '10px'}}/>
                                                )}
                                            </div>

                                            <Row justify='center' align='middle'>
                                                    <Col span={12}>
                                                        <h3>Capture Photo</h3>
                                                        <h3>
                                                            {studentName} - {studentID}
                                                        </h3>
                                                    </Col>
                                                    <Col span={12}>
                                                        { showProgress === true ?                                     
                                        
                                                            <Progress
                                                                strokeColor={{
                                                                    '0%': '#108ee9',
                                                                    '100%': '#87d068',
                                                                }}
                                                                showInfo={true}
                                                                percent={progressPercent}
                                                            /> : 
                                                        
                                                                <Row >
                                                                    <Col span={24}><Button
                                                                    block
                                                                        onClick={(e) => {uploadPicture(picture)}}
                                                                        disabled={picture === '' ? true : false}
                                                                        className="gx-text-white gx-btn-actionGreen"
                                                                    >
                                                                        <IntlMessages id="Submit"/>
                                                                    </Button></Col>
                                                                    <Col span={24}><Button
                                                                    block
                                                                        onClick={(e) => {
                                                                            setPicture('');
                                                                        }}
                                                                        className="gx-text-white gx-btn-danger"
                                                                    >
                                                                        <IntlMessages id="Retry"/>
                                                                    </Button></Col>

                                                                    <Col span={24}><Button
                                                                        block
                                                                        onClick={(e) => {
                                                                            setCameraSwitch(c=>{
                                                                                if (c === FACING_MODES.USER){
                                                                                    return FACING_MODES.ENVIRONMENT
                                                                                }
                                                                                else {
                                                                                    return FACING_MODES.USER
                                                                                }
                                                                            })
                                                                        }}
                                                                        className="btn btn-danger"
                                                                    >
                                                                        <IntlMessages id="SwitchCamera"/>
                                                                    </Button></Col>
                                                                    
                                                                    

                                                                    

                                                                    
                                                                
                                                                </Row>
                                                        }
                                                    </Col>
                                            </Row>


                                            
                                        <div>
                
                                        </div>
                                    </div>
                    
                                }
                            
                            </>

            
                }



            </div>
            }
        
            <div className="gx-mt-3 gx-p-4" >

            <div style={{display: 'flex', gap: '10px', flexDirection : 'row' ,justifyContent: 'center', alignItems: 'center'}}>
                <span className="gx-fs-lg gx-font-weight-normal" style={{color: '#8C8C8C'}}>Powered By</span>
                <Image
                    width={90}
                    src={"/assets/images/logo-white.png"}
                    preview = {false}
                />
            </div>

            </div>
        </>
    )
}

export default TakePicture