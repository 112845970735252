import React, { useState, useEffect, useCallback } from "react";
import { Progress, Col, Row, Button, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { decodeString } from "../../../util/misc";
import {get} from "lodash-es";
import { useTranslation } from "react-i18next";
import { RootState
 } from "../../../NextApp";
import Widget from "../../Widget";

const profileCompletionChecks = [
    "Add Subject",
    "Add Batch",
    "Add Student",
    "Update Avatar",
    "Take Roll Call",
    "Send SMS",
    "Accept Payment",
]

const DashboardTopWidget = () => {

    const {db}                                          =   useSelector( (state: RootState) => state.auth);
    const [name, setName]                               =   useState('Person');
    const [profileCompletion, setProfileCompletion]     =   useState('50');
    const [greet, setGreet]                             =   useState('Morning');
    const { t }                                         =   useTranslation();
    const [completionComponent, setcompletionComponent] =   useState(<></>);
    const [mode, setMode]                               =   useState('SMS');

    const decideMorningEveNight = useCallback((time: number) => {
        
        if (time >= 5 && time < 12){
            return t('Morning');
        }
        else if (time >= 12 && time < 18 ){
            return t('Afternoon');
        }
        else if (time >= 18 && time < 22){
            return t('Evening');
        }
        else {
            return t('Evening');
        }
    
    }, [t]);

    //set the name
    useEffect(()=>{
        let {UserName} = get(db, ['PublicInfo'], 'Person');
        setName(decodeString(UserName));
    }, [db]);

    

    useEffect(() => {

        let currentAppMode = get(db, ['ReactAppMode'], undefined);
        if (currentAppMode === 'SMS_LMS_GATEWAY'){
            setMode('LMS');
        }
        else{
            setMode('SMS');
        }

    }, [db]);

    //set the time and weather -- need to re-run this after every 30 mins
    useEffect(()=>{

        let currentdate             = new Date();
        const time                  = currentdate.getHours();

        setGreet(decideMorningEveNight(time));

        setInterval(()=>{

            let currentdate             = new Date();
            const time                  = currentdate.getHours();
    
            setGreet(decideMorningEveNight(time));

        }, 1800000);

    }, [db,t, decideMorningEveNight]);

    const redirectSubjectsScreenAndPressAddSubject = () => {


    }

    const redirectBatchesScreenAndPressAddBatch = () => {

        
    }

    const redirectStudentsScreenAndPressAddStudent = () => {

        
    }

    const redirectSettingsScreen = () => {

        
    }

    const takeRollCall_ShortCut = () => {

    }

    const sendSMS_Shortcut = () => {
        
    }

    const acceptPayment_Shortcut = () => {
        
    }

    //set the profile completion section
    useEffect(()=>{

        let currentAppMode = get(db, ['ReactAppMode'], undefined);
        if (currentAppMode === 'SMS_LMS_GATEWAY'){

            let appProgress = get(db, ['AppProgress'], {});
            for (let oneCheck of profileCompletionChecks){
    
                if (oneCheck === 'Add Subject'){
                    //check to see if a subject has been added
    
                    let userClass = get(db, ['UserClass'], undefined);
    
                    if (userClass === undefined){
                        setProfileCompletion('2');
                        setcompletionComponent(
                            <Tooltip title="You need to add a Subject">
                                <Button style={{
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    lineHeight: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#8C8C8C',
                                }} className="gx-px-0 gx-mx-0 gx-pl-1 gx-pr-1 gx-ml-1 gx-mb-0" type="text" onClick={()=>{
                                    //take to the subjects screen and press on add subject
                                    redirectSubjectsScreenAndPressAddSubject();
                                }}>
                                   &nbsp;Novice&nbsp;
                                </Button>
                            </Tooltip>
                        )
                        return ()=>{
                        
                        };
                    }
                    
                }
                else if (oneCheck === 'Add Batch'){
                    //check to see if a batch has been added
    
                    if (appProgress['AddBatch'] !== true){
                        setProfileCompletion('20');
                        setcompletionComponent(
                            <Tooltip title="You need to add a Batch">
                                <Button style={{
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    lineHeight: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#8C8C8C',
                                }} className="gx-px-0 gx-mx-0 gx-pl-1 gx-pr-1 gx-ml-1 gx-mb-0" type="text" onClick={()=>{
                                    //take to the batches screen and press on add batch
                                    redirectBatchesScreenAndPressAddBatch();
                                }}>
                                    &nbsp;Novice&nbsp;
                                </Button>
                            </Tooltip>
                        )
                        return ()=>{
                        
                        };
                    }
                }
                else if (oneCheck === 'Add Student'){
                    //check to see if a student has been added
                    if ((get(db, ['VolatileLocalData', 'UniqueStudentAlphabeticalList'], [])).length === 0){
                        setProfileCompletion('30');
                        setcompletionComponent(
                            <Tooltip title="You need to add some students!">
                                <Button style={{
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    lineHeight: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#8C8C8C',
                                }} className="gx-px-0 gx-mx-0 gx-pl-1 gx-pr-1 gx-ml-1 gx-mb-0" type="text" onClick={()=>{
                                    //take to the subjects screen and press on add subject
                                    redirectStudentsScreenAndPressAddStudent();
                                }}>
                                    &nbsp;Intermediate&nbsp;
                                </Button>
                            </Tooltip>
                        )
                        return ()=>{
                        
                        };
                    }
                }
                else if (oneCheck === 'Update Avatar'){
                    if (appProgress['UpdateAvatar'] !== true){
                        setProfileCompletion('40');
                        setcompletionComponent(
                            <Tooltip title="You should update your profile picture">
                                <Button style={{
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    lineHeight: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#8C8C8C',
                                }} className="gx-px-0 gx-mx-0 gx-pl-1 gx-pr-1 gx-ml-1 gx-mb-0" type="text" onClick={()=>{
                                    //take to the subjects screen and press on add subject
                                    redirectSettingsScreen();
                                }}>
                                    &nbsp;Intermediate&nbsp;
                                </Button>
                            </Tooltip>
                        )
                        return ()=>{
                        
                        };
                    }
                }
                else if (oneCheck === 'Take Roll Call'){
                    if (appProgress['TakeRollCall'] !== true){
                        setProfileCompletion('50');
                        setcompletionComponent(
                            <Tooltip title="You should try taking roll calls!">
                                <Button style={{
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    lineHeight: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#8C8C8C',
                                }} className="gx-px-0 gx-mx-0 gx-pl-1 gx-pr-1 gx-ml-1 gx-mb-0" type="text" onClick={()=>{
                                    //take to the subjects screen and press on add subject
                                    takeRollCall_ShortCut();
                                }}>
                                    &nbsp;Intermediate&nbsp;
                                </Button>
                            </Tooltip>
                        )
                        return ()=>{
                        
                        };
                    }
                }
                else if (oneCheck === 'Send SMS'){
                    if (appProgress['SendSMS'] !== true){
                        setProfileCompletion('70');
                        setcompletionComponent(
                            <Tooltip title="Try sending an SMS">
                                <Button style={{
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    lineHeight: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#8C8C8C',
                                }} className="gx-px-0 gx-mx-0 gx-pl-1 gx-pr-1 gx-ml-1 gx-mb-0" type="text" onClick={()=>{
                                    //take to the subjects screen and press on add subject
                                    sendSMS_Shortcut();
                                }}>
                                    &nbsp;Advanced&nbsp;
                                </Button>
                            </Tooltip>
                        )
                        return ()=>{
                        
                        };
                    }
                }
                else if (oneCheck === 'Accept Payment'){
                    if (appProgress['AcceptPayment'] !== true){
                        setProfileCompletion('80');
                        setcompletionComponent(
                            <Tooltip title="Try accepting some payments">
                                <Button style={{
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    lineHeight: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#8C8C8C',
                                }} className="gx-px-0 gx-mx-0 gx-pl-1 gx-pr-1 gx-ml-1 gx-mb-0" type="text" onClick={()=>{
                                    //take to the subjects screen and press on add subject
                                    acceptPayment_Shortcut();
                                }}>
                                    &nbsp;Advanced&nbsp;
                                </Button>
                            </Tooltip>
                        )
                        return ()=>{
                        
                        };
                    }
                }
                else{
                    setProfileCompletion('100');
                    setcompletionComponent(
                        <Tooltip title="Congrats!">
                            <Button style={{
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    lineHeight: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#8C8C8C',
                                }} className="gx-px-0 gx-mx-0 gx-pl-1 gx-pr-1 gx-ml-1 gx-mb-0" type="text" onClick={()=>{
                                
                            }}>
                                &nbsp;Expert&nbsp;
                            </Button>
                        </Tooltip>
                    )
                    return ()=>{
                        
                    };
                }
    
    
            }

        }
        else{
            let appProgress = get(db, ['AppProgress'], {});
            for (let oneCheck of profileCompletionChecks){
                if (oneCheck === 'Update Avatar'){
                    if (appProgress['UpdateAvatar'] !== true){
                        setProfileCompletion('40');
                        setcompletionComponent(
                            <Tooltip title="You should update your profile picture">
                                <Button style={{
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    lineHeight: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#8C8C8C',
                                }} className="gx-px-0 gx-mx-0 gx-pl-1 gx-pr-1 gx-ml-1 gx-mb-0" type="text" onClick={()=>{
                                    //take to the subjects screen and press on add subject
                                    redirectSettingsScreen();
                                }}>
                                    &nbsp;Intermediate&nbsp;
                                </Button>
                            </Tooltip>
                        )
                        return ()=>{
                        
                        };
                    }
                }
                else if (oneCheck === 'Send SMS'){
                    if (appProgress['SendSMS'] !== true){
                        setProfileCompletion('70');
                        setcompletionComponent(
                            <Tooltip title="Try sending an SMS">
                                <Button style={{
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    lineHeight: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#8C8C8C',
                                }} className="gx-px-0 gx-mx-0 gx-pl-1 gx-pr-1 gx-ml-1 gx-mb-0" type="text" onClick={()=>{
                                    //take to the subjects screen and press on add subject
                                    sendSMS_Shortcut();
                                }}>
                                    &nbsp;Advanced&nbsp;
                                </Button>
                            </Tooltip>
                        )
                        return ()=>{
                        
                        };
                    }
                }
                else{
                    setProfileCompletion('100');
                    setcompletionComponent(
                        <Tooltip title="Congrats!">
                            <Button style={{
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    lineHeight: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#8C8C8C',
                                }} className="gx-px-0 gx-mx-0 gx-pl-1 gx-pr-1 gx-ml-1 gx-mb-0" type="text" onClick={()=>{
                                
                            }}>
                                &nbsp;Expert&nbsp;
                            </Button>
                        </Tooltip>
                    )
                    return ()=>{

                    };
                }
    
    
            }

        }


    }, [db]);




    return(
        <Widget styleName="gx-mb-4">

            <Row className="gx-mb-1 gx-dash-search Dashboard-widget" align="middle" justify="center" style={{height: window.innerWidth > 768 ?  '22vh' : '40vh'}}>
                {/* gutter={[32,16]} */}
                <Col sm={{span:24}} md={{ span: 14 }} className="dashboard-top-widget-text-col">
                    
                    <h2 className="gx-mb-1" style={{width: '100%'}}>{t("Good")} {greet} {name}!</h2>
                    <p className="gx-fs-md gx-mt-3" style={{width: '100%'}}>{t("WelcomeDashboardMessage")}</p>

                    <h5 style={{width: '100%'}} className="Profile-progress-text">{t("profileCompleted")} {profileCompletion}% {mode === 'SMS' ? '' : '|'} {mode === 'SMS' ? <></> : completionComponent}


                    </h5>
                    <Progress className="dashboard-top-widget-progress-bar" strokeColor={{
                                    '0%': '#108ee9',
                                    '100%': '#87d068',
                                }} percent={parseInt(profileCompletion)} />
                </Col>

                <Col  sm={{span:24}} md={{span : 10}} style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img 
                        style={{paddingBottom: 0, maxWidth: '220px'}} 
                        className="gx-slider-img dashboard-top-img" 
                        alt="bannerIMG" 
                        src={"/assets/images/dashboard-top-widget-image.png"}/>
                                    
                </Col>
            </Row>

        </Widget>

    );
}
export default DashboardTopWidget