import React from "react";
import { Button, Popover} from "antd";
import { useHistory } from "react-router-dom";
import CustomScrollbars from "../../util/CustomScrollbars";

import {
    THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import { useSelector, useDispatch } from "react-redux";

import { switchLanguage, toggleCollapsedSideNav } from "../../appRedux/actions";
import DarkLightMode from "../../components/DarkLightMode";
import languageData from "../Topbar/languageData";



const LandingSidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
    
    const { navStyle, themeType }           = useSelector(({ settings }) => settings);
    const { authUser }                      = useSelector(({ auth }) => auth);
    const navCollapsed                      = useSelector(({ common }) => common.navCollapsed);

    const dispatch                          = useDispatch();
    const history                           = useHistory();
    const locale                            = useSelector(({ settings }) => settings.locale);

    
    const languageMenu = () => (
        <CustomScrollbars className="gx-popover-lang-scroll">
        <ul className="gx-sub-popover">
            {languageData.map(language =>
            <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
                dispatch(switchLanguage(language))
            }>
                <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
                <span className="gx-language-text">{language.name}</span>
            </li>
            )}
        </ul>
        </CustomScrollbars>);

    return (
        <>
        <div className="gx-p-4 mobile-background-dark" style={{
            display:"flex",
            flexDirection:"column",
            gap:"2.5rem"
        }}>
            <div style={{display: 'flex', justifyContent:  'space-between', alignItems: 'center'}}>
                
                {themeType === THEME_TYPE_LITE ?
                        <img style={{
                            width:"128px"
                        }} alt="logo1" src={("/assets/images/logo-dark.png")}/> :
                        <img style={{
                            width:"128px"
                        }} alt="logo2" src={("/assets/images/logo-light.png")}/>}
                
                    
                <div style={{
                    display:"flex",
                    gap:".5rem"
                }} className="gx-linebar">
                    <span style={{
                    display:"flex",
                    gap:".75rem"
                }} className="gx-header-notifications">
                        <span className="gx-language">
                            <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={languageMenu()}
                                trigger="click">
                                <span className="gx-pointer gx-flex-row gx-align-items-center">
                                    <i className={`flag flag-24 flag-${locale.icon}`} />
                                    {/* <span className="gx-pl-2 gx-language-name">{locale.name}</span>
                                    <i className="icon icon-chevron-down gx-pl-2" /> */}
                                </span>
                            </Popover>
                        </span>
                        <span style={{ marginTop: '3px' }}>
                            <DarkLightMode />
                        </span>
                    </span>
                    <i
                    className={`gx-icon-btn icon icon-${!sidebarCollapsed ? 'menu-fold' : 'menu-unfold'} ${themeType !== THEME_TYPE_LITE ? 'gx-text-white' : ''}`}
                    onClick={() => {
                        dispatch(toggleCollapsedSideNav(!navCollapsed));
                    }}
                    />
                    
                </div>

            </div>

                <div>
                        <Button style={{
                            width:"100%"
                        }} onClick={() => history.push('/signin')} className="ant-btn ant-btn-default  btn-modifier gx-text-white"  type='primary'  >
                            Login
                        </Button>
                </div>
        </div>

            


        </>
    );
};

export default React.memo(LandingSidebarContent);