


import EdutechCloudServer from "../EdutechCloudServer";
import {get} from "lodash-es"
import axios from 'axios'

class OnlinePay {


    initiate({payloadKEY, callback, db}) {

        axios.get('https://geolocation-db.com/json/')
            .then(res=>{

                if (res.status !== 200){
                    alert('Please disable any adblockers and try again!');
                    return false;
                }
                
                let ipAddress_USER              = res.data.IPv4;

                let customerName                = get(db,   ['PublicInfo', 'UserName'], 'CNAME');
                let customerUID                 = get(db,   ['UID'], 'NULL');
                let customerProffession         = get(db,   ['PublicInfo', 'UserProffession'], 'NULL');
                let customerEmail               = get(db,   ['PublicInfo', 'UserEmail'], 'NULL');
                let customerLocation            = get(db,   ['PublicInfo', 'UserLocation'], 'NULL');
                let customerPhone               = get(db,   ['PublicInfo', 'UserPhone'], 'NULL');
                let customerIP                  = ipAddress_USER; 
        
                let dataPayloadKEY              = payloadKEY;
        
                let t                           = new Date();
                let timeInMilliSeconds          = t.getTime();
        
                //add 6 hours since server is ahead
                timeInMilliSeconds = 21600000 + timeInMilliSeconds;
        
                EdutechCloudServer.call('api-ssl-v2/ssl_get_V2-parameters', {
                    CustomerName            : customerName,
                    CustomerProffession     : customerProffession,
                    CustomerUID             : customerUID,
                    CustomerEmail           : customerEmail,
                    CustomerLocation        : customerLocation,
                    CustomerPhone           : customerPhone,
                    Time                    : timeInMilliSeconds,
                    payloadKey              : dataPayloadKEY,
                    CustomerIP              : customerIP
                }, (response) => {
                    if (response){
                        console.log(JSON.parse(response.msg));
                        callback(JSON.parse(response.msg));
                    }
                });
            })
            .catch(e=>{
                console.log(e);
            })
    }

    initiateBkash({amount, callback, db}) {

        let customerUID                 = get(db,   ['UID'], 'NULL');
        let customerEmail               = get(db,   ['PublicInfo', 'UserEmail'], 'NULL');
        
        let t                           = new Date();
        let timeInMilliSeconds          = t.getTime();

        //add 6 hours since server is ahead
        timeInMilliSeconds = 21600000 + timeInMilliSeconds;

        EdutechCloudServer.call('create-bkash-payment-request', {
            uid: customerUID,
            email: customerEmail,
            time: timeInMilliSeconds,
            amount: amount
        }, (response) => {

            console.log(response);
            if (response){
                console.log(JSON.parse(response.msg));
                callback(JSON.parse(response.msg));
            }
        });

    }

}


export default new OnlinePay()