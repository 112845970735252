
import { Col, Row, Button } from "antd";
import { useTranslation } from "react-i18next";
import Widget from "../../Widget";
import { useHistory } from "react-router-dom";
import SendSMSModal from "../../SendSMSModal";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState
} from "../../../NextApp";
import ButtonWidget from "../../ButtonWidget";
import Email from "../../../api/Email";
import { sms, paymentSummary, attendanceSummary, rollCall, settings, acceptPayment2 } from "../../../assets/dashboardIcons";

const DashboardReferralCard = ({styleName}) => {

    const { t }                                         = useTranslation();
    const history                                       = useHistory();
    const [isSmsModalVisible, setIsSmsModalVisible]     = useState(false);
    return(
            
        <Widget>

            <Row style={{ height: '22vh' }} align="middle" justify="center">

                <Col md={{ span: 22 }} xs={{ span: 22 }} style={{ height: '70%' }}>

                    <ButtonWidget
                        title={'Send SMS'}
                        icon={sms}
                        onClick={() => setIsSmsModalVisible(true)}
                        color={"#9193D1"}
                        buttonStyle={{ marginBottom: 0, height: '100%' }}
                        bodyStyle={{ height: '100%' }}
                    />

                </Col>

            </Row>

            <SendSMSModal
                active={[]}
                isModalVisible={isSmsModalVisible}
                setIsModalVisible={setIsSmsModalVisible}
            />

        </Widget>

    );
}
export default DashboardReferralCard