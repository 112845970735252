import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import logo from '../../../assets/images/logoLight-NoBackground.png'
import { devLoginPassword } from "../../../business_config";

import {
  hideMessage,
  showAuthLoader,
  userSignIn,
  switchLanguage
} from "../../../appRedux/actions";

import firebase from "firebase";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "../../../components/CircularProgress";
import { MailOutlined, LockFilled } from "@ant-design/icons";
import CustomScrollbars from "util/CustomScrollbars";
import languageData from "../../Topbar/languageData";
import DarkLightMode from "../../../components/DarkLightMode";


const DevLogin = () => {

    const dispatch                                          = useDispatch();
    const { loader, alertMessage, showMessage, authUser }   = useSelector(({ auth }) => auth);
    const history                                           = useHistory();
    const { locale}                                         = useSelector(({ settings }) => settings);

    const [localLoading, setLocalLoading]                   = useState(false);     

    useEffect(() => {

        if (showMessage) {
            setTimeout(() => {
                dispatch(hideMessage());
            }, 100);
        }

        //if there is existing user then redirect to his dashboard
        if (authUser !== null) {
            setLocalLoading(true);
            history.push(`/main/dashboard`);
        }
        
    });


    const onFinishFailed = errorInfo => {
    };

    const onFinish = values => {
        console.log('On finish form values: ', values);
        if (values.password === devLoginPassword){
            values['TrueByPass'] = true;
            dispatch(showAuthLoader());
            dispatch(userSignIn(values));
        }
        else{
            message.error('Invalid Credentials..');
        }
    };

    const languageMenu = () => (
        <CustomScrollbars className="gx-popover-lang-scroll">
        <ul className="gx-sub-popover">
            {languageData.map(language =>
            <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={() => {
                console.log('Switch to language: ', language);
                dispatch(switchLanguage(language))
            }

            }>
                <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
                <span className="gx-language-text">{language.name}</span>
            </li>
            )}
        </ul>
        </CustomScrollbars>);

    return (
        <div className="gx-app-login-wrap">

            <ul className="gx-header-notifications gx-ml-auto" style={{ position: 'absolute', top: '5px', right: '25px' }}>
                <li className="gx-language">
                <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={languageMenu()}
                    trigger="click">
                    <span className="gx-pointer gx-flex-row gx-align-items-center">
                    <i className={`flag flag-24 flag-${locale.icon}`} />
                    <span className="gx-pl-2 gx-language-name">{locale.name}</span>
                    <i className="icon icon-chevron-down gx-pl-2" />
                    </span>
                </Popover>
                </li>
                <li style={{marginTop:'3px'}}>
                <DarkLightMode />
                </li>
            </ul>

            <div className="gx-app-login-container sign-up-page-wrapper">
                <div className="gx-app-login-main-content sign-up-page-flex">
                <div className="gx-app-logo-content left-column">
                    <Link to="/welcome" style={{textAlign: 'center'}}> 
                        <div className="gx-app-logo">
                        <img alt="brand" src={logo} />
                        </div>
                    </Link>
                </div>
                <div className="gx-app-login-content right-column">
                    <div className="right-column-wrapper">
                    <div className="welcome-text">
                        <h3>For Developers Only!</h3>
                    </div>
                    <Form
                        initialValues={{ remember: true }}
                        name="basic"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        className="gx-signin-form gx-form-row0">


                        <label className="uk-form-label"> <IntlMessages id="appModule.email" /></label>
                        <Form.Item
                        initialValue=""
                        rules={[{ required: true, message: 'Please enter a valid email!' }]} name="email">
                        <Input type="email" placeholder="example@gmail.com" prefix={<MailOutlined />} />
                        </Form.Item>



                        <label className="uk-form-label"> <IntlMessages id="appModule.password" /></label>
                        <Form.Item className="uk-position-relative"
                        initialValue=""
                        rules={[{ required: true, message: 'Please enter your Password!' }]} name="password" >
                        <Input.Password type="password" placeholder="Password" prefix={<LockFilled />} />
                        </Form.Item>


                        <Form.Item>
                        <Button loading={localLoading} className="gx-mb-0 ant-btn-primary btn-block" htmlType="submit">
                            &nbsp; <IntlMessages id="app.userAuth.signIn" />
                        </Button>
                        {/* <Button onClick={() => {
                            dispatch(showAuthLoader());
                            dispatch(userGoogleSignIn('SignIn', 'NotNeeded'));
                        }} className="gx-mb-0 ant-btn-mid-danger btn-block">
                            <GoogleOutlined /> <IntlMessages id="app.userAuth.signInWithGoogle" />

                        </Button>

                        <NavLink to="/phone-login">
                            <button className="btn button-secondary btn-block" ><FontAwesomeIcon className="icon" icon={faPhone} />&nbsp;<IntlMessages id="app.userAuth.signInWithPhone" /></button>
                        </NavLink> */}

                        </Form.Item>


                    </Form>
                    </div>
                </div>

                {loader ?
                    <div className="gx-loader-view">
                    <CircularProgress />
                    </div> : null}
                {showMessage ?
                    message.error(alertMessage.toString()) : null}
                </div>
            </div>
        </div>
    );
};

export default DevLogin;
