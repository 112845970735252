import { useState, useEffect } from 'react';
import { Drawer, Button, Collapse, Divider, Typography, Card, Switch, Select } from 'antd';
import {CloseOutlined} from '@ant-design/icons'
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import firebase from 'firebase';
import PanelHead from '../../../../components/PanelHead';
import {get} from "lodash-es";
import EditAutoSMSCheckInParent from './EditAutoSMSCheckInParent';
import EditAutoSMSAbsent from './EditAutoSMSAbsent';
import EditNotice from './EditNotice';
import UpdateDiscordWebhook from './UpdateDiscordWebhook';
import UpdateSlackWebhook from './UpdateSlackWebhook';

const database      = firebase.database();
const { Option }    = Select;

const SettingsDrawer = ({ size, onClose, visible }) => {

    const { Panel }                                                     = Collapse;
    const { t }                                                         = useTranslation();

    const { db }                                                        = useSelector(({ auth }) => auth);
    const [parent1CheckInAutoSMS, setparent1CheckInAutoSMS]             =   useState(false);
    const [showEditMessageSMSCheckIn, setShowEditMessageSMSCheckIn]     =   useState(false);
    const [checkInSendTo, setCheckInSendTo]                             =   useState('Student,Parent1');


    const [parent1AbsentAutoSMS, setparent1AbsentAutoSMS]               =   useState(false);
    const [showEditMessageSMSAbsent, setShowEditMessageSMSAbsent]       =   useState(false);
    const [AbsentSendTo, setAbsentSendTo]                               =   useState('Student,Parent1');

    const [managePlaySound, setManagePlaySound]                         =   useState(false);

    const [defaultSelection, setDefaultSelection]                       =   useState('ALL');

    const [noticeBoardStatus, setNoticeBoardStatus]                     =   useState(false);
    const [showEditNoticeBoard, setEditNoticeBoard]                     =   useState(false);

    const [showDiscordModal, setShowDiscordModal]                       =   useState(false);
    const [discordAutoNotifyStatus, setDiscordAutoNotifyStatus]         =   useState(false);

    const [showSlackModal, setShowSlackModal]                           =   useState(false);
    const [slackAutoNotifyStatus, setSlackAutoNotifyStatus]             =   useState(false);


    useEffect(() => {
        if (db){
            let currentState                = get(db, ['AccessControlSettings', 'AutoSendSMS', 'Parent1CheckInAutoSMS'], false);
            let toSendToCheckInSMS          = get(db, ['AccessControlSettings', 'AutoSendSMS', 'checkInSendTo'], 'Student,Parent1');

            let currentStateAbsent          = get(db, ['AccessControlSettings', 'AutoSendSMS', 'parent1AbsentAutoSMS'], false);
            let toSendToAbsentSMS           = get(db, ['AccessControlSettings', 'AutoSendSMS', 'AbsentSendTo'], 'Student,Parent1');

            let managePlaySoundBOOL         = get(db, ['AccessControlSettings', 'managePlaySound', 'status'], false);
            let currentDefaultSelection     = get(db, ['AccessControlSettings', 'MultipleClassroomDefaultSelection'], 'ALL');

            let showNoticeBoard             = get(db, ['AccessControlSettings', 'manageNoticeBoard', 'status'], false);

            let discordStatus               = get(db, ['AccessControlSettings', 'discordAutoNotify', 'status'], false);
            let slackStatus                 = get(db, ['AccessControlSettings', 'SlackAutoNotify', 'status'], false);

            setparent1CheckInAutoSMS(currentState);
            setparent1AbsentAutoSMS(currentStateAbsent);

            setCheckInSendTo(toSendToCheckInSMS);
            setAbsentSendTo(toSendToAbsentSMS);
            setManagePlaySound(managePlaySoundBOOL);
            setDefaultSelection(currentDefaultSelection);

            setNoticeBoardStatus(showNoticeBoard);
            setDiscordAutoNotifyStatus(discordStatus);
            setSlackAutoNotifyStatus(slackStatus);
        }
    }, [db, onClose])

    return (
        <>
            <Drawer
                size        =   {size}
                placement   =   "right"
                closable    =   {false}
                onClose     =   {onClose}
                visible     =   {visible}
            >
                <div className="drawer-content">
                    <div 
                        className="drawer-head" 
                        style={{ display: 'flex', justifyContent: 'space-between', padding: '30px 20px 5px 30px' }}
                    >
                        <div 
                            className="drawer-head-left" 
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}
                        >
                        
                            <CloseOutlined 
                                onClick={onClose} 
                                className="gx-mr-3" 
                                style={{ fontSize: '22px', cursor: 'pointer' }} 
                            />
                           
                           <div className="drawer-title">
                                <Typography.Title className='gx-mb-0' level={4}>Access Control Settings</Typography.Title>
                                
                            </div>
                        </div>
                        <div className="drawer-head-right" style={{display:'flex', alignItems:'center'}}>



                        </div>
                    </div>

                    <Divider />

                    <div 
                        className="drawer-body" 
                        style={{padding: '0px 25px'}}
                    >
                        <Collapse defaultActiveKey={['1', '2']} ghost>
                            <Panel header={<PanelHead title={'Notification'} isLineAfterText={false} titleLevel={5}/>} key="1" style={{fontSize: '18px', margin: '0px 20px', marginTop: '15px'}} >
                                
                                <Card className=" gx-mb-3 gx-border-0 gx-pl-2 gx-pr-2" bodyStyle={{display: 'flex',
                                                                                                    alignItems: 'center',
                                                                                                    justifyContent: 'space-between',
                                                                                                    width: '100%',
                                                                                                    padding: '2%',
                                                                                                    border: 'none !important',
                                                                                                    borderRadius: '6px'}}>
                                    <div className="gx-card-left-contents">
                                        <h3 className="gx-mb-0">Play Sound</h3>
                                        <span style={{width: '50%'}}>This will play a sound on QR scan success/fail</span>
                                    </div>

                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '40%'}}>

                                        <Switch
                                            name=           "managePlaySound"
                                            onChange        ={(checked, event) => {
                                                database.ref(`USERS/${db['UID']}/AccessControlSettings/managePlaySound`).update({
                                                    status: checked
                                                })
                                            }}
                                            checked             ={managePlaySound}
                                            size                ={'large'}
                                            style               ={{width: '100%', borderRadius: '5px', marginBottom: '2.5%'}}
                                            checkedChildren     ="ON" 
                                            unCheckedChildren   ="OFF"
                                        />


                                    </div>

                                </Card>

                                <Card className=" gx-mb-3 gx-border-0 gx-pl-2 gx-pr-2" bodyStyle={{display: 'flex',
                                                                                                    alignItems: 'center',
                                                                                                    justifyContent: 'space-between',
                                                                                                    width: '100%',
                                                                                                    padding: '2%',
                                                                                                    border: 'none !important',
                                                                                                    borderRadius: '6px'}}>
                                    <div className="gx-card-left-contents">
                                        <h3 className="gx-mb-0">Notice-Board</h3>
                                        <span style={{width: '50%'}}>Overwrites terminal to only show this message</span>
                                    </div>

                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '40%'}}>

                                        <Switch
                                            name=           "manageNoticeAutoCheckIn"
                                            onChange        ={(checked, event) => {
                                                database.ref(`USERS/${db['UID']}/AccessControlSettings/manageNoticeBoard`).update({
                                                    status: checked
                                                })
                                            }}
                                            checked             ={noticeBoardStatus}
                                            size                ={'large'}
                                            style               ={{width: '100%', borderRadius: '5px', marginBottom: '2.5%'}}
                                            checkedChildren     ="ON" 
                                            unCheckedChildren   ="OFF"
                                        />


                                        <Button className='gx-mt-2' block type="primary" onClick={()=>setEditNoticeBoard(true)}>Edit Notice</Button>

                                    </div>

                                </Card>

                                <Card className=" gx-mb-3 gx-border-0 gx-pl-2 gx-pr-2" bodyStyle={{display: 'flex',
                                                                                                    alignItems: 'center',
                                                                                                    justifyContent: 'space-between',
                                                                                                    width: '100%',
                                                                                                    padding: '2%',
                                                                                                    border: 'none !important',
                                                                                                    borderRadius: '6px'}}>
                                    <div className="gx-card-left-contents">
                                        <h3 className="gx-mb-0">Discord Web-Hook</h3>
                                        <span style={{width: '50%'}}>Notifies you in chosen discord channel</span>
                                    </div>

                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '40%'}}>

                                        <Switch
                                            name=           "discordAutoNotify"
                                            onChange        ={(checked, event) => {
                                                database.ref(`USERS/${db['UID']}/AccessControlSettings/discordAutoNotify`).update({
                                                    status: checked
                                                })
                                            }}
                                            checked             ={discordAutoNotifyStatus}
                                            size                ={'large'}
                                            style               ={{width: '100%', borderRadius: '5px', marginBottom: '2.5%'}}
                                            checkedChildren     ="ON" 
                                            unCheckedChildren   ="OFF"
                                        />


                                        <Button className='gx-mt-2' block type="primary" onClick={()=>setShowDiscordModal(true)}>Update Web-Hook</Button>

                                    </div>

                                </Card>

                                <Card className=" gx-mb-3 gx-border-0 gx-pl-2 gx-pr-2" bodyStyle={{display: 'flex',
                                                                                                    alignItems: 'center',
                                                                                                    justifyContent: 'space-between',
                                                                                                    width: '100%',
                                                                                                    padding: '2%',
                                                                                                    border: 'none !important',
                                                                                                    borderRadius: '6px'}}>
                                    <div className="gx-card-left-contents">
                                        <h3 className="gx-mb-0">Slack Web-Hook</h3>
                                        <span style={{width: '50%'}}>Notifies you in chosen slack channel</span>
                                    </div>

                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '40%'}}>

                                        <Switch
                                            name=           "SlackAutoNotify"
                                            onChange        ={(checked, event) => {
                                                database.ref(`USERS/${db['UID']}/AccessControlSettings/SlackAutoNotify`).update({
                                                    status: checked
                                                })
                                            }}
                                            checked             ={slackAutoNotifyStatus}
                                            size                ={'large'}
                                            style               ={{width: '100%', borderRadius: '5px', marginBottom: '2.5%'}}
                                            checkedChildren     ="ON" 
                                            unCheckedChildren   ="OFF"
                                        />


                                        <Button className='gx-mt-2' block type="primary" onClick={()=>setShowSlackModal(true)}>Update Web-Hook</Button>

                                    </div>

                                </Card>
                            </Panel>

                        </Collapse>
                    </div>
                </div>
            </Drawer>

            <EditAutoSMSCheckInParent 
                isModalVisible = {showEditMessageSMSCheckIn} 
                handleClose    =  {()=>setShowEditMessageSMSCheckIn(false)}
            />

            <EditAutoSMSAbsent 
                isModalVisible = {showEditMessageSMSAbsent} 
                handleClose    =  {()=>setShowEditMessageSMSAbsent(false)}
            />

            <EditNotice 
                isModalVisible = {showEditNoticeBoard} 
                handleClose    =  {()=>setEditNoticeBoard(false)}
            />

            <UpdateDiscordWebhook 
                isModalVisible = {showDiscordModal} 
                handleClose    =  {()=>setShowDiscordModal(false)}
            />

            <UpdateSlackWebhook 
                isModalVisible = {showSlackModal} 
                handleClose    =  {()=>setShowSlackModal(false)}
            />
        </>
    );
};

export default SettingsDrawer;