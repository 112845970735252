import { useState, useEffect, useRef, useCallback } from "react";
import { Modal, Button, Row, Col, Input,notification, Card,Alert, Tag, Dropdown, Menu, DatePicker, message, Tooltip, Checkbox } from "antd";
import AddFromFile from "./AddFromFile";
import AddGroups from "./AddGroups";
import Templates from "./Templates";
import { Footer } from 'antd/lib/layout/layout';
import { CheckCircleOutlined, CloseCircleOutlined, FacebookFilled, FieldTimeOutlined, FireOutlined, LoadingOutlined, MailOutlined, PlusOutlined } from "@ant-design/icons";
import SMS from "../../api/SMS";
import { useSelector } from "react-redux";
import {  NumbersToLetters, decodeString, encodeString, isValidPhoneNumber, returnCommaSeparatedNumbers_AsArr, returnSpaceSeparatedNumbers_AsArr, getRandomInt, totalCharactersUSED, fixBangladeshPhoneNumbers, truncate, containsEmojis } from "../../util/misc";
import {database} from "../../firebase/firebase";
import {  get, isEmpty, set, unset } from "lodash-es";
import { useTranslation } from "react-i18next";
import SentSMSStatusModalTable from "./SentSMSStatusModalTable";
import Mixpanel from "../../api/Mixpanel";
import ExcelQuery from "../../api/ExcelQuery";
import DynamicSentSMSStatusModalTable from "./DynamicSentSMSStatusModalTable";
import EdutechCloudServer from "../../EdutechCloudServer";
import Facebook from "../../api/Facebook";
import { DEFAULT_SMS_RATE_NON_MASKING } from "../../business_config";

const ButtonGroup       = Button.Group;


//defaultRecepients = [{studentPhone: [number1, number2], tagName}]
//defaultDynamicMessageWithRecepients = {numbers (array of phone numbers), title (any title of this dynamic message), messsage (actual SMS text), excelRowsData (row by row data of table must inlcude headers)}
const SendSMSModal = ({isModalVisible, setIsModalVisible, defaultRecepients, defaultMessage, defaultDynamicMessageWithRecepients}) => {

    const [textAreaValue, setTextAreaValue]             =    useState("");
    const [recepientInputField, setRecepientInputField] =    useState("");
    const [recepients, setRecepients]                   =    useState([]);
    const { t }                                         =    useTranslation();

    const [popoverVisibility, setPopoverVisibility]     =    useState(false);
    const { db }                                        =    useSelector(({ auth }) => auth);

    const [isResponseModal, setIsResponseModal]         =    useState(false);
    const [requestID, setRequestID]                     =    useState(undefined);
    const [sentTimeStamp, setSentTimeStamp]             =    useState(0);

    const [isDResponseModal, setIsDResponseModal]       =    useState(false);
    const [dynamicID, setDynamicID]                     =    useState(undefined);

    const [recepientTagsJSX, setRecepientTagsJSX]       =    useState([]);

    const [mode, setMode]                               =   useState('SMS');
    const [smsQuotaAvailable, setSmsQuotaAvailable]     =   useState(0);
    const [count, setCount]                             =   useState(0);

    const [previewModal, setPreviewModal]               =   useState(false);
    const [previewJSX, setPreviewJSX]                   =   useState([]);

    const [excelFileData, setExcelFileData]             =   useState({}); //this will keep a unique id of any excel file loaded and a 2d array representation of all of its data
    const [totalExcelLoaded, setTotalExcelLoaded]       =   useState(1);

    const [dynamicImportExcel, setDynamicImportExcel]   =   useState(false);
    const [dynamicImportJSX, setDynamicImportJSX]       =   useState([]);
    const [importBTN_ExcelJSX, setImportBTN_ExcelJSX]   =   useState([]);

    const [cursorPosition, setCursorPosition]           =   useState(undefined);

    const [forceRefreshDynamicExcelData, setforceRefreshDynamicExcelData]   = useState(0);
    const inputTextArea                                 =  useRef();
    const [loading, setLoading]                         =  useState(false);
    const [removeCharCount, setRemoveCharCount]         =  useState(false);

    const [previewSMSTotalValid,setPreviewSMSTotalValid]=  useState(0);
    const [previewSMSTotalErr,setPreviewSMSTotalErr]    =  useState(0);
    const [totalRecPreview, setTotalPreviewRec]         =  useState(0);
    const [tooltipTotalInvalid, setTooltipTotalInvalid] =  useState('');
    const [checkedBatches, setCheckedBatches]           = useState([]);

    const [fbGroupModal, setFbGroupModal]               = useState(false);
    const [chosenFacebookGroups, setChosenFacebookGroups]   =  useState([]);
    const [fbGroupOptions, setFbGroupOptions]               =  useState([]);

    const [fbAccessToken, setfbAccessToken]                 =  useState('');
    const [showExportFBMODAL, setShowExportFBMODAL]         =  useState('');


    //this will remove a recpient tag by its index
    const removeRecepientByIndex = useCallback((index, removedData)=>{
        if (removedData['id']){
            if (recepients.length === 1){
                setTotalExcelLoaded(1);
            }

            setExcelFileData(currentExcelFileData => {
                let newFileDataKEY      = removedData['id'];
                let newFinalJSONData    =   {};
                for (let oneKey in currentExcelFileData){
                    if (!oneKey.includes(newFileDataKEY)){
                        newFinalJSONData[oneKey] = currentExcelFileData[oneKey];
                    }
                }
                return newFinalJSONData;
            })
        }

        setRecepients(currentRecepients => {
            let newCurrentRecepients = [...currentRecepients];
            newCurrentRecepients.splice(index,1);
            return newCurrentRecepients
        });

        setforceRefreshDynamicExcelData(x=>x+1);


    }, [recepients]); 

    useEffect(() => {

        let currentAppMode = get(db, ['ReactAppMode'], undefined);
        if (currentAppMode === 'SMS_LMS_GATEWAY'){
            setMode('LMS');
        }
        else{
            setMode('SMS');
        }

        //read from db and setup SMS info here
        let smsQUOTA    = get(db, ['SMS-Quota'], {});
        let {v}         = smsQUOTA;
        if (!v) v       = 0;

        setSmsQuotaAvailable(v);
    }, [db]);

    // On-Mount check to see if default numbers have been passed in..
    useEffect(()=>{
        if (defaultDynamicMessageWithRecepients){

            let {numbers, title, messsage, excelRowsData} = defaultDynamicMessageWithRecepients;

            setRecepients([{
                tagName: `Default: ${title}`,
                numbers,
                tooltip: numbers.join(' '),
                id: 'Default'
            }]);

            setTotalExcelLoaded(x=>x+1);
            setTextAreaValue(messsage);

            let excelOBJ = {};
            set(excelOBJ, ['id'], 'Default');
            set(excelOBJ, ['file'], excelRowsData);

            setExcelFileData(currentExcelFileData => {
                let newFileDataKEY  = 'Default';
                let newJSONDATA     =   currentExcelFileData
                set(newJSONDATA, [newFileDataKEY], excelOBJ);
                return newJSONDATA;
            });

            setforceRefreshDynamicExcelData(x=>x+1);
        }
        else{
            if(defaultRecepients){
                if (defaultRecepients.length > 0){
    
                    //do a validation check to make sure all default recepients passed in are valid phone numbers
                    let validatedDefaultRecepients = [];
    
                    for (let oneDefault of defaultRecepients){
                        let validatedArr    =   [];
                        for (let onePhone of (oneDefault.studentPhone)){
                            onePhone = fixBangladeshPhoneNumbers(onePhone);
                            if (isValidPhoneNumber(onePhone)) validatedArr.push(onePhone);
                        }
                        validatedDefaultRecepients.push({
                            tagName   : `${oneDefault.tagName}`, 
                            numbers     : validatedArr
                        })
                    }
    
                    setRecepients([...validatedDefaultRecepients]);
                }
            }
    
            if (defaultMessage){
                setTextAreaValue(defaultMessage);
            }
        }


        if (isModalVisible === true){
            //record to mixpanel
            Mixpanel.record({eventName: 'SMS-WINDOW-OPENED', eventProperties: {}});
        }
    },[defaultRecepients, isModalVisible, defaultMessage, defaultDynamicMessageWithRecepients]); 

    //setup and deploy the recepients tags JSX
    useEffect(()=>{

        let outputJSX = [];

        for (let index = 0; index < recepients.length; index++) {

            let oneRecepient = recepients[index];

            if (oneRecepient.tooltip){
                outputJSX.push (
                    <Tooltip title={oneRecepient.tooltip} key={getRandomInt(0,99999)}>
                        <Tag style={{
                            fontSize: "12px"
                        }} className="number-tag" key={index} closable onClose={() => removeRecepientByIndex(index, oneRecepient)}>
                            {oneRecepient.tagName ? decodeString(oneRecepient.tagName) : 'Unknown'}
                        </Tag>
                    </Tooltip>
                );

            }
            else{
                outputJSX.push(
                    <Tag style={{
                        fontSize: "12px"
                    }} className="number-tag" key={getRandomInt(0,99999)} closable onClose={() => removeRecepientByIndex(index, oneRecepient)}>
                        {oneRecepient.tagName ? decodeString(oneRecepient.tagName) : 'Unknown'}
                    </Tag>
                );
            }
            
        }

        setRecepientTagsJSX(outputJSX);
    }, [recepients, removeRecepientByIndex]);

    //controls the excel dynamic cell import value
    useEffect(()=>{

        //set-up the import BTN
        if (isEmpty(excelFileData)){
            setImportBTN_ExcelJSX(<></>);
        }
        else{
            setImportBTN_ExcelJSX( <Button loading={loading} className="gx-btn-warning" style={{position:'absolute', bottom: '1em', right: '13em'}}
                                    onClick={
                                        () => {
                                            setDynamicImportExcel(true);
                                            Mixpanel.record({eventName: 'OPEN-MODAL-IMPORT-COLUMN-EXCEL-FOR-DYNAMIC-SMS', eventProperties: {}});
                                        }
                                    }
                                >
                                        {t("Import Column")} <PlusOutlined />
                                </Button>)
        }

        //setup the content inside the dynamic excel column picker
        let outputJSX = [];

        //need to convert excelFileData to ordered array
        let excelFileDataArr = [];
        for (let oneFile in excelFileData){
            excelFileDataArr.push(excelFileData[oneFile]);
        }


        excelFileDataArr.sort((a,b)=>{
            return a.index - b.index
        })


        for (let oneExcelFile of excelFileDataArr){

            outputJSX.push(
                <h3 className={`gx-mx-2 ${outputJSX.length === 0? '': 'gx-mt-4'}`}>From {decodeString(oneExcelFile['id'])}:</h3>
            )

            if ((oneExcelFile['file']).length === 0 || !oneExcelFile['file']) continue;

            let headerRow = oneExcelFile['file'][0];
            for (let oneHeader of headerRow){

                if (!oneHeader) continue;

                outputJSX.push(
                    <Button block type="dashed" onClick={()=>{

                        let caretPosition           = inputTextArea.current.selectionStart;
                        let craftedExcelQueryLang   = `{${decodeString(oneExcelFile[['id']])}(${NumbersToLetters(headerRow.indexOf(oneHeader))}2++)}`;

                        //take this column and place it inside the text part 
                        setTextAreaValue(currentVal=>{
                            let newVal = currentVal.slice(0, caretPosition) + ' ' + craftedExcelQueryLang + ' ' + currentVal.slice(caretPosition);
                            return newVal
                        });

                        setDynamicImportExcel(false);
                        setTimeout(()=>{
                            inputTextArea.current.focus();
                            inputTextArea.current.selectionStart = caretPosition + parseInt(craftedExcelQueryLang.length) + 2;
                        }, 250);

                        //message.info('Your excel column has been imported. Please check results in preview.')
                        Mixpanel.record({eventName: 'SUCCESSFUL-IMPORTED-COLUMN-EXCEL-FOR-DYNAMIC-SMS', eventProperties: {'QUERY': craftedExcelQueryLang, 'COLUMN': oneHeader}});
                    }}>
                        Import Column {oneHeader} 
                    </Button>
                )

            }
        }

        setDynamicImportJSX(outputJSX);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [excelFileData, forceRefreshDynamicExcelData, cursorPosition]);





    //this will reset the entire SMS modal to default
    const resetModalToDefault = () => {
        setIsModalVisible(false);
        setRecepients([]);
        setTextAreaValue("");
        setExcelFileData({});
        setforceRefreshDynamicExcelData(x=>x+1);
        setTotalExcelLoaded(1);
        setCheckedBatches([]);
        setChosenFacebookGroups([]);
    };

    
    // for Count from text
    useEffect(()=>{

        if (isEmpty(excelFileData)){
            //let english = /^[A-Za-z0-9]*$/; // regular exp for check english
            let bangla  = /[\u0980-\u09FF]/; // for check bangla 
            
            let newArray = textAreaValue.split('');
            
    
            // always setCount 0 so that every time count the array value 
            setCount(0);
            let total = 0;
            for(let i in newArray){
                
                if (bangla.test(newArray[i])){
                    setCount((prevState)=> prevState + 1);
                    total = total + 1;
                }
                else{
                    setCount((prevState)=> prevState + 1);
                    total = total + 1;
                }
                
            }

            setRemoveCharCount(false);
        }
        else{
            setRemoveCharCount(true);
        }
        
    },[textAreaValue, excelFileData]);


    const onChange = (e) => {
        // Custom set cursor position in input text field
        let output = [];
        output.push(e.target.selectionStart);
        output.push(e.target.selectionEnd);
        setCursorPosition(output);
        setTextAreaValue(e.target.value);
    };


    const onChangeInput = (e) => {
        //setTextAreaValue(e.target.value);
        setRecepientInputField(e.target.value)
    };

    const onPressEnter = (v) => {
        //check to see if its just an entry with 14 digits
        let toCheckInput    = (v).trim();

        //check to see if there is comma in this entry
        if(toCheckInput.indexOf(',') !== -1){
            let commaSeparatedNumbers = returnCommaSeparatedNumbers_AsArr(toCheckInput);
            
            if (commaSeparatedNumbers.length === 0){
                message.error(('Phone numbers accepted only! e.g +8801700766174'))
            }
            else{

                let newArrayOfRecepients = [];
                for (let oneNum of commaSeparatedNumbers){

                    oneNum = fixBangladeshPhoneNumbers(oneNum);

                    if (isValidPhoneNumber(oneNum)){
                        newArrayOfRecepients.push({
                            tooltip: `${oneNum}`,
                            tagName: oneNum,
                            numbers: [oneNum]
                        })
                    }
                }

                setRecepients(currentRecepients => {
                    let newCurrentRecepients    = [...currentRecepients];
                    let concatRecepients        = [...newCurrentRecepients, ...newArrayOfRecepients];
                    return concatRecepients
                })
                setRecepientInputField('');
            }


        }
        //check to see if there is space in this entry
        else if(toCheckInput.indexOf(' ') !== -1){
            let spaceSeparatedNumbers = returnSpaceSeparatedNumbers_AsArr(toCheckInput);
            
            if (spaceSeparatedNumbers.length === 0){
                message.error(('Phone numbers accepted only! e.g +8801700766174'))
            }
            else{

                let newArrayOfRecepients = [];
                for (let oneNum of spaceSeparatedNumbers){

                    oneNum = fixBangladeshPhoneNumbers(oneNum);

                    if (isValidPhoneNumber(oneNum)){
                        newArrayOfRecepients.push({
                            tooltip: `${oneNum}`,
                            tagName: oneNum,
                            numbers: [oneNum]
                        })
                    }
                }

                setRecepients(currentRecepients => {
                    let newCurrentRecepients    = [...currentRecepients];
                    let concatRecepients        = [...newCurrentRecepients, ...newArrayOfRecepients];
                    return concatRecepients
                })
                setRecepientInputField('');
            }
        }
        else if (isValidPhoneNumber(fixBangladeshPhoneNumbers(toCheckInput))){
            setRecepients(currentRecepients => {
                let newCurrentRecepients = [...currentRecepients];
                newCurrentRecepients.push({
                    tagName: `${fixBangladeshPhoneNumbers(toCheckInput)}`,
                    numbers: [fixBangladeshPhoneNumbers(toCheckInput)]
                })
                return newCurrentRecepients
            })
            setRecepientInputField('');
        }
        else{
            message.error(('Phone numbers accepted only! (You can enter multiple numbers divided by comma or spaces)'))
        }
    }
    
    const hideModal = () => {
        setPopoverVisibility(false);
    }

    //this will send out the same sms to all supplied phone numbers
    const callCloudFunction_ForGroupSMS_SameMessage = ({allPhone, textAreaValue, rate, callback}) => {

        //check to see if enough quota is available
        let smsNumberPerPerson  =   Math.ceil((totalCharactersUSED(textAreaValue))/134);
        let totalSMSNeeded      =   (parseInt(totalCurrentRecepients()*smsNumberPerPerson));

        if (totalSMSNeeded > smsQuotaAvailable){

            let key = `open${Date.now()}`;
            notification.open({
                message: "Not Enough SMS Quota",
                description: `You do not have enough SMS Quota. You need ${(parseInt(totalCurrentRecepients()*smsNumberPerPerson))} total quota with ${smsNumberPerPerson} SMS needed to be sent per person. Your current quota is ${smsQuotaAvailable}.`,
                duration: 0,
                key: key,
                type: 'warning'
            });
            
            setLoading(false);
            return false;
        }

        setLoading(true);
        // create the comma separated phone numbers
        let uniqueAllPhone  = allPhone.filter((v, i, a) => a.indexOf(v) === i);
        let t = ''
        uniqueAllPhone.forEach((phone) => {

            phone = fixBangladeshPhoneNumbers(phone);

            if (isValidPhoneNumber(phone)){
                t = (t === '' ? '': (t + ',') ) + phone
            }
        });

        if(uniqueAllPhone.length !== 0){
            SMS.send({gateway: get(db, ['SMS-Quota', 'GATEWAY'], undefined), message: textAreaValue, phone: t, uid:db['UID'], callback: (r)=>{

                console.log('SUCCESS SMS RESPONSE: ', r);

                if (r.error){
                    message.error(r.msg ? r.msg : r.error);
                    setLoading(false);
                    Mixpanel.record({eventName: 'SMS-SEND-FAIL', eventProperties: {response: r, textAreaValue, t, uid: db['UID']}});
                    return false;
                }
                const filteredNumbers = uniqueAllPhone
                    .map(phone => fixBangladeshPhoneNumbers(phone))
                    .filter(phone => isValidPhoneNumber(phone));
                
                let sms_request_id      =   r['data']['request_id'];

                const data = {
                    tags            : filteredNumbers,
                    time            : (new Date()).getTime(),
                    total_recipients: filteredNumbers.length,
                    message         : encodeString(textAreaValue),
                    cost            : ((filteredNumbers.length)*smsNumberPerPerson) * parseFloat(rate),
                    request_id      : sms_request_id,
                    total_sms_quota_used: ((filteredNumbers.length)*smsNumberPerPerson),
                    GATEWAY         : get(db, ['SMS-Quota', 'GATEWAY'], undefined) ? get(db, ['SMS-Quota', 'GATEWAY'], undefined) : 'ALPHA'
                }

                //record to mixpanel
                Mixpanel.record({eventName: 'SMS-SEND-SUCCESS', eventProperties: data});
                
                let dbAddress =  `USERS/${db['UID']}/SMS-Sent-History/`;
                database.ref(dbAddress).push(data)
                .catch(e=>{
                    message.error(e)
                })


                let smsRemains = get(db, ["SMS-Quota", "v"], undefined);
                if(smsRemains){
                    let dbAddressSmsQuota   =  `USERS/${db['UID']}/SMS-Quota/`;
                    //need to check how many SMS were sent per person as each SMS can only have 153 SMS characters
                    let newRemainingSMS     =   (parseInt(smsRemains)-(totalSMSNeeded));

                    database.ref(dbAddressSmsQuota).update({
                        v:  newRemainingSMS,
                    })
                }

                console.log('Calling callback with request id: ', sms_request_id);

                callback(sms_request_id);

                let appProgress = get(db, ['AppProgress', 'SendSMS'], undefined);
                if (appProgress === undefined){
                    database.ref(`USERS/${db['UID']}/AppProgress`).update({'SendSMS': true});
                }

                setLoading(false);

            }});

            crossPostSMS_ToFacebook();
        } 
        else{
            setLoading(false);
            message.error(('At least one phone number needs to be supplied'));
        }

    }

    //this will send out a personalised SMS to each supplied phone numbers
    const callCloudFunction_ForGroupSMS_DifferentMessage = ({isDynamicRowsIncrement, sendArray, rate, callback}) => {

        //check to see if enough quota is available
        //if all messages are the same then count from the first message
        let totalSMSNeeded = 0;
        if (isDynamicRowsIncrement === false){
            let smsNumberPerPerson  =   Math.ceil((totalCharactersUSED(sendArray[0]['totalSMSNeeded']))/134);
            totalSMSNeeded          =   (parseInt(totalCurrentRecepients()*smsNumberPerPerson));
            if (totalSMSNeeded > smsQuotaAvailable){
                let key = `open${Date.now()}`;
                notification.open({
                    message: "Not Enough SMS Quota",
                    description: `You do not have enough SMS Quota. You need ${(parseInt(totalCurrentRecepients()*smsNumberPerPerson))} total quota with ${smsNumberPerPerson} SMS needed to be sent per person (153 characters per SMS only). Your current quota is ${smsQuotaAvailable}.`,
                    duration: 0,
                    key: key,
                    type: 'warning'
                });
                setLoading(false);
                return false;
            }
        }
        else{
            for (let onePhoneOBJ of sendArray){
                if (isValidPhoneNumber(onePhoneOBJ['phone'])){
                    totalSMSNeeded = totalSMSNeeded + parseInt(onePhoneOBJ['totalSMSNeeded']);
                }
            }
            if ((totalSMSNeeded) > smsQuotaAvailable){
                let key = `open${Date.now()}`;
                notification.open({
                    message: "Not Enough SMS Quota",
                    description: `You do not have enough SMS Quota. You need ${totalSMSNeeded} total quota. Your current quota is ${smsQuotaAvailable}.`,
                    duration: 0,
                    key: key,
                    type: 'warning'
                });
                setLoading(false);
                return false;
            }
        }

        setLoading(true);

        let smsRemains          = get(db, ["SMS-Quota", "v"], undefined);
        let allPhone            =   [];

        for (let oneObj of sendArray) {
            if (isValidPhoneNumber(oneObj['phone'])){
                allPhone.push(oneObj['phone']);
            }
        }

        const filteredArray = sendArray.filter(obj => {
            const phone = obj.phone;
            return isValidPhoneNumber(phone)
        });
        
        if(sendArray.length !== 0){
            SMS.sendSMS_Group_Individually_Firebase_Middleware({gateway: get(db, ['SMS-Quota', 'GATEWAY'], undefined),sendingArray: filteredArray, rate,smsRemains,textAreaValue, uid:db['UID'], callback: (r)=>{

                console.log('RESPONSE: ', r);
                callback(r['msg']);
            }})
        } 
        else{
            setLoading(false);
            message.error(('At least one phone number needs to be supplied'));
        }

    }
    
    const crossPostSMS_ToFacebook = () => {
        if (chosenFacebookGroups.length !== 0){
            let allSavedFacebookGroups = get(db, ['FacebookSavedGroups'], {});
            Facebook.getToken({db, notification, database, callback: (res)=>{
                if (res.success){
                    for (let oneGroupID of chosenFacebookGroups){
                        Facebook.makePostToGroup({
                            access_token: res.success.access_token,
                            groupID: oneGroupID, 
                            postMessage:textAreaValue, 
                            message:message, 
                            callback: (d)=>{
                                console.log(d);
                                message.success(`Cross-Posted to Facebook Group ${decodeString(allSavedFacebookGroups[oneGroupID])} successfully`);
                            }
                        })
                    }
                }
                else{
                    message.error(res.error);
                }
            }})
        }
    }
    //confirm and send SMS
    const sendMessage = () => {

        if (textAreaValue === '' || !textAreaValue){
            message.error('Empty message cannot be sent!');
            return false;
        }

        let {contains, caughtChar} = containsEmojis(textAreaValue, true)

        if (contains === true){
            message.error(`Emoji & special characters cannot be sent in SMS. Please remove character: ${caughtChar}`);
            return false;
        }

        console.log('Sending an SMS to recepients: ', recepients);

        let allPhone        =   [];
        let rate            =   get(db, ['SMS-Quota', 'rate'], DEFAULT_SMS_RATE_NON_MASKING);

        for (let oneRecepient of recepients){
            allPhone.push(...(oneRecepient['numbers'] ? oneRecepient['numbers'] : []));
        }
        
        if (isEmpty(excelFileData)){
            console.log('No excel file data so just send a regular group SMS..');
            callCloudFunction_ForGroupSMS_SameMessage({allPhone, textAreaValue, rate, callback: (sms_request_id)=>{
                setRequestID(sms_request_id);
                setSentTimeStamp((new Date()).getTime());
                setIsResponseModal(true);
                resetModalToDefault();
            }});
        }
        else{
            //there is an excel file loaded so check to see if there are any query paraments inside the SMS
            console.log('Excel File Detected so need to send individual SMS..', excelFileData);

            let excelQueried = ExcelQuery.excelQueryLanguage({
                allPhone        : allPhone,
                fullString      : textAreaValue,
                excelFileData   : excelFileData
            });


            let outputArr               = excelQueried[0];
            let isDynamicRowsIncrement  = excelQueried[1];

            console.log({outputArr, isDynamicRowsIncrement});

            //this means that each message is not unique so send same message to everyone
            if (isDynamicRowsIncrement === false){
                console.log('SEND SAME MESSAGE TO EVERYONE!');
                callCloudFunction_ForGroupSMS_SameMessage({isDynamicRowsIncrement, allPhone, textAreaValue: outputArr[0]['text'], rate, callback: (sms_request_id)=>{
                    setRequestID(sms_request_id);
                    setSentTimeStamp((new Date()).getTime());
                    setIsResponseModal(true);
                    resetModalToDefault();
                }});
            }
            else{
                //need to send out different SMS message for each number
                setLoading(true);
                callCloudFunction_ForGroupSMS_DifferentMessage({isDynamicRowsIncrement, sendArray: outputArr, rate, callback: (firebaseKey)=>{

                    setTimeout(()=>{
                        setSentTimeStamp((new Date()).getTime());
                        setDynamicID(firebaseKey);
                        setIsDResponseModal(true);
                        resetModalToDefault();
                        setLoading(false);
                    }, 6000);

                }});


            }
        }
    };

    //confirm and schedule SMS to later
    const setSMS_ToSendLater = (time, momentObj) => {

        if (textAreaValue === '' || !textAreaValue){
            message.error(('Empty message cannot be sent!'));
            return false;
        }

        if (!time){
            message.error('Please pick a proper time to send!');
            return false;
        }

        console.log('Sending a Scheduled SMS to recepients: ', time);

        let allPhone        =   [];
        let rate            =   get(db, ['SMS-Quota', 'rate'], DEFAULT_SMS_RATE_NON_MASKING);

        for (let oneRecepient of recepients){
            allPhone.push(...(oneRecepient['numbers'] ? oneRecepient['numbers'] : []));
        }

        if (isEmpty(excelFileData)){

            //check to see if enough quota is available
            let smsNumberPerPerson  =   Math.ceil((totalCharactersUSED(textAreaValue))/134);
            let totalSMSNeeded      =   (parseInt(totalCurrentRecepients()*smsNumberPerPerson));

            if (totalSMSNeeded > smsQuotaAvailable){

                let key = `open${Date.now()}`;
                notification.open({
                    message: "Not Enough SMS Quota",
                    description: `You do not have enough SMS Quota. You need ${(parseInt(totalCurrentRecepients()*smsNumberPerPerson))} total quota with ${smsNumberPerPerson} SMS needed to be sent per person. Your current quota is ${smsQuotaAvailable}.`,
                    duration: 0,
                    key: key,
                    type: 'warning'
                });
                
                setLoading(false);
                return false;
            }

            // create the comma separated phone numbers
            let uniqueAllPhone  = allPhone.filter((v, i, a) => a.indexOf(v) === i);
            let t = ''
            uniqueAllPhone.forEach((phone) => {
                if (isValidPhoneNumber(phone)){
                    t = (t === '' ? '': (t + ',') ) + phone
                }
            });
        
    
            const d                 = momentObj.toDate();    //moment schedule to js date obj
    
    
            if(uniqueAllPhone.length !== 0){
    
                SMS.scheduleSMS({
                    gateway     : get(db, ['SMS-Quota', 'GATEWAY'], undefined),
                    message     : textAreaValue,
                    phone       : t,
                    uid         : db['UID'],
                    schedule    : time,
                    callback    : (r) => {
    
                        console.log('RESPONSE: ', r);
    
    
                        const data = {
                            tags            : uniqueAllPhone,
                            time            : (new Date()).getTime(),
                            total_recipients: uniqueAllPhone.length,
                            message         : encodeString(textAreaValue),
                            cost            : uniqueAllPhone.length * parseFloat(rate),
                            request_id      : 'SCHEDULED-SMS',
                            scheduled       : d.getTime()
                        }
                    
                        let dbAddress =  `USERS/${db['UID']}/SMS-Sent-History/`;
                        database.ref(dbAddress).push(data)
                        .catch(e=>{
                            message.error(e)
                        })
    
                        let smsRemains = get(db, ["SMS-Quota", "v"], undefined);
                        if(smsRemains){
                            let dbAddressSmsQuota =  `USERS/${db['UID']}/SMS-Quota/`;
                            //need to check how many SMS were sent per person as each SMS can only have 153 SMS characters
                            let newRemainingSMS     =   (parseInt(smsRemains)-parseInt(totalSMSNeeded));
    
                            database.ref(dbAddressSmsQuota).update({
                                v:  newRemainingSMS,
                            })
                        }
                    
    
                        setSentTimeStamp(d.getTime());
                        resetModalToDefault();
    
                        message.success('SMS scheduled successfully!');
    
                        let appProgress = get(db, ['AppProgress', 'SendSMS'], undefined);
                        if (appProgress === undefined){
                            database.ref(`USERS/${db['UID']}/AppProgress`).update({'SendSMS': true});
                        }
    
                        //record to mixpanel
                        Mixpanel.record({eventName: 'SMS-SCHEDULE-SEND-SUCCESS', eventProperties: data});
    
                    },
                });
                
            } 
            else{
                message.error(('At least one phone number needs to be supplied!'));
            }
    
            setPopoverVisibility(false);

        }
        else{
            //complex excel file data..
            //there is an excel file loaded so check to see if there are any query paraments inside the SMS
            console.log('Excel File Detected so need to send individual SMS..');

            let excelQueried = ExcelQuery.excelQueryLanguage({
                allPhone        : allPhone,
                fullString      : textAreaValue,
                excelFileData   : excelFileData
            });


            let outputArr               = excelQueried[0];
            let isDynamicRowsIncrement  = excelQueried[1];

            //this means that each message is not unique so send same message to everyone
            if (isDynamicRowsIncrement === false){


                //check to see if enough quota is available
                let smsNumberPerPerson  =   Math.ceil((totalCharactersUSED(outputArr[0]['text']))/134);
                let totalSMSNeeded      =   (parseInt(totalCurrentRecepients()*smsNumberPerPerson));

                if (totalSMSNeeded > smsQuotaAvailable){

                    let key = `open${Date.now()}`;
                    notification.open({
                        message: "Not Enough SMS Quota",
                        description: `You do not have enough SMS Quota. You need ${(parseInt(totalCurrentRecepients()*smsNumberPerPerson))} total quota with ${smsNumberPerPerson} SMS needed to be sent per person. Your current quota is ${smsQuotaAvailable}.`,
                        duration: 0,
                        key: key,
                        type: 'warning'
                    });
                    
                    setLoading(false);
                    return false;
                }

                // create the comma separated phone numbers
                let uniqueAllPhone  = allPhone.filter((v, i, a) => a.indexOf(v) === i);
                let t = ''
                uniqueAllPhone.forEach((phone) => {
                    
                    phone = fixBangladeshPhoneNumbers(phone);

                    if (isValidPhoneNumber(phone)){
                        t = (t === '' ? '': (t + ',') ) + phone
                    }
                });
            
        
                const d                 = momentObj.toDate();    //moment schedule to js date obj
        
        
                if(uniqueAllPhone.length !== 0){
        
                    SMS.scheduleSMS({
                        gateway     : get(db, ['SMS-Quota', 'GATEWAY'], undefined),
                        message     : outputArr[0]['text'],
                        phone       : t,
                        uid         : db['UID'],
                        schedule    : time,
                        callback    : (r) => {
        
                            console.log('RESPONSE: ', r);
        
        
                            const data = {
                                tags            : uniqueAllPhone,
                                time            : (new Date()).getTime(),
                                total_recipients: uniqueAllPhone.length,
                                message         : encodeString(outputArr[0]['text']),
                                cost            : uniqueAllPhone.length * parseFloat(rate),
                                request_id      : 'SCHEDULED-SMS',
                                scheduled       : d.getTime()
                            }
                        
                            let dbAddress =  `USERS/${db['UID']}/SMS-Sent-History/`;
                            database.ref(dbAddress).push(data)
                            .catch(e=>{
                                message.error(e)
                            })
        
                            let smsRemains = get(db, ["SMS-Quota", "v"], undefined);
                            if(smsRemains){
                                let dbAddressSmsQuota =  `USERS/${db['UID']}/SMS-Quota/`;
                                //need to check how many SMS were sent per person as each SMS can only have 153 SMS characters
                                let newRemainingSMS     =   (parseInt(smsRemains)-parseInt(totalSMSNeeded));
        
                                database.ref(dbAddressSmsQuota).update({
                                    v:  newRemainingSMS,
                                })
                            }
                        
        
                            setSentTimeStamp(d.getTime());
                            resetModalToDefault();
        
                            message.success('SMS scheduled successfully!');
        
                            let appProgress = get(db, ['AppProgress', 'SendSMS'], undefined);
                            if (appProgress === undefined){
                                database.ref(`USERS/${db['UID']}/AppProgress`).update({'SendSMS': true});
                            }
        
                            //record to mixpanel
                            Mixpanel.record({eventName: 'SMS-SCHEDULE-SEND-SUCCESS', eventProperties: data});
        
                        },
                    });
                    
                } 
                else{
                    message.error(('At least one phone number needs to be supplied!'));
                }
        
                setPopoverVisibility(false);
            }
            else{
                //need to send out different SMS message for each number
                message.error('Dynamic Personalised SMS Messages cannot be scheduled for later!');
            }

        }

    }

    //import numbers from other places MENU..
    const menu = (
        <Menu onClick={()=>{}}>
            <Menu.Item key="1" type="primary">
                <AddFromFile
                    key                     =   "4"
                    getNumbers              =   {({fileName, numbers, id})=>{

                                                    if (numbers.length === 0){
                                                        message.error('Could not find any valid phone numbers!')
                                                    }
                                                    else{
                                                        setRecepients(currentRecepients => {
                                                            let newCurrentRecepients = [...currentRecepients];
                                                            newCurrentRecepients.push({
                                                                tagName: `${fileName}`,
                                                                numbers,
                                                                tooltip: numbers.join(' '),
                                                                id: id
                                                            })
                                                            return newCurrentRecepients
                                                        })
                                                        setTotalExcelLoaded(x=>x+1); //comment this out to have support for multiple dynamic query excel files
                                                    }

                                                }}

                    getFileData             =   {(data)=>{
                                                    //now data is an array with each element being sheets of the excel file
                                                    setExcelFileData(currentExcelFileData => {
                                                        let newJSONDATA     =   currentExcelFileData;

                                                        for (let oneSheet of data){
                                                            let newFileDataKEY  = oneSheet.id;
                                                            set(newJSONDATA, [newFileDataKEY], oneSheet);
                                                        }
                                                        return newJSONDATA;
                                                    })
                                                    setforceRefreshDynamicExcelData(x=>x+1);
                                                }}
                    currentExcelFilesLoaded = {totalExcelLoaded}
                />
            </Menu.Item>
            <Menu.Item key="2" type="primary">
                <AddGroups
                    key         = "5"

                    getNumbers  =   {groupObjArr => {
                        let output      = [];
                        for (let oneGroup of groupObjArr){
                            let {groupName, numbers} = oneGroup;
                            output.push({
                                tagName: `${(groupName)}`,
                                numbers,
                                tooltip: numbers.join(' ')
                            })
                        }

                        setRecepients(currentRecepients => {
                            let newCurrentRecepients = [...currentRecepients];

                            newCurrentRecepients.push(...output);
                            return newCurrentRecepients
                        })

                    }}

                />
            </Menu.Item>

            
        </Menu>
    );

    //create a preview of the SMS and show the preview MODAL
    const previewSMS = () => {  
        
        if (textAreaValue === undefined || textAreaValue === ''){
            message.error(("Cannot preview empty SMS!"));
            return false;
        }

        let allPhone                =   [];
        let totalSMS_ToUse          =   0;
        let totalInvalid            =   0;
        let totalInvalidIDArr       =   [];
        let totalPeople             =   0;

        for (let oneRecepient of recepients){
            allPhone.push(...(oneRecepient['numbers'] ? oneRecepient['numbers'] : []));
        }

        if (isEmpty(excelFileData)){

            let outputJSX   = [];
            let i           =   1;

            for (let onePh of allPhone){

                let isValid = false;
                onePh = fixBangladeshPhoneNumbers(onePh);
        
                if (isValidPhoneNumber(onePh)) isValid = true;

                let smsNumberPerPerson  =   Math.ceil((totalCharactersUSED(textAreaValue))/134);
                
                outputJSX.push(

                    <Card
                        key={`${onePh}-${Math.random()}`} 
                        className='padding_change'
                        style={{ borderRadius: "16px", paddingBottom: "0px", width: "100%" }}
                        hoverable
                        bodyStyle={{paddingBottom: '0px'}}
                    >
                        
                        <h3>
                        {`${i}) To: ${onePh}`}
                        </h3>
                        <p>
                            {textAreaValue}
                        </p>
                        <Footer style={{ paddingBottom: "0px", paddingTop: "0px", paddingLeft: 0, paddingRight: 0 }}>
                            <Row justify='space-between' align="center">
                                <Col span={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Button block size="small" className={isValid === true ? 'gx-mx-0 gx-mb-2 gx-mt-2 gx-btn-actionGreen' : 'gx-mx-0 gx-mb-2 gx-mt-2 gx-btn-danger'} type="dashed"  icon={isValid === true ? <CheckCircleOutlined /> : <CloseCircleOutlined />}>
                                        {isValid === true ? "Valid" : "Invalid"}
                                    </Button>
                                </Col>
                                <Col span={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Button block size="small" className={isValid === true ? 'gx-mx-0 gx-mb-2 gx-mt-2 gx-btn-info' : 'gx-mx-0 gx-mb-2 gx-mt-2 gx-btn-info'} type="default" icon={<MailOutlined />}>
                                        {smsNumberPerPerson} SMS
                                    </Button>
                                </Col>
                            </Row>
                        </Footer>
                    </Card>

                )
                totalPeople = totalPeople + 1;

                if (isValid === true){
                    totalSMS_ToUse = totalSMS_ToUse + parseInt(smsNumberPerPerson);
                }
                else{
                    totalInvalid = totalInvalid + parseInt(smsNumberPerPerson);
                    totalInvalidIDArr.push(i);
                }
                i++;
            }
    
            setPreviewJSX(outputJSX);
            setPreviewModal(true);
        }
        else{
            //there is an excel file loaded so check to see if there are any query paraments inside the SMS
            //console.log('EXCEL JSON DATA: ', excelFileData);

            let outputArr = ExcelQuery.excelQueryLanguage({
                allPhone        : allPhone,
                fullString      : textAreaValue,
                excelFileData   : excelFileData
            })[0]


            let outputJSX   = [];
            let i           =   1;
            for (let onePreviewJSX of outputArr){

                let {text, phone} = onePreviewJSX;

                let isValid = false;
                phone = fixBangladeshPhoneNumbers(phone);
        
                if (isValidPhoneNumber(phone)) isValid = true;

                let smsNumberPerPerson  =   Math.ceil((totalCharactersUSED(text))/134);
                
                outputJSX.push(

                    <Card
                        key={`${phone}-${Math.random()}`} 
                        className='padding_change'
                        style={{ borderRadius: "16px", paddingBottom: "0px", width: "100%" }}
                        hoverable
                        bodyStyle={{paddingBottom: '0px'}}
                    >
                        
                        <h3>
                        {`${i}) To: ${phone}`}
                        </h3>
                        <p>
                            {text}
                        </p>
                        <Footer style={{ paddingBottom: "0px", paddingTop: "0px", paddingLeft: 0, paddingRight: 0 }}>
                            <Row justify='space-between' align="center">
                                <Col span={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Button block size="small" className={isValid === true ? 'gx-mx-0 gx-mb-2 gx-mt-2 gx-btn-actionGreen' : 'gx-mx-0 gx-mb-2 gx-mt-2 gx-btn-danger'} type="dashed"  icon={isValid === true ? <CheckCircleOutlined /> : <CloseCircleOutlined />}>
                                        {isValid === true ? "Valid" : "Invalid"}
                                    </Button>
                                </Col>
                                <Col span={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Tooltip title={`Total Characters: ${(totalCharactersUSED(text))}`} >
                                        <Button block size="small" className={isValid === true ? 'gx-mx-0 gx-mb-2 gx-mt-2 gx-btn-info' : 'gx-mx-0 gx-mb-2 gx-mt-2 gx-btn-info'} type="default" icon={<MailOutlined />}>
                                            {smsNumberPerPerson} SMS
                                        </Button>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Footer>
                    </Card>

                )
                totalPeople = totalPeople + 1;

                if (isValid === true){
                    totalSMS_ToUse = totalSMS_ToUse + parseInt(smsNumberPerPerson);
                }          
                else{
                    totalInvalid = totalInvalid + parseInt(smsNumberPerPerson);
                    totalInvalidIDArr.push(i);
                }

                i++;
            }

            setPreviewJSX(outputJSX);
            setPreviewModal(true);

        }

        setPreviewSMSTotalValid(totalSMS_ToUse);
        setPreviewSMSTotalErr(totalInvalid);
        setTooltipTotalInvalid(`Invalid on ${totalInvalidIDArr.join(',')}`);
        setTotalPreviewRec(totalPeople);

        Mixpanel.record({eventName: 'OPEN-SMS-PREVIEW-WINDOW', eventProperties: {totalSMS_ToUse: totalSMS_ToUse, totalInvalid:totalInvalid}})
    }

    const totalCurrentRecepients = () => {

        let allPhone        =   [];

        for (let oneRecepient of recepients){
            allPhone.push(...(oneRecepient['numbers'] ? oneRecepient['numbers'] : []));
        }

        // create the comma separated phone numbers
        let uniqueAllPhone  = allPhone.filter((v, i, a) => a.indexOf(v) === i);
        let finalArr        = [];
        uniqueAllPhone.forEach((phone) => {

            phone = fixBangladeshPhoneNumbers(phone);

            if (isValidPhoneNumber(phone)){
                finalArr.push(phone);
            }
        });

        return parseInt(finalArr.length)
    }

    const improveSMS_viaChatGPT = () => {

        if (textAreaValue === '' || textAreaValue === undefined){
            message.error('Cannot improve empty SMS!');
            return false;
        }

        setLoading(true);
        let originalT   =  textAreaValue; 
        let prompt      = 'Improve this SMS, only respond with the updated SMS, assume SMS is for marketing - ' + textAreaValue;

        EdutechCloudServer.postRequest('just-chat-completion', {
            prompt: prompt, uid: db['UID']
        }, (response)=>{
            if (response.message === 'success'){
                setLoading(false);
                Mixpanel.record({eventName: 'SMS-IMPROVE-GPT-SUBMIT-MESSAGE', eventProperties: {
                    prompt: prompt, uid: db['UID'], timeStamp: (new Date()).getTime()
                }});

                console.log('DATA: ', response.data);
                let data = response.data;
                let choices = get(data, ['choices'], [{
                    message: {
                        'content': textAreaValue
                    }
                }]);

                let newImprovedText = get(choices[0], ['message', 'content'], textAreaValue);
                newImprovedText = newImprovedText.replace('{', ' ');
                newImprovedText = newImprovedText.replace('}', ' ');

                setTextAreaValue(newImprovedText);

                let key = `open${Date.now()}`;
                notification.open({
                    message: "SMS Improved!",
                    description:
                    <>
                            <p>
                                Hope you like the new version. If not then press the below button to revert to before.
                            </p>
    
                            <Button type="primary" onClick={() => {
                                setTextAreaValue(originalT);
                                }}>Revert to Original SMS</Button>
                    </>,
                    duration: 0,
                    key: key,
                    type: 'success'
                });
            }
            else{
                setLoading(false);
                message.error(response.message);
            }
        });

    }

    useEffect(() => {

        let allSavedFacebookGroups = get(db, ['FacebookSavedGroups'], {});

        let outputData = [];
        for (let oneGroupKey in allSavedFacebookGroups){

            if (!allSavedFacebookGroups[oneGroupKey]) continue;

            outputData.push({
                'label': truncate(decodeString(allSavedFacebookGroups[oneGroupKey]), 40),
                'id': oneGroupKey,
                'value': oneGroupKey
            })
        }

        setFbGroupOptions(outputData);

    }, [db]);

    const facebookButtonClick = () => {
        setLoading(true);
        setFbGroupOptions([]);
        Facebook.getToken({db, notification, database, callback: (res)=>{
            if (res.success){
                setLoading(false);
                setFbGroupModal(true);

                let allSavedFacebookGroups = get(db, ['FacebookSavedGroups'], {});

                let outputData = [];
                for (let oneGroupKey in allSavedFacebookGroups){

                    if (!allSavedFacebookGroups[oneGroupKey]) continue;
                    outputData.push({
                        'label': truncate(decodeString(allSavedFacebookGroups[oneGroupKey]), 40),
                        'id': oneGroupKey,
                        'value': oneGroupKey
                    })
                }
                setFbGroupOptions(outputData);
            }
            else{
                setLoading(false);
                message.error(res.error);
            }
        }})
    }

    return (
        <>
            <Modal
                data-testid         =   "SMS-Send-Modal"
                title               =   {`${t("Send SMS")} | ${t('Quota Available')}: ${smsQuotaAvailable}`}
                width               =   {800}
                style               =   {{ top: 20 }}
                visible             =   {isModalVisible}
                onOk                =   {resetModalToDefault}
                onCancel            =   {resetModalToDefault}
                cancelButtonProps   =   {{ style: { display: "none" } }}

                footer              =   {[
                    <div key="543" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>

                        <div>
                            <Button 
                                data-testid =   'sms-preview-btn'
                                onClick     =   {() => previewSMS()} 
                                size        =   "medium" 
                                className   =   'gx-ml-3 gx-mt-1 gx-mb-1' 
                                loading     =   {loading}
                            >
                                {t('Preview')}
                            </Button>

                            <Tooltip title="Magic SMS">
                                    <Button 
                                        loading={loading}
                                        data-testid="improve-sms-btn"
                                        onClick={() => improveSMS_viaChatGPT()}>
                                        <FireOutlined />
                                    </Button>
                            </Tooltip>

                            {(window.location.href).includes('localhost') ? <Tooltip title="Auto-Post to Facebook Groups">
                                <Button 
                                    loading={loading}
                                    data-testid="facebook-post-group-sms-btn"
                                    onClick={() => facebookButtonClick()}>
                                    <FacebookFilled/>
                                </Button>
                            </Tooltip>:<></>}
                            

                        </div>

                        <ButtonGroup className="gx-mr-3 gx-mt-1 gx-mb-1">
                        
                            <Tooltip title={t("Schedule to send later")}>
                                <Button 
                                    onClick     =   {() => setPopoverVisibility(!popoverVisibility)} 
                                    size        =   "medium" 
                                    className   =   'gx-mb-0 gx-btn-geekblue' 
                                    style       =   {{ borderRight: '1px solid white' }}
                                    loading     =   {loading}
                                >
                                    <FieldTimeOutlined />
                                </Button>
                            </Tooltip>
                          

                            <Modal
                                //title="Modal"
                                className   =   "mobile-top-position"
                                visible     =   {popoverVisibility}
                                onOk        =   {hideModal}
                                onCancel    =   {hideModal}
                                footer      =   {null}
                                width       =   {280}
                            >
                                <div>    
                                        <DatePicker 
                                            showTime 
                                            use12Hours
                                            onOk        =   {(value)=>setSMS_ToSendLater(value.format('YYYY-MM-DD HH:mm:ss'), value)} 
                                            showNow     =   {false}
                                            allowClear  =   {false}
                                        />
                                    </div>

                            </Modal>

                            <Button 
                                onClick         =   {() => sendMessage()} 
                                size            =   "medium" 
                                loading         =   {loading}
                                className       =   'gx-mb-0 gx-ml-0 gx-btn-info' 
                                style           =   {{ width: '10em' }}>
                                    {t("Send")}
                            </Button>

                        </ButtonGroup>


                    </div>
                ]}
            >
                <div className="gx-mb-2 gx-flex-row gx-justify-content-between gx-align-items-center">
                    <span
                        className="gx-px-2 gx-fs-lg gx-font-weight-normal"
                    >
                        {t("Recipients")} <Tooltip title="Total Unique Recepients">({totalCurrentRecepients()})</Tooltip> 
                    </span>
                    <Dropdown overlay={menu} >
                        <Button type="dashed" className="gx-mb-0 gx-mr-2"
                            onClick={
                                () => {
                                    Mixpanel.record({eventName: 'SMS-IMPORT-PHONE-NUMBERS-CLICKED', eventProperties: {}});
                                }
                            }
                        >
                            {t("Import Phone Numbers")} <PlusOutlined />
                        </Button>
                    </Dropdown>
                </div>
                <Row className="gx-px-2 gx-mb-4">
                    <Col span={24}>
                        <div
                            className="gx-pt-3 gx-pl-3 recepientTagCont"
                            style={{
                                border: "1px dashed #d9d9d9",
                                borderRadius: "5px",
                                height: "180px",
                                overflowY: "scroll",
                            }}
                        >

                            {recepientTagsJSX}

                            <Input
                                data-testid         =   "SMS-Number-Input"
                                placeholder         =   {t("+ Add New Recipient")}
                                value               =   {recepientInputField}
                                className           =   "tag-input-field"
                                onChange            =   {onChangeInput}
                                onPressEnter        =   {(v) => onPressEnter(v.target.value)}
                                style={{
                                    height: "25px",
                                }}
                                addonAfter          =   {

                                    <div>
                                        <Button data-testid="SMS-Number-Submit-BTN" className="gx-mb-0" onClick={()=>{
                                            onPressEnter(recepientInputField);
                                            Mixpanel.record({eventName: 'SMS-NEW-RECEPIENT-ADDED-MANUALLY', eventProperties: {'recepient': recepientInputField}});
                                        }} type="primary" icon={ <PlusOutlined />} />
                                    </div>
                                }
                            />


                        </div>
                    </Col>

                </Row>

                <div className="gx-mb-2">
                    <span
                        className="gx-px-2 gx-fs-lg gx-font-weight-normal"
                    >
                        {t("Message Content")}
                    </span>
                </div>
                <Row className="gx-px-2">
                    <Col span={24}>
                        <textarea
                            placeholder="..."
                            style={{
                                border: "1px dashed #d9d9d9",
                                borderRadius: "5px",
                                marginBottom: "10px",
                                width: '100%',
                                minHeight: '35vh',
                                padding: '1%',
                                background: 'rgba(0,0,0,0)'
                            }}
                            value={textAreaValue}
                            onChange={onChange}
                            ref={inputTextArea}
                        />

                        <p data-testid="sms-character-count" style={{position:'absolute', bottom: '1em', left: '2em', color: "#D3D3D3", display: 'none'}}>{t('Character Count')} {count}</p>
                        <p style={{position:'absolute', bottom: '1em', left: '2em', color: "#D3D3D3", display: removeCharCount === false ? 'none': 'block'}}>Please check preview for character count</p>

                        <div style={{position:'absolute', bottom: '1em', right: '2em'}}>
                            <Templates
                                key                 =   "7"
                                setTextAreaValue    =   {setTextAreaValue}
                                textAreaValue       =   {textAreaValue}
                                loading             =   {loading}
                                currentFileLoaded   =   {excelFileData}
                            />
                        </div>

                        {importBTN_ExcelJSX}

                    </Col>
                </Row>
            </Modal>

            <SentSMSStatusModalTable 
                request_id              = {requestID} 
                setIsModalVisible       = {setIsResponseModal} 
                isModalVisible          = {isResponseModal}
                sentTimeStamp           = {sentTimeStamp}
                resendCallback          = {(resendNumbersArr)=>{

                    if (resendNumbersArr.length === 0){
                        message.error('At least one phone number needs to be checked to resend!');
                        return false;
                    }

                    let defaultREC = [];
                    for (let onePhoneOBJ of resendNumbersArr){
                        defaultREC.push({studentPhone: [`+${onePhoneOBJ.Phone}`], tagName: `+${onePhoneOBJ.Phone}`});
                    }

                    setRecepients(defaultREC);

                    setTimeout(()=>{
                        setIsResponseModal(false);
                        setIsModalVisible(true);
                    }, 1000);

                }}
            />

            <DynamicSentSMSStatusModalTable 
                firebaseKey             = {dynamicID} 
                setIsModalVisible       = {setIsDResponseModal} 
                isModalVisible          = {isDResponseModal}
                sentTimeStamp           = {sentTimeStamp}
            />

            <Modal
                title           =   {t("Preview SMS")}
                className       =   "template-modal"
                width           =   {400}
                footer          =   {null}
                bodyStyle       =   {{
                    height: "400px",
                    overflowY: "scroll",
                }}
                visible         =   {previewModal}
                onOk            =   {() => setPreviewModal(false)}
                onCancel        =   {() => setPreviewModal(false)}
            >
                <div className="gx-px-1" data-testid="preview-sms-numbers-container">
                    <Alert message={`Total SMS Quota Currently: ${smsQuotaAvailable}`} type="info" showIcon/>
                    <Alert message={`Total ${previewSMSTotalValid} SMS Needed over ${totalRecPreview} People`} type="success" showIcon/>
                    <Tooltip title={tooltipTotalInvalid}>
                        <Alert message={`Total Invalid Messages: ${previewSMSTotalErr}`} type="error" showIcon/>
                    </Tooltip>

                    {previewJSX}
                </div>
            </Modal>

            <Modal
                title           =   {t("Dynamic Import Excel Cell")}
                className       =   "template-modal"
                width           =   {450}
                bodyStyle       =   {{
                    height: "450px",
                    overflowY: "scroll",
                }}
                visible         =   {dynamicImportExcel}
                onOk            =   {() => {
                    setDynamicImportExcel(false)
                }}
                onCancel        =   {() => setDynamicImportExcel(false)}
            >
                <div className="gx-px-1">
                    {dynamicImportJSX}
                </div>
            </Modal>

            <Modal
                title           =   {t("Facebook Group Cross-Post")}
                className       =   "template-modal"
                width           =   {400}
                bodyStyle       =   {{
                    height: "400px",
                    overflowY: "scroll",
                }}
                visible         =   {fbGroupModal}
                footer          =   {[
                    <Button type="primary" onClick={()=>{
                        Facebook.getToken({db, notification, database, callback: (res)=>{
                            if (res.success){
                                setfbAccessToken(res.success.access_token);
                                setShowExportFBMODAL(true);
                            }
                            else{
                                setLoading(false);
                                message.error(res.error);
                            }
                        }})

                    }}>Import Other Groups</Button>,
                    <Button onClick={()=>{
                        console.log('NEED TO CROSS-POST TO FB ID: ', chosenFacebookGroups);
                        setFbGroupModal(false);
                    }} className="gx-ml-3 gx-mt-1 gx-mb-1 gx-btn-geekblue">Save to Cross-Post</Button>
                ]}
                onCancel        =   {() => setFbGroupModal(false)}
            >
                <div className="fb-group-checkbox-wrapper">

                    {fbGroupOptions.length === 0 ? <div style={{width: '100%', height: 320, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <p style={{textAlign: 'center'}}>You do not have any saved groups. Press the Import button to import some Facebook Groups</p>
                    </div>:
                    
                    <>
                        <p>Pick the groups you want this SMS to be cross-posted to. If you cannot find a group here or want to edit your saved groups then press the Import button in the bottom.</p>
                        <Checkbox.Group options={fbGroupOptions} defaultValue={[]} onChange={(checkedValues)=>{
                            setChosenFacebookGroups(checkedValues);
                        }} />
                    </>
                    
                    }


                </div>

            </Modal>

        </>
    );
};

export default SendSMSModal;