import { useState, useEffect } from 'react';
import moment from 'moment';
import firebase from "firebase";

import { Drawer, Button, Collapse, Divider, Typography, message, Modal, Popconfirm, Tooltip, Table, notification, Row, Col } from 'antd';
import {CloseOutlined, CommentOutlined, CopyOutlined, ReloadOutlined} from '@ant-design/icons'
import { copyToClipboard, decodeString, isValidPhoneNumber, encodeString, doIHaveThisAddOnAvailable, fixBangladeshPhoneNumbers } from '../../../util/misc';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {get, size as lodashSize} from "lodash-es";
import Mixpanel from '../../../api/Mixpanel';
import SMS from '../../../api/SMS';
import ProfileGeneral from './ProfileGeneral';
import { DEFAULT_SMS_RATE_NON_MASKING } from '../../../business_config';
const database = firebase.database();

const UserProfileDrawer = ({ size, onClose, visible, studentUID, showStdEditDrawer, zIndex }) => {

    const { Panel }                                         = Collapse;
    const panelStyle                                        = {
        fontSize: '18px',
        marginTop: '1.5em'
    }
    const { t }                                             = useTranslation();

    const { db }                                            = useSelector(({ auth }) => auth);
    const [zIndexValue, setZIndexValue]                     = useState('1001');
    const [stdUid, setStdUid]                               = useState('');
    const [loading, setLoading]                             = useState(false);
    const [oneStdInfo, setOneStdInfo]                       = useState({})
    const [studentInfo, setStudentInfo]                     = useState([]);

    const [livePhotoModal, setLivePhotoModal]               = useState(false);

    const Parser                                            = require('hot-formula-parser').Parser;



    // load student all inforamtion and all accepted batches
    useEffect(() => {

        let linkHostName                    = '';

        if (window.location.hostname){
            
            if(window.location.hostname === 'localhost'){
                linkHostName = 'localhost:3000';
            }
            else{
                linkHostName = window.location.hostname;
            }
        }

        if(zIndex){
            setZIndexValue(zIndex)
        }

        if(studentUID){
            setStdUid(studentUID);

            let localDataOfThisStudent = get(db, ['AddedUsers', studentUID], undefined);

            if (localDataOfThisStudent === undefined){
                return false;
            }

            setOneStdInfo(localDataOfThisStudent)
            const Phone         = get(localDataOfThisStudent, ['Phone'],'');
            let data=[];
            data.push({
                type: 'student',
                numbers: Phone,
            });
            setStudentInfo(data);
            setLoading(false);

        }    

    }, [Parser, db, studentUID, zIndex])



    return (
        <>
            <Drawer
                width       =   {'52%'}
                placement   =   {"right"}
                closable    =   {false}
                onClose     =   {onClose}
                visible     =   {visible}
                zIndex      =   {zIndexValue}
            >
                <div className="drawer-content gx-pb-5">
                    <div 
                        className="drawer-head" 
                        style={{ display: 'flex', justifyContent: 'space-between', padding: '30px 20px 5px 30px' }}
                    >
                        <div 
                            className="drawer-head-left" 
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}
                        >
                        
                            <CloseOutlined 
                                onClick={onClose} 
                                className="gx-mr-3" 
                                style={{ fontSize: '22px', cursor: 'pointer' }} 
                            />
                           
                           <div className="drawer-title">
                                <Typography.Title data-testid="student-profile-drawer-title-teacher" className='gx-mb-0' level={4}>User Profile</Typography.Title>
                                
                            </div>
                        </div>
                        <div className="drawer-head-right" style={{display:'flex', alignItems:'center'}}>

                            <Button 
                                loading={loading} 
                                size="medium" 
                                className='gx-mb-0 btn-modifier gx-text-white gx-btn-info gx-mr-3'
                                onClick={()=>{

                                    showStdEditDrawer(stdUid)

                                    
                                }}
                            >
                                    {t("Edit")}
                            </Button>

                            <Popconfirm
                                title="Are you sure?"
                                onConfirm={() => {
                                    //delete this user
                                    database.ref(`USERS/${db['UID']}/AddedUsers/${studentUID}`).remove();
                                    database.ref(`USERS/${studentUID}/AcceptedByOwner/${db['UID']}`).remove();
                                    message.success('User deleted successfully');
                                    onClose();
                                }}
                                onCancel={() => {}}
                                okText="Yes"
                                cancelText="No"
                                >
                                <Button 
                                    loading={loading} 
                                    size="medium" 
                                    className='gx-mb-0 btn-modifier gx-text-white gx-btn-red gx-mr-1'
                                    onClick={()=>{
                                    }}
                                >
                                        Delete
                                </Button>
                            </Popconfirm>

                            


                        </div>
                    </div>

                    <Divider />

                    <div 
                        className="drawer-body" 
                        style={{padding: '0px 25px'}}
                    >
                        <Collapse defaultActiveKey={['1']} ghost>
                            <Panel header={t('General')} key="1" style={panelStyle} >
                                <ProfileGeneral 
                                    profileInfo         =   {oneStdInfo}
                                    setLivePhotoModal   =   {(v)=>setLivePhotoModal(v)}
                                />
                            </Panel>
                            

                        </Collapse>
                    </div>
                </div>
            </Drawer>

            <Modal
                title           =   {t("OK, where do you want to take the photo?")}
                visible         =   {livePhotoModal}
                onCancel        =   {() => setLivePhotoModal(false)}
                bodyStyle       =   {{ paddingTop: 0, margin: 0 }}
                wrapClassName   =   {'PromptWithMultiOptions'}
                style           =   {{top: 200,}}
                zIndex          =   {1007}
                footer          =   {null}
            > 

                    <Row justify="center" align="middle" gutter={[12,12]}>

                        <Col  span={24}>
                            <Button  block onClick={() => {

                                let p = database.ref(`LivePhotoKeys`).push({
                                    suid            : oneStdInfo['UID'],
                                    customID        : oneStdInfo['customID'],
                                    name            : oneStdInfo['Name'],
                                    type            : 'Student-Live-Photo',
                                    timeStamp       : (new Date()).getTime(),
                                    generatedUID    : db['UID'] 
                                })

                                p.then(()=>{
                                    let url = `${window.location.origin}/take-picture?key=${p.key}`;
                                    window.open(url, '_blank', 'noopener,noreferrer');
                                    setLivePhotoModal(false);
                                })

                        }} size="medium" className='gx-text-white gx-mb-0 gx-btn-actionBlue'>{t("Use Webcam/Laptop")}</Button>
                       
                        </Col>

                        <Col  span={24} >
                        <Button block onClick={() => {
                                    let p = database.ref(`LivePhotoKeys`).push({
                                        suid            : oneStdInfo['UID'],
                                        customID        : oneStdInfo['customID'],
                                        name            : oneStdInfo['Name'],
                                        type            : 'Student-Live-Photo',
                                        timeStamp       : (new Date()).getTime(),
                                        generatedUID    : db['UID'] 
                                    })

                                    let url = `https://edutechs.app/take-picture?key=${p.key}`;

                                    //SMS this URL to the user phone..
                                    let smsMessage          = `Edutechs Live Student Photo: ${url}`;
                                    let userPhone           = db['PublicInfo']['UserPhone'];

                                    userPhone = fixBangladeshPhoneNumbers(userPhone);

                                    if (!isValidPhoneNumber(userPhone)){
                                        message.error('Could not find a valid phone number for you. Please update it in your settings.')
                                        return false;
                                    }

                                    //check to see if enough quota is available
                                    let smsQUOTA            = get(db, ['SMS-Quota'], {});
                                    let rate                = get(db, ['SMS-Quota', 'rate'], DEFAULT_SMS_RATE_NON_MASKING);
                                    let {v}                 = smsQUOTA;
                                    if (!v) v               = 0;
                                    let smsQuotaAvailable   = v;
                                    let smsNumberPerPerson  = Math.ceil((smsMessage.length)/134);

                                    if ((parseInt(1*smsNumberPerPerson)) > smsQuotaAvailable){
                                        let key = `open${Date.now()}`;
                                        notification.open({
                                            message: "Not Enough SMS Quota",
                                            description: `You do not have enough SMS Quota. You need ${(parseInt(1*smsNumberPerPerson))} total quota with ${smsNumberPerPerson} SMS needed to be sent per person (160 characters per SMS only). Your current quota is ${smsQuotaAvailable}.`,
                                            duration: 0,
                                            key: key,
                                            type: 'warning'
                                        });
                                        return false;
                                    }

                                    SMS.send({gateway: get(db, ['SMS-Quota', 'GATEWAY'], undefined), message: smsMessage, phone: userPhone, uid:db['UID'], callback: (r)=>{

                                        if (r.error){
                                            message.error(r.msg);
                                            Mixpanel.record({eventName: 'SMS-SEND-FAIL', eventProperties: {response: r, smsMessage, t, uid: db['UID']}});
                                            return false;
                                        }
                        
                                        let smsNumberPerPerson  =   Math.ceil((smsMessage.length)/134);
                                        let sms_request_id      =   r['data']['request_id'];
                        
                                        const data = {
                                            tags            : [userPhone],
                                            time            : (new Date()).getTime(),
                                            total_recipients: 1,
                                            message         : encodeString(smsMessage),
                                            cost            : ((1)*smsNumberPerPerson) * parseFloat(rate),
                                            request_id      : sms_request_id,
                                            total_sms_quota_used: ((1)*smsNumberPerPerson),
                                        }
                        
                                        //record to mixpanel
                                        Mixpanel.record({eventName: 'SMS-SEND-SUCCESS', eventProperties: data});
                                        
                                        let dbAddress =  `USERS/${db['UID']}/SMS-Sent-History/`;
                                        database.ref(dbAddress).push(data)
                                        .catch(e=>{
                                            message.error(e)
                                        })
                        
                        
                                        let smsRemains = get(db, ["SMS-Quota", "v"], undefined);
                                        if(smsRemains){
                                            let dbAddressSmsQuota   =  `USERS/${db['UID']}/SMS-Quota/`;
                                            //need to check how many SMS were sent per person as each SMS can only have 160 SMS characters
                                            let newRemainingSMS     =   (parseInt(smsRemains)-((1)*smsNumberPerPerson));
                        
                                            database.ref(dbAddressSmsQuota).update({
                                                v:  newRemainingSMS,
                                            })
                                        }

                                        message.success('The Link has been SMS to your phone. Please click it!');
                                        setLivePhotoModal(false);
                        
                                    }})

                        }} size="medium" className='gx-text-white gx-mb-0 gx-btn-actionGreen'>{t("Use Your Mobile Phone")}</Button>
                       
                        </Col>

                        <Col span={24}>
                            <Button  block onClick={() => {
                                    let p = database.ref(`LivePhotoKeys`).push({
                                        suid            : oneStdInfo['UID'],
                                        customID        : oneStdInfo['customID'],
                                        name            : oneStdInfo['Name'],
                                        type            : 'Student-Live-Photo',
                                        timeStamp       : (new Date()).getTime(),
                                        generatedUID    : db['UID'] 
                                    })

                                    let url = `https://edutechs.app/take-picture?key=${p.key}`;

                                  //SMS this URL to the user phone..
                                  let smsMessage          = `Hello from ${decodeString(db['PublicInfo']['UserName'])}. Please upload your Student Photo by clicking this link: ${url}`;
                                  let userPhone           = oneStdInfo['Phone'];

                                    userPhone = fixBangladeshPhoneNumbers(userPhone);

                                  if (!isValidPhoneNumber(userPhone)){
                                      message.error('Could not find a valid phone number. Please update the Student Phone and try again!')
                                      return false;
                                  }

                                  //check to see if enough quota is available
                                  let smsQUOTA            = get(db, ['SMS-Quota'], {});
                                  let rate                = get(db, ['SMS-Quota', 'rate'], DEFAULT_SMS_RATE_NON_MASKING);
                                  let {v}                 = smsQUOTA;
                                  if (!v) v               = 0;
                                  let smsQuotaAvailable   = v;
                                  let smsNumberPerPerson  = Math.ceil((smsMessage.length)/134);

                                  if ((parseInt(1*smsNumberPerPerson)) > smsQuotaAvailable){
                                      let key = `open${Date.now()}`;
                                      notification.open({
                                          message: "Not Enough SMS Quota",
                                          description: `You do not have enough SMS Quota. You need ${(parseInt(1*smsNumberPerPerson))} total quota with ${smsNumberPerPerson} SMS needed to be sent per person (160 characters per SMS only). Your current quota is ${smsQuotaAvailable}.`,
                                          duration: 0,
                                          key: key,
                                          type: 'warning'
                                      });
                                      return false;
                                  }

                                  SMS.send({gateway: get(db, ['SMS-Quota', 'GATEWAY'], undefined), message: smsMessage, phone: userPhone, uid:db['UID'], callback: (r)=>{

                                      if (r.error){
                                          message.error(r.msg);
                                          Mixpanel.record({eventName: 'SMS-SEND-FAIL', eventProperties: {response: r, smsMessage, t, uid: db['UID']}});
                                          return false;
                                      }
                      
                                      let smsNumberPerPerson  =   Math.ceil((smsMessage.length)/134);
                                      let sms_request_id      =   r['data']['request_id'];
                      
                                      const data = {
                                          tags            : [userPhone],
                                          time            : (new Date()).getTime(),
                                          total_recipients: 1,
                                          message         : encodeString(smsMessage),
                                          cost            : ((1)*smsNumberPerPerson) * parseFloat(rate),
                                          request_id      : sms_request_id,
                                          total_sms_quota_used: ((1)*smsNumberPerPerson),
                                      }
                      
                                      //record to mixpanel
                                      Mixpanel.record({eventName: 'SMS-SEND-SUCCESS', eventProperties: data});
                                      
                                      let dbAddress =  `USERS/${db['UID']}/SMS-Sent-History/`;
                                      database.ref(dbAddress).push(data)
                                      .catch(e=>{
                                          message.error(e)
                                      })
                      
                      
                                      let smsRemains = get(db, ["SMS-Quota", "v"], undefined);
                                      if(smsRemains){
                                          let dbAddressSmsQuota   =  `USERS/${db['UID']}/SMS-Quota/`;
                                          //need to check how many SMS were sent per person as each SMS can only have 160 SMS characters
                                          let newRemainingSMS     =   (parseInt(smsRemains)-((1)*smsNumberPerPerson));
                      
                                          database.ref(dbAddressSmsQuota).update({
                                              v:  newRemainingSMS,
                                          })
                                      }

                                      message.success('The Link has been SMS to student phone!');
                                      setLivePhotoModal(false);
                      
                                  }})


                                }} size="medium" className='gx-text-white gx-mb-0 gx-btn-darkerActionBlue'>{t("Use User Mobile Phone")}</Button>

                        </Col>
                    </Row>

            </Modal>
        </>
    );
};

export default UserProfileDrawer;