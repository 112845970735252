import { Button, Col, Form, Input, Row ,Space, Card,Switch, Dropdown, Menu, Collapse, Select} from "antd";
import React, { useState, useEffect } from "react";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { decodeString, encodeString } from "../../../util/misc";
import firebase from 'firebase';
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash-es";
import { message } from "antd/lib/index";
import PanelHead from "../../../components/PanelHead";
import { currency_code } from "../../../Common-Currency";


const database                      = firebase.database();
const currencyDropDownOptions       = [];

for (let oneCurrency in currency_code){

    let {name, symbol, code} = currency_code[oneCurrency];

    currencyDropDownOptions.push({
        value: code,
        label: `${name} - ${symbol}`,
    })
}

currencyDropDownOptions?.sort((a, b) => a.label.localeCompare(b.label));
  
const Preferences = () => {
    const { Panel }                                     = Collapse;
    const { t }                                         = useTranslation();
    const { db }                                        = useSelector(({ auth }) => auth);
    const [institutionDefault, setInstitutionDefault]   = useState();
    const [currencyDefault, setCurrencyDefault]         = useState();
    const [languageDefault, setLanguageDefault]         = useState();
    const [themeDefault, setThemeDefault]               = useState();
   
    //set default name and other fields
    useEffect(() => {

        let institutionType  = get(db, ['Preferences', "type"], 'Select Type');
        let defaultLanguage  = get(db, ['Preferences', "language"], 'Select Language');
        let defaultTheme     = get(db, ['Preferences', "theme"], 'Pick Theme');
        let defaultCurrency  = get(db, ['Preferences', "currency"], 'BDT');

        setInstitutionDefault(institutionType);
        setLanguageDefault(defaultLanguage);
        setThemeDefault(defaultTheme);
        setCurrencyDefault(defaultCurrency);

    }, [db]);

    const handleChangeIT = (value) => {
        
        if(value){
            setInstitutionDefault(value);
            database.ref(`USERS/${db['UID']}/Preferences`).update({type: value})
            .then(res => {
                message.success(`You selected the Institution Type as ${value}`);
            })
            .catch(e=>{
                message.error(e)
            })
        }
    };
    const handleChangeDL = (value) => {
        
        if(value){
            setLanguageDefault(value);
            database.ref(`USERS/${db['UID']}/Preferences`).update({language: value})
            .then(res => {
                message.success(`You selected the Default Language as ${value}`);
            })
            .catch(e=>{
                message.error(e)
            })
        }
    };
    const handleChangeDT = (value) => {
        
        if(value){
            setThemeDefault(value);
            database.ref(`USERS/${db['UID']}/Preferences`).update({theme: value})
            .then(res => {
                message.success(`You selected the Default Theme as ${value}`);
            })
            .catch(e=>{
                message.error(e)
            })
        }
    };

    const handleChangeCurrency = (value) => {
        if(value){
            setCurrencyDefault(value);
            database.ref(`USERS/${db['UID']}/Preferences`).update({currency: value})
            .then(res => {
                message.success(`Your currency has been updated to ${value}`);
            })
            .catch(e=>{
                message.error(e)
            })
        }

    };

    return (
        <div className="gx-p-3">
            <Collapse defaultActiveKey={['1', '2', '3']} ghost>
                <Panel header={<PanelHead title={t("Genaral")} isLineAfterText={false} titleLevel={5}/>} key="1" style={{fontSize: '18px'}} >
                    <Row className="gx-p-3">
                        <Col span={18}>
                            <h3 className="gx-fs-xl gx-mb-0 gx-font-weight-normal">Currency</h3>
                            <span className="gx-fs-md">Configure your currency to be used across the whole app</span>
                        </Col>
                        <Col span={6}>
                            <div className="gx-py-2">
                            <Select
                                value   =   {currencyDefault}
                                style={{
                                    width: '100%',
                                }}
                                onChange={handleChangeCurrency}
                                options={currencyDropDownOptions}
                            />
                            </div>
                        </Col>
                        <Col span={18} className="gx-pt-3">
                            <h3 className="gx-fs-xl gx-mb-0 gx-font-weight-normal">Institution Type</h3>
                            <span className="gx-fs-md">Configure this product to be used for university, school or private tutor.</span>
                        </Col>
                        <Col span={6} className="gx-pt-3">
                            <div className="gx-py-2">
                            <Select
                                value   =   {institutionDefault}
                                style={{
                                    width: '100%',
                                }}
                                onChange={handleChangeIT}
                                options={[
                                    {
                                    value: 'University',
                                    label: 'University',
                                    },
                                    {
                                    value: 'School',
                                    label: 'School',
                                    },
                                    {
                                    value: 'Small_Coaching',
                                    label: 'Small Coaching/ Private Tutor',
                                    },
                                ]}
                            />
                            </div>
                        </Col>
                        <Col span={18} className="gx-pt-3">
                            <h3 className="gx-fs-xl gx-mb-0 gx-font-weight-normal">Default Language</h3>
                            <span className="gx-fs-md">Configure the site to use your preferred language</span>
                        </Col>
                        <Col span={6} className="gx-pt-3">
                            <div className="gx-py-2">
                            <Select
                                value   =   {languageDefault}
                                style={{
                                    width: '100%',
                                }}
                                onChange={handleChangeDL}
                                options={[
                                    {
                                    value: 'Bangla',
                                    label: 'Bangla',
                                    },
                                    {
                                    value: 'English',
                                    label: 'English',
                                    }
                                ]}
                            />
                            </div>
                        </Col>
                        <Col span={18} className="gx-pt-3">
                            <h3 className="gx-fs-xl gx-mb-0 gx-font-weight-normal">Default Theme</h3>
                            <span className="gx-fs-md">Selecting a theme will alter the accent color of the entire site.</span>
                        </Col>
                        <Col span={6} className="gx-pt-3">
                            <div className="gx-py-2">
                                <Select
                                    value={themeDefault}
                                    style={{
                                        width: '100%',
                                    }}
                                    onChange={handleChangeDT}
                                    options={[
                                        {
                                        value: 'Dark_mode',
                                        label: 'Dark Mode',
                                        },
                                        {
                                        value: 'Light_mode',
                                        label: 'Light Mode',
                                        }
                                    ]}
                                />
                            </div>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        </div>
    );
};

export default Preferences;
