import React, { useState, useEffect } from "react";
import { Modal, Button , Tooltip, Collapse} from "antd";
import { Content } from "antd/lib/layout/layout";
import { useTranslation } from "react-i18next";
import {  EditOutlined } from "@ant-design/icons";
import { decodeString } from "../../util/misc";
import { useSelector } from "react-redux";
import { get } from "lodash-es";
import AddTemplateModal from "../../routes/main/sms_settings/AddTemplateModal";
import Mixpanel from "../../api/Mixpanel";

const { Panel } = Collapse;

const Templates = ({ setTextAreaValue, textAreaValue, loading, currentFileLoaded }) => {

    const [modal1Visible, setModal1Visible] = useState(false);
    const { t } = useTranslation();
    const [isTemplateModalVisibleAdd, setIsTemplateModalVisibleAdd]   = useState(false);
    const { db }                                                      = useSelector(({ auth }) => auth);
    
    const [defaultMessage_, setDefaultMessage_]                       = useState(undefined);
    const [templatesJSX, setTemplatesJSX]                             = useState([]);
    

    useEffect(() => {

        const temp              = [];
        let smsTemplates        = get(db, ["SMS-Templates"], {});

        for (let key in smsTemplates) {
            const templates = smsTemplates[key];
            temp.push({
                key : key,
                ...templates,
                message: decodeString(templates.message),
            });
        }

        let outputJSX           = [];
        let index               = 1;

        for (let item of temp){

            //need to find and remove Excel1 mentions in this message
            let localMessage = item.message;

            let fileArray = [];
            for (let oneExcel in currentFileLoaded){
                fileArray.push(currentFileLoaded[oneExcel]);
            }

            //ascending order
            fileArray.sort((a, b) => {
                return a.index - b.index
            });

            if (fileArray[0]){
                localMessage = localMessage.replaceAll('Excel1', fileArray[0]['id'] ? decodeString(fileArray[0]['id']) : '');
            }

            outputJSX.push(                        
                <Panel 
                    header={item.description ? decodeString(item.description): `Template ${index}`} 
                    key={index} 
                    extra={<Button size        =   "small" 
                            className   =   'gx-btn-info'  onClick={()=>{
                        setModal1Visible(false);
                        setTextAreaValue(
                            `${textAreaValue}${localMessage}`
                        );
                    }}>{'USE'}</Button>}
                >
                    <div className="gx-pt-3 gx-pb-3">{localMessage}</div>
                </Panel>
            )
            index = index + 1;
        }

        setTemplatesJSX(outputJSX);


    }, [db, setTextAreaValue, setModal1Visible, textAreaValue, currentFileLoaded, modal1Visible]);

    const saveCurrentMessageNewTemplate = () => {
        setDefaultMessage_(textAreaValue);
        setIsTemplateModalVisibleAdd(true);
    }



    return (
        <>
            <Button 
                loading={loading===undefined ? false : loading}
                data-testid="sms-template-btn"
                className="gx-btn-purple" 
                onClick={() => {setModal1Visible(true); Mixpanel.record({eventName: 'OPEN-SMS-TEMPLATES', eventProperties: {}})}}>
                    {t("Templates")} <EditOutlined />
            </Button>

            <Modal
                title= {t("Templates")}
                className="template-modal"
                width={400}
                footer={[

                    <Tooltip key={563} title="Quickly add your current message content as a new template">
                        <Button 
                            onClick     =   {() => saveCurrentMessageNewTemplate()} 
                            size        =   "medium" 
                            className   =   'gx-ml-3 gx-mt-1 gx-mb-1 gx-btn-geekblue' 
                            type="primary"
                        >
                            {t('Save Current Message')}
                        </Button>
                    </Tooltip>

                ]}
                bodyStyle={{
                    height: "400px",
                    overflowY: "scroll",
                }}
                visible={modal1Visible}
                onOk={() => setModal1Visible(false)}
                onCancel={() => setModal1Visible(false)}
                data-testid         =   "all-templates-container"
            >
                <Content className="gx-px-1">

                <Collapse
                    defaultActiveKey    =   {[]}
                    onChange            =   {(k)=>{console.log(k)}}
                    expandIconPosition  =   {'start'}
                    className           =   "template-collapse-panel-cont"
                    accordion
                    
                >

                    {templatesJSX.length === 0 ? 
                    <p style={{padding: '8%'}}>
                        {t('You do not have any templates added yet')}
                    </p> : templatesJSX}

                </Collapse>


                </Content>
            </Modal>

            <AddTemplateModal 
              key               =   {111}
              isModalVisible    =   {isTemplateModalVisibleAdd}
              handleCancel      =   {()=>setIsTemplateModalVisibleAdd(false)}
              handleOk          =   {()=>setIsTemplateModalVisibleAdd(false)}
              defaultMessage    =   {defaultMessage_}
            />
        </>
    );
};

export default Templates;