import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Input, Image , Form, message, Menu, Dropdown} from 'antd';

import GroupTableTransfer from '../../../../components/GroupTableTransfer/index';
import { get, unset, set, forEach } from "lodash-es";
import { useSelector } from 'react-redux';
import { encodeString, isValidPhoneNumber, isNumber, getRandomInt, decodeString, fixBangladeshPhoneNumbers } from "../../../../util/misc";
import firebase from 'firebase';
import { useTranslation } from "react-i18next";
import AddFromFile from "../../../../components/SendSMSModal/AddFromFile";
import AddGroups from "../../../../components/SendSMSModal/AddGroups";
import {  PlusOutlined } from "@ant-design/icons";
const database    = firebase.database();



const AddGroupModal = ({ isModalVisible, loading, handleOk, handleCancel, onItemSelect }) => {

    const [form]                                    = Form.useForm();
    const { db }                                    = useSelector(({ auth }) => auth);
    const [targetKeys, setTargetKeys]               = useState([]);
    const [disabled, setDisabled]                   = useState(false);
    const [allStudentInfo, setAllStudentInfo]       = useState([]);

    const [customName, setCustomName]               = useState('');
    const [customPhone, setCustomPhone]             = useState('');

    const [excelFileData, setExcelFileData]         = useState({}); //this will keep a unique id of any excel file loaded and a 2d array representation of all of its data
    const [totalExcelLoaded, setTotalExcelLoaded]   = useState(1);

    const [advancedExcelINFO, setAdvancedExcelinfo] = useState(true);
    const [recepients, setRecepients]               = useState([]);
    const [mode, setMode]                           = useState('SMS');

    const { t }                                     = useTranslation();

    useEffect(() => {

        let currentAppMode = get(db, ['ReactAppMode'], undefined);
        if (currentAppMode === 'SMS_LMS_GATEWAY'){
            setMode('LMS');
        }
        else{
            setMode('SMS');
        }

    }, [db]);

    const leftTableColumns = [
        {
            title: t('Name'),
            dataIndex: 'Name',
            render: (t, r) => 
            //if customNumber then do not treat this as student // treat this as a single number
            {return r.customNumber !== undefined ? 
            <>
                <Image style={{ width: '25px', borderRadius: '50%' }} src={`${r.avatarURL}`} /> <span>{r.customName}</span>
            </>: 
                <>
                    <Image style={{ width: '25px', borderRadius: '50%' }} src={`${r.avatarURL}`} /> <span>{t}</span>
                    <Row style={{paddingLeft: '30px'}}>
                        <Col>
                            <h6 className="darkMode-Color-White-title">Parents 1</h6>
                            <h6 className="darkMode-Color-White-title">Parents 2</h6>
                        </Col>
                    </Row>
                </>
        
            }
        },
        {
            title: t('Phone number'),
            dataIndex: 'Phone',
            render: (t, r) => 
                //if customNumber then do not treat this as student // treat this as a single number        
                {
 
                    return r.customNumber !== undefined ? 
                    <span>{t}</span>
                    : 
                    <>
                        <span>{t}</span>
                        <Row style={{ paddingTop: '5px'}}>
                            <Col>
                                <h5 className="darkMode-Color-White-title">{r.parent1phone? r.parent1phone: "Number not given"}</h5>
                                <h5 className="darkMode-Color-White-title">{r.parent2phone? r.parent2phone: "Number not given"}</h5>
                            </Col>
                        </Row>
                    </>
            
                }
        },
    ];
    const rightTableColumns = [
        {
            dataIndex: 'Name',
            title: t('Name'),
            render: (t, r) => 
            //if customNumber then do not treat this as student // treat this as a single number
            {return r.customNumber !== undefined ? 
                <>
                    <Image style={{ width: '25px', borderRadius: '50%' }} src={`${r.avatarURL}`} /> <span>{r.customName}</span>
                </>: 
                    <>
                        <Image style={{ width: '25px', borderRadius: '50%' }} src={`${r.avatarURL}`} /> <span>{t}</span>
                        <Row style={{paddingLeft: '30px'}}>
                            <Col>
                                <h6 className="darkMode-Color-White-title">Parents 1</h6>
                                <h6 className="darkMode-Color-White-title">Parents 2</h6>
                            </Col>
                        </Row>
                    </>
            
                }
        },
        {
            title: t('Phone number'),
            dataIndex: 'Phone',
            render: (t, r) => 
                //if customNumber then do not treat this as student // treat this as a single number        
                {
                    return r.customNumber !== undefined ? 
                    <span>{t}</span>
                    : 
                    <>
                        <span>{t}</span>
                        <Row style={{ paddingTop: '5px'}}>
                            <Col>
                                <h5 className="darkMode-Color-White-title">{r.parent1phone? r.parent1phone: "Number not given"}</h5>
                                <h5 className="darkMode-Color-White-title">{r.parent2phone? r.parent2phone: "Number not given"}</h5>
                            </Col>
                        </Row>
                    </>
            
                }
            
        },
    ];

    const filterOption = (inputValue, option) =>{
        let name = option.Name ? option.Name : option.customName; 
        let phone = option.Phone ? option.Phone : option.customPhone;   
        let itemValue = option.customID + name.toLowerCase() + phone.toLowerCase();
        return itemValue.indexOf(inputValue.toLowerCase()) > -1;
        
    };

    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
    };
    
    const onFinish = async (values) => {
        const {name} = values;

        let localCopyOfAllStudentInfo = [...allStudentInfo];

        //remove any here that does not have target key
        let newSelectedData           = [];
        for (let oneUserObj of localCopyOfAllStudentInfo){
            if (targetKeys.includes(oneUserObj['key'])){
                newSelectedData.push(oneUserObj);
            }
        }

        const data = {
            groupName : encodeString(name),
            groupColor: '#BF40BF',
            selectedStudents: newSelectedData,
            targetKey: targetKeys
        }

        if (data.groupName === undefined || data.groupName === null){
            message.error("Please Provide a Group Name");
            return false;
        }

        let dbAddress =  `USERS/${db['UID']}/GROUPS/`;
        await database.ref(dbAddress).push(data)
        .then(res => {
            message.success('New SMS Group has been added successfully!');
            form.resetFields();
            setTargetKeys([]);
            handleOk();
        })
        .catch(e=>{
            message.error(e)
        })
    };
    
    useEffect(()=>{
        const allStudentData                        =   [];
        let UniqueStudentAlphabeticalList           =   get(db, ['VolatileLocalData', 'UniqueStudentAlphabeticalList'], {}); 

        for (let std in UniqueStudentAlphabeticalList){
            let student = UniqueStudentAlphabeticalList[std]

            if (!student['parent1phone']) unset(student, ['parent1phone']);
            if (!student['parent2phone']) unset(student, ['parent2phone']);

            allStudentData.push({
                key : student.UID,
                ...student,
            });
        }

        //also loop through groups and any extra numbers add it to existing numbers
        let groups                      =   get(db, ["GROUPS"], {});
        let allCustomNumbers            =   {};

        for (let key in groups) {
            let groupsDetails       = groups[key];
            let selectedPeople      = get(groupsDetails, ['selectedStudents'], []);

            for (let oneNumber of selectedPeople){
                if (oneNumber['customNumber']){
                    allCustomNumbers[oneNumber['customName']] = oneNumber['Phone'];
                }

            }
        }

        for (let customName in allCustomNumbers){

            let phone_          =   allCustomNumbers[customName];
            allStudentData.push({
                customNumber        : true,
                customName          : customName,
                customPhone         : `${phone_}`,
                Phone               : `${phone_}`,
                key                 : customName,
                avatarURL           : 'https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png',
            });
        }

        setAllStudentInfo(allStudentData);
        
    },[db]);

    function getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    //import numbers from other places MENU..
    const menu = (
        <Menu onClick={(e) => {}}>
            <Menu.Item key="1" type="primary">
                <AddFromFile
                    key                     =   "4"
                    getNumbers              =   {({fileName, numbers, id})=>{

                                                    if (numbers.length === 0){
                                                        message.error('Could not find any valid phone numbers!')
                                                    }
                                                    else{

                                                        let allPhoneJSON    = [];
                                                        let allNewKeys      = [];

                                                        for (let onePhone of numbers){

                                                            if (onePhone !== null && onePhone!=='+880'){

                                                                onePhone = fixBangladeshPhoneNumbers(onePhone);
                                                                if (!allStudentInfo.find(item => item.key === `${onePhone}`)){
                                                                    let thisKey         = onePhone;
                                                                    allNewKeys.push(thisKey);
                                                                    allPhoneJSON.push({
                                                                        customNumber    : true,
                                                                        customName      : `From ${fileName}`,
                                                                        customPhone     : onePhone,
                                                                        Phone           : onePhone,
                                                                        key             : thisKey,
                                                                        avatarURL       : 'https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png',
                                                                    });
                                                                }
                                                            }
                                                        }

                                                        if (allPhoneJSON.length === 0){
                                                            message.error('Could not find any valid numbers in your uploaded Excel File / Selected Column');
                                                            return false;
                                                        }

                                                        setAllStudentInfo(existingInfo => {
                                            
                                                            let currentExistingInfo = [...existingInfo];
                                                            let newExistingInfo = [...allPhoneJSON, ...currentExistingInfo];
                                                            
                                                            return (newExistingInfo);
                                                        });
                                                
                                                        //also set the target key so these numbers will auto be transferred to the selected group table
                                                        setTargetKeys(currentKeys => {
                                                            let currentExistingInfo = [...currentKeys];
                                                            let newKeys             = [...allNewKeys, ...currentExistingInfo];
                                                            
                                                            return (newKeys)
                                                        });
                                                      
                                                        message.info('Numbers imported successfully');
                                                    }

                                                }}

                    getFileData             =   {(data)=>{
                                                setExcelFileData(currentExcelFileData => {
                                                    let newFileDataKEY  = data.id;
                                                    let newJSONDATA     =   currentExcelFileData
                                                    set(newJSONDATA, [newFileDataKEY], data);
                                                    return newJSONDATA;
                                                })
                                            }}
                    currentExcelFilesLoaded = {totalExcelLoaded}
                />
            </Menu.Item>
            <Menu.Item key="2" type="primary">
                <AddGroups
                    key         = "5"

                    getNumbers  =   {groupObjArr => {

                        let allPhoneJSON    = [];
                        let allNewKeys      = [];

                        for (let oneGroup of groupObjArr){

                            let {groupName, numbers} = oneGroup;

                            for (let onePhone of numbers){

                                if (onePhone !== null && onePhone!=='+880'){

                                    onePhone = fixBangladeshPhoneNumbers(onePhone);

                                    if (!allStudentInfo.find(item => item.key === `${onePhone}`)){
                                        let thisKey         = onePhone;
                                        allNewKeys.push(thisKey);
                                        allPhoneJSON.push({
                                            customNumber    : true,
                                            customName      : `From ${decodeString(groupName)}`,
                                            customPhone     : onePhone,
                                            Phone           : onePhone,
                                            key             : thisKey,
                                            avatarURL       : 'https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png',
                                        });
                                    }
                                }
                            }

                        }

                        setAllStudentInfo(existingInfo => {
                    
                            let currentExistingInfo = [...existingInfo];
                            let newExistingInfo = [...allPhoneJSON, ...currentExistingInfo];
                            return(newExistingInfo);
                        });
                
                        //also set the target key so these numbers will auto be transferred to the selected group table
                        setTargetKeys(currentKeys => {
                            let currentExistingInfo = [...currentKeys];
                            let newKeys             = [...allNewKeys, ...currentExistingInfo];
                            //var deduped = Array.from(new Set(newKeys));
                            return (newKeys) 
                        });

                        message.info('Numbers imported successfully');

                    }}

                />
            </Menu.Item>


        </Menu>
    );

    const addCustomNumber = () => {
                                        
        if (customName !== '' && isValidPhoneNumber(`+880${customPhone}`)){

            //check to see if this number has already been imported
            for (let oneInfo of allStudentInfo){
                //console.log('oneInfo: ', oneInfo);
                if (`+880${customPhone}` === oneInfo['Phone']){
                    if (targetKeys.includes(oneInfo['key'])){
                        message.error('This Phone number has already been added to this Group!');
                        return false;
                    }
                }
            }

            setAllStudentInfo(existingInfo => {

                let currentExistingInfo = [...existingInfo];
                currentExistingInfo.unshift({
                    customNumber: true,
                    customName: customName,
                    customPhone: `+880${customPhone}`,
                    Phone: `+880${customPhone}`,
                    key: customName,
                    avatarURL: 'https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png',
                })
                return(currentExistingInfo);
            });

            //also set the target key so these numbers will auto be transferred to the selected group table
            setTargetKeys(currentKeys => {
                let currentExistingInfo = [...currentKeys];
                currentExistingInfo.unshift(customName)
                return(currentExistingInfo)
            });

        }
        else{
            message.error('Please enter a proper contact name and phone number e.g +8801700766173')
            return false;   
        }

    }

    return (
        <>
            <Modal
                centered
                title           =   "Create New SMS Group"
                visible         =   {isModalVisible}
                bodyStyle       =   {{paddingTop: '5px'}}
                onOk            =   {handleOk}
                onCancel        =   {handleCancel}
                width           =   {950}
                footer          =   {[
                    <Button form='myForm' type="primary" htmlType="submit">
                        {t("Create SMS Group")}
                    </Button>
                ]}
            >
                <div className="content-insideModal-smsSetting-groups">
                    <Form
                        id="myForm"
                        initialValues   =   {{ remember: true }}
                        name            =   "basic"
                        form            =   {form}
                        onFinish        =   {onFinish}
                        layout          =   "horizontal"
                        className       =   "gx-form-row0"
                    >
                        <Row>
                        
                            <Col span={24}>

                                <Form.Item
                                    name    =   "name"
                                    label   =   {t('Group Name')}
                                    rules   =   {[{ required: false, message: t('Group Name is required') }]}
                                >
                                    <Input style={{ padding: '5px', marginLeft: '15px' }} placeholder={t("Enter name")} />
                                </Form.Item>

                            </Col>

                            <Col span={24} className="gx-mb-4">

                                <label>Add A Custom Name & Phone </label>

                                <Row align="middle" justify="left" gutter={[12,12]}>


                                    <Col span={5} className="gx-px-0">

                                        <Input onChange={(e)=>
                                            setCustomName(e.target.value)
                                        } value={customName} type        =   "text" style={{ padding: '5px' }} placeholder={t("Contact Name")} />
                                        
                                    </Col>
                                    <Col span={6} className="gx-px-0 gx-ml-2">

                                        <Input  onChange={(e)=>
                                            setCustomPhone(e.target.value)
                                        } value={customPhone} prefix      =   "+880"  type        =   "text" style={{ padding: '5px' }} placeholder="1700755174" />
                                       
                                    </Col>

                                    <Col span={1}></Col>
                                    
                                    <Col span={6}>
                                        <Button className="gx-mb-0" type="primary" onClick={()=>addCustomNumber()}>Add Number To Group + </Button>
                                    
                                    </Col>

                                    <Col span={3}>
                                        <Dropdown overlay={menu} className="gx-mb-0">
                                            <Button type="dashed" className="gx-mb-0"
                                                onClick={
                                                    () => {
                                                        
                                                    }
                                                }
                                            >
                                                {t("Import Phone Numbers")} <PlusOutlined />
                                            </Button>
                                        </Dropdown>
                                    
                                    </Col>


                                </Row>

                            </Col>


                            <Col span={12}><h5 className="darkMode-Color-White-title">{t("Add From Existing Numbers")}</h5></Col>
                            <Col span={12}><h5 className="darkMode-Color-White-title">{t("Selected Numbers for Group")}</h5></Col>
                            <Col span={24}>
                                <GroupTableTransfer
                                    className       =   "table-striped-rows custom-transfer remove-header-transfer"
                                    dataSource      =   {allStudentInfo}
                                    targetKeys      =   {targetKeys}
                                    disabled        =   {disabled}
                                    showSearch
                                    filterOption    =   {filterOption}
                                    pagination      =   {false}
                                    onChange        =   {onChange}
                                    onItemSelect    =   {onItemSelect}
                                    leftColumns     =   {leftTableColumns}
                                    rightColumns    =   {rightTableColumns}
                                />
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

export default AddGroupModal;