import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import firebase from 'firebase';
import ImgCrop from 'antd-img-crop';
import { Drawer, Button, Collapse, Divider, Form, Input, Row, Col, Checkbox, Upload, Typography , message, Progress, Tooltip, notification, Modal, Card } from 'antd';
import { CloseOutlined, UploadOutlined, IdcardOutlined } from '@ant-design/icons'


import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { get, isNumber } from 'lodash-es';
import { decodeString, generateNumberKey, checkIfIdClashes, formatDate, fixBangladeshPhoneNumbers } from '../../../util/misc';

import PanelHead from '../../PanelHead';
import EdutechCloudServer from '../../../EdutechCloudServer';

const database    = firebase.database();



const EditUserDrawer = ({ size, onClose, visible, studentUID, zIndex }) => {

    const panelStyle = { fontSize: '18px', margin: '0px 20px', marginTop: '30px' };

    const [form]                                =   Form.useForm();
    const { Panel }                             =   Collapse;
    const { t }                                 =   useTranslation();
    const { db }                                =   useSelector(({ auth }) => auth);
    const [zIndexValue, setZIndexValue]         = useState('1002');
    const [loading, setLoading]                 =   useState(false);
    const [progressPercent, setProgressPercent] =   useState(0);
    const [showProgress, setShowProgress]       =   useState(false);

    const [studentPic, setStudentPic]           =   useState(undefined);
    const [uploadPicText, setUploadPicText]     =   useState('Click to upload');
    const [sInstitution, setSInstitution]       =   useState('');

    const [sName, setSName]                     =   useState('');
    const [avatar, setAvatar]                   =   useState('');
    const [phone, setPhone]                     =   useState('');
    const [email, setEmail]                     =   useState('');
    const [stdUid, setStdUid]                   =   useState('');
    const [customId, setCustomId]               =   useState('')
    
    const [stdAcceptBatches, setStdAcceptBatches]   = useState([]);
    const [restBatches, setRestBatches]         =   useState([]);
    const [accBatchesJx, setAccBatchesJx]       =   useState([])


    const [modalJx, setModalJx]                 =   useState([]);
    const [isModalVisible, setIsModalVisible]   =   useState(false);
    const [modalConfirmLoading, setModalConfirmLoading] = useState(false)

    const [unsavedChanges, setUnsavedChanges]   = useState(false);
    const [unsavedModal, setUnsavedModal]       = useState(false);
    const submitButton_REF                      = useRef();



    // set student all information 
    useEffect(()=>{

        if(zIndex){
            setZIndexValue(zIndex)
        }


        let localDataOfThisStudent = get(db, ['AddedUsers', studentUID], undefined);
        if (localDataOfThisStudent === undefined){
            return false;
        }

        const Name          = get(localDataOfThisStudent, ['Name'],'');
        const Email         = get(localDataOfThisStudent, ['Email'],'');
        const avatarURL     = get(localDataOfThisStudent, ['avatarURL'],'');
        const Phone         = get(localDataOfThisStudent, ['Phone'],'');
        const UID           = get(localDataOfThisStudent, ['UID'], '');
        let customID        = get(localDataOfThisStudent, ['customID'], '');

        if (isNumber(customID)) customID = `ID-${customID}`;


        setSName(Name);
        setEmail(Email);
        setStdUid(UID);
        setPhone(Phone);
        setAvatar(avatarURL);
        setCustomId(customID)
 


        form.setFieldsValue({
            StudentName         : decodeString(Name),
            StudentEmail        : decodeString(Email) === 'unset@gmail.com'? '' : decodeString(Email),
            StudentPhone        : Phone !== '' ? decodeString(Phone).toString().substring(4) : Phone,
            StudentID           : customID? customID.split('ID-').join("") : 'Unknown',
        })

       
        setLoading(false);    

    },[studentUID, form, db, zIndex])
    

    const normFile = (e) => {

        if (Array.isArray(e)) {
          return e;
        }
      
        return e?.fileList;
    };


    const onFinish = (values) => {

        
        let toUpdateOBJ = {
            studentuid      : stdUid,                           //REQUIRED
            phone           : fixBangladeshPhoneNumbers(values.StudentPhone),     //Either phone or email must be provided
            name            : values.StudentName, 
            email           : values.StudentEmail
        }


        editOneStudent(toUpdateOBJ, values, studentPic);

    };
    





    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };





    const editOneStudent = async (toUpdateOBJ, values, pictureObj) => {

        let {StudentID} = values;
       
        setLoading(true);
        setShowProgress(true);
        setProgressPercent(1);
        const loadingKey    = 'loading-key';
        message.loading('Finding User..', loadingKey);
        EdutechCloudServer.postRequest('api-react-client-update-seer-user', toUpdateOBJ
        , (response)=>{
        
            if (response.data){

                setProgressPercent(20);

                let studentUID  = response.data['UID'];
                let studentData = response.data;


                //need to check if the custom uid supplied clashes with any other account
                let {valid, clash} = checkIfCustomID_IsValid({uid: studentUID, customID: `ID-${StudentID}`});
                if (valid === false){
                    let h = message.error(`Your given custom ID is already in use by User ${decodeString(clash)}. Please use another!`, 0);
                    setLoading(false);
                    setTimeout(()=>{
                        h();
                    }, 5000);
                    setProgressPercent(100);
                    setShowProgress(false);
                    return false;
                }

                //if a student photo was supplied then upload it first
                if (pictureObj){
                    setProgressPercent(30);
                    uploadStudentPhoto({
                        photo: pictureObj,
                        uid: studentUID,
                        loadKey: loadingKey,
                        callback: (avatarURL)=>{

                            setProgressPercent(60);
                            studentData['PublicInfo']['avatarURL'] = avatarURL;
                            database.ref(`USERS/${studentUID}/PublicInfo`).update({'avatarURL': avatarURL});
                            message.loading('Student found, adding to Batches..', loadingKey);
                            //loop through all batches and add this student there

                            let thisStudents_LocalVolatileData  = get(db, ['AddedUsers', studentUID], undefined);

                            if (thisStudents_LocalVolatileData === undefined){
                                message.error('Error: Could not find this user in local database..');
                                return false;
                            }
                           
            
                            let promises = [];

                            let studentData_TeacherSide = {
                                Email           : get(studentData, ['PublicInfo', 'UserEmail'], 'Null'),
                                Name            : get(studentData, ['PublicInfo', 'UserName'], 'Null'),
                                Phone           : get(studentData, ['PublicInfo', 'UserPhone'], 'Null'),
                                Type            : 'Physical',
                                UID             : studentUID,
                                avatarURL       : avatarURL,
                                customID        : `ID-${StudentID}`
                            };

                            promises.push(database.ref(`USERS/${db['UID']}/AddedUsers/${studentUID}`).update(studentData_TeacherSide));

                            const studentData_StudentSide = {
                                ownerUID      : db['UID'],
                                Type            : 'Physical'
                            }

                            database.ref(`USERS/${studentUID}/AcceptedByOwner/${db['UID']}/`).update(studentData_StudentSide);
        
                            setProgressPercent(80);
                            Promise.all(promises)
                                .then(()=>{
                                    setLoading(false);
                                    onClose();
                                    setUnsavedChanges(false);
                                    setUnsavedModal(false);
                                    setProgressPercent(100);
                                    setShowProgress(false);
                                    message.success('User has been updated successfully!', loadingKey);
                                    database.ref(`USERS/${db['UID']}/`).update({forceReactClientRefresh: (new Date()).getTime()});

                                    notification.open({
                                        message: "For User Photo updates, please refresh the page to see them in effect.",
                                    });
                                })
                                .catch(e=>{
                                    setLoading(false);
                                    onClose();
                                    setUnsavedChanges(false);
                                    setUnsavedModal(false);
                                    setShowProgress(false);
                                    message.error(e, loadingKey);
                                })
                        }
                    })
                }
                else{

                    message.loading('User found, adding to Batches..', loadingKey);
                    //loop through all batches and add this student there
 
    
                    let promises = [];
                    let studentData_TeacherSide = {
                        Email           : get(studentData, ['PublicInfo', 'UserEmail'], 'Null'),
                        Name            : get(studentData, ['PublicInfo', 'UserName'], 'Null'),
                        Phone           : get(studentData, ['PublicInfo', 'UserPhone'], 'Null'),
                        Type            : 'Physical',
                        UID             : studentUID,
                        avatarURL       : get(studentData, ['PublicInfo', 'avatarURL'], 'Null'),
                        customID        : `ID-${StudentID}`,
                       
                    };

                    promises.push(database.ref(`USERS/${db['UID']}/AddedUsers/${studentUID}`).update(studentData_TeacherSide));

                    const studentData_StudentSide = {
                        ownerUID      : db['UID'],
                        Type            : 'Physical',
                    }

                    database.ref(`USERS/${studentUID}/AcceptedByOwner/${db['UID']}/`).update(studentData_StudentSide);


                    setProgressPercent(65);
                    Promise.all(promises)
                        .then(()=>{
                            setLoading(false);
                            onClose();
                            setUnsavedChanges(false);
                            setProgressPercent(100);
                            setShowProgress(false);
                            message.success('User has been update successfully!', loadingKey);
                            setUnsavedModal(false);
                            database.ref(`USERS/${db['UID']}/`).update({forceReactClientRefresh: (new Date()).getTime()});
                        })
                        .catch(e=>{
                            setLoading(false);
                            onClose();
                            setUnsavedChanges(false);
                            setUnsavedModal(false);
                            message.error(e, loadingKey);
                            setShowProgress(false);
                        })
                }


            }
            else{
                message.error(`Error ${response.error}`);
                setLoading(false);
                setShowProgress(false);
            }
        });
    }

    //will return obj {valid, clash}
    const checkIfCustomID_IsValid = ({uid, customID}) => {
        let valid       = true;
        let clash       = 'No Clash';
        let mapper      = {};
        //first create a mapper fn with custom-uid to UID
        let userClass = get(db, ['AddedUsers'], {});

        for (let oneSUID in userClass){
            if (userClass[oneSUID]['customID']){
                let this_customID = userClass[oneSUID]['customID'];
                mapper[this_customID] = {
                    uid: oneSUID,
                    name: userClass[oneSUID]['Name']
                }
            }
        }

        //if this students provided custom ID is already in user by another UID
        if (mapper[customID]){
            if (mapper[customID]['uid'] !== uid){
                return {valid:false, clash: mapper[customID]['name']}
            }
        }

        return {valid, clash}
    }

    const uploadStudentPhoto = async ({photo, uid,loadKey, callback}) => {

       
        message.loading('Uploading Student Picture..', loadKey);
        EdutechCloudServer.postRequest('upload-avatar', {}, async (res) => {
            if (res.msg === 'success'){

                let uploadUrl                           = res.uploadUrl;
                let uploadAuthorizationToken            = res.uploadAuthorizationToken;
                let filename                            = `${uid}-avatar.png`;
            
                await axios.post( uploadUrl, photo, {
                withCredentials: true,
                headers: {
                    Authorization: uploadAuthorizationToken,
                    'Content-Type': 'b2/x-auto',
                    'X-Bz-File-Name': `${filename}`,
                    'X-Bz-Content-Sha1': 'do_not_verify',
                },
                onUploadProgress: ({ loaded, total }) => {
                    const totalProgress = parseInt((loaded / total) * 100);
                    message.loading(`Uploading Student Picture ${totalProgress}%`, loadKey);
                }
                } )
            
                let friendlyURL = `https://f002.backblazeb2.com/file/Edutech-Avatars/${filename}`


                database.ref(`USERS/${uid}/PublicInfo`).update({
                    'avatarURL': friendlyURL
                })
                .then(()=>{
                    message.loading(`Student Picture uploaded successfully..`, loadKey);
                    setUnsavedChanges(true);
                    callback(friendlyURL);
                })
            }

            else{
                message.error('Image Upload Failed. Server could not provide valid upload token');
            }
        });

    }


    const onCloseDrawer = () => {

        // console.log('UNSAVED CHANGES? ', unsavedChanges);

        if (unsavedChanges === false){
            onClose();
        }
        else{
            //throw popup if user is sure 
            setUnsavedModal(true);
        }
    }

    const generateUniqueID = () => {
        let possibleKey = generateNumberKey(5);
        if (!checkIfIdClashes(String(possibleKey), db)){
            generateUniqueID();
        }
        else{
            form.setFieldsValue({
                StudentID: possibleKey
            })
        }
    }

    return (
        <>
            <Drawer
                size        =   {size}
                placement   =   "right"
                closable    =   {false}
                onClose     =   {onCloseDrawer}
                visible     =   {visible}
                zIndex      =   {zIndexValue}
            >
                <Form
                    form                    =   {form}
                    name                    =   "Edit One User Modal Drawer"
                    onFinish                =   {onFinish}
                    onFinishFailed          =   {onFinishFailed}
                    layout                  =   'vertical'
                    className               =   "gx-force-two-cols"
                    onValuesChange          =   {(e)=>setUnsavedChanges(true)}
                >
                    <div className="drawer-content">
                        <div
                            className   =   "drawer-head"
                            style       =   {{ display: 'flex', justifyContent: 'space-between', padding: '30px 20px 5px 30px' }}
                        >
                            <div
                                className   =   "drawer-head-left"
                                style       =   {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                            >

                                <CloseOutlined
                                    onClick     =   {onCloseDrawer}
                                    className   =   "gx-mr-3"
                                    style       =   {{ fontSize: '22px', cursor: 'pointer' }}
                                />

                                    <div className="drawer-title">
                                        <Typography.Title className='gx-mb-0' level={4}>Edit User Profile</Typography.Title>
                                        <span>{t("You may use their Phone and/or Email")}</span>
                                    </div>
                            </div>
                            <div className="drawer-head-right" style={{ display: 'flex', alignItems: 'center' }}>


                                <Button
                                    size        =   "medium"
                                    htmlType    =   "submit"
                                    className   =   "gx-btn-dark gx-mb-0"
                                    ref         =   {submitButton_REF}
                                >
                                    {t("Save Changes")}
                                </Button>


                            </div>
                        </div>

                        <Divider />

                        <div className="drawer-body" style={{ padding: '0px 0px 20px 0' }}>

                            {showProgress ?

                                <div className='gx-mr-5 gx-ml-5'>
                                    <Progress
                                        strokeColor={{
                                            '0%': '#108ee9',
                                            '100%': '#87d068',
                                        }}
                                        percent={progressPercent}
                                    />
                                </div> :
                                <></>
                            }


                            <Collapse bordered={false} defaultActiveKey={['1']} ghost={true}>
                                <Panel 
                                    header={<PanelHead title= {t("General")} isLineAfterText={false} titleLevel={5} />} 
                                    key="1" style={{ fontSize: '18px', margin: '0px 20px', marginTop: '5px' }} 
                                >
                                    <Row justify='space-evenly'>
                                        <Col span={12} >

                                            <Form.Item
                                                className   =   "gx-mb-0 gx-mt-0"
                                                label       =   {"User Name"}
                                                rules       =   {[{ required: true, message: t('Please enter your user name!') }]}
                                                name        =   "StudentName"
                                                required
                                                tooltip     =   {t("Must be provided")}

                                            >
                                                <Input
                                                    type        =   "text"
                                                    placeholder =   {t("Student Name")}
                                                    name        =   "StudentName"
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                className   =   "gx-mb-0 gx-mt-3"
                                                label       =   {"User Phone"}
                                                rules       =   {[{ required: true, message: t('Please enter your user phone!') }]}
                                                name        =   "StudentPhone"
                                                required
                                                tooltip     =   {t("Must be provided")}

                                            >
                                                <Input
                                                    type        =   "text"
                                                    placeholder =   "1700766173"
                                                    name        =   "StudentPhone"
                                                    prefix      =   "+880"
                                                />
                                            </Form.Item>


                                            <Form.Item
                                                className   =   "gx-mb-0 gx-mt-3"
                                                label       =   {"User Email"}
                                                rules       =   {[{ required: false, message: t('Please enter your user email!') }]}
                                                name        =   "StudentEmail"
                                            >
                                                <Input
                                                    type        =   "email"
                                                    placeholder =   "john@gmail.com"
                                                    name        =   "StudentEmail"
                                                />
                                            </Form.Item>


                                        </Col>

                                        <Col span={12}>

                                            <Form.Item
                                                className   =   "gx-mb-0 gx-mt-0"
                                                label       =   {"User ID"}
                                                rules       =   {[{ required: true, message: t('Please enter a unique user ID!') }]}
                                                name        =   "StudentID"
                                                // tooltip     =   "Students ID is fixed"
                                            >
                                                <Input
                                                    // disabled    =   {true}
                                                    type        =   "text"
                                                    placeholder =   "007"
                                                    name        =   "StudentID"
                                                    prefix      =   "ID-"
                                                    addonAfter      =   {<>
                                                                            <Tooltip title="Generate Unique ID">
                                                                                <Button size="small" type="text" icon={<IdcardOutlined />} className="gx-mb-0" onClick={()=>generateUniqueID()}></Button>
                                    
                                                                            </Tooltip>
                                                                        </>}
                                                />
                                            </Form.Item>


                                            <Form.Item
                                                className       =   "gx-mb-0 gx-mt-3"
                                                name            =   "StudentPicture"
                                                label           =   {"User Picture"}
                                                valuePropName   =   "fileList"
                                                getValueFromEvent=  {normFile}
                                            >
                                                <ImgCrop rotate>

                                                    <Upload
                                                        name="StudentPicture"
                                                        maxCount={1}
                                                        action={(file) => console.log(file)}
                                                        showUploadList={false}
                                                        customRequest={(x) => {
                                                            setStudentPic(x.file);
                                                            setUploadPicText(x.file.name);
                                                        }}
                                                        listType="picture"
                                                        beforeUpload={(file) => {
                                                            const isPNG = (file.type === 'image/png') || (file.type === 'image/jpg') || (file.type === 'image/jpeg');

                                                            if (!isPNG) {
                                                                message.error(`${file.name} is not a png, jpg or jpeg file`);
                                                            }

                                                            return isPNG || Upload.LIST_IGNORE;
                                                        }}
                                                    >
                                                        <Button block icon={<UploadOutlined />} className="gx-btn-dullLavender" type="dashed">
                                                            {uploadPicText}
                                                        </Button>
                                                    </Upload>

                                                </ImgCrop>
                                            </Form.Item>


                                        </Col>

                                    </Row>
                                </Panel>

                            </Collapse>
                        </div>
                    </div>

                </Form>

            </Drawer>


            <Modal
                title           =   {t("You have unsaved changes. Do you want to save them?")}
                visible         =   {unsavedModal}
                onCancel        =   {() => {
                    setUnsavedModal(false);
                    setUnsavedChanges(false);
                    onClose();
                }}
                bodyStyle       =   {{ padding: 0, margin: 0 }}
                wrapClassName   =   {'PromptWithMultiOptions'}
                style           =   {{top: 200}}
                zIndex          =   {1006}

                footer={[
                    <div key={12312347653847}>
                        <Button loading={loading} onClick={() => {
                                setUnsavedModal(false);
                                setUnsavedChanges(false);
                                onClose();
                            }} size="medium" className='gx-text-white gx-mb-0 gx-btn-actionBlue'>{t("No")}</Button>
                        
                        <Button loading={loading} onClick={() => {
                            //submit form
                            submitButton_REF.current.click();
                        }} size="medium" className='gx-text-white gx-mb-0 gx-btn-darkerActionBlue'>{t("Yes, Save and Implement Changes")}</Button>
                    </div>

                ]}
            >
                <span style={{ height: '5px' }}>&nbsp;</span>

            </Modal>
        </>
    );
};

export default EditUserDrawer;