import {
    Button,
    Modal, Table, Spin, Card, Row, Col, Progress, message
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';
import SMS from "../../../api/SMS";

import { formatDateAMPM, copyToClipboard, getRandomInt } from "../../../util/misc";
import { CheckCircleTwoTone, CloseCircleFilled, ReloadOutlined } from '@ant-design/icons';

const { Column }            = Table;

const antIcon               = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
);



const SentSMSStatusModalTable = ({ request_id, setIsModalVisible, isModalVisible, sentTimeStamp, resendCallback }) => {

    const { db }                                        = useSelector(({ auth }) => auth);
    const { t }                                         = useTranslation();

    const [tableDataSource, setTableData]               = useState([]);
    const [loading, setLoading]                         = useState(false);

    const [totalSMS, setTotalSMS]                       = useState(0);
    const [successSMS, setSuccessSMS]                   = useState(0);
    const [failSMS, setFailSMS]                         = useState(0);
    const [pendSMS, setPendSMS]                         = useState(0);
    
    const [selectedRows, setSelectedRows]               = useState();
    const [progSuccess, setProgSuccess]                 = useState(0);
    const [progPending, setProgPending]                 = useState(0);

    const [refreshCounter, setRefreshCounter]           = useState(0);

    const recursiveLoad_ArraySenderID = ({index, senderID_Arr, finalArr_JSX, callback}) => {

        if (senderID_Arr[index] === undefined){
            callback(finalArr_JSX);
            return false;
        }

        let one_request_id = senderID_Arr[index];

        SMS.getSentSMS_INFO({sms_id: one_request_id, uid: db['UID'], callback: (smsResponse)=>{
          
            let {recipients} = smsResponse['data'];
            
            for (let index = 0; index < recipients.length; index++) {

                let {number, status} = recipients[index];
                let jsx_status_comp  = <></>;

                if (status === 'Sent') {
                    jsx_status_comp = <Row gutter={[2,0]} justify="center" data-status="success">
                                        <Col span={2}>
                                            <CheckCircleTwoTone style={{paddingTop: "15px"}} twoToneColor="#2FBB67" />
                                        </Col> 
                                        <Col span={10}>
                                            <p style={{paddingTop: "13px",color: "#2FBB67"}}>Delivered</p>
                                        </Col>
                                    </Row>
                }
                if (status === 'Pending') {
                    jsx_status_comp = <Row gutter={[2,0]} justify="center" data-status="pending">
                                        <Col span={2}>
                                            <ReloadOutlined style={{paddingTop: "15px"}} twoToneColor="#038FDE" />
                                        </Col> 
                                        <Col span={10}>
                                            <p style={{paddingTop: "13px",color: "#038FDE"}}>In Transit</p>
                                        </Col>
                                    </Row>
                }
                if (status === 'Fail') {
                    jsx_status_comp = <Row gutter={[2,0]} justify="center" data-status="fail">
                                        <Col span={2}>
                                            <CloseCircleFilled style={{paddingTop: "15px"}} twoToneColor="#F5222D" />
                                        </Col> 
                                        <Col span={10}>
                                            <p style={{paddingTop: "13px",color: "#F5222D"}}>Failed</p>
                                        </Col>
                                    </Row>
                }


                finalArr_JSX.push({
                    key: getRandomInt(0,9999999),
                    "Phone": number,
                    "Status": status,
                    phoneComponent: 
                        <p style={{paddingTop: "15px"}}>
                            +{number}
                        </p>,
                    statusComponent: jsx_status_comp,   
                });

                console.log('Completed One recursion with ongoing final JSX looking like: ', finalArr_JSX);
            }

            index = index + 1;
            recursiveLoad_ArraySenderID({index, senderID_Arr, finalArr_JSX, callback})
        }})


    }

    useEffect(()=>{

        //console.log('Loading SMS RESPONSE MODAL USEEFFECT: ', {request_id, db, refreshCounter});

        if (!db) return false;

        let outputArr           =   [];
        let jsxStatusComp       =   [];

        setTableData([]);
        setTotalSMS(0);
        setSuccessSMS(0);
        setFailSMS(0);
        setPendSMS(0);
        setSelectedRows(undefined);
        setProgSuccess(0);
        setProgPending(0);
        setLoading(true);

        setTimeout(()=>{

            if (db['UID'] && request_id){

                //check to see if request_id is a string or array
                //if string then its a single SMS message multiple recepients
                //if array then different SMS message was sent to every single recepients
                if (Array.isArray(request_id)){
                    
                    message.info('Please be patient. This may take some time to fetch status of all numbers.');
                    recursiveLoad_ArraySenderID({
                        index           : 0, 
                        senderID_Arr    : request_id, 
                        finalArr_JSX    : [], 
                        callback        : (r)=>{
                            console.log('RECURSIVE FETCH OVER: ', r);

                            let totalSuccess = 0;
                            let totalFail    = 0;
                            let totalPending = 0;

                            for (let oneComp of r){
                                let status = oneComp['statusComponent']['props']['data-status'];

                                if (status === 'success'){
                                    totalSuccess = totalSuccess + 1;
                                }
                                if (status === 'pending'){
                                    totalPending = totalPending + 1;
                                }
                                if (status === 'fail'){
                                    totalFail = totalFail + 1;
                                }

                            }

                            setTableData(r);
                            setTotalSMS(request_id.length);
                            setLoading(false);

                            setSuccessSMS(totalSuccess);
                            setPendSMS(totalPending);
                            setFailSMS(totalFail);

                            //for Dynamic progress 
                            let success= totalSuccess*100 / (totalSuccess+totalPending+totalFail);
                            let pending= totalPending*100 / (totalSuccess+totalPending+totalFail);
                            setProgSuccess(success);
                            setProgPending(pending);
                            setSelectedRows([]);
                        }
                    })

                }
                else{
                    //simple one request_id will have all the info we need
                    console.log('SIMPLE ONE REQUEST ID SO ONCE HAS ALL INFO WE NEED..');
                    SMS.getSentSMS_INFO({sms_id: request_id, uid: db['UID'], callback: (smsResponse)=>{

                        let {recipients} = smsResponse['data'];
        
                        let totalSuccess = 0;
                        let totalPending = 0;
                        let totalError   = 0;
            
                        for (let index = 0; index < recipients.length; index++) {
            
                            let {number, status} = recipients[index];
        
                            if (status === 'Sent') {
                                totalSuccess++;
                                jsxStatusComp = <Row gutter={[2,0]} justify="center">
                                                    <Col span={2}>
                                                        <CheckCircleTwoTone style={{paddingTop: "15px"}} twoToneColor="#2FBB67" />
                                                    </Col> 
                                                    <Col span={10}>
                                                        <p style={{paddingTop: "13px",color: "#2FBB67"}}>Delivered</p>
                                                    </Col>
                                                </Row>
                            }
                            if (status === 'Pending') {
                                totalPending++;
                                jsxStatusComp = <Row gutter={[2,0]} justify="center">
                                                    <Col span={2}>
                                                        <ReloadOutlined style={{paddingTop: "15px"}} twoToneColor="#038FDE" />
                                                    </Col> 
                                                    <Col span={10}>
                                                        <p style={{paddingTop: "13px",color: "#038FDE"}}>In Transit</p>
                                                    </Col>
                                                </Row>
                            }
                            if (status === 'Fail') {
                                totalError++;
                                jsxStatusComp = <Row gutter={[2,0]} justify="center">
                                                    <Col span={2}>
                                                        <CloseCircleFilled style={{paddingTop: "15px"}} twoToneColor="#F5222D" />
                                                    </Col> 
                                                    <Col span={10}>
                                                        <p style={{paddingTop: "13px",color: "#F5222D"}}>Failed</p>
                                                    </Col>
                                                </Row>
                            }
                            outputArr.push({
                                key: getRandomInt(0,9999999),
                                "Phone": number,
                                "Status": status,
                                phoneComponent: 
                                    <p style={{paddingTop: "15px"}}>
                                        +{number}
                                    </p>,
                                statusComponent: jsxStatusComp,
                                    
                            })
            
                        }
        
                        setTotalSMS(recipients.length);
                        setSuccessSMS(totalSuccess);
                        setPendSMS(totalPending);
                        setFailSMS(totalError);
                        setLoading(false);
                        setTableData(outputArr);
        
                        //for Dynamic progress 
                        let success= totalSuccess*100 / (totalSuccess+totalPending+totalError);
                        let pending= totalPending*100 / (totalSuccess+totalPending+totalError);
                        setProgSuccess(success);
                        setProgPending(pending);
                        setSelectedRows([]);
        
                    }})
                }
    
            }

        }, 1000);


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request_id, refreshCounter]);

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOk = () => {

    }
    
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
        },
    };
      
    const resendFunc = () =>{
        resendCallback(selectedRows);
    }
    return (
        <>
            <Modal
                    width           =   {680}
                    title           =   "Sent SMS Status"
                    visible         =   {isModalVisible}
                    onOk            =   {handleOk}
                    onCancel        =   {handleCancel}
                    footer={[
                        <>

                            <Button
                                loading={loading}
                                disabled={selectedRows?.length === 0}
                                onClick={()=>{
                                    let finalText = '';

                                    for (let onePhoneOBJ of selectedRows){
                                        finalText = finalText + `+${onePhoneOBJ['Phone']},`
                                    }

                                    copyToClipboard(finalText);
                                    message.info('Selected numbers have been copied to your clipboard!');
                                }}
                                type="default"
                            >
                                {t("Copy")}
                            </Button>
                            
                            <Button
                                loading={loading}
                                disabled={selectedRows?.length === 0}
                                onClick={resendFunc}
                                style={{
                                    backgroundColor: '#545454',
                                    color: '#fff',
                                }}  
                            >
                                {t("Resend")}
                            </Button>

                            <Button loading={loading} onClick={() => setRefreshCounter(x=>x+1)} size="medium" className='gx-mb-0 gx-btn-geekblue' style={{ borderRight: '1px solid white' }}>Refresh and Check Again</Button>
                            

                        </>
                    ]}
                >

                {loading === true ? <span style={{    display: 'flex',justifyContent: 'center'}}>

                    <Spin indicator={antIcon} />

                </span>:
                
                <>
                    <Card className="card-css-design">
                        <Row>
                            <Col span={6}>
                                <p className="gx-fs-lg gx-font-weight-normal">Total Sent: {totalSMS}</p>  
                            </Col>
                            <Col span={2}>
                                |
                            </Col>
                            <Col span={12}>
                                <p className="gx-fs-lg gx-font-weight-normal">Time: {formatDateAMPM(new Date(sentTimeStamp))}</p>
                            </Col>
                            <Col span={8}>
                                <p className="success-text gx-fs-lg gx-font-weight-medium">Success: {successSMS}</p>
                            </Col>
                            <Col span={8}>
                                <p className="pending-text gx-fs-lg gx-font-weight-medium">In Transit: {pendSMS}</p>
                            </Col>
                            <Col span={8}>
                                <p className="fail-text gx-fs-lg gx-font-weight-medium">Fail: {failSMS}</p>
                            </Col>
                            <Col span={24}>
                                <Progress showInfo={false} percent={progSuccess+progPending} success={{ percent: progSuccess }} trailColor={"#F97F7C"}/>
                            </Col>
                        </Row>
                    </Card>

                    <Table
                        
                        className= "attendance-table scrollbar-remove"
                        rowSelection    =   {{ ...rowSelection }}
                        dataSource      =   {tableDataSource} 
                        pagination      =   {false}
                        style                   =   {{
                            height: "calc(50vh - 100px)",
                            overflowY: "auto",
                        }}        
                    >

                            <Column 
                                title       =   "Phone"
                                align       =   "left"
                                dataIndex   =   "Phone"
                                key         =   "Phone"
                                render      =   {(text, record, index) => {

                                    return record.phoneComponent
                                } }
                            />

                            <Column
                                title       =   "Status"
                                dataIndex   =   "Status"
                                key         =   "Status"
                                align       =   "center"
                                render      =   {(text, record, index) => {

                                    return record.statusComponent
                                } }
                            />
                    

                    </Table>
                
                </>
                
                }





            </Modal>
        </>
    );
};

export default SentSMSStatusModalTable;
