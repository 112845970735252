import React, { useEffect } from "react";
import { Button, Popover , Layout, Image, Space, Card, Row, Col, Carousel} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "../../Topbar/languageData";
import DarkLightMode from "../../../components/DarkLightMode";
import { CalendarOutlined } from "@ant-design/icons";
import LandingFooter from "../../Footer/LandingFooter";
import LandingHeader from "../../Topbar/LandingHeader";
import Column from "antd/lib/table/Column";
const { Header, Footer, Sider, Content } = Layout;

  

const AboutUs = () => {


    const dispatch                                            = useDispatch();
    const history                                             = useHistory();
    const { locale}                                           = useSelector(({ settings }) => settings);





  return (

    <Layout className="gx-app-layout">
        <Layout> 

            <LandingHeader />

            <Content
                className={`gx-layout-content gx-container-wrap`}
                >

                <div className   =   "bg-color-match-with-darkMode-2" style={{padding : '1% 5%'}}>
                    <Image
                        className   =   "gx-pt-2"
                        preview     =   {false}
                        width={'100%'}
                        src="/assets/images/Career_Page_Banner.webp"
                    />
                    <h1 className="gx-pt-5 gx-p-5 gx-fs-xlxl gx gx-font-weight-bold gx-text-center" style={{ color: '#1f3965'}}>Our core guiding principle: Improve Quality of Education and all factors of economic growth and prosperity will naturally follow</h1>
                    
                    <p className="gx-pt-2 gx-fs-xl" style={{textAlign: 'center'}}>Edutechs was bought about by a fervent desire to affect the lives of millions and allow them to live better lives. Cheap and good quality education is the fastest way to rejuventate the population and jump-start their economic growth. In the 21st century the best and fastest way to bring this about is by introducing the magic of technology into stagnant education practices. With thousands of other edtech companies creating amazing education tools, one area that is neglected is the adoption of tech in education itself. This is our area of expertise and through our ongoing reasearch we are systematically breaking down barriers for tech adoption in education.</p>

                    <h1 className="gx-pt-5 gx-mt-5 gx-fs-iconcard gx gx-font-weight-bold gx-text-center" style={{ color: '#1f3965'}}>Our Unique Approach</h1>

                        <Row>
                            <Col xs={24} md={6} xl={6}>
                                <Card className="gx-pt-5 gx-mt-5" bordered = {false} style={{height: '86%'}}>
                                    <div style={{display: 'flex' , alignItems : 'center', justifyContent: 'center'}}>
                                        <Image
                                            style       =   {{alignItems : 'center'}}
                                            preview     =   {false}
                                            width       =   {'30%'}
                                            src         =   {'/assets/images/aboutUs/ws.svg'} 
                                        />
                                    </div>
                                    <h1 className="gx-pt-4 gx-text-center gx-fs-xxl gx-font-weight-medium">Minimal Disruption</h1>
                                    <p className="gx-pt-3" style={{textAlign: 'center'}}>Contrary to popular approach of startups trying to disrupt indsutries - we have found that integrating seamlessly to current user work-flow is the best appraoch.</p>
                                </Card>
                            </Col>

                            <Col xs={24} md={6} xl={6}>
                            <Card className="gx-pt-5 gx-mt-5" bordered = {false} style={{height: '86%'}}>
                                    <div style={{display: 'flex' , alignItems : 'center', justifyContent: 'center'}}>
                                        <Image
                                            style       =   {{alignItems : 'center'}}
                                            preview     =   {false}
                                            width       =   {'30%'}
                                            src         =   {'/assets/images/aboutUs/ws1.svg'} 
                                        />
                                    </div>
                                    <h1 className="gx-pt-4 gx-text-center gx-fs-xxl gx-font-weight-medium">Language Support</h1>
                                    <p className="gx-pt-3" style={{textAlign: 'center'}}>The Software has to be built fundamentally from the ground-up to support the population's language and not as an after-thought translation. Different languages will have differing nuances which will lead to dramatic UI/UX flow experiences.</p>
                                </Card>
                            </Col>

                            <Col xs={24} md={6} xl={6}>
                            <Card className="gx-pt-5 gx-mt-5" bordered = {false} style={{height: '86%'}}>
                                    <div style={{display: 'flex' , alignItems : 'center', justifyContent: 'center'}}>
                                        <Image
                                            style       =   {{alignItems : 'center'}}
                                            preview     =   {false}
                                            width       =   {'30%'}
                                            src         =   {'/assets/images/aboutUs/ws2.svg'} 
                                        />
                                    </div>
                                    <h1 className="gx-pt-4 gx-text-center gx-fs-xxl gx-font-weight-medium">A Growing Experience</h1>
                                    <p className="gx-pt-3" style={{textAlign: 'center'}}>We understand that the needs of customers are not stagnant but ever-evolving and growing. To that end we build software that is as simple as the user requires or as feature-proof as they eventually evolve into needing.</p>
                                </Card>
                            </Col>

                            <Col xs={24} md={6} xl={6}>
                                <Card className="gx-pt-5 gx-mt-5" bordered = {false} style={{ height: '86%'}}>
                                    <div style={{display: 'flex' , alignItems : 'center', justifyContent: 'center'}}>
                                        <Image
                                            style       =   {{alignItems : 'center'}}
                                            preview     =   {false}
                                            width       =   {'30%'}
                                            src         =   {'/assets/images/aboutUs/ws3.svg'} 
                                        />
                                    </div>
                                    <h1 className="gx-pt-4 gx-text-center gx-fs-xxl gx-font-weight-medium">A Helping Hand</h1>
                                    <p className="gx-pt-3" style={{textAlign: 'center'}}>We do our best to extend our support beyond just our  software and ensure that the Classroom is evolving and providing better quality education to its Students. Often all Teachers and Institutions need is just someone to advice and guide them on how to get better.</p>
                                </Card>
                            </Col>
                        </Row>
                        <div className="gx-pt-5" style={{display: 'flex' , justifyContent: 'center'}}>
                            <Button onClick={()=>history.push('/Careers')} className="btn-round-border" size='large' type="primary" ><span className="gx-fs-xl">Join Us</span></Button>
                        </div>
                </div>

                {/* Carousel section */}
                <div className   =   "bg-color-match-with-darkMode-grey" style={{padding : '1% 5%', display: 'none'}}>
                    <h1 className="gx-pt-4 gx-fs-xlxl gx-font-weight-semi-bold gx-text-center" style={{ color: '#1f3965'}}>Our Ongong Journey</h1>
                    <Carousel autoplay >
                        <div className   =   "bg-color-match-with-darkMode-grey">
                            <Row>
                                <Col xs={24} md={6} xl={6}>
                                
                                    <Card className="gx-mt-5 bg-color-white-custom-darkMode" bordered = {false} style={{height: '82%'}} cover={<Image
                                                style       =   {{alignItems : 'center'}}
                                                preview     =   {false}
                                                width       =   {'100%'}
                                                src         =   {'/assets/images/aboutUs/may-20.webp'} 
                                            />}>
   
                                        <h1 className="gx-text-center gx-fs-xxl gx-font-weight-medium" style={{ color: '#1f3965'}}><CalendarOutlined/> May 2020</h1>

                                        <p style={{textAlign: 'center', marginTop: '1em'}}>Initial idea, market research. Talking to stakeholders and getting feedback</p>
                                    </Card>

                                </Col>
                                <Col xs={24} md={6} xl={6}>
                                
                                    <Card className="gx-mt-5 bg-color-white-custom-darkMode" bordered = {false} style={{ height: '82%'}} cover={<Image
                                                style       =   {{alignItems : 'center'}}
                                                preview     =   {false}
                                                width       =   {'100%'}
                                                src         =   {'/assets/images/aboutUs/may-20.webp'} 
                                            />}>
   
                                        <h1 className="gx-text-center gx-fs-xxl gx-font-weight-medium" style={{ color: '#1f3965'}}><CalendarOutlined/> May 2020</h1>

                                        <p style={{textAlign: 'center', marginTop: '1em'}}>Initial idea, market research. Talking to stakeholders and getting feedback</p>
                                    </Card>

                                </Col>
                                <Col xs={24} md={6} xl={6}>
                                
                                    <Card className="gx-mt-5 bg-color-white-custom-darkMode" bordered = {false} style={{ height: '82%'}} cover={<Image
                                                style       =   {{alignItems : 'center'}}
                                                preview     =   {false}
                                                width       =   {'100%'}
                                                src         =   {'/assets/images/aboutUs/may-20.webp'} 
                                            />}>
   
                                        <h1 className="gx-text-center gx-fs-xxl gx-font-weight-medium" style={{ color: '#1f3965'}}><CalendarOutlined/> May 2020</h1>

                                        <p style={{textAlign: 'center', marginTop: '1em'}}>Initial idea, market research. Talking to stakeholders and getting feedback</p>
                                    </Card>

                                </Col>
                                <Col xs={24} md={6} xl={6}>
                                
                                    <Card className="gx-mt-5 bg-color-white-custom-darkMode" bordered = {false} style={{ height: '82%'}} cover={<Image
                                                style       =   {{alignItems : 'center'}}
                                                preview     =   {false}
                                                width       =   {'100%'}
                                                src         =   {'/assets/images/aboutUs/may-20.webp'} 
                                            />}>
   
                                        <h1 className="gx-text-center gx-fs-xxl gx-font-weight-medium" style={{ color: '#1f3965'}}><CalendarOutlined/> May 2020</h1>

                                        <p style={{textAlign: 'center', marginTop: '1em'}}>Initial idea, market research. Talking to stakeholders and getting feedback</p>
                                    </Card>

                                </Col>
                            </Row>
                        </div>

                    </Carousel>
                </div>


                {/* community section */}
                <div className   =   "bg-color-match-with-darkMode-2" style={{padding : '1% 5%', display: 'none'}}>
                    <h1 className="gx-pt-5 gx-mt-5 gx-fs-xlxl gx-font-weight-semi-bold gx-text-center" style={{ color: '#1f3965'}}>Powered by an incredible community</h1>
                    <Row className="gx-pt-5" style={{display : 'flex' , justifyContent : 'center'}}>
                        <Col xs={24} md={6} xl={6}>
                            <Card className='center bg-color-blueShadeWhite-darkMode'>
                               
                                <div className="center">
                                    <h1 className="gx-mt-4 gx-fs-icon-lg gx-font-weight-normal gx-text-center" style={{color : '#1f3965'}}>15M+</h1>
                                    <span className="gx-fs-xl gx-font-weight-semi-bold gx-text-center" style={{color : '#6b82ab'}}>Users</span>
                                </div>
                                
                            </Card>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                            <Card className='center bg-color-blueShadeWhite-darkMode'>

                                <div className="center">
                                    <h1 className="gx-mt-4 gx-fs-icon-lg gx-font-weight-normal gx-text-center" style={{color : '#1f3965'}}>15M+</h1>
                                    <span className="gx-fs-xl gx-font-weight-semi-bold gx-text-center" style={{color : '#6b82ab'}}>Users</span>
                                </div>

                            </Card>
                        </Col>
                        <Col xs={24} md={6} xl={6} >
                            <Card className='center bg-color-blueShadeWhite-darkMode'>
                                <div className="center">
                                    <h1 className="gx-mt-4 gx-fs-icon-lg gx-font-weight-normal gx-text-center" style={{color : '#1f3965'}}>15M+</h1>
                                    <span className="gx-fs-xl gx-font-weight-semi-bold gx-text-center" style={{color : '#6b82ab'}}>Users</span>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                    <h1 className="gx-pt-5 gx-fs-xlxl gx-font-weight-semi-bold gx-text-center" style={{ color: '#1f3965'}}>Believe. Achieve. Celebrate.</h1>
                    <div style={{padding : '2% 8%'}}>
                        <Row gutter={[0,20]}>
                            <Col xs={12} md={8} xl={8}>
                                <Image
                                    preview     =   {false}
                                    height      =   {'400px'}
                                    src         =   {"/assets/images/aboutUs/1.webp"} 
                                />
                            </Col>
                            <Col xs={24} md={16} xl={16}>
                                <Image
                                    preview     =   {false}
                                    height      =   {'400px'}
                                    src         =   {"/assets/images/aboutUs/2.webp"} 
                                />
                            </Col>
                            <Col xs={12} md={8} xl={8}>
                                <Image
                                    preview     =   {false}
                                    height      =   {'400px'}
                                    src         =   {"/assets/images/aboutUs/3.webp"} 
                                />
                            </Col>
                            <Col xs={24} md={8} xl={8}>
                                <Image
                                    preview     =   {false}
                                    height      =   {'400px'}
                                    src         =   {"/assets/images/aboutUs/extra.svg"} 
                                />
                            </Col>
                            <Col xs={12} md={8} xl={8}>
                                <Image
                                    preview     =   {false}
                                    height      =   {'400px'}
                                    src         =   {"/assets/images/aboutUs/4.webp"} 
                                />
                            </Col>
                            <Col xs={24} md={16} xl={16}>
                                <Image
                                    preview     =   {false}
                                    height      =   {'400px'}
                                    src         =   {"/assets/images/aboutUs/5.webp"} 
                                />
                            </Col>
                            <Col xs={12} md={8} xl={8}>
                                <Image
                                    preview     =   {false}
                                    height      =   {'400px'}
                                    src         =   {"/assets/images/aboutUs/6.webp"} 
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className="gx-pt-5" style={{display: 'flex' , justifyContent: 'center'}}>
                        <Button className="btn-round-border" size='large' type="primary" ><span className="gx-fs-xl">Join Us</span></Button>
                    </div>
                </div>

                <div className   =   "bg-color-match-with-darkMode-grey" style={{padding : '1% 5%', display: 'none'}}>
                    <h1 className="gx-pt-5 gx-fs-xlxl gx-font-weight-semi-bold gx-text-center" style={{ color: '#1f3965'}}>Our investors</h1>
                    <Row className="gx-p-2" gutter={[60,20]}>
                        <Col xs={12} md={6} xl={6}>
                            <Card style={{display: 'flex',justifyContent: 'center',borderRadius: '15px'}}>
                                <Image
                                    className="gx-p-4"
                                    preview     =   {false}
                                    height      =   {'100px'}
                                    src         =   {"/assets/images/aboutUs/learn.svg"} 
                                />
                            </Card>
                        </Col>
                        <Col xs={12} md={6} xl={6}>
                            <Card style={{display: 'flex',justifyContent: 'center',borderRadius: '15px'}}>
                                <Image
                                    className="gx-p-4"
                                    preview     =   {false}
                                    height      =   {'100px'}
                                    src         =   {"/assets/images/aboutUs/learn.svg"} 
                                />
                            </Card>
                        </Col>
                        <Col xs={12} md={6} xl={6}>
                            <Card style={{display: 'flex',justifyContent: 'center',borderRadius: '15px'}}>
                                <Image
                                    className="gx-p-4"
                                    preview     =   {false}
                                    height      =   {'100px'}
                                    src         =   {"/assets/images/aboutUs/learn.svg"} 
                                />
                            </Card>
                        </Col>
                        <Col xs={12} md={6} xl={6}>
                            <Card style={{display: 'flex',justifyContent: 'center',borderRadius: '15px'}}>
                                <Image
                                    className="gx-p-4"
                                    preview     =   {false}
                                    height      =   {'100px'}
                                    src         =   {"/assets/images/aboutUs/goodwater.svg"} 
                                />
                            </Card>
                        </Col>
                        <Col xs={12} md={6} xl={6}>
                            <Card style={{display: 'flex',justifyContent: 'center',borderRadius: '15px'}}>
                                <Image
                                    className="gx-p-4"
                                    preview     =   {false}
                                    height      =   {'100px'}
                                    src         =   {"/assets/images/aboutUs/learn.svg"} 
                                />
                            </Card>
                        </Col>
                        <Col xs={12} md={6} xl={6}>
                            <Card style={{display: 'flex',justifyContent: 'center',borderRadius: '15px'}}>
                                <Image
                                    className="gx-p-4"
                                    preview     =   {false}
                                    height      =   {'100px'}
                                    src         =   {"/assets/images/aboutUs/lightspeed.svg"} 
                                />
                            </Card>
                        </Col>
                        <Col xs={12} md={6} xl={6}>
                            <Card style={{display: 'flex',justifyContent: 'center',borderRadius: '15px'}}>
                                <Image
                                    className="gx-p-4"
                                    preview     =   {false}
                                    height      =   {'100px'}
                                    src         =   {"/assets/images/aboutUs/cmv.svg"} 
                                />
                            </Card>
                        </Col>
                        <Col xs={12} md={6} xl={6}>
                            <Card style={{display: 'flex',justifyContent: 'center',borderRadius: '15px'}}>
                                <Image
                                    className="gx-p-4"
                                    preview     =   {false}
                                    height      =   {'100px'}
                                    src         =   {"/assets/images/aboutUs/learn.svg"} 
                                />
                            </Card>
                        </Col>
                    </Row>
                </div>

                <div className   =   "bg-color-match-with-darkMode-2" style={{padding : '1% 5%', display: 'none'}}>
                    <h1 className="gx-pt-5 gx-fs-iconcard gx gx-font-weight-bold gx-text-center" style={{ color: '#1f3965'}}>Recognized as innovators</h1>
                    
                    <Row>
                        <Col xs={24} md={6} xl={6}>
                            <Card className="gx-p-2 gx-mt-5" bordered = {false} style={{ height: '86%'}}>
                                <div style={{display: 'flex' , alignItems : 'center', justifyContent: 'center'}}>
                                    <Image
                                        style       =   {{alignItems : 'center'}}
                                        preview     =   {false}
                                        width       =   {'40%'}
                                        src         =   {'/assets/images/aboutUs/best-software-solutions-provider.svg'} 
                                    />
                                </div>
                                <h1 className="gx-pt-4 gx-fs-xxl gx-text-center gx-font-weight-normal" style={{color: '#1f3965'}}>Best software solutions provider</h1>
                                <h1 className="gx-pt-3 gx-text-center gx-fs-xl" style={{ color: '#6b82ab'}}>Startup awards, 2022</h1>
                            </Card>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                            <Card className="gx-p-2 gx-mt-5" bordered = {false} style={{ height: '86%'}}>
                                <div style={{display: 'flex' , alignItems : 'center', justifyContent: 'center'}}>
                                    <Image
                                        style       =   {{alignItems : 'center'}}
                                        preview     =   {false}
                                        width       =   {'40%'}
                                        src         =   {'/assets/images/aboutUs/most-transformational-company.svg'} 
                                    />
                                </div>
                                <h1 className="gx-pt-4 gx-fs-xxl gx-text-center gx-font-weight-normal" style={{color: '#1f3965'}}>Most transformational company</h1>
                                <h1 className="gx-pt-3 gx-text-center gx-fs-xl" style={{ color: '#6b82ab'}}>GSV #EdTech150, 2022</h1>
                            </Card>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                            <Card className="gx-p-2 gx-mt-5" bordered = {false} style={{ height: '86%'}}>
                                <div style={{display: 'flex' , alignItems : 'center', justifyContent: 'center'}}>
                                    <Image
                                        style       =   {{alignItems : 'center'}}
                                        preview     =   {false}
                                        width       =   {'40%'}
                                        src         =   {'/assets/images/aboutUs/most-transformational-company.svg'} 
                                    />
                                </div>
                                <h1 className="gx-pt-4 gx-fs-xxl gx-text-center gx-font-weight-normal" style={{color: '#1f3965'}}>EdTech startup of the year</h1>
                                <h1 className="gx-pt-3 gx-text-center gx-fs-xl" style={{ color: '#6b82ab'}}>Entrepreneur India awards, 2021</h1>
                            </Card>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                            <Card className="gx-p-2 gx-mt-5" bordered = {false} style={{ height: '86%'}}>
                                <div style={{display: 'flex' , alignItems : 'center', justifyContent: 'center'}}>
                                    <Image
                                        style       =   {{alignItems : 'center'}}
                                        preview     =   {false}
                                        width       =   {'40%'}
                                        src         =   {'/assets/images/aboutUs/most-transformational-company.svg'} 
                                    />
                                </div>
                                <h1 className="gx-pt-4 gx-fs-xxl gx-text-center gx-font-weight-normal" style={{color: '#1f3965'}}>Most transformational company</h1>
                                <h1 className="gx-pt-3 gx-text-center gx-fs-xl" style={{ color: '#6b82ab'}}>GSV #EdTech150, 2022</h1>
                            </Card>
                        </Col>
                    </Row>

                    <div className="gx-pt-4">
                        <h1 className="gx-pt-5 gx-fs-iconcard gx gx-font-weight-bold gx-text-center" style={{ color: '#1f3965'}}>Newsroom</h1>
                        <Row className="gx-pt-5">
                            <Col xs={12} md={4} xl={4}>
                                <Card size='small' style={{display: 'flex',justifyContent: 'center',borderRadius: '15px'}}>
                                    <Image
                                        className="gx-p-4"
                                        preview     =   {false}
                                        height      =   {'100px'}
                                        src         =   {"/assets/images/aboutUs/business-insider.svg"} 
                                    />
                                </Card>
                            </Col>
                            <Col xs={12} md={4} xl={4}>
                                <Card size='small' style={{display: 'flex',justifyContent: 'center',borderRadius: '15px'}}>
                                    <Image
                                        className="gx-p-4"
                                        preview     =   {false}
                                        height      =   {'100px'}
                                        src         =   {"/assets/images/aboutUs/forbes.svg"} 
                                    />
                                </Card>
                            </Col>
                            <Col xs={12} md={4} xl={4}>
                                <Card size='small' style={{display: 'flex',justifyContent: 'center',borderRadius: '15px'}}>
                                    <Image
                                        className="gx-p-4"
                                        preview     =   {false}
                                        height      =   {'100px'}
                                        src         =   {"/assets/images/aboutUs/tc.svg"} 
                                    />
                                </Card>
                            </Col>
                            <Col xs={12} md={4} xl={4}>
                                <Card size='small' style={{display: 'flex',justifyContent: 'center',borderRadius: '15px'}}>
                                    <Image
                                        className="gx-p-4"
                                        preview     =   {false}
                                        height      =   {'100px'}
                                        src         =   {"/assets/images/aboutUs/cnbc.svg"} 
                                    />
                                </Card>
                            </Col>
                            <Col xs={12} md={4} xl={4}>
                                <Card size='small' style={{display: 'flex',justifyContent: 'center',borderRadius: '15px'}}>
                                    <Image
                                        className="gx-p-4"
                                        preview     =   {false}
                                        height      =   {'100px'}
                                        src         =   {"/assets/images/aboutUs/business-insider.svg"} 
                                    />
                                </Card>
                            </Col>
                            <Col xs={12} md={4} xl={4}>
                                <Card size='small' style={{display: 'flex',justifyContent: 'center',borderRadius: '15px'}}>
                                    <Image
                                        className="gx-p-4"
                                        preview     =   {false}
                                        height      =   {'100px'}
                                        src         =   {"/assets/images/aboutUs/business-insider.svg"} 
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </div>

                    <div className="gx-pt-5 gx-p-2">
                        <Row>
                            <Col xs={24} md={12} xl={12}>
                                <h1 className="gx-pt-5 gx-mt-5 gx-fs-iconcard gx gx-font-weight-medium" style={{ color: '#1f3965'}}>Let’s build the world’s largest education infrastructure company together</h1>
                                <Button className="btn-round-border" size='large' type="primary" ><span className="gx-fs-xl">Join Us</span></Button>
                            </Col>
                            <Col xs={24} md={12} xl={12}>
                                <Image
                                        preview     =   {false}
                                        src         =   {'/assets/images/aboutUs/globe.svg'} 
                                    />
                            </Col>
                        </Row>
                    </div>
                </div>
                


                    <LandingFooter />
            </Content>
            



            
        </Layout>
    </Layout>


  );
};

export default AboutUs;
