import { Button } from 'antd';
import React from 'react';
import { PlusOutlined } from '@ant-design/icons';

const AddBtn = ({text, onClick,className,loading}) => {
    return (
       <div style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
           {
            //    loading ? <Skeleton.Input active={false} size={'small'} /> :
               <Button disabled= {loading ? true : false}  icon={<PlusOutlined />} onClick={onClick} className={`gx-mb-0 gx-mt-0 ${className}`}>{text}</Button>
           }
            
       </div>
    );
};

export default AddBtn;