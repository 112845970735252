import { Button, Row, Col, message, Spin, InputNumber, Alert, Tag } from "antd";
import { useState , useEffect} from "react";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import Widget from "../../../components/Widget";
import { useSelector } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
import {
    MessageOutlined, LoadingOutlined
  } from '@ant-design/icons';

import { isNumber, isPositive, formatDateAMPM, returnMappedProfessionRoute_Route } from "../../../util/misc";
import OnlinePay from "../../../api/OnlinePay";
import {get} from "lodash-es";
import RequestMaskingModal from "../../../components/SendSMSModal/RequestMaskingModal";
import Mixpanel from "../../../api/Mixpanel";
import { useHistory, Link } from "react-router-dom";
import { DEFAULT_SMS_RATE_NON_MASKING, DEFAULT_SMS_RATE_MASKING, MINIMUM_SMS_PURCHASE_AMOUNT_BDT } from "../../../business_config.js";
import {database} from "../../../firebase/firebase";

const antIcon       = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

const General = () => {

    const { t }                                             =   useTranslation();
    const { db }                                            =   useSelector(({ auth }) => auth);

    const [smsQuotaAvailable, setSmsQuotaAvailable]         =   useState(0);
    const [chargeRate, setChargeRate]                       =   useState('BDT 0.6');
    const [activePhone, setActivePhone]                     =   useState('+880173711688');
    const [maskingName, setMaskingName]                     =   useState('None');
    const [loading, setLoading]                             =   useState(false);
    const [smsRate, setSMSrate]                             =   useState(DEFAULT_SMS_RATE_NON_MASKING);

    const history                                           =   useHistory();
    const [wpQuotaAvailable, setWpQuotaAvailable]           =   useState(0);
    const [chargeWpRate, setChargeWpRate]                   =   useState('BDT 0.2');
    const [wpRate, setWpRate]                               =   useState(0.2);

    const [showSMSRechargeModal, setShowSMSRechargeModal]   =   useState(false);
    const [showWPRechargeModal, setShowWPRechargeModal]     =   useState(false);
    const [showRequestMaskModal, setShowRequestMaskModal]   =   useState(false);

    const [payBTNTEXT,setPayBTNTEXT]                        =   useState(0);
    const [enteredSMSNumber, setEnteredSMSNumber]           =   useState();

    const [isSpecialBonus, setIsSpecialBonus]               =   useState(undefined);

    const [referralLink, setReferralLink]                   =   useState("/teacher/settings?open=referrals");

    useEffect(()=>{

        //read from db and setup SMS info here
        let smsQUOTA = get(db, ['SMS-Quota'], {
            masking: 'None',
            number: 'Default',
            rate: DEFAULT_SMS_RATE_NON_MASKING,
            v: 0
        });

        let {masking, number, rate, v} = smsQUOTA;

        if (!masking) masking   = 'None';
        if (!rate) rate         =  DEFAULT_SMS_RATE_NON_MASKING;
        if (!number) number     = 'Default';

        setChargeRate(`BDT ${rate}`);
        setActivePhone(`+${number}`);
        setMaskingName(masking);
        setSMSrate(rate);
        setSmsQuotaAvailable(v);


        //check to see if any special bonus campaigns have been applied..
        let specialCampaignBonusAmount  = get(db, ['SMS-Quota', 'SpecialBonusCampaign', 'BonusValue'], undefined);
        let campaignEndDate             = get(db, ['SMS-Quota', 'SpecialBonusCampaign', 'ExpireTime'], undefined);
        let useStatus                   = get(db, ['SMS-Quota', 'SpecialBonusCampaign', 'UseStatus'], false);
        let redeemStatus                = get(db, ['SMS-Quota', 'SpecialBonusCampaign', 'RedeemStatus'], false);
        
        if (specialCampaignBonusAmount && campaignEndDate && useStatus === false && redeemStatus === true){
            if ((new Date()).getTime() < campaignEndDate){
                setIsSpecialBonus(specialCampaignBonusAmount);
            }
        }


        //read from db and setup whatsapp info here
        let wpQUOTA = get(db, ['Whatsapp-Quota'], {
            rate: 0.2,
            v: 0
        });

        let rateW   = wpQUOTA['rate'];
        let vW      = wpQUOTA['v'];

        if (!rateW) rateW         = 0.2;

        setChargeWpRate(`BDT ${rateW}`);
        setWpRate(rateW);
        setWpQuotaAvailable(vW);

        if (db){
            setReferralLink(`/${returnMappedProfessionRoute_Route(db['PublicInfo']['UserProffession'])}/settings?open=referrals`);
        }

    }, [db]);

    const rechargeSMS = (v) => {

        console.log('RECHARGE AMOUNT: ', enteredSMSNumber);
        let smsAmount = enteredSMSNumber;

        if (!isNumber(smsAmount)){
            message.error(('Invalid SMS Amount'), 0);
            return false;
        }

        if (!isPositive(smsAmount)){
            message.error(('Invalid SMS Amount'), 0);
            return false;   
        }


        //calulate how much BDT is needed
        let bdtAmountNeeded     = parseInt(parseInt(smsAmount) * smsRate);

        if (bdtAmountNeeded < MINIMUM_SMS_PURCHASE_AMOUNT_BDT){
            message.error(('Minimum SMS Purchase Amount is BDT 600'));
            return false;
        }

        if (isSpecialBonus){
            let extraBonusToAdd = parseFloat(parseFloat(isSpecialBonus)/100)*parseFloat(smsAmount);
            smsAmount = smsAmount + parseInt(extraBonusToAdd);
        }

        setLoading(true);

        //call SSL function to show URL
        let pushRef         = database.ref(`SSL-Attempt-Transaction/`);


        let pushPromise     = pushRef.push({
            'amount': bdtAmountNeeded,
            'stamp': (new Date()).getTime(),
            'time': formatDateAMPM((new Date()).getTime()),
            'smsAmount': smsAmount,
            'type': 'smsBuy',
            'origin': 'seer',
            'campaignBonusActive': isSpecialBonus? true : false
        });

        let payloadKey      = pushPromise.key;

        OnlinePay.initiate({
            payloadKEY: payloadKey,
            db: db,
            callback: (response)=>{

                if (response.status === 'SUCCESS'){

                    Mixpanel.record({eventName: 'RECHARGE-SMS-ATTEMPT', eventProperties: {
                        'SMS-AMOUNT'                    : v,
                        "bdtAmountNeeded"               : bdtAmountNeeded,
                        "current-smsRate"               : smsRate,
                        "current-smsQuotaAvailable"     : smsQuotaAvailable
                    }});

                    setTimeout(()=>{
                        window.location.href = response.GatewayPageURL
                    }, 2000);

                }
                else{
                    message.error(`Failed to initiate online payment. ${response.failedreason}`, 0);
                }

            }
        });

    }


    const rechargeWhatsapp = (v) => {

        let smsAmount = v;

        if (!isNumber(smsAmount)){
            message.error(('Invalid Whatsapp Amount'), 0);
            return false;
        }

        if (!isPositive(smsAmount)){
            message.error(('Invalid Whatsapp Amount'), 0);
            return false;   
        }

        //calulate how much BDT is needed
        let bdtAmountNeeded     = parseInt(parseInt(smsAmount) * wpRate);

        if (bdtAmountNeeded < 11){
            message.error(('At least BDT 11 must be spent to pay online'), 0);
            return false;  
        }

        setLoading(true);


        //call SSL function to show URL
        let pushRef         = database.ref(`SSL-Attempt-Transaction/`);


        let pushPromise     = pushRef.push({
            'amount': bdtAmountNeeded,
            'stamp': (new Date()).getTime(),
            'time': formatDateAMPM((new Date()).getTime()),
            'whatsappAmount': smsAmount,
            'type': 'whatsappBuy'
        });

        let payloadKey      = pushPromise.key;

        OnlinePay.initiate({
            payloadKEY: payloadKey,
            db: db,
            callback: (response)=>{

                if (response.status === 'SUCCESS'){
                    window.open(response.GatewayPageURL);
                    setLoading(false);
                    setShowWPRechargeModal(false);
                    message.success('Please complete online payment in new window and come back here to use your new whatsapp!');
                }
                else{
                    message.error(`Failed to initiate online payment. ${response.failedreason}`, 0);
                }

            }
        });

    }
        

    return (

        <div className="content-wrapper-SMS-settings">
        
            <Row align="center" justify="center">
                    <Col md={{span:14}} sm={{span:20}}>
                        <Widget styleName="gx-widget-bg">
                            <span className="gx-widget-badge">{chargeRate}/{t("SMS")}</span>
                            <MessageOutlined style={{fontSize: 40}} className="gx-mb-3"/>

                            <h1 className="gx-fs-xxxl gx-font-weight-semi-bold gx-mb-3 gx-mb-sm-4" data-testid="smsQuotaAvailable">{smsQuotaAvailable} {t("SMS AVAILABLE")}</h1>
                            <p>{t("Phone number")}: {activePhone} (Masking {maskingName})</p>

                            <Row align="right" justify="right">

                                <Col md={{span : 24}} sm={{span:24}}>
                                    <Button block onClick={()=>setShowSMSRechargeModal(true)} className="gx-mb-1 gx-btn-warning">
                                    {t("Recharge")}
                                    </Button>
                                
                                </Col>


                                <Col md={{span : 8}} sm={{span:12}}>

                                </Col>

                                


                            </Row>
                        </Widget>
                    
                    </Col>

                    <Col md={{span:14}} sm={{span:20}}>
                        <div style={{border: '1px dashed lightgray', borderRadius: '0.6em', padding: '5%', fontSize: '1.5em', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
                            <Link to={referralLink} style={{textAlign: 'center'}}> 
                                {t('Check out the Referral Page For Free SMS')}
                            </Link>
                        </div>
                    </Col>


            </Row>

            <SweetAlert 
                show            =   {showSMSRechargeModal}
                customClass     =   "gx-sweet-alert-top-space"
                title           =   {t('Recharge SMS')}
                onConfirm       =   {rechargeSMS}
                showCancel
                onCancel        =   {()=>setShowSMSRechargeModal(false)}
                confirmBtnText  =   {t('Pay Online')}
            >

                {
                    loading ? 
                    <>
                        <p>{t("Loading payment gateway, please wait")}</p>
                        <Spin indicator={antIcon} /> 
                    </> 
                    : 
                    <>
                            {isSpecialBonus === undefined ? <></> : 
                                <Alert
                                    message         ="Special Campaign Bonus Activated"
                                    description     ={`You get ${isSpecialBonus}% more SMS.`}
                                    type            ="success"
                                    showIcon
                            />}

                        <p>{t("Please enter SMS Amount")} <br/>
                        {t("Online Card / Banking charges may apply")}
                        <br/>SMS Rate: {smsRate}

                        <br /> Min Purchase: BDT 600
                        </p>


                        

                        <InputNumber className="input-recharge" addonAfter={<Tag className="gx-mb-0">৳{payBTNTEXT}</Tag>} style={{width: '70%'}} placeholder="" value={enteredSMSNumber} onChange={(v)=>{

                            //calulate how many SMS BDT and show preview in pay btn text
                            let smsBDTAmount        = parseInt(parseInt(v) * smsRate);

                            if (!isNumber(smsBDTAmount)){
                                smsBDTAmount = 0;
                            }

                            setPayBTNTEXT(`${smsBDTAmount}`);
                            setEnteredSMSNumber(v);
        

                        }} min={0} max={9999999999}/>
                    
                    </>
                }
                

            </SweetAlert>

            <RequestMaskingModal 
                onClose             =   {()=>setShowRequestMaskModal(false)}
                visible             =   {showRequestMaskModal}
                handleOk            =   {()=>{}} 
                handleCancel        =   {()=>setShowRequestMaskModal(false)}
            />

            <SweetAlert 
                show            =   {showWPRechargeModal}
                customClass     =   "gx-sweet-alert-top-space"
                input
                inputType       =   "number"
                title           =   {t('Recharge Whatsapp')}
                onConfirm       =   {rechargeWhatsapp}
                showCancel
                onCancel        =   {()=>setShowWPRechargeModal(false)}
                confirmBtnText  =   {'Pay Online'}
                inputPlaceHolder =  {500}
            >

                {loading ? <>
                    <p>{t("Loading payment gateway, please wait")}</p>
                    <Spin indicator={antIcon} /> </> : <p>{t("Please enter Whatsapp amount")} <br/>
                {t("Online Card / Banking charges may apply")}</p>}
                

            </SweetAlert>
        </div>

    );
};

export default General;