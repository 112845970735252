import React from "react";
import {  Col, Row } from 'antd';
import { Link } from "react-router-dom";




const LandingFooter = () => {

  return (
    <div >
      <div style={{ width: "100%", backgroundColor: "#28303f" }}>
        <Row gutter={{ xs: [16, 48] }} justify='center'>
          <Col xs={{ span: 22 }} md={{ span: 18 }} xl={{ span: 18 }} className="gx-p-4  gx-mt-4">
            <Row justify="space-around">
              <Col xs={{ span:24 }} md={8} xl={8} className="gx-mb-3" style={{marginBottom:"auto"}}>
                <img
                  src={`https://edutechs.app/ex/sms/images/footer-logo.png`}
                  alt="edutechs-logo"
                  style={{ width: '220px' }}
                />
                <br></br>
                <br></br>
                <span className='gx-mt-4 gx-mb-4 gx-mobile-marginBottom' style={{ color: "#8C8C8C" }}>
                  Our mission is simple - technology in education should be easy to use and adopt. Only then will we truly be able to democratize technology in education.
                </span>
              </Col>
              <Col xs={6} md={6} xl={4} className="footer_links" style={{marginBottom:"auto"}} >
                <h3 className='gx-text-white'>Our Service</h3>
                <Link to="/Welcome"><p className="gx-text-blue gx-mt-1">SaaS platform</p></Link>
                <Link to="/Welcome"><p className="gx-text-blue">Custom Solution</p></Link>
                <Link to="/Welcome"><p className="gx-text-blue">School Management</p></Link>
              </Col>
              <Col xs={6} md={6} xl={4} className="footer_links" style={{marginBottom:"auto"}}>
                <h3 className='gx-text-white'>Social Sites</h3>
                <a href="https://www.facebook.com/edutechs.org"><p className="gx-text-blue gx-mt-1">Facebook</p></a>
                <a href="https://www.linkedin.com/company/edutechbd/"><p className="gx-text-blue">LinkedIn</p></a>

                {/* <Link to="/"><p className="gx-text-blue">Instagram</p></Link> */}
              </Col>
              <Col xs={6} md={6} xl={4} className="footer_links" style={{marginBottom:"auto"}}>
                <h3 className='gx-text-white'>Usage</h3>
                <Link to="/Privacy"><p className="gx-text-blue gx-mt-1">Privacy Policy</p></Link>
                <Link to="/Terms"><p className="gx-text-blue">Terms of Service</p></Link>
                <Link to="/Welcome"><p className="gx-text-blue">Contact Us</p></Link>
              </Col>
            </Row>
            <Col style={{ marginTop: "60px" }} className='center'>
              <h4 className='gx-text-white'>© 2023 Edutechs. All Rights Reserved</h4>
            </Col>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LandingFooter;
