import React,  { useState, useMemo } from "react";
import { Col, Row, Checkbox, Pagination, Switch, Collapse, Button  } from "antd";
import { THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import { useSelector } from "react-redux";
import { DownOutlined, UpOutlined } from '@ant-design/icons';
const { Panel } = Collapse;
const Tile = ({ data , getAllCheck, rowPerPage}) => {

  const themeType = useSelector(({ settings }) => settings.themeType);
  const [current, setCurrent] = useState(1);
  const rowsPerPage = rowPerPage;
  const totalPages = data.length;
  //const totalPages = Math.ceil(count / rowsPerPage);
  const [isOpen, setIsOpen] = useState(false);
  const [indexSpec, setIndexSpec] = useState(null);
  const [checkAll, setCheckAll] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [list,setList]= useState(data);
  //console.log (data.slice(0, 4));
  const currentTableData = useMemo(() => {
    const firstPageIndex = (current - 1) * rowsPerPage;
    const lastPageIndex = firstPageIndex + rowsPerPage;
    return list.slice(firstPageIndex, lastPageIndex);
  }, [current]);
  const onChange = (e,index,val) => {
    setCheckAll(false);
    console.log(`checked = ${e.target.checked}`);
    console.log(val);
    console.log(index);

  };
  const onClickCollapse= (index) => {
    setIsOpen(!isOpen);
    console.log(index);
    setIndexSpec(index);
  };
  const onChangePage = (page,pageSize) => {
    console.log(page);
    console.log(pageSize);
    setCurrent(page);
  };
  const onChangeSwitch= (checked,event,index,val) => {
    console.log(checked);
    console.log(event);
    console.log(index);
    console.log(val);
  };
  const onItemCheck = (e, item)=> {
    let tempList = list;
    tempList.map((user) => {
      if (user.id === item.id) {
        user.type = e.target.checked;
      }
      return user;
      
    });
    console.log(item.id);
    //To Control Master Checkbox State
    const totalItems = data.length;
    const totalCheckedItems = tempList.filter((e) => e.type).length;
    console.log(totalItems);
    console.log(totalCheckedItems);
    setCheckAll(totalItems === totalCheckedItems);
    setList(tempList);
    setSelectedList(list.filter((e) => e.type));
  }
  const onMasterCheck = (e)=> {
    let tempList = list;
    // Check/ UnCheck All Items
    tempList.map((user) => (user.type = e.target.checked));
    setCheckAll(e.target.checked);
    setSelectedList(list.filter((e) => e.type));
    setList(tempList);
  }
  
  return(
      <div>
        <Row style={{paddingLeft: '40px'}}>
          <input
            style={{paddingRight: '5px'}}
            type="checkbox"
            className="form-check-input"
            checked={checkAll}
            id="mastercheck"
            onChange={(e) => onMasterCheck(e)}
            onClick={getAllCheck}
          />
          <h5 style={{marginTop: '-4px',marginLeft: '4px'}}>Mark All</h5>
        </Row>
        {currentTableData.map((val,index)=>{
            return(
              <>
              {index===indexSpec && isOpen ? (
                <div className="content-component-tile-CollapseH" key={val.value}>
                    <input
                        type="checkbox"
                        checked={val.type}
                        className="form-check-input"
                        id="rowcheck{val.id}"
                        onChange={(e) => onItemCheck(e, val)}
                      />
                    <Col>
                        <h6>Date & Time</h6>
                        <h5>{val.people}</h5>
                    </Col>
                    <Col>
                        <h6>Repeat</h6>
                        <h5>Every Month</h5>
                    </Col>
                    <div className="content-smsSetting-line-afterCollapse"></div>
                    <Col style={{paddingRight: "50px"}}>
                        <h6>Recipients</h6>
                        <h6 style={{width: '110px',color: '#54A5EF'}}>Group 1,Group 2,Group 3,Group 4,Group 1,Group 2,Group 3,Group 4</h6>
                    </Col>
                    <Col>
                        <h6>Message Body</h6>
                        <h6 style={{width: '170px'}}>Every Month times,Every Month Sometimes,Every Month Sometimes,Every Month Sometimes
                        Every Month times,Every Month Sometimes,Every Month Sometimes,Every Month Sometimes
                        </h6>
                    </Col>
                    <div className="content-smsSetting-line-afterCollapse"></div>
                    <Row style={{justifyContent:'space-between',width: '100px'}}>
                      <h6 style={{width: '20px'}}>#Tution Reminder</h6>
                      <Switch checkedChildren="On" unCheckedChildren="off" onChange={(checked,event) => onChangeSwitch(checked,event,val,index)} />
                    </Row>
                    <div className="content-collapsed-icon">
                      {index===indexSpec && !isOpen ? (
                          <Button onClick={() => onClickCollapse(index)} shape="circle" icon={<UpOutlined />} size="small"/>
                        ) : (
                          <Button onClick={() => onClickCollapse(index)} shape="circle" icon={<UpOutlined />} size="small"/>
                        )}
                    </div>
                </div>):
                (
                  <div className="content-component-tile" key={val.value}>
                    <input
                        type="checkbox"
                        checked={val.type}
                        className="form-check-input"
                        id="rowcheck{val.id}"
                        onChange={(e) => onItemCheck(e, val)}
                      />
                    <Col>
                        <h6>Date & Time</h6>
                        <h5>{val.people}</h5>
                    </Col>
                    <Col>
                        <h6>Repeat</h6>
                        <h5>Every Month</h5>
                    </Col>
                    <div className="content-smsSetting-line"></div>
                    <Col style={{paddingRight: "50px"}}>
                        <h6>Recipients</h6>
                        <h5 style={{color: '#54A5EF'}}>Group 1...</h5>
                    </Col>
                    <Col>
                        <h6>Message Body</h6>
                        <h5>Every Month Sometimes...
                        </h5>
                    </Col>
                    <div className="content-smsSetting-line"></div>
                    <Row style={{justifyContent:'space-between',width: '110px'}}>
                      <h6 style={{width: '20px'}}>#Tution Reminder</h6>
                      <Switch checkedChildren="on" unCheckedChildren="off" onChange={(checked,event) => onChangeSwitch(checked,event,val,index)} />
                    </Row>
                    <div className="content-collapsed-icon-before">
                      {index===indexSpec && !isOpen ? (
                          <Button onClick={() => onClickCollapse(index)} shape="circle" icon={<DownOutlined />} size="small"/>
                        ) : (
                          <Button onClick={() => onClickCollapse(index)} shape="circle" icon={<DownOutlined />} size="small"/>
                        )}
                    </div>
                </div>
                )}
                </>
            );
        })}
        <Pagination pageSize={rowsPerPage} current={current} onChange={onChangePage} total={totalPages} />
      </div>
  );

};

export default Tile;
