import { useState, useEffect, useMemo, useCallback } from "react";
import { Col, Row, Button,Tooltip, Tabs,message,Dropdown,Timeline, Collapse, Popconfirm,Menu } from "antd";
import { EllipsisOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import RouteTitle from "../../../components/RouteTitle";
import ContentTopBar from "../../../components/ContentTopBar";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import {get} from "lodash-es";
import { decodeString, formatDateAMPM } from "../../../util/misc";
import AddNewRoomModal from "./AddNewRoomModal";
import AddNewTimingModal from "./AddNewTimingModal";
import Mixpanel from "../../../api/Mixpanel";
import SettingsDrawer from "./SettingsDrawer";

import firebase from 'firebase';
import RoomLinkQRModal from "./RoomLinkQRModal";
import EditRoomNameModal from "./EditRoomNameModal";
import EditRoomTimingModal from "./EditRoomTimingModal";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
const database    = firebase.database();

const returnOnlyAMPM = (t) => {
    return `${t.split(' ')[1]} ${t.split(' ')[2]}`
}

const AccessControl = () => {

    const { db }                                                        =   useSelector(({ auth }) => auth);
    const { Panel }                                                     =   Collapse;

    const [collapsePanels, setCollapsePanels]                           =   useState([]);
    const [defaultActiveKey, setDefaultActiveKey]                       =   useState(['0']);
    const daysArr                                                       =   useMemo(() => ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], []);

    const [addNewModalVisible, setAddNewModalVisible]                   =  useState(false);
    const [addNewTimingVisible, setAddNewTimingVisible]                 =  useState(false);
    const [addNewTimingSelectedData, setAddNewTimingSelectedData]       =  useState({});

    const [roomQrCode, setRoomQrCode]                                   =  useState(false);
    const [qrCodeData, setQrCodeData]                                   =  useState({});

    const [editRoomModal, setEditRoomModal]                             =  useState(false);
    const [editRoomData, setEditRoomData]                               =  useState({});

    const [editTimingVisible, setEditTimingVisible]                     =  useState(false);
    const [editTimingSelectedData, setEditTimingSelectedData]           =  useState({});

    const [showSettingsDrawer, setShowSettingsDrawer]                   =  useState(false);

    const renderOneRoomJSXForView = useCallback((roomJSON, key, oneRoomKey) =>{

        let {room, timings}             = roomJSON;
        let daysTabPaneJSX               = [];
        let currentTimeObj               = new Date();

        let i = 0;
        for (let oneDay of daysArr){

            let timingsForThisDay = get(timings, [oneDay], {});
            let timelineJSX       = [];

            for (let oneTimingKey in timingsForThisDay){

                let {startTime, endTime, teacherName, timingName} = timingsForThisDay[oneTimingKey];

                let startTimeOBJ        = new Date(parseInt(startTime));
                let endTimeOBJ          = new Date(parseInt(endTime));

                let updatedStartTimeObj = new Date(currentTimeObj.setHours(startTimeOBJ.getHours()));

                updatedStartTimeObj.setMinutes(startTimeOBJ.getMinutes());

                let updatedEndTimeObj   = new Date(currentTimeObj.setHours(endTimeOBJ.getHours()));
                updatedEndTimeObj.setMinutes(endTimeOBJ.getMinutes());

                timelineJSX.push(
                    <Timeline.Item sort={updatedStartTimeObj.getTime()}>
                        <h3>{returnOnlyAMPM(formatDateAMPM((updatedStartTimeObj)))} to {returnOnlyAMPM(formatDateAMPM((updatedEndTimeObj)))}</h3>
                        
                        <div style={{background: 'rgb(30,30,30)', borderRadius: '6px', width: '50%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', padding: '1.5%'}}>
                            <p style={{margin: 0}}>{decodeString(teacherName)} &nbsp;| {decodeString(timingName)}</p>
                            <div>
                                <Tooltip title="Edit Timing">

                                    <EditOutlined onClick={()=>{
                                        setEditTimingSelectedData({
                                            roomKey:oneRoomKey, weekDay:oneDay, roomName:room, timingKey:oneTimingKey,
                                            startTime,
                                            endTime,
                                        });
                                        setEditTimingVisible(true);
                                        Mixpanel.record({
                                            eventName: 'EDIT-ONE-TIMING-ACCESS-CONTROL',
                                            eventProperties: {
                                                roomKey:oneRoomKey, weekDay:oneDay, roomName:room, timingKey:oneTimingKey,
                                                startTime,
                                                endTime,
                                            }
                                        })
                                    }} style={{ fontSize: '18px', cursor: 'pointer', marginRight: 5 }}/>
                                </Tooltip>

                                <Popconfirm
                                    title="Are you sure you want to delete this timing?"
                                    onConfirm={()=>{
                                        database.ref(`USERS/${db['UID']}/access_control/${oneRoomKey}/timings/${oneDay}/${oneTimingKey}`).remove()
                                            .then(()=>{
                                                message.success('Room timing removed successfully');
                                            })
                                    }}
                                    onCancel={()=>{}}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <CloseOutlined style={{ fontSize: '18px', cursor: 'pointer' }}/>
                                </Popconfirm>

                            </div>
                        </div>
                         
                    </Timeline.Item>
                )
            }

            //ascending order
            timelineJSX.sort((a, b) => {
                return a.props.sort - b.props.sort
            });

            daysTabPaneJSX.push(
                <TabPane tab={oneDay} key={i}>
                    <div className="gx-my-3"></div>

                    <Timeline>
                        {timelineJSX}
                        <Timeline.Item>
                            <Button type="primary" onClick={()=>{

                                setAddNewTimingSelectedData({
                                    weekDay: oneDay,
                                    roomKey: oneRoomKey,
                                    roomName: room
                                });
                                setAddNewTimingVisible(true);

                                Mixpanel.record({
                                    eventName: 'ADD-ONE-TIMING-ACCESS-CONTROL',
                                    eventProperties: {
                                        weekDay: oneDay,
                                        roomKey: oneRoomKey,
                                        roomName: room
                                    }
                                })

                            }}>Add New Timing +</Button>
                        </Timeline.Item>
                    </Timeline>

                </TabPane>
            );
            i++;
        }


        return   <Panel header={<div >
            <span style={{fontSize: '1em'}}>{decodeString(room)}</span>
            </div>} key={key} style={{fontSize: '18px', margin: '0px 20px', marginTop: '15px'}} extra={<>

                <Button style={{margin: 0, marginRight: 5}} type="primary" onClick={(e)=>{
                    e.stopPropagation();
                    setQrCodeData({
                        link: `${window.location.origin}/access-control-qr-scanner?key=${db['UID']}&room=${oneRoomKey}`,
                        roomName: room
                    })
                    setRoomQrCode(true);

                    Mixpanel.record({
                        eventName: 'ROOM-LINK-ACCESS-CONTROL-CLICKED',
                        eventProperties: {
                            link: `${window.location.origin}/access-control-qr-scanner?key=${db['UID']}&room=${oneRoomKey}`,
                            roomName: room
                        }
                    })
                }}>Room Link</Button>

                <Dropdown
                    overlay={<Menu onClick={()=>{}}>
                                            

                            <Menu.Item key={String(1)} type="primary" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }} 
                                onClick={(e)=>{
                                    e.stopPropagation();
                                    setEditRoomData({
                                        roomKey: oneRoomKey,
                                        currentName: room
                                    });
                                    setEditRoomModal(true);
                                    Mixpanel.record({
                                        eventName: 'EDIT-ROOM-ACCESS-CONTROL-CLICKED',
                                        eventProperties: {
                                            roomKey: oneRoomKey,
                                            currentName: room
                                        }
                                    })
                                }}>
                                    <span>Edit Name</span>
                                </div>
                            </Menu.Item>


                            <Menu.Item  key={String(2)} type="primary" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Popconfirm
                                    placement="bottom"
                                    title="Are you sure?"
                                    onConfirm={()=>{
                                        database.ref(`USERS/${db['UID']}/access_control/${oneRoomKey}/`).remove()
                                            .then(()=>{
                                                message.success('Room removed successfully');
                                            })

                                            Mixpanel.record({
                                                eventName: 'DELETE-ROOM-ACCESS-CONTROL-CLICKED',
                                                eventProperties: {
                                                    roomKey: oneRoomKey
                                                }
                                            })
                                    }}
                                    onCancel={()=>{}}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <span style={{margin:0}} block onClick={e=>e.stopPropagation()} type="text">Delete Room</span>
                                </Popconfirm>
                            </Menu.Item>

                    </Menu>}
                >
                    <Button onClick={(e) => {e.stopPropagation();}} className="gx-mx-0 gx-ml-1" style={{marginBottom: 0}} icon={<EllipsisOutlined/>}></Button>
                </Dropdown>




                </>}>
            
                <Tabs 
                    defaultActiveKey    =   {String((new Date()).getDay())} 
                    onChange            =   {()=>{}}
                >
                    {daysTabPaneJSX}
                </Tabs>

        </Panel>
     },[daysArr, db])


    //read from db and setup current rooms / buildings
    useEffect(() => {

        if (!db) return ()=>{};

        let allRoomsJSON = get(db, ['access_control'], {});
        let outputJSX       =   [];
        let index           =   0;
        let activeKeys      =   [];

        for (let oneRoomKey in allRoomsJSON){
            outputJSX.push(renderOneRoomJSXForView(allRoomsJSON[oneRoomKey], index, oneRoomKey));
            activeKeys.push(String(index));
            index++;
        }

        setDefaultActiveKey(activeKeys);
        setCollapsePanels(outputJSX);

    }, [db, renderOneRoomJSXForView]);
  




    return (
        <div className="gx-main-content-wrapper padding-0">
            <ContentTopBar
                leftContent={<RouteTitle title={'Retail Analytics'} icon={faQrcode} />}
                middleContent={<></>}
                rightContent={
                <div style={{display: 'flex', justifyContent: 'end', alignItems: 'center', flexDirection: 'row', gap: '10px'}}>

                    <Button style={{margin: 0}} onClick={()=>setAddNewModalVisible(true)} type="primary">Add Room +</Button>
                

                    <Tooltip title="Settings/Preferences">
                        <Button onClick={()=>setShowSettingsDrawer(true)} className="gx-m-0 gx-p-2" style={{height: '34px', width: '34px'}} icon={<SettingOutlined />} />
                    </Tooltip>

                </div>
                }
            />

            <Row justify="middle" align="center" style={{marginTop: '1%'}}>
                <Col span={24}>

                <Collapse defaultActiveKey={defaultActiveKey} ghost>

                    {collapsePanels}

                </Collapse>



                </Col>

            </Row>


            <AddNewRoomModal 
                isModalVisible  =   {addNewModalVisible}
                handleCancel    =   {()=>setAddNewModalVisible(false)}
            />

            <EditRoomNameModal 
                isModalVisible  =   {editRoomModal}
                handleCancel    =   {()=>setEditRoomModal(false)}
                roomKey         =   {editRoomData['roomKey']} 
                currentName     =   {editRoomData['currentName']}
            />

            <AddNewTimingModal 
                isModalVisible  =   {addNewTimingVisible}
                handleCancel    =   {()=>setAddNewTimingVisible(false)}
                roomKey         =   {addNewTimingSelectedData['roomKey']}
                weekDay         =   {addNewTimingSelectedData['weekDay']}
                roomName        =   {addNewTimingSelectedData['roomName']}
            />

            <EditRoomTimingModal 
                isModalVisible  =   {editTimingVisible}
                handleCancel    =   {()=>setEditTimingVisible(false)}
                roomKey         =   {editTimingSelectedData['roomKey']}
                weekDay         =   {editTimingSelectedData['weekDay']}
                roomName        =   {editTimingSelectedData['roomName']}
                timingKey       =   {editTimingSelectedData['timingKey']}
                startTime       =   {editTimingSelectedData['startTime']} 
                endTime         =   {editTimingSelectedData['endTime']} 
                selectedBatches =   {editTimingSelectedData['selectedBatches']}
            />

            <RoomLinkQRModal 
                isModalVisible  =   {roomQrCode}
                handleCancel    =   {()=>setRoomQrCode(false)}
                link            =   {qrCodeData['link']}
                roomName        =   {qrCodeData['roomName']}
            />

            <SettingsDrawer 
                size            = {'large'}
                onClose         = {()=>setShowSettingsDrawer(false)} 
                visible         = {showSettingsDrawer}
            />


        </div>
    );
};

export default AccessControl;
