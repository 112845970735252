
import React from "react";
import { Transfer,Table } from "antd";

import { difference } from "lodash";

 
const GroupTableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (

    
    <Transfer {...restProps}>
        {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
        }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns;
        const rowSelection = {
            getCheckboxProps: (item) => ({
            disabled: listDisabled || item.disabled,
            }),

        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },

        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },

        selectedRowKeys: listSelectedKeys,
      };
        return (
          <Table
            className="custom-tHead custom-tHead-border-remove"
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            pagination={false}
            scroll={{ y: 260, /*x: '0vw'*/ }}
            size="small"
            style={{
              pointerEvents: listDisabled ? 'none' : undefined,
            }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return;
                onItemSelect(key, !listSelectedKeys.includes(key));
              },
            })}
          />
        );
      }}
    </Transfer>
);
export default GroupTableTransfer