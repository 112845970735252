import React from "react";
import {Route, Switch} from "react-router-dom";
import Dashboard from "./dashboard";
import SMS_Settings from "./sms_settings";
import Settings from "./settings";
import Users from "./users";
import AccessControl from "./access_control";
import MonetizeScreens from "./monetize_screen";

const Main = ({match}) => (
  <Switch>
    <Route path={`${match.url}/dashboard`} component={Dashboard}/>
    <Route path={`${match.url}/users`} component={Users}/>
    <Route path={`${match.url}/access-control`} component={AccessControl}/>
    <Route path={`${match.url}/monetize-screens`} component={MonetizeScreens}/>
    <Route path={`${match.url}/sms-settings`} component={SMS_Settings}/>
    <Route path={`${match.url}/settings`} component={Settings}/>
  </Switch>
);

export default Main;
