import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Input, Image , Form, message, Menu, Dropdown, Checkbox} from 'antd';
import { get, indexOf, set } from "lodash-es";
import { useSelector } from 'react-redux';
import { decodeString, encodeString } from "../../../../util/misc";
import firebase from 'firebase';
import { useTranslation } from "react-i18next";


import {  PlusOutlined } from "@ant-design/icons";

const database    = firebase.database();


const AddNewRoomModal = ({ isModalVisible, handleCancel }) => {

    const { db }                                     = useSelector(({ auth }) => auth);
    const [form]                                     = Form.useForm();
    

    const createRoom = (values) => {

        const {NAME} = values;
        if (NAME) {

            const data = {
                room : encodeString(NAME),
                created: (new Date()).getTime()
            }


            database.ref(`USERS/${db['UID']}/access_control/`).push(data)
            .then(res => {
                message.success('Added successfully!');
                form.resetFields();
                handleCancel();
            })
            .catch(e=>{
                message.error(e)
            })
        }
        else{
            message.error('Empty name not accepted!');
        }

    }
    
    return (
        <>
            <Modal
                centered
                title       =   "Create Room / Building to Access Control"
                visible     =   {isModalVisible}
                onOk        =   {()=>{}}
                onCancel    =   {handleCancel}
                bodyStyle   =   {{paddingTop: '5px'}}
                width       =   {550}
                footer      =   {[
                    <Button form='createNewRoomAccessControl' type="primary" htmlType="submit">
                        Create
                    </Button>
                ]}
            >

                    <Form
                        id="createNewRoomAccessControl"
                        initialValues   =   {{ remember: true }}
                        name            =   "basic"
                        form            =   {form}
                        
                        onFinish        =   {createRoom}
                        layout          =   "vertical"
                        className       =   "gx-force-two-cols gx-form-row0"
                    >
                        <Row className="gx-mt-3">
                        
                            <Col span={24}>
                                <Form.Item
                                    name="NAME"
                                    label='Room/Building Name'
                                    rules={[{ required: true}]}
                                >
                                <Input
                                    name        ="NAME"
                                    placeholder ={'ROOM1A'}
                                />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
            
            </Modal>
        </>
    );
};

export default AddNewRoomModal;