import React from "react";
import { Badge, Checkbox, Tag } from "antd";
import { decodeString } from "../../../util/misc";
import Widget from "../../Widget"
 
interface Props {
    showStdProfileDrawer    : (key: string)=> void;
    selectOnStudentHandler  : (c:boolean, key: string)=> void;
    studentInfo             : {};
    badgeText               : string;
    badgeColor              : string;
    badgePlacement          : string;

}


const ProfileCard: React.FC<Props> = ({  studentInfo, badgeText, badgeColor, badgePlacement, selectOnStudentHandler, showStdProfileDrawer }: Props)  => {
    
    const setAvatar = (inputURL: string) => {
        if (inputURL) {
            if (decodeString(inputURL) === 'https://i.imgur.com/XqL3xLp.png') {
                return `/assets/images/avatar-outline/avatar${(2)}.jpeg`;
            }
            else {
                if (decodeString(inputURL) === 'https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png') {
                    return `/assets/images/avatar-outline/avatar${(2)}.jpeg`;
                }
                else {
                    return decodeString(inputURL)
                }
            }
        }
        else {
            return `/assets/images/avatar-outline/avatar${2}.jpeg`;
        }
    }



    const returnWidget = () => {
        return (
            <Widget styleName="gx-card-full gx-dot-arrow-hover gx-student-card">
                <div 
                    className   =   "gx-user-wid-row" 
                    onClick     =   {() =>  showStdProfileDrawer(studentInfo['UID'])}
                    style       =   {{cursor:'pointer'}}
                >
                    <div className="gx-user-wid gx-mr-3">
                        <img
                            alt="..."
                            src={
                                setAvatar(studentInfo["avatarURL"])
                            }
                            className="gx-object-cover"
                        />
                    </div>
                    <div className="gx-user-wid-body gx-py-3 gx-pr-3">
                        <div className="ant-row-flex" style={{ alignItems: 'center' }}>

                            <h2 className="h4 gx-mr-1 gx-mb-1">

                                {studentInfo["Name"]
                                    ? decodeString(studentInfo["Name"])
                                    : "Unknown"}
                            </h2>

                            <Tag color="#eeeeee" style={{color: '#000'}}>#{studentInfo['customID'] ? studentInfo['customID'] : 'NO ID'}</Tag>
                        </div>
                        <p className="gx-mb-1 gx-text-grey gx-fs-sm">
                            {studentInfo["Email"]
                                ? `${decodeString(studentInfo["Email"])}`
                                : "Unknown"}
                            <br />{" "}
                            {studentInfo["Phone"]
                                ? decodeString(studentInfo["Phone"])
                                : "Unknown"}
                        </p>

                        <Checkbox
                            value   =   {studentInfo['UID']}  
                            onClick={(e)=> {
                                e.stopPropagation()
                            }}
                            onChange =   {(e)=> {
                                e.stopPropagation()
                                selectOnStudentHandler(e.target.checked , studentInfo['UID'])
                            }}
                            checked = {studentInfo['props']['isSelected']}
                        />
                 

                        <div className="gx-dot-arrow">
                            <div
                                onClick={(e) => {
                                    e.stopPropagation()
                                    showStdProfileDrawer(studentInfo['UID'])
                                }}
                                className={
                                    studentInfo['color'] ? "gx-hover-arrow" : "gx-bg-primary gx-hover-arrow"
                                }
                                style={{ backgroundColor: studentInfo['color'] ? studentInfo['color'] : "#FFC0CB" }}
                            >
                                <i className="icon icon-long-arrow-right gx-text-white" />
                            </div>
                            <div className="gx-dot">
                                <i className="icon icon-ellipse-v gx-text-primary" />
                            </div>
                        </div>
                    </div>
                </div>


            </Widget>
        )
    }

    if (badgeText) {

        return (
            <Badge.Ribbon text={badgeText} color={badgeColor} 
            // placement={badgePlacement}
            >

                {returnWidget()}

            </Badge.Ribbon>)
    }
    else {
        return (
            <>
                {returnWidget()}

            </>

        );
    }

};

export default ProfileCard;
