import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Drawer} from "antd";
import {toggleCollapsedSideNav} from "../../appRedux/actions";

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting";
import LandingSidebarContent from "./LandingSidebarContent";


const LandingSidebar = () => {

    let [sidebarCollapsed, setSidebarCollapsed]             =       useState(false);
    const {themeType, navStyle}                             =       useSelector(({settings}) => settings);
    const navCollapsed                                      =       useSelector(({common}) => common.navCollapsed);
    const width                                             =       useSelector(({common}) => common.width);
    const dispatch = useDispatch();

    const onToggleCollapsedNav = () => {
        dispatch(toggleCollapsedSideNav(!navCollapsed));
    };

    useEffect(() => {
        setSidebarCollapsed(navStyle === NAV_STYLE_MINI_SIDEBAR)
    }, [navStyle])

    let drawerStyle = "gx-collapsed-sidebar";

    if (navStyle === NAV_STYLE_FIXED) {
        drawerStyle = "";
    } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
        drawerStyle = "gx-mini-sidebar gx-mini-custom-sidebar";
    } else if (navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
        drawerStyle = "gx-custom-sidebar"
    } else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
        drawerStyle = "gx-mini-sidebar";
    } else if (navStyle === NAV_STYLE_DRAWER) {
        drawerStyle = "gx-collapsed-sidebar"
    }
    if ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR
        || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) && width < TAB_SIZE) {
        drawerStyle = "gx-collapsed-sidebar"
    }



    return (
        <Drawer 
            height= "fit-content"
            placement   =   "top"
            closable    =   {false}
            onClose     =   {onToggleCollapsedNav}
            visible     =   {navCollapsed}
        >

            <LandingSidebarContent />

        </Drawer>
        )
};
export default LandingSidebar;
