import { useEffect, useState } from "react";
import { Result, Spin} from "antd";

import { useHistory } from "react-router-dom";

import QRCode from "react-qr-code";
import { decodeString, getUrlVars } from "../../../util/misc";
import Logo from "../../App/Logo";
import { LoadingOutlined } from '@ant-design/icons';
import {get} from "lodash-es";

const antIcon               = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
);

const UserQRCodeShow = () => {


    const history                                             = useHistory();
    const [qrCode, setQrCode]                                 = useState(undefined); 
    const [name, setName]                                     = useState('');

    //setup the qr code ID from URL
    useEffect(()=>{
        if (history.location.search){
            if ((history.location.search).includes('?uid=')){

                let d = getUrlVars(history.location.search);

                setQrCode(`?uid=${d['uid']}`);

                if (get(d, ['n'], undefined) !== undefined){
                    setName(decodeString(d['n']));
                }
            }
        }

    }, [history]);


  return (
    <div className="gx-app-login-wrap">

        <div style={{ position: 'absolute', top: '15px', left: '25px' }}>
            <Logo/>
        </div>



        <div className="gx-app-login-container sign-up-page-wrapper">

            <Result
                status      =   "info"
                title       =   {'User QR Code'}
                subTitle    =   {<p>
                    {decodeString(decodeString(name))}
                </p>}
            >
                
                <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
                
                    {qrCode !== undefined ?
                    
                    <>
                        <QRCode fgColor={'black'} value={qrCode} viewBox={`0 0 256 256`} style={{ height: "auto", maxWidth: "100%", width: "100%", color: 'black' }} color='black' size={256}/> 
                        
                    </>  : 
                    
                    <Spin indicator={antIcon} />}
                
                </div>




            </Result>

        </div>



    </div>
  );
};

export default UserQRCodeShow;
