import { useState, useEffect, useRef } from 'react'
import {Button, message, Spin, Row, Col } from "antd";
import { decodeString, fixBangladeshPhoneNumbers, isValidPhoneNumber } from '../../../util/misc';
import {get} from "lodash-es";
import { useSelector } from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';
import QRCode from "react-qr-code";
import {useReactToPrint} from "react-to-print";
import SendSMSModal from '../../../components/SendSMSModal';
import ContentTopBar from '../../../components/ContentTopBar';
import RouteTitle from '../../../components/RouteTitle';


const antIcon               = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
);




const AccessCodeForAllUsers = () => {

    const handlePrint = useReactToPrint({
        content: () => {
           const tableStat = allPagesRef.current.cloneNode(true);
           const PrintElem = document.createElement('div');
           PrintElem.appendChild(tableStat);
           return PrintElem;
         },
        pageStyle: `
         @page {
           size: A4;
           margin: 0;
         }
         
       `,
    });


    const { db }                                            =   useSelector(({ auth }) => auth);
    const [studentArrJSON, setStudentArrJSON]               =   useState([[]]);
    const perA4PageCodesToShow                              =   10;
    const [loading, setLoading]                             =   useState(true);
    const [allStudentChecked, setAllStudentChecked]         =   useState(true);

    const [active, setActive]                               = useState([]);

    const allPagesRef                                       =   useRef();

    const [isSmsModalVisible, setIsSmsModalVisible]                     = useState(false);

    const [defaultDYSMS, setDefaultDYSMS]                               = useState(undefined);

    //will actually read the room json - find timings and set active class if applicable
    useEffect(() => {

        if (!db) return ()=>{}

        let usersArr                = [];
        let userList                = get(db, ["AddedUsers"], {});
        let selectedUsers           = window.sessionStorage.getItem('Selected-Users-Access-Codes');

        if (!selectedUsers){
            return ()=>{}
        }

        let selectedUsersArr        = selectedUsers.split(',');

        for (let oneUserID of selectedUsersArr){
            if (userList[oneUserID]){
                usersArr.push(userList[oneUserID]);
            }
        }

        usersArr?.sort( ( a, b ) =>
            a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0
        );

        const result = usersArr.reduce((resultArray, item, index) => { 
            const chunkIndex = Math.floor(index/perA4PageCodesToShow)
            if(!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = [] // start a new chunk
            }
          
            resultArray[chunkIndex].push(item)
          
            return resultArray
        }, []);

        console.log('usersArr: ', result);

        setStudentArrJSON(result);
        setLoading(false);

        return ()=>{
            setStudentArrJSON([[]]);
        }
    }, [db, active, allStudentChecked]);


    const sendSMS = () => {

        message.info('Users with missing / invalid phone numbers have been excluded.')

        let convertedTableExcelData         = [['User Name', 'User Phone', 'Link']];
        let allPhoneNumbers                 = [];

        for (let onePage of studentArrJSON){
            for (let oneStudent of onePage){
    
                let studentUID      = oneStudent.UID;
                let studentPhone    = oneStudent.Phone ? fixBangladeshPhoneNumbers(oneStudent.Phone) : oneStudent.Phone;
                let studentname     = oneStudent.Name;
    
                if (!isValidPhoneNumber(studentPhone)) continue;
                
                
                convertedTableExcelData.push([
                    decodeString(studentname),
                    decodeString(studentPhone),
                    `${window.location.origin}/qr-code-user?uid=${studentUID}&n=${studentname}`
                ]);
    
                
                allPhoneNumbers.push(studentPhone);
            }
        }

        let dynamiCSMS_Message = `Hi, {Default(A2++)}. For Access-Control please use this link to get your QR Code ID:  {Default(C2++)}`;

        setDefaultDYSMS({numbers: allPhoneNumbers, title: decodeString( 'SMS User QR Code' ), messsage: dynamiCSMS_Message, excelRowsData:convertedTableExcelData});
        setIsSmsModalVisible(true);
    }

    
    return (
        <>
            {loading === true ? <Spin style={{marginLeft: '50%', marginTop: '25%'}} indicator={antIcon} />: 
            
            <div style={{display: 'flex', flexDirection: 'column', height: '100svh'}}>  
                <ContentTopBar
                    leftCol={6}
                    rightCol={3}
                    middleCol={5}
                    leftContent={<h1 style={{color: 'black', marginTop: '5%'}} className='gx-mx-2'>Access Codes</h1>}
                    middleContent={ <></>
                    }
                    rightContent = { 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Button className='gx-mb-0' type="primary"  onClick={()=>handlePrint()}>Print</Button>
                            <Button className='gx-mb-0' type="primary"  onClick={()=>sendSMS()}>SMS</Button>
                        </div>
                    }
                />
                <div ref={allPagesRef} style={{display: 'flex', flex: 1, overflowY: 'scroll', alignItems: 'center', flexDirection: 'column', background: 'darkgray'}}>

                        {studentArrJSON.map((innerChunk)=>{

                            return(
                                <div style={{width: '210mm', height: '297mm', border: '1px dashed red', background: 'white'}}>
                                    <Row align='middle' justify='start' style={{marginLeft: 0, marginRight: 0, margin:0}}>

                                        {innerChunk.map((obj)=>{

                                            return <Col span={12} style={{marginTop: 15, border: '1px dashed gray', paddingTop: '15px', paddingBottom: '12px'}}>
                                                        <Row align="middle" justify="space-between">
                                                            <Col span={12}><p style={{fontSize: 12, color: 'black'}}>{obj['Name'] ? decodeString(obj['Name']) : 'Unknown-User'}</p>
                                                                            <p style={{fontSize: 12, color: 'black'}}>{obj['customID'] ? (obj['customID']): 'Unknown-id'}</p>
                                                                            <p style={{fontSize: 12, color: 'black'}}>{obj['Phone'] ? decodeString(obj['Phone']): 'Unknown-Phone'}</p>
                                                            </Col>
                                                            <Col span={12}><QRCode fgColor={'black'} viewBox={`0 0 256 256`} style={{ height: "auto", maxWidth: "100%", width: "100%", color: 'black' }} color='black' size={256} value={`?uid=${obj['UID']}`} /> </Col>
                                                        </Row>
                                            
                                            
                                                </Col>


                                        })}


                                    </Row>

                                </div>
                            )

                        })}

                </div>
            
            </div>
            }

            <SendSMSModal
                active                                  =   {[]}
                isModalVisible                          =   {isSmsModalVisible}
                setIsModalVisible                       =   {setIsSmsModalVisible}
                defaultDynamicMessageWithRecepients     =   {defaultDYSMS}
            />
        </>
    )
}

export default AccessCodeForAllUsers