import React, {  useState, useEffect } from "react";
import { Col, Row } from "antd";

import { Menu } from "antd";
import { useHistory } from "react-router-dom";
import RouteTitle from "../../../components/RouteTitle";
import ContentTopBar from "../../../components/ContentTopBar";
import { faTools } from "@fortawesome/free-solid-svg-icons";
import EditProfile from "./EditProfile";
import Preferenceslast from "./Preferenceslast";
import Features from "./Features";
import Referrals from "./Referrals";
import { useTranslation } from "react-i18next";
import BillingHistory from "./BillingHistory";


function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
}

const items = [
    getItem('General', 'sub1', <p>&nbsp;</p>, [
        getItem('Edit Profile', 'Profile'),
        getItem('Feature Request', 'FeatureRequest')
    ]),
    {
      type: 'divider',
    }
];

const Settings = () => {

    const { t }                                         =   useTranslation();
    const history                                       =   useHistory();
    const [activeKey, setActiveKey]                     =   useState('Profile');   
    const [defaultSelectedKeys, setDefaultSelectedKeys] =   useState(['Profile']);


    useEffect(() => {

        if (history.location.search){
            if ((history.location.search).includes('?open=referrals')){
                setActiveKey("Referrals");
                setDefaultSelectedKeys(["Referrals"]);
            }
        }

    }, [history])
  


    const routing = (key) => {
        switch (key) {
        case "Profile":
            return <EditProfile />;
        case "FeatureRequest":
            return <Features/>;
        case "Referrals":
                return <Referrals/>;
        case "BillingHistory":
                return <BillingHistory />
        default:
            return <EditProfile />;
        }
    };

    return (
        <div className="gx-main-content-wrapper padding-0 gx-main-content-wrapper-match-background-to-theme">
        <ContentTopBar
            leftContent={<RouteTitle title={t("Settings")} icon={faTools} />}
            middleContent={

            <></>
            }
        />

        <div
            className="content-wrapper-settings"
        >
            <Row className="gx-row-m-0 content-row">
            <Col
                lg={5}
                md={5}
                sm={24}
                xs={24}

                className="gx-pl-0"

            >

                <Menu
                    onClick={(e)=>{
                        setActiveKey(e.key)
                    }}
                    style={{
                        width: '105%',
                    }}
                    defaultSelectedKeys={defaultSelectedKeys}
                    defaultOpenKeys={['sub1']}
                    mode="inline"
                    items={items}
                />

            </Col>
            <Col lg={19} md={19} sm={24} xs={24} >
                <div className="site-layout-background gx-pl-4">
                    {routing(activeKey)}
                </div>
            </Col>

            </Row>
        </div>
        </div>
    );
};

export default Settings;
