
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMale, faFemale, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { decodeString } from '../../../../util/misc';
import { Avatar, Tooltip, Button } from 'antd';
import { useTranslation } from "react-i18next";
import { CameraOutlined, QrcodeOutlined } from '@ant-design/icons';

const style = {
    addToNoteStyle: {
        marginLeft: '10px',
        padding: '3px 13px',
        backgroundColor: '#eeeeee',
        color: '#000000',
        borderRadius: '4px',
        fontSize: '13px'
    },
    ul:
    {
        listStyle: 'none',
        margin: '0',
        padding: '0',
        width: '50%'
    },

    li: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '5px',
        fontSize: '14px',
        fontWeight: 'normal'
    },

  
    avatarParentDiv:{
        position:'relative',
        width:'90px',
        height: '80px',
    },
    avatarStyle: {
        width: '100%',
        height: '100%',
        border: '3px solid #038FDE',
        borderOpacity: .1,
    },
    iconStyle:{
        position:'absolute',
        right: '-15px',
        top: '70%',
        padding:'3px',
        // border: '1px solid red',
        borderRadius:'50%',
        cursor: 'pointer'
    },

}

const ProfileGeneral = ({ profileInfo, setLivePhotoModal }) => {
    
    const { t } = useTranslation();


    return (
        <div className='profile-wrap' style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>

                
            <div style={style.avatarParentDiv}>
                <Avatar
                    className="gx-mr-3"
                    style={style.avatarStyle}
                    src={profileInfo['avatarURL']}
                    alt="student-profile-general-avatar-teacher"
                    data-testid="student-profile-general-avatar-teacher"
                />
               
                <Button style={{position: 'absolute', bottom: -14, right: -9}} onClick={()=>setLivePhotoModal(true)} type="danger" shape="circle" icon={<CameraOutlined />} />
            </div>

            <div className="profile-information gx-pl-5" style={{width: '100%'}}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <h4 data-testid ="student-profile-general-name-teacher" className='gx-text-dark gx-mb-0 gx-fs-xl'>
                        {decodeString(profileInfo['Name'])}
                    </h4>
                   <span data-testid="student-profile-general-customID-teacher" style={style.addToNoteStyle}>#{decodeString(profileInfo['customID'])}</span>
                   
                   <Tooltip title="Open QR Code ID">
                        <a target="_blank" rel="noreferrer" href={`${window.location.origin}/qr-code-user?uid=${profileInfo['UID']}&n=${profileInfo['Name']}`} data-testid="student-profile-general-qrcode-teacher" style={style.addToNoteStyle}><QrcodeOutlined /></a>
                   </Tooltip>
                </div>


                <div className="profile-info-flex" style={{ display: 'flex',width: '100%'  }}>

                    <ul style={style.ul} className="gx-mr-3">
                        <li data-testid="student-profile-general-email-teacher" style={style.li}><FontAwesomeIcon icon={faEnvelope} className="gx-student-icon" /> &nbsp; {decodeString(profileInfo['Email'])}</li>
                        <li data-testid="student-profile-general-phone-teacher" style={style.li}><FontAwesomeIcon icon={faPhone} className="gx-student-icon" />  &nbsp; {decodeString(profileInfo['Phone'])}</li>
                    </ul>



                </div>


            </div>
        </div>
    );
};

export default ProfileGeneral;