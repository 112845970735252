import React, { useState, useEffect } from "react";
import {Col, Row, Checkbox, Button} from "antd";
import "../../../i18n";
import { get } from "lodash-es";
import { useSelector } from 'react-redux';
import GroupDetailsModal from "./GroupDetailsModal";
import AddBtn from '../../../components/AddBtn/index';
import AddGroupModal from "./AddGroupModal";
import { useTranslation } from "react-i18next";
import { decodeString, getRandomInt } from "../../../util/misc";
import firebase from 'firebase';

const database      = firebase.database();

const Groups        = () => {

    const { db }                                    = useSelector(({ auth }) => auth);
    const { t }                                     = useTranslation();
    const [group, setGroup]                         = useState();
    const [selectedGroup, setSelectedGroup]         = useState([]);
    const [items, setItems]                         = useState([]);
    const [isModalVisible, setIsModalVisible]       = useState(false);
    const [isModalVisibleAdd, setIsModalVisibleAdd] = useState(false);
 


    const onChange = (val) => {
        setSelectedGroup((oldArray) =>oldArray.includes(val)?oldArray.filter(x => x !== val) : [...oldArray,val] ); // For delete , multiple groups delete check there is no same value in array.
    };

    const showGroupDetailsModal = (val) => {
        setGroup(val.key);
        setIsModalVisible(!isModalVisible);
    }
    const handleOk = () => {
        //setLoading(true);
        setIsModalVisible(false);
    };
    const onItemSelect = (key) => {
       // console.log(key);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const showModalAdd = () => {
        setIsModalVisibleAdd(true);
    };
    const handleOkAdd = () => {
        //setLoading(true);
        setIsModalVisibleAdd(false);
    };
    const handleCancelAdd = () => {
        setIsModalVisibleAdd(false);
    };
    const deleteSelected = () => {
        
        let deleteArray = [];
        //delete the selected rows
        for (let oneKey of selectedGroup){
            
            deleteArray.push(database.ref(`USERS/${db['UID']}/GROUPS/${oneKey}`).remove());
        }
        Promise.all(deleteArray);
    }; 

    
    useEffect(() => {
        const data      = [];
        let groups      = get(db, ["GROUPS"], {});
        for (let key in groups) {
            const groupsDetails     = groups[key];
            data.push({
                key : key,
                ...groupsDetails,
                lengthh: (groupsDetails.selectedStudents) ? (groupsDetails.selectedStudents).length : 0,
            });
        }
        
        setItems(data);
    }, [db]);

    return (
        <>  
            <Row justify='end' className="gx-pt-2 gx-pr-3">


                <Button 
                    onClick={deleteSelected} 
                    className={` gx-mb-0 gx-mt-0 gx-btn-danger`}
                >
                    {t('Delete')}
                </Button>
                <AddBtn 
                    onClick={showModalAdd} 
                    text={t('Add Group')} 
                    className='gx-btn-primary' 
                />
            </Row>

            <div className="content-wrapper-SMS-settings">

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{rowGap: '5px'}}>
                {
                    items?.map((val) => 
                        
                        <Col key={val.key} className="gutter-row" xs={14} sm={14} md={10} lg={6} xl={6} style={{paddingLeft: '5px', paddingRight: '5px', marginLeft: '16px',border: '4px solid'+val.groupColor,borderRadius: '5px'}}>
                            <Row >
                                <Col span={2}>
                                    <Checkbox style={{padding: '15px'}} onChange={()=>onChange(val.key)}></Checkbox>
                                </Col>
                                <Col span={20} onClick={()=>showGroupDetailsModal(val)} style={{cursor: "pointer"}}>
                                    <h4 className="gx-fs-xl gx-font-weight-medium" style={{color: val.groupColor,paddingLeft: '10px', paddingTop: '10px'}}>{val.groupName ? decodeString(val.groupName): 'Unknown Group'}</h4>
                                    <h6 className="gx-fs-lg gx-font-weight-small" style={{color: val.groupColor, paddingLeft: '10px',marginTop: '-10px'}}>Participants: {val.lengthh}</h6>
                                </Col>
                            </Row>
                        </Col>
                    )
                }
                </Row>
            </div>

            <GroupDetailsModal
                key             =   {1}
                isModalVisible  =   {isModalVisible}
                handleCancel    =   {handleCancel}
                handleOk        =   {handleOk} 
                onItemSelect    =   {group}
            />

            <AddGroupModal
                key={getRandomInt(2,1000)}
                isModalVisible={isModalVisibleAdd} 
                handleCancel={handleCancelAdd} 
                handleOk={handleOkAdd} 
            />
        </>
    );
};

export default Groups;
