


import EdutechCloudServer from "../EdutechCloudServer";
import {get} from "lodash-es"
import { encodeString } from "../util/misc";
import axios from "axios";

class Facebook {

    //this will open new tab for user to facebook login and accept permission scopes for group permissions
    initiateFacebookOAuth({db, notification}){

        

        let fbAPP_ID            = '1456060241804831';
        let redirectURL         = `${window.location.origin}/complete-fb-login`;
        let state               = db['UID'];
        let finalURL            = (`https://www.facebook.com/v16.0/dialog/oauth?client_id=${fbAPP_ID}&redirect_uri=${encodeString(redirectURL)}&state=${state}&scope=publish_to_groups,groups_show_list`);
        
        console.log('INITIATING FACEBOOK OAUTH WITH REDIRECT URL: ', redirectURL);

        let key = `open${Date.now()}`;
        notification.open({
            message: "Facebook Login Required",
            description: `A new tab has been opened. Please login with your Facebook account there / accept all permissions and come back here to use the feature again.`,
            duration: 0,
            key: key,
            type: 'info'
        });

        window.open(finalURL, '_blank');
    }

    getToken({db, notification, callback, database}) {

        let localFacebookJSON = get(db, ['FacebookAPI'], undefined);

        //this is first time user is trying to get token / use facebook api
        if (localFacebookJSON === undefined){
            this.initiateFacebookOAuth({db, notification});
            return false;
        }

        let {access_token, expires_in, tokenRefreshTime} = localFacebookJSON;

        let timeStampNow = (new Date()).getTime();

        //user has already used facebook api but his token has expired
        if (timeStampNow > ((parseInt(expires_in) + parseInt(tokenRefreshTime))-100000)){
            this.initiateFacebookOAuth({db, notification});
            return false;
        }

        EdutechCloudServer.call('facebook-get-access-token-from-current-valid-token', {
            token: access_token
        }, (response) => {

            if (response){

                let tokenRefreshTime = (new Date()).getTime();
                if (response.success){
                    response.success.refreshTime = tokenRefreshTime;
                    database.ref(`USERS/${db['UID']}/FacebookAPI`).update(response.success);
                }
                
                callback(response);
            }
        });

    }

    getAllGroupList({access_token, allResultArray, urlToUse, message, finalCallback}){

        let alreadyFetchedGroupsThisSession         = window.prefetchedFB_GROUPS;
        if (alreadyFetchedGroupsThisSession){
            finalCallback(alreadyFetchedGroupsThisSession);
            return false;
        }

        let url_ =  urlToUse === undefined ? `https://graph.facebook.com/v16.0/me/groups?access_token=${access_token}` : urlToUse;

        axios.get(url_)
            .then(res2 => {

                console.log('res2: ', res2);

                if (res2.data.error){
                    message.error(res2.data.error);
                    return false;
                }

                if (get(res2, ['data', 'paging', 'next'], undefined) !== undefined){
                    let updatedArray = [...allResultArray, ...res2.data.data];
                    this.getAllGroupList({access_token, allResultArray:updatedArray, finalCallback, urlToUse:res2.data.paging.next})
                }
                else{
                    let updatedArray = [...allResultArray, ...res2.data.data];
                    window.prefetchedFB_GROUPS = updatedArray;
                    finalCallback(updatedArray);
                    return false;
                }
            })
            .catch(err => {
                console.log(err);
                message.error(err.message);
                finalCallback(allResultArray);
            });
    }

    makePostToGroup({access_token, groupID, postMessage, message, callback}){

        let url = `https://graph.facebook.com/v16.0/${groupID}/feed?access_token=${access_token}`

        axios.post(
            url,
            {
                message: postMessage
            },
            { headers: {  } })
            .then(function (response) {
                if (response.data){
        
                    callback(response.data);
            
                }
                else{
                    message.error('Failed to Post to Group');
                }
            })
            .catch(function (err) {
                console.log(err);
                message.error(err);
            });

    }
}


export default new Facebook()