import {
    Button,
    Modal, Table, Card, Row, Col, Progress, message
} from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SMS from "../../../api/SMS";
import firebase from "firebase";
import { formatDateAMPM, copyToClipboard, getRandomInt } from "../../../util/misc";
import { CheckCircleTwoTone, CloseCircleFilled, ReloadOutlined } from '@ant-design/icons';

const { Column }            = Table;
const database              = firebase.database();


const DynamicSentSMSStatusModalTable = ({ firebaseKey, setIsModalVisible, isModalVisible, sentTimeStamp }) => {

    const { db }                                        = useSelector(({ auth }) => auth);
    const { t }                                         = useTranslation();

    const [tableDataSource, setTableData]               = useState([]);

    const [totalSMS, setTotalSMS]                       = useState(0);
    const [successSMS, setSuccessSMS]                   = useState(0);
    const [failSMS, setFailSMS]                         = useState(0);
    const [pendSMS, setPendSMS]                         = useState(0);
    
    const [selectedRows, setSelectedRows]               = useState();
    const [progSuccess, setProgSuccess]                 = useState(0);
    const [progPending, setProgPending]                 = useState(0);

    const [refreshCounter, setRefreshCounter]           = useState(0);
    const [loadedID, setLoadedID]                       = useState([]);

    const resetEverythingToDefault = () => {

        setTableData([]);
        setLoadedID([]);

        setTotalSMS(0);
        setSuccessSMS(0);
        setFailSMS(0);
        setPendSMS(0);
        setSelectedRows(undefined);
        setProgSuccess(0);
        setProgPending(0);

    }

    const loadOne_RequestID = ({one_request_id}) => {

        console.log('loadOne_RequestID function triggerd with SMS_ID: ', one_request_id);

        SMS.getSentSMS_INFO({sms_id: one_request_id, uid: db['UID'], callback: (smsResponse)=>{

            console.log('smsResponse: ', smsResponse);
            let {recipients} = smsResponse['data'];
            
            for (let index = 0; index < recipients.length; index++) {

                let {number, status} = recipients[index];
                let jsx_status_comp  = <></>;

                if (status === 'Sent') {
                    jsx_status_comp = <Row gutter={[2,0]} justify="center" data-status="success">
                                        <Col span={2}>
                                            <CheckCircleTwoTone style={{paddingTop: "15px"}} twoToneColor="#2FBB67" />
                                        </Col> 
                                        <Col span={10}>
                                            <p style={{paddingTop: "13px",color: "#2FBB67"}}>Delivered</p>
                                        </Col>
                                    </Row>
                    setSuccessSMS(x=>x+1);
                }
                if (status === 'Pending') {
                    jsx_status_comp = <Row gutter={[2,0]} justify="center" data-status="pending">
                                        <Col span={2}>
                                            <ReloadOutlined style={{paddingTop: "15px"}} twoToneColor="#038FDE" />
                                        </Col> 
                                        <Col span={10}>
                                            <p style={{paddingTop: "13px",color: "#038FDE"}}>In Transit</p>
                                        </Col>
                                    </Row>
                    setPendSMS(x=>x+1);
                }
                if (status === 'Fail') {
                    jsx_status_comp = <Row gutter={[2,0]} justify="center" data-status="fail">
                                        <Col span={2}>
                                            <CloseCircleFilled style={{paddingTop: "15px"}} twoToneColor="#F5222D" />
                                        </Col> 
                                        <Col span={10}>
                                            <p style={{paddingTop: "13px",color: "#F5222D"}}>Failed</p>
                                        </Col>
                                    </Row>
                    setFailSMS(x=>x+1);
                }
                else if (status === 'Failed') {
                    jsx_status_comp = <Row gutter={[2,0]} justify="center" data-status="fail">
                                        <Col span={2}>
                                            <CloseCircleFilled style={{paddingTop: "15px"}} twoToneColor="#F5222D" />
                                        </Col> 
                                        <Col span={10}>
                                            <p style={{paddingTop: "13px",color: "#F5222D"}}>Failed</p>
                                        </Col>
                                    </Row>
                    setFailSMS(x=>x+1);
                }


                setTableData(current=>{
                    let newCopyCurrent = [...current];
                    newCopyCurrent.push({
                        key: getRandomInt(0,9999999),
                        one_request_id: one_request_id,
                        "Phone": number,
                        "Status": status,
                        phoneComponent: 
                            <p style={{paddingTop: "15px"}}>
                                +{number}
                            </p>,
                        statusComponent: jsx_status_comp,   
                    })
                    return newCopyCurrent;
                });

                setTotalSMS(x=>x+1);
            }
        }})
    }



    useEffect(()=>{

        if (!db) return ()=>{};

        if (firebaseKey !== undefined){
            message.info('Please wait as we fetch sms response..');
            resetEverythingToDefault();

            setTimeout(()=>{

                console.log('LISTENING AT ADDRESS: ',  `Dynamic-SMS/${db['UID']}/${firebaseKey}`);
                
                database.ref(`Dynamic-SMS/${db['UID']}/${firebaseKey}`).on('child_added', snapshot=>{

                    if (!snapshot.exists()) return false;
    
                    if (snapshot.val()['data'] === undefined) return false;
                    if (snapshot.val()['data']['request_id'] === undefined) return false;

                    loadOne_RequestID({one_request_id: snapshot.val()['data']['request_id']});
                });

            }, 1500);
        }
        else{
            database.ref(`Dynamic-SMS/${db['UID']}/${firebaseKey}`).off();
        }

        return ()=>{
            database.ref(`Dynamic-SMS/${db['UID']}/${firebaseKey}`).off();
        }

    }, [firebaseKey, refreshCounter]);

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOk = () => {

    }
    
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
        },
    };
      

    return (
        <>
            <Modal
                    width           =   {680}
                    title           =   "Dynamic SMS Sent Status"
                    visible         =   {isModalVisible}
                    onOk            =   {handleOk}
                    onCancel        =   {handleCancel}
                    footer={[
                        <>

                            <Button
                                disabled={selectedRows?.length === 0}
                                onClick={()=>{
                                    let finalText = '';

                                    for (let onePhoneOBJ of selectedRows){
                                        finalText = finalText + `+${onePhoneOBJ['Phone']},`
                                    }

                                    copyToClipboard(finalText);
                                    message.info('Selected numbers have been copied to your clipboard!');
                                }}
                                type="default"
                            >
                                {t("Copy")}
                            </Button>
                            

                            <Button onClick={() => {setTableData([]); window.loadedRequestIDS     = []; setRefreshCounter(x=>x+1); }} size="medium" className='gx-mb-0 gx-btn-geekblue' style={{ borderRight: '1px solid white' }}>Refresh and Check Again</Button>
                            

                        </>
                    ]}
                >

                <>
                    <Card className="card-css-design">
                        <Row>
                            <Col span={6}>
                                <p className="gx-fs-lg gx-font-weight-normal">Total Sent: {totalSMS}</p>  
                            </Col>
                            <Col span={2}>
                                |
                            </Col>
                            <Col span={12}>
                                <p className="gx-fs-lg gx-font-weight-normal">Time: {formatDateAMPM(new Date(sentTimeStamp))}</p>
                            </Col>
                            <Col span={8}>
                                <p className="success-text gx-fs-lg gx-font-weight-medium">Success: {successSMS}</p>
                            </Col>
                            <Col span={8}>
                                <p className="pending-text gx-fs-lg gx-font-weight-medium">In Transit: {pendSMS}</p>
                            </Col>
                            <Col span={8}>
                                <p className="fail-text gx-fs-lg gx-font-weight-medium">Fail: {failSMS}</p>
                            </Col>
                            <Col span={24}>
                                <Progress showInfo={false} percent={progSuccess+progPending} success={{ percent: progSuccess }} trailColor={"#F97F7C"}/>
                            </Col>
                        </Row>
                    </Card>

                    <Table
                        
                        className= "attendance-table scrollbar-remove"
                        rowSelection    =   {{ ...rowSelection }}
                        dataSource      =   {tableDataSource} 
                        pagination      =   {false}
                        style                   =   {{
                            height: "calc(50vh - 100px)",
                            overflowY: "auto",
                        }}     
                        // loading         =   {tableDataSource.length === 0 ? true : false}   
                    >

                            <Column 
                                title       =   "Phone"
                                align       =   "left"
                                dataIndex   =   "Phone"
                                key         =   "Phone"
                                render      =   {(text, record, index) => {

                                    return record.phoneComponent
                                } }
                            />

                            <Column
                                title       =   "Status"
                                dataIndex   =   "Status"
                                key         =   "Status"
                                align       =   "center"
                                render      =   {(text, record, index) => {

                                    return record.statusComponent
                                } }
                            />
                    

                    </Table>
                
                </>





            </Modal>
        </>
    );
};

export default DynamicSentSMSStatusModalTable;
