import {useEffect} from "react";
import { Modal, Button, Input, Form, message, Alert} from 'antd';
import { useSelector } from 'react-redux';
import { decodeString, encodeString } from "../../../../../util/misc";
import firebase from 'firebase';
import { useTranslation } from "react-i18next";
import {get} from "lodash-es";

const database    = firebase.database();

const { TextArea } = Input;


const UpdateDiscordWebhook = ({ isModalVisible, handleClose }) => {
    
    
    const [form]     = Form.useForm();
    const { db }     = useSelector(({ auth }) => auth);
    const { t }      = useTranslation();

    useEffect(() => {

        let {webhook} = get(db, ['AccessControlSettings', 'discordAutoNotify'], {});
        if (webhook){
            if (typeof webhook === 'string' || webhook instanceof String){
                form.setFieldsValue({
                    'templateMessage': decodeString(webhook),
                });
            }
            else{
                form.setFieldsValue({
                    'templateMessage': `Go to channel settings on discord. Then click integrations and click create webhook and paste webhook URL here.`,
                });
            }
        }
        else{
            form.setFieldsValue({
                'templateMessage': `Go to channel settings on discord. Then click integrations and click create webhook and paste webhook URL here.`,
            });
        }


    }, [form, isModalVisible, db])
    

    const onFinish = async (values) => {
        const {templateMessage} = values;
        if (templateMessage) {

            if (!templateMessage.includes('https://') && !templateMessage.includes('discord')){
                message.error('Please double check your webhook URL. Your submitted URL is not valid.');
                return false;
            }

            const data = {
                'webhook' : encodeString(templateMessage),
            }

            let dbAddress =  `USERS/${db['UID']}/AccessControlSettings/discordAutoNotify`;
            await database.ref(dbAddress).update(data)
            .then(res => {
                message.success('Webhook updated successfully!');
            })
            .catch(e=>{
                message.error(e)
            })
        }
        else{
            message.error('Empty field not accepted!');
        }
    };

    return (
        <>
            <Modal
                centered
                title={'Update Discord Webhook'}
                visible={isModalVisible}
                onOk={()=>handleClose()}
                onCancel={()=>handleClose()}
                width={500}
                footer={[
                    <Button form='myFormSmsTemplatesAdd' type="primary" htmlType="submit">
                        {t("Update")}
                    </Button>
                ]}
            >
                <div className="content-insideModal-smsSetting-groups">
                    <Form
                        id="myFormSmsTemplatesAdd"
                        initialValues   =   {{ remember: true }}
                        name            =   "basic"
                        form            =   {form}
                        onFinish        =   {onFinish}
                        layout          =   "vertical"
                        className       =   "gx-force-two-cols gx-form-row0"
                    >
                                <Form.Item
                                    name="templateMessage"
                                    label='Webhook URL'
                                    rules={[{ required: true, message: 'Empty templates cannot be accepted!' }]}
                                >
                                <TextArea
                                    name="templateMessage"
                                    placeholder={t("Enter your webhook URL (Obtained from Channel Settings)")}
                                    style={{
                                    height: 120,
                                    }}
                                />
                                </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

export default UpdateDiscordWebhook;