import React, { useState } from 'react';
import { ArrowUpOutlined } from '@ant-design/icons';
import { Button } from 'antd';


const ScrollTopButton = () => {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
                in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <div className='gx-scroll-top' style={{ display: visible ? 'inline' : 'none' }}>
            <Button className='gx-scroll-top-btn' onClick={scrollToTop}>
                <ArrowUpOutlined
                     />
            </Button>
        </div>
    );
}

export default ScrollTopButton;
