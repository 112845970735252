import { useEffect, useState } from "react";
import {  Layout, Image, Row, Col, message, Modal, Form,Button, Input, Card } from "antd";
import {  RightOutlined } from '@ant-design/icons';
import firebase from 'firebase';
import Email from "../../../api/Email";

import LandingFooter from "../../Footer/LandingFooter";
import LandingHeader from "../../Topbar/LandingHeader";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import { encodeString } from "../../../util/misc";
const {  Content } = Layout;

const database    = firebase.database();


const Careers = () => {

    const { t }       =       useTranslation();
    const { TextArea }      =       Input;
    const [form]            =       Form.useForm();
    const [showModal, setShowModal]     =   useState(false);




    const onFinish = async (values) => {
        const {name, email, phone, job, applicationText} = values;


        let data = {
            name: encodeString(name),
            email: encodeString(email),
            phone: encodeString(phone), 
            applicationText: encodeString(applicationText),
            timeStamp: (new Date()).getTime(),
            job: encodeString(job)
        }

        database.ref(`Careers/`).push(data);
        message.success(('Thank you for your application. We will get in touch with you soon!'));
        form.resetFields();
        Email.sendAdmin({
            message: `${applicationText}`,
            subject: `${name} ${email} ${phone} Career Application: ${(job)}`, 
            uid: 'GUEST', 
            callback: ()=>setShowModal(false)
        });
    };

    return (
        <Layout className="gx-app-layout">


            <LandingHeader />

            <Content
                className={`gx-layout-content gx-container-wrap`}
                >

                
                    <Image
                        preview =   {false}
                        width   =   {'100%'}
                        src     =   "/assets/images/Career_Page_Banner.webp"
                        loading =   "lazy"
                    />


                    {
                        // Open Role Section
                    }

                    <div
                        className   =   "bg-color-match-with-darkMode"
                        style={{
                            padding: '2% 13%',
                        }}
                    >
                        <Row style={{ rowGap: '15px' }} justify="space-between" align="center">
                            <Col xs={24} md={6} xl={6}>
                                <span className="gx-fs-xl gx-text-purple">&nbsp;Software Engineer</span>
                            </Col>

                            <Col xs={24} md={4} xl={4}>
                                <div className="rounded-Grey-role">
                                    <span className="gx-fs-md gx-text-blue">5 Open Roles</span>
                                </div>
                            </Col>

                            <Col span={24}>
                                <Card hoverable onClick={()=>setShowModal(true)}>
                                    <Row>
                                        <Col xs={24} md={16} xl={16}>
                                            <h2 className="gx-fs-xxl">3rd Party API Integrations</h2>
                                            <span className="gx-fs-lg">Will be required to work with many public and private API integrations such as Zoom, Google Classroom, Paypal, Stripe and others.
                                            </span>
                                            {/* <Collapse defaultActiveKey={['1']} ghost>
                                                <Panel style={{fontSize: '22px'}} header={<div style={{display: 'flex', flexDirection:'column'}}><h2 className="gx-fs-xxl">3rd Party API Integrations</h2>
                                                                <span className="gx-fs-lg">Will be required to work with many public and private API integrations such as Zoom, Google Classroom, Paypal, Stripe and others.
                                                                </span>
                                                                </div>
                                                                }> 
                                                    <span className="gx-mt-5 gx-fs-md">Better job descriptions attract better candidates. Optimized for job board approval and SEO, our 1000+ job description templates boost exposure, provide inspiration and speed up hiring. Rich in the right kind of content, they also lead to more qualified applicants.</span><br></br>
                                                    <div className="gx-mt-5" style={{display: 'flex' , justifyContent: 'start'}}>
                                                        <Button type="primary" onClick={()=>setShowModal(true)}>Apply Now</Button>
                                                    </div>
                                                </Panel>
                                            </Collapse> */}
                                        </Col>
                                        <Col xs={24} md={6} xl={6} className='gx-pt-4'>
                                            <span className="gx-fs-lg">Dhaka,Bangladesh</span>
                                        </Col>
                                        <Col xs={24} md={2} xl={2} className='gx-pt-3'>
                                            <RightOutlined
                                                width   =   {60}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                                <Card hoverable onClick={()=>setShowModal(true)}>
                                    <Row>
                                        <Col xs={24} md={16} xl={16}>
                                            <h2 className="gx-fs-xxl">React Native Mobile Developer</h2>
                                            <span className="gx-fs-lg">Must showcase ability to use functional react components along with hooks. Will develop and test for both iOS and Android platforms.
                                            </span>
                                        </Col>
                                        <Col xs={24} md={6} xl={6} className='gx-pt-4'>
                                            <span className="gx-fs-lg">Dhaka,Bangladesh</span>
                                        </Col>
                                        <Col xs={24} md={2} xl={2} className='gx-pt-3'>
                                            <RightOutlined
                                                width   =   {60}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                                <Card hoverable onClick={()=>setShowModal(true)}>
                                    <Row>
                                        <Col xs={24} md={16} xl={16}>
                                            <h2 className="gx-fs-xxl">Back-End Node Express Developer</h2>
                                            <span className="gx-fs-lg">Must showcase strong grasp of REST API along with Docker, Linux Terminal usage experience. Big plus if experienced with socket communications as well.
                                            </span>
                                        </Col>
                                        <Col xs={24} md={6} xl={6} className='gx-pt-4'>
                                            <span className="gx-fs-lg">Dhaka,Bangladesh</span>
                                        </Col>
                                        <Col xs={24} md={2} xl={2} className='gx-pt-3'>
                                            <RightOutlined
                                                width   =   {60}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                                <Card hoverable onClick={()=>setShowModal(true)}>
                                    <Row>
                                        <Col xs={24} md={16} xl={16}>
                                            <h2 className="gx-fs-xxl">Front-End React Developer</h2>
                                            <span className="gx-fs-lg">Must showcase ability to use functional react components along with hooks. Big plus if experienced with Redux, Next and Saga.
                                            </span>
                                        </Col>
                                        <Col xs={24} md={6} xl={6} className='gx-pt-4'>
                                            <span className="gx-fs-lg">Dhaka,Bangladesh</span>
                                        </Col>
                                        <Col xs={24} md={2} xl={2} className='gx-pt-3'>
                                            <RightOutlined
                                                width   =   {60}
                                            />
                                        </Col>
                                    </Row>
                                </Card>

                                <Card hoverable onClick={()=>setShowModal(true)}>
                                    <Row>
                                        <Col xs={24} md={16} xl={16}>
                                            <h2 className="gx-fs-xxl">Machine Learning / Image to Text Classifcation Engineer</h2>
                                            <span className="gx-fs-lg">Must showcase ability to convert hand-written text to a high degress success of converted digital text. Will be working on English and Bengali hand-written text. May use online models or create own custom ones.
                                            </span>
                                        </Col>
                                        <Col xs={24} md={6} xl={6} className='gx-pt-4'>
                                            <span className="gx-fs-lg">Dhaka,Bangladesh</span>
                                        </Col>
                                        <Col xs={24} md={2} xl={2} className='gx-pt-3'>
                                            <RightOutlined
                                                width   =   {60}
                                            />
                                        </Col>
                                    </Row>
                                </Card>

                            </Col>
                        </Row>
                    </div>


                    <div
                        className   =   "bg-color-match-with-darkMode"
                        style={{
                            padding: '2% 13%',
                        }}
                    >
                        <Row style={{ rowGap: '15px' }} justify="space-between" align="center">
                            <Col xs={24} md={6} xl={6}>
                                <span className="gx-fs-xl gx-text-purple">&nbsp;Finance</span>
                            </Col>

                            <Col xs={24} md={4} xl={4}>
                                <div className="rounded-Grey-role">
                                    <span className="gx-fs-md gx-text-blue">1 Open Roles</span>
                                </div>
                            </Col>

                            <Col span={24}>
                                <Card hoverable onClick={()=>setShowModal(true)}>
                                    <Row>
                                        <Col xs={24} md={16} xl={16}>
                                            <h2 className="gx-fs-xxl">Internal Audits Accountant</h2>
                                            <span className="gx-fs-lg">Will be required to work with excel, FreshBooks and other finance management software to keep track of expenses, revenue and financial audits.
                                            </span>
                                        </Col>
                                        <Col xs={24} md={6} xl={6} className='gx-pt-4'>
                                            <span className="gx-fs-lg">Dhaka,Bangladesh</span>
                                        </Col>
                                        <Col xs={24} md={2} xl={2} className='gx-pt-3'>
                                            <RightOutlined
                                                width   =   {60}
                                            />
                                        </Col>
                                    </Row>
                                </Card>

                            </Col>
                        </Row>
                    </div>

                    <div
                        className   =   "bg-color-match-with-darkMode"
                        style={{
                            padding: '2% 13%',
                        }}
                    >
                        <Row style={{ rowGap: '15px' }} justify="space-between" align="center">
                            <Col xs={24} md={6} xl={6}>
                                <span className="gx-fs-xl gx-text-purple">&nbsp;Sales</span>
                            </Col>

                            <Col xs={24} md={4} xl={4}>
                                <div className="rounded-Grey-role">
                                    <span className="gx-fs-md gx-text-blue">3 Open Roles</span>
                                </div>
                            </Col>

                            <Col span={24}>
                                <Card hoverable onClick={()=>setShowModal(true)}>
                                    <Row>
                                        <Col xs={24} md={16} xl={16}>
                                            <h2 className="gx-fs-xxl">Sales Executive Out-Calls</h2>
                                            <span className="gx-fs-lg">Will be required to make outcalls to potential customers, demo our product and make converts. Must show experience is dealing with and selling software products.
                                            </span>
                                        </Col>
                                        <Col xs={24} md={6} xl={6} className='gx-pt-4'>
                                            <span className="gx-fs-lg">Dhaka,Bangladesh</span>
                                        </Col>
                                        <Col xs={24} md={2} xl={2} className='gx-pt-3'>
                                            <RightOutlined
                                                width   =   {60}
                                            />
                                        </Col>
                                    </Row>
                                </Card>

                                <Card hoverable onClick={()=>setShowModal(true)}>
                                    <Row>
                                        <Col xs={24} md={16} xl={16}>
                                            <h2 className="gx-fs-xxl">Customer On-Boarding and Support</h2>
                                            <span className="gx-fs-lg">Must be good at handling software. Will be required to on-board customers, fix their problems and provide ongoing-support.
                                            </span>
                                        </Col>
                                        <Col xs={24} md={6} xl={6} className='gx-pt-4'>
                                            <span className="gx-fs-lg">Dhaka,Bangladesh</span>
                                        </Col>
                                        <Col xs={24} md={2} xl={2} className='gx-pt-3'>
                                            <RightOutlined
                                                width   =   {60}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </div>

                    <div
                        className   =   "bg-color-match-with-darkMode"
                        style={{
                            padding: '2% 13%',
                        }}
                    >
                        <Row style={{ rowGap: '15px' }} justify="space-between" align="center">
                            <Col xs={24} md={6} xl={6}>
                                <span className="gx-fs-xl gx-text-purple">&nbsp;Marketing</span>
                            </Col>

                            <Col xs={24} md={4} xl={4}>
                                <div className="rounded-Grey-role">
                                    <span className="gx-fs-md gx-text-blue">2 Open Roles</span>
                                </div>
                            </Col>

                            <Col span={24}>
                                <Card hoverable onClick={()=>setShowModal(true)}>
                                    <Row>
                                        <Col xs={24} md={16} xl={16}>
                                            <h2 className="gx-fs-xxl">Online Branding</h2>
                                            <span className="gx-fs-lg">Will be required to create online branding content on all social media platforms. Must showcase fluid intelligence and good design fundamentals. Canva and Figma or equivalent experience also needed.
                                            </span>
                                        </Col>
                                        <Col xs={24} md={6} xl={6} className='gx-pt-4'>
                                            <span className="gx-fs-lg">Dhaka,Bangladesh</span>
                                        </Col>
                                        <Col xs={24} md={2} xl={2} className='gx-pt-3'>
                                            <RightOutlined
                                                width   =   {60}
                                            />
                                        </Col>
                                    </Row>
                                </Card>

                            </Col>
                        </Row>
                    </div>
                    
                

                    <LandingFooter />
            </Content>
            

            <Modal
                centered
                title           ={t("Application")}
                visible         ={showModal}
                onOk            ={()=>{}}
                onCancel        ={()=>setShowModal(false)}
                width           ={500}
                footer          ={[
                    <Button form='myFormSmsTemplatesAdd' type="primary" htmlType="submit">
                        {t("Submit")}
                    </Button>
                ]}
            >
                <div className="content-insideModal-smsSetting-groups">
                    <Form
                        id="myFormSmsTemplatesAdd"
                        initialValues   =   {{ remember: true }}
                        name            =   "basic"
                        form            =   {form}
                        onFinish        =   {onFinish}
                        layout          =   "vertical"
                        className       =   "gx-force-two-cols gx-form-row0"
                    >
                        <Row>
                        
                            <Col span={24}>

                                <Form.Item
                                    name="name"
                                    label='Name'
                                    rules={[{ required: true}]}
                                >
                                    <Input
                                        name="name"
                                        placeholder={t("e.g John Doe")}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="email"
                                    label='Email'
                                    rules={[{ required: true}]}
                                >
                                    <Input
                                        name="email"
                                        placeholder={t("e.g john@gmail.com")}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="phone"
                                    label='Phone'
                                    rules={[{ required: true}]}
                                >
                                    <Input
                                        name="name"
                                        placeholder={t("e.g +8801700766173")}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="job"
                                    label='Job'
                                    rules={[{ required: true}]}
                                >
                                    <Input
                                        name="job"
                                        placeholder={t("Please enter the job you are applying for")}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="applicationText"
                                    label='Details'
                                    rules={[{ required: true }]}
                                >
                                <TextArea
                                    name="applicationText"
                                    placeholder={t("Please include a link to your CV and any other links that showcase your work such as LinkedIn, Github, Figma, Dribbble etc.")}
                                    style={{
                                    height: 120,
                                    }}
                                />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>

            
        </Layout>

    );
};

export default Careers;
