import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { THEME_TYPE_LITE } from '../../constants/ThemeSetting';
import { useSelector } from 'react-redux';
import { Skeleton } from 'antd';

const RouteTitle = ({ title,icon, loading, fontSize }) => {
    //accept title and icon from props
    const themeType                                     = useSelector(({ settings }) => settings.themeType);

    const parentDivStyle = {
        
        padding: '18px 5px' ,
        background: themeType === THEME_TYPE_LITE ? '#fff': '#38424B',
        display: 'flex', 
        alignItems: 'center', 
        borderRight: themeType === THEME_TYPE_LITE ? '1px solid rgba(232,232,232,0.5)' :'1px solid #495762',
        // width: '100%'
    }
    const titleStyle = {
        fontSize: fontSize ? fontSize : '1.5em'
    }

    return (

        <>

            {loading === true ? <Skeleton active paragraph={false}/>
        
                :
                    <div style={parentDivStyle}>
                        <FontAwesomeIcon className="icon gx-mr-3" icon={icon} style={{ fontSize: '1.5em' }} />
                        <span style={titleStyle} >{title}</span>
                    </div>
            }
        
        </>
    );
};

export default RouteTitle;