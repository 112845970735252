import React, { useState, useEffect } from "react";
import { Button, Popover } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import CustomScrollbars from "../../../util/CustomScrollbars";

import DarkLightMode from "../../../components/DarkLightMode";
import languageData from "../languageData";
import { switchLanguage, toggleCollapsedSideNav } from "../../../appRedux/actions/Setting";
import { THEME_TYPE_LITE } from "../../../constants/ThemeSetting";
import HorizontalNav from "../HorizontalNav";
import Logo from "../../App/Logo";
import {
    MenuFoldOutlined, MenuUnfoldOutlined
  } from '@ant-design/icons';
import LandingSidebar from "../../Sidebar/LandingSidebar";
import { useHistory } from "react-router-dom";
import firebase from "firebase";
import {get} from "lodash-es";

const database    = firebase.database();

const LandingHeader = ({searchInput}) => {

    const history = useHistory();


    const locale                  = useSelector(({ settings }) => settings.locale);
    const navCollapsed            = useSelector(({ common }) => common.navCollapsed);
    const dispatch                = useDispatch();
    const { authUser, db }        = useSelector(({ auth }) => auth);

    const [swidth, setSWidth]     = useState(window.innerWidth);
    const isMobile                = swidth <= 768;
    const {themeType}             = useSelector(({settings}) => settings);


    function handleWindowSizeChange() {
        setSWidth(window.innerWidth);
    }

    useEffect(() => {

        if (authUser !== null) {
            console.log('HISTORY: ', history.location.pathname);
            history.push(`/main/dashboard`);
        }

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [authUser, history, db]);

    




    return (
        <div className="gx-header-horizontal gx-above-header-horizontal gx-py-2" >

            {isMobile === false ? (
                <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve" style={{background: 'rgba(0,0,0,0)'}}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '18px',
                            paddingLeft: '7%',
                            paddingRight: '7%'
                        }}>

                            <Logo/>

                            <HorizontalNav />

                            {
                                searchInput !== undefined ? searchInput : null
                            }

                            <ul style={{
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center"
                            }} className="gx-header-notifications">

                            <Button 
                                onClick={() => history.push('/signin')}
                                className="ant-btn ant-btn-default gx-mb-0 btn-modifier gx-text-white" size='large' type='primary'  >
                                Log-In
                            </Button>
                            </ul>

                        </div>
                </div>
            ):
            
            <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve" style={{background: 'rgba(0,0,0,0)'}}>
                    <div  style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '18px',
                        paddingLeft: '7%',
                        paddingRight: '7%'
                    }}>
                    
                        <LandingSidebar />
                        

                        <img style={{width: '120px'}} className="sidebarFullLogo" alt="logo1" src={("/assets/images/logo-light.png")}/>

                        {/* <ul className="gx-header-notifications">
                            <li className="gx-language">
                            <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={languageMenu()}
                                trigger="click">
                                <span className="gx-pointer gx-flex-row gx-align-items-center">
                                <i className={`flag flag-24 flag-${locale.icon}`} />
                                <span   className="gx-pl-2 gx-language-name">{locale.name}</span>
                                <i className="icon icon-chevron-down gx-pl-2" />
                                </span>
                            </Popover>
                            </li>
                            <li style={{marginTop:'3px'}}>
                            <DarkLightMode />
                            </li>   
                        </ul> */}
                        <Button style={{
                            backgroundColor:"transparent",
                            borderColor:"transparent"
                        }} className="gx-m-0 gx-p-0" size="large" type="text" onClick={()=>dispatch(toggleCollapsedSideNav(!navCollapsed))}>{navCollapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}</Button>


                    </div>
            </div>
                    
            }


        </div>
    );
};


export default LandingHeader;
