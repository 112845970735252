import { Button, Space,Table , Tabs, Tag, message, Row} from "antd";
import  { useState, useEffect } from "react";
import "../../../i18n";
import { useSelector } from "react-redux";
import { get } from "lodash-es";
import { decodeString, formatDateAMPM } from "../../../util/misc";
import SentSMSStatusModalTable from "../../../components/SendSMSModal/SentSMSStatusModalTable";
import { useTranslation } from "react-i18next";
import SendSMSModal
 from "../../../components/SendSMSModal";
import CRangePicker from "../../../components/CRangePicker";
import PrintExportPlot from "../../../components/PrintExportPlot";
import DynamicSentSMSStatusModalTable from "../../../components/SendSMSModal/DynamicSentSMSStatusModalTable";

const { TabPane } = Tabs;

const History = () => {

    const [tabPanes, setTabPanes]                           = useState([]);
    const [tabPanes2, setTabPanes2]                         = useState([]);


    const { db }                                            = useSelector(({ auth }) => auth);
    const { t }                                             = useTranslation();
    const [isResponseModal, setIsResponseModal]             =   useState(false);
    const [requestID, setRequestID]                         =   useState(undefined);
    const [sentTimeStamp, setSentTimeStamp]                 =   useState(0);

    const [isDResponseModal, setIsDResponseModal]           =    useState(false);
    const [dynamicID, setDynamicID]                         =    useState(undefined);

    const [isSmsModalVisible, setIsSmsModalVisible]         =   useState(false);
    const [defaultSMSMessage, setDefaultSMSMessage]         =   useState('');
    const [defaultSMSRecepients, setDefaultSMSRecepients]   =   useState([]);
    const [openHistoryOBJ, setOpenHistoryObj]               =   useState(undefined);
    const [startDateSPH , setStartDateSPH]                  =   useState(0);
    const [endDateSPH , setEndDateSPH]                      =   useState(0);
    const [startDateSSH , setStartDateSSH]                  =   useState(0);
    const [endDateSSH , setEndDateSSH]                      =   useState(0);
    const [startDateSWH , setStartDateSWH]                  =   useState(0);
    const [endDateSWH , setEndDateSWH]                      =   useState(0);


    const [columns, setColumns] = useState([
        {
          title: t('Recipients'),
          dataIndex: 'tags',
          render: (_, { tags }) => (
            <>
                <Tag color={'red'} key={'45sd13'}>
                    Total: {tags?.length} 
                </Tag>
              {tags?.map((tag) => {

                if (tag){
                    return (
                      <Tag color={'geekblue'} key={tag}>
                        {String(truncate(tag,28)).toUpperCase()}
                      </Tag>
                    );
                }
                else{
                    return <></>
                }
      
              })}
            </>
          ),
        },
        {
          title: t('Message Body'),
          dataIndex: 'message',
          width : "30%"
        },
        {
          title: t('Time & Date'),
          dataIndex: 'time',
          key: 'time',
          render: (_, record) => (
                <span className="gx-mx-0 gx-px-0">{formatDateAMPM(new Date(record?.time))}</span>
          ),
        },
        {
          title: t('Status'),
          dataIndex: 'status',
          key: 'status',
          render: (_, record) => (
            <Space size="middle">
              <Button className="gx-mx-0 gx-px-0" type="link" onClick={()=>openHistoryStatusOfSentSMS(record)} >&nbsp; OPEN &nbsp;</Button>
            </Space>
          ),
        },
    ]);

    const columnsPurchase = [
        {
            title: t('Currency'),
            dataIndex: 'currency',
            },
        {
        title: t('Currency Amount'),
        dataIndex: 'currencyAmount',
        },
        {
        title: t('Rate'),
        dataIndex: 'rate',
        },
        {
        title: t('Sms Amount Bought'),
        dataIndex: 'smsAmountBought',
        },
        {
        title: t('Time & Date'),
        dataIndex: 'time',
        key: 'time',
          render: (_, record) => (
                <span className="gx-mx-0 gx-px-0">{formatDateAMPM(new Date(record?.time))}</span>
          ),
        }
    ];
    

    const onChangeTab = (key) => {
        console.log(key);
    };



    const onChangeSMSPurchaseHistoy = (data) => {
        setStartDateSPH(data.startDate);
        setEndDateSPH(data.endDate);
    }
    const onChangeSSH = (data) => {
        setStartDateSSH(data.startDate);
        setEndDateSSH(data.endDate);
    }
    const onChangeSWH = (data) => {
        setStartDateSWH(data.startDate);
        setEndDateSWH(data.endDate);
    }
    const onChange = () =>{}
    //set up purchase sms history
    useEffect(() => {
        let outputTabs = [];
        const dataPurchase = [];
        let smsPurchaseHistory = get(db, ["SMS-Purchase-History"], {});
        for (let key in smsPurchaseHistory) {

            const student = smsPurchaseHistory[key];
            let date = formatDateAMPM(student.time);
            dataPurchase.push({
            key : key,
            ...student,
            //time: date,
            });
        }
    
        let result = dataPurchase?.filter(d => {var time = d.time;
            return (startDateSPH < time && time < endDateSPH);
           });
     
        const reversed =  result.reverse();
        //const reversed =  dataPurchase.reverse(); 
        outputTabs.push(
            <TabPane tab="SMS Purchase History" key="2">
                <div className="gx-mt-2"></div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <CRangePicker
                        containerProps =    {{justify:'start'}}
                        onChange       =    {onChangeSMSPurchaseHistoy}
                        size           =    "middle"
                        defaultValue   =    {"12M"}
                    />
                    <PrintExportPlot
                                containerProps     =    {{justify:'end'}}
                                size               =    {"middle"}
                                headers            =    {[
                                                            { header: 'Currency', dataKey: 'currency' },
                                                            { header: 'currency Amount', dataKey: 'currencyAmount' },
                                                            { header: 'Rate', dataKey: 'rate' },
                                                            { header: 'Sms Amount Bought', dataKey: 'smsAmountBought' },
                                                            { header: 'Time', dataKey: 'time' },
                                                        ]}
                                columnExcelHeader  =    {["currency","currencyAmount","rate","smsAmountBought","time"]}
                                data               =    {result}
                                xAxis              =    {"time"}
                                lineCol            =    {[{v:"currencyAmount",c:"#82ca9d"}]} 
                                reverse            =    {true}
                    />
                </div>
                <Table
                    className="table-striped-rows custom-fix-pagination"
                    columns={columnsPurchase}
                    dataSource={reversed}
                    style={{marginTop: '1em'}}
                    pagination={{pageSize: 6}}
                />
            </TabPane>
        );


        outputTabs.reverse();
        setTabPanes(outputTabs);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [db, startDateSPH, endDateSPH]);

    //set up sent sms history
    useEffect(() => {

        let outputTabs      = [];
        const data          = [];
        let smsHistory      = get(db, ["SMS-Sent-History"], {});
        for (let key in smsHistory) {
            const student = smsHistory[key];
            
            data.push({
                key : key,
                ...student,
                message: decodeString(student.message),
            });
        }
        let result = data?.filter(d => {var time = d.time;
            return (startDateSSH < time && time < endDateSSH);
           });
        
        const reversed =  result.reverse();
        outputTabs.push(
            <TabPane tab="Sent SMS History" key="1">

                <div className="gx-mt-2"></div>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    
                        <CRangePicker
                            containerProps =    {{justify:'start'}}
                            onChange       =    {onChangeSSH}
                            size           =    "middle"
                            defaultValue   =    {"12M"}
                        />
                        <PrintExportPlot
                            containerProps      =    {{justify:'end'}}
                            size                =    {"middle"}
                            headers             =    {[
                                                        { header: 'Tags', dataKey: 'tags' },
                                                        { header: 'Message', dataKey: 'message' },
                                                        { header: 'Time', dataKey: 'time' },
                                                    ]}
                            columnExcelHeader   =    {["Tags","Message","Time"]}
                            data                =    {result}
                            xAxis               =    {"time"}
                            lineCol             =    {[{v:"total_recipients",c:"#82ca9d"}]}
                            reverse             =    {true}
                        />
                </div>
                        <Table
                            className   =   "table-striped-rows custom-fix-pagination"
                            columns     =   {columns}
                            dataSource  =   {reversed}
                            style       =   {{marginTop: '1em'}}
                            pagination  =   {{pageSize: 6}}
                        />
            </TabPane>
        );

        outputTabs.reverse();
        setTabPanes2(outputTabs);
    }, [db, columns, startDateSSH, endDateSSH]);



    const openHistoryStatusOfSentSMS = (obj) => {

        if (obj['firebaseKey']){
            setDynamicID(obj['firebaseKey']);
            setSentTimeStamp(get(obj, ['time'], 0));
            setIsDResponseModal(true);
            return false;
        }

        if (obj['request_id'] === 'SCHEDULED-SMS'){
            message.info('This was a Scheduled-SMS!');
            return false;
        }

        setOpenHistoryObj(obj);
        setRequestID(get(obj, ['request_id'], undefined));
        setSentTimeStamp(get(obj, ['time'], 0));
        setIsResponseModal(true);
    }


    const OperationsSlot = {
        //left: <Button className="tabs-extra-demo-button">Left Extra Action</Button>,
        //right: 
      };
    
    function truncate(str, n){
        return (str.length > n) ? str.slice(0, n-1) + '...' : str;
    };
    
    return (

        <div className="content-wrapper-SMS-settings ">
            
            <div className="custom-line-up-table" ></div>


            <Tabs 
                tabBarExtraContent  = {OperationsSlot}
                defaultActiveKey    =   "1" 
                onChange            =   {onChangeTab}

            >
                
                {tabPanes2}
                {tabPanes}

            </Tabs>
            {/* <Button className="gx-btn-dark btn-pos-selection-manualy">Print</Button> */}

            <SentSMSStatusModalTable 
            
                request_id              = {requestID} 
                setIsModalVisible       = {setIsResponseModal} 
                isModalVisible          = {isResponseModal}
                sentTimeStamp           = {sentTimeStamp}
                resendCallback          = {(resendNumbersArr)=>{

                    if (resendNumbersArr.length === 0){
                        message.error('At least one phone number needs to be checked to resend!');
                        return false;
                    }

                    let defaultREC = [];
                    setDefaultSMSRecepients(undefined);
                    for (let onePhoneOBJ of resendNumbersArr){
                        defaultREC.push({studentPhone: [`+${onePhoneOBJ.Phone}`], tagName: `+${onePhoneOBJ.Phone}`});
                    }

                    if (openHistoryOBJ !== undefined){
                        setDefaultSMSMessage(decodeString(openHistoryOBJ.message));
                    }

                    setTimeout(()=>{
                        setDefaultSMSRecepients(defaultREC);
                        setIsSmsModalVisible(true);
                    }, 1000);

                }}
            />

            <DynamicSentSMSStatusModalTable 
                firebaseKey             = {dynamicID} 
                setIsModalVisible       = {setIsDResponseModal} 
                isModalVisible          = {isDResponseModal}
                sentTimeStamp           = {sentTimeStamp}
            />

            <SendSMSModal
                active              =   {[]}
                isModalVisible      =   {isSmsModalVisible}
                setIsModalVisible   =   {setIsSmsModalVisible}
                defaultMessage      =   {defaultSMSMessage}
                defaultRecepients   =   {defaultSMSRecepients}
            />


             
        </div>
    );
};

export default History;