import React, {useEffect} from 'react';
import './Auth.css'
import { useDispatch } from "react-redux";
import { updateInLocalDatabase, userSignOut } from '../appRedux/actions';


const ForceLogout = () => {

    const dispatch                      = useDispatch();

    useEffect(() => {
        dispatch(updateInLocalDatabase({ objToAdd: undefined}));
        dispatch(userSignOut());
    }, [dispatch]);

    return (
        <div className="auth-main-wrapper">
            <label>You have been logged out!</label>
        </div>
    );
};

export default ForceLogout;