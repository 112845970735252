import React from "react";
import {useSelector} from "react-redux";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";


const Logo = () => {


  const {width, themeType} = useSelector(({settings}) => settings);
  let navStyle = useSelector(({settings}) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  return (
    <>
    {      themeType === THEME_TYPE_LITE ?
        <img className="sidebarFullLogo" alt="logo1" src={("/assets/images/logo-dark.png")}/> :
        <img className="sidebarFullLogo" alt="logo2" src={("/assets/images/logo-light.png")}/>}
    </>
  );
};

export default Logo;
