import React from 'react';
import { Typography } from 'antd';

const PanelHead = ({title, isLineAfterText, titleLevel}) => {

    return (
        <div className= {isLineAfterText === true ? "drawer-pannel-head": "drawer-pannel-head-no-line-after-text"} >
            <Typography.Title level={titleLevel ? titleLevel : 4}>{title}</Typography.Title>
        </div>
    );
};

export default PanelHead;