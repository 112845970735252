import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Image , Form, Table} from 'antd';
import { get } from "lodash-es";
import { useSelector } from 'react-redux';
import EditGroupModal from "../EditGroupModal";
import { useTranslation } from "react-i18next";
import { decodeString } from "../../../../util/misc";


const GroupDetailsModal = ({ isModalVisible, loading, handleOk, handleCancel, onItemSelect }) => {


    const { db }                                      = useSelector(({ auth }) => auth);
    const [title, setTitle]                           = useState();
    const [selectedGroup, setSelectedGroup]           = useState();
    const [isEditGroupModal, setIsEditGroupModal]     = useState(false);
    const [allStudentInfo, setAllStudentInfo]         = useState([]);
    const [selectedStudent, setSelectedStudent]       = useState([]);
    const { t }                                       = useTranslation();

    
    const columns = [
        {
            dataIndex: 'Name',
            title: t('Student Info'),
            render: (t, r) => 
            //if customNumber then do not treat this as student // treat this as a single number
            {

                //console.log('R: ', r);
                
                return r.customNumber !== undefined ? 
                <>
                    <Image style={{ width: '25px', borderRadius: '50%' }} src={`${r.avatarURL}`} /> <span>{r.customName}</span>
                </>: 
                    <>
                        <Image style={{ width: '25px', borderRadius: '50%' }} src={`${r.avatarURL}`} /> <span>{t}</span>
                        <Row style={{paddingLeft: '50px'}}>
                            <Col>
                                <h6 className="darkMode-Color-White-title">Parents 1</h6>
                                <h6 className="darkMode-Color-White-title">Parents 2</h6>
                            </Col>
                        </Row>
                    </>
            
                }
        },
        {
            title: t('Phone number'),
            dataIndex: 'Phone',
            render: (t, r) => 
            //if customNumber then do not treat this as student // treat this as a single number        
            {
                return r.customNumber !== undefined ? 
                <span>{t}</span>
                : 
                <>
                    <span>{t}</span>
                    <Row style={{ paddingTop: '5px', paddingLeft:'15px' }}>
                        <Col>
                            <h5 className="darkMode-Color-White-title">{r.parent1phone? r.parent1phone: "Number not given"}</h5>
                            <h5 className="darkMode-Color-White-title">{r.parent2phone? r.parent2phone: "Number not given"}</h5>
                        </Col>
                    </Row>
                </>
        
            }
        },
    ];
    
    useEffect(() => {
        //console.log('Group Details Modal Input Data: ', onItemSelect);
        if (onItemSelect) {
            let groups      = get(db, ["GROUPS",onItemSelect], {});
            let title       = `${groups.groupName}`;
            setTitle(title);
            setSelectedStudent(groups.selectedStudents);
            setSelectedGroup(groups);
        }

     }, [db, onItemSelect ]);
    
    const openEditGroupModal = () => {
        setIsEditGroupModal(!isEditGroupModal);
        handleCancel();
    };
    const handleEditModalOk = () => {
        setIsEditGroupModal(false);
    };
    
    const handleEditModalCancel = () => {
        setIsEditGroupModal(false);
    };
    
    return (
        <>
            <Modal
                centered
                bodyStyle       =   {{paddingTop: '5px'}}
                title           =   {title?decodeString(title) : 'Group details'}
                visible         =   {isModalVisible}
                onOk            =   {handleOk}
                onCancel        =   {handleCancel}
                width           =   {500}
                footer          =   {[
                    <Button onClick={openEditGroupModal} type="primary">
                        {t("Edit")}
                    </Button>
                ]}
            >
                <Table
                    className   =   "table-striped-rows scrollbar-remove"
                    columns     =   {columns}
                    dataSource  =   {selectedStudent}
                    pagination  =   {false}
                    style                   =   {{
                        height: "calc(50vh - 10px)",
                        marginTop: '1em',
                        overflowY: "auto",
                    }}     
                />
            </Modal>


            <EditGroupModal
                isModalVisible      =   {isEditGroupModal}
                handleCancel        =   {handleEditModalCancel}
                handleOk            =   {handleEditModalOk} 
                onItemSelect        =   {onItemSelect}
            />
        </>
    );
};

export default GroupDetailsModal;