import React, { useState, useEffect } from "react";
import { Menu, Row, Col, Skeleton } from "antd";
import { Link } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import {
    NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
    THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import { useSelector } from "react-redux";

import UserProfile from "./UserProfile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUsers,
    faCog,
    faCommentSms,
    faHome,
    faLock,
    faMoneyBill
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";


const iconStyle = {
    width: "20px",
    height: "20px",
};



const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
    
    const { navStyle, themeType }           = useSelector(({ settings }) => settings);
    const pathname                          = useSelector(({ common }) => common.pathname);

    const [sidebarRoutes, setSidebarRoutes] = useState([]);

    const { db }                            = useSelector(({ auth }) => auth);

    const selectedKeys                      = pathname.substr(1);
    const defaultOpenKeys                   = selectedKeys.split("/")[1];
    const { t }                             = useTranslation();

    const getNoHeaderClass = (navStyle) => {
        if (
            navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
            navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
        ) {
            return "gx-no-header-notifications";
        }
        return "";
    };

    //setup the side-bar content routes based on whoever is logged in (for test purposes we are assuming its teacher right now)
    useEffect(() => {

        if(!db){
            return false
        }

        let potentialSidebar_Routes             = [];


        potentialSidebar_Routes = (
            <MenuItemGroup
                key="main"
                className="gx-menu-group"
                title={
                    <div className={`${getNoHeaderClass(navStyle)}`}>
                        <UserProfile />
                    </div>
                }
            >
                <Menu.Item key="main/dashboard" style={{display:'flex'}}>
                    <Link to="/main/dashboard" style={{'display': 'flex','justifyContent': 'flex-start','alignItems': 'center'}}>
                        <FontAwesomeIcon
                            className="icon"
                            style={iconStyle}
                            icon={faHome}
                        />
                        <span className="gx-font-weight-normal">{t("Dashboard")}</span>
                    </Link>
                </Menu.Item>
                

                <Menu.Item key="main/users">
                    <Link to="/main/users" style={{'display': 'flex','justifyContent': 'flex-start','alignItems': 'center'}}>
                        <FontAwesomeIcon
                            className="icon"
                            style={iconStyle}
                            icon={faUsers}
                        />
                        <span className="gx-font-weight-normal">Employees</span>
                    </Link>
                </Menu.Item>

                <Menu.Item key="main/access-control">
                    <Link to="/main/access-control" style={{'display': 'flex','justifyContent': 'flex-start','alignItems': 'center'}}>
                        <FontAwesomeIcon
                            className="icon"
                            style={iconStyle}
                            icon={faLock}
                        />
                        <span className="gx-font-weight-normal">Retail Analytics</span>
                    </Link>
                </Menu.Item>
            
                {/* <Menu.Item key="main/monetize-screen">
                    <Link to="/main/monetize-screen" style={{'display': 'flex','justifyContent': 'flex-start','alignItems': 'center'}}>
                        <FontAwesomeIcon
                            className="icon"
                            style={iconStyle}
                            icon={faMoneyBill}
                        />
                        <span className="gx-font-weight-normal">Monetize Screens</span>
                    </Link>
                </Menu.Item> */}

                <Menu.Item key="main/sms-settings" style={{display:'flex'}}>
                    <Link to="/main/sms-settings" style={{'display': 'flex','justifyContent': 'flex-start','alignItems': 'center'}}>
                        <FontAwesomeIcon
                            className="icon"
                            style={iconStyle}
                            icon={faCommentSms}
                        />
                        <span className="gx-font-weight-normal"> {t("Manage SMS")}</span>
                    </Link>
                </Menu.Item>

                <Menu.Item key="main/settings" style={{display:'flex'}}>
                    <Link to="/main/settings" style={{'display': 'flex','justifyContent': 'flex-start','alignItems': 'center'}}>
                        <FontAwesomeIcon
                            className="icon"
                            style={iconStyle}
                            icon={faCog}
                        />
                        <span className="gx-font-weight-normal"> {t("Settings")}</span>
                    </Link>
                </Menu.Item>



            </MenuItemGroup>
        );


        setSidebarRoutes(potentialSidebar_Routes);
    }, [navStyle, t, db, sidebarCollapsed]);



    //will set basic variables such as user-name, proffession etc
    // useEffect(() => {
    //     if (db) {
    //         let data = db["PublicInfo"];
    //         if (data) {
    //             let { UserProffession, UserEmail } = data;
    //             setProffession(UserProffession);
    //             if (window.$crisp){
    //                 window.$crisp.push(["set", "user:email", decodeString(UserEmail)]);
    //             }
    //         }
    //     }
    // }, [db]);

    return (
        <>
            <SidebarLogo
                sidebarCollapsed={sidebarCollapsed}
                setSidebarCollapsed={setSidebarCollapsed}
                onClose={()=>setSidebarCollapsed(true)}
            />

            <div className="gx-sidebar-content">
                <CustomScrollbars 
                    className="gx-layout-sider-scrollbar"
                >

                    {sidebarRoutes.length === 0 ? 
                        <Row align="center" justify="middle" className="gx-mt-2">
                            <Col span={24} className="gx-ml-4">
                                <Skeleton active paragraph={{ rows: 1 }} />
                            </Col>
                            <Col span={24} className="gx-ml-4">
                                <Skeleton active paragraph={{ rows: 1 }} />
                            </Col>
                            <Col span={24} className="gx-ml-4">
                                <Skeleton active paragraph={{ rows: 1 }} />
                            </Col>
                            <Col span={24} className="gx-ml-4">
                                <Skeleton active paragraph={{ rows: 1 }} />
                            </Col>
                            <Col span={24} className="gx-ml-4">
                                <Skeleton active paragraph={{ rows: 1 }} />
                            </Col>
                            <Col span={24} className="gx-ml-4">
                                <Skeleton active paragraph={{ rows: 1 }} />
                            </Col>

                        </Row>
                        :
                        <></>
                    }

                    <Menu
                        style                   =   {{paddingBottom: '10%'}}
                        defaultOpenKeys         =   {[defaultOpenKeys]}
                        selectedKeys            =   {[selectedKeys]}
                        theme                   =   {themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                        mode                    =   "inline"
                    >
                        {sidebarRoutes}

                    </Menu>


                </CustomScrollbars>


            </div>

        </>
    );
};

export default React.memo(SidebarContent);