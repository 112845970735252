

import EdutechCloudServer from "../EdutechCloudServer";


class Email {

    send({message, subject, emailArr, callback, senderName}){
        EdutechCloudServer.postRequest('send-email-amazon-ses', {
            customHTML      : message,
            subject         : subject,
            recepients      : emailArr,
            sender          : senderName ? senderName : 'Sender'
        }, (r)=>{
            if (callback) {
                callback(r)
            }
        });
    }

    sendAdmin({message, subject, callback}){
        EdutechCloudServer.postRequest('send-email-amazon-ses', {
            customHTML: message,
            subject: `${subject}`,
            recepients: ['ekram@raikiri.app'],
            sender: 'Edutechs Admin'
        }, ()=>{
            if (callback) {
                callback()
            }
        });
    }

}


export default new Email()