import EdutechCloudServer from "../EdutechCloudServer";
import {decodeString, isError, isValidPhoneNumber, totalCharactersUSED, encodeString, fixBangladeshPhoneNumbers} from "../util/misc";
import {get} from "lodash-es";
import {set} from "lodash-es";
import xmldoc from "xmldoc";
import ExcelQuery from "./ExcelQuery";
import { DEFAULT_SMS_RATE_NON_MASKING } from "../business_config";



function containsNonLatinCodepoints(s) {
    // eslint-disable-next-line no-control-regex
    return (/[^\u0000-\u00ff]/.test(s));
}

class SMS {

    send({message, phone, uid, callback, gateway}){

        var regexp      = /#\S+/g;
        message         = message.replace(regexp, ''); //remove any hashtags from the message string..
        let thisType    = 'text';

        if (containsNonLatinCodepoints(message)){
            thisType = 'unicode';
        }

        if (!gateway) gateway = 'ALPHA';

        console.log('Sending SMS with Payload: ', {message, phone, uid, gateway});

        EdutechCloudServer.postRequest('send-sms-seer', {
            message: (message), phone, uid
        }, (response)=>{
            console.log('SMS RESPONSE:', response);
            if (!isError(response)){
                callback(JSON.parse(response.msg));
            }
            else{
                console.log('ERROR RESPONSE FROM SENDING SMS: ', response);
                callback({'error': (JSON.parse(response.msg))['error']});
            }
        });
    }


    sendSMS_Group_Individually_Firebase_Middleware({sendingArray, uid, callback, rate, smsRemains,textAreaValue, gateway}){

        EdutechCloudServer.postRequest('send-group-sms-sent-individually-firebase-middleware-seer', {
            sendingArray:sendingArray, uid, rate, smsRemains,textAreaValue
        }, (response)=>{
            console.log(response);
            if (!isError(response)){
                callback(response);
            }
            else{
                console.log('ERROR RESPONSE FROM SENDING SMS: ', response);
                callback(response);
            }
        });

    }

    getSentSMS_INFO({uid, sms_id, callback}){

        console.log('FETCH SMS INFO: ', sms_id);

        EdutechCloudServer.postRequest('get-sent-sms-info-seer', {
            sms_id, uid
        }, (response)=>{
            if (!isError(response)){
                callback(JSON.parse(response.msg));
            }
            else{
                console.log('ERROR RESPONSE FROM GETTING SMS INFO: ', response);
            }
        });
    }


    //schedule arg Date and time must be formatted as Y-m-d H:i:s (eg. 2021-10-13 16:00:52)
    scheduleSMS({message, phone, uid, schedule, callback, gateway}){

        var regexp      = /#\S+/g;
        message         = message.replace(regexp, ''); //remove any hashtags from the message string..

        if (!gateway) gateway = 'ALPHA';

        EdutechCloudServer.postRequest('schedule-send-sms-seer', {
            message: (message), phone, uid, schedule
        }, (response)=>{
            if (!isError(response)){
                callback(JSON.parse(response.msg));
            }
            else{
                console.log('ERROR RESPONSE FROM SCHEDULING SMS: ', response);
            }
        });
    }


    sendGroupSMS_WithExcelMockData({db, excelMockData, textAreaValue, allInputPhone, notification}){

        let newExcelObj         = {};
        set(newExcelObj, 'Default', {
            'file': excelMockData,
            'id': 'Default'
        })

        let smsQUOTA            = get(db, ['SMS-Quota'], {});
        let {v}                 = smsQUOTA;
        if (!v) v               = 0;
        let rate                = get(db, ['SMS-Quota', 'rate'], DEFAULT_SMS_RATE_NON_MASKING);

        let smsQuotaAvailable   = v;

        let excelQueried = ExcelQuery.excelQueryLanguage({
            allPhone        : allInputPhone,
            fullString      : textAreaValue,
            excelFileData   : newExcelObj
        });

        let outputArr      = excelQueried[0];
        let sendArray      = outputArr;

        //check to see if enough quota is available
        let totalSMSNeeded = 0;
        for (let onePhoneOBJ of sendArray){
            if (isValidPhoneNumber(onePhoneOBJ['phone'])){
                totalSMSNeeded = totalSMSNeeded + parseInt(onePhoneOBJ['totalSMSNeeded']);
            }
        }

        if ((totalSMSNeeded) > smsQuotaAvailable){
            let key = `open${Date.now()}`;
            notification.open({
                message: "Not Enough SMS Quota",
                description: `You do not have enough SMS Quota. You need ${totalSMSNeeded} total quota. (153 characters per SMS only). Your current quota is ${smsQuotaAvailable}.`,
                duration: 0,
                key: key,
                type: 'warning'
            });
            return false;
        }


        //enough quota is available send the actual SMS..
        let smsRemains          = get(db, ["SMS-Quota", "v"], undefined);
        let allPhone            =   [];

        for (let oneObj of sendArray) {
            allPhone.push(oneObj['phone']);
        }

        if(sendArray.length !== 0){
            this.sendSMS_Group_Individually_Firebase_Middleware({gateway: get(db, ['SMS-Quota', 'GATEWAY'], undefined),sendingArray: sendArray, rate, smsRemains,textAreaValue, uid:db['UID'], callback: (r)=>{
                console.log('SMS for ROll Call Sent: ', r);
            }})
        } 
    }

}


export default new SMS()