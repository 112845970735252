import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Input, Image , Form, message, Menu, Dropdown, Checkbox, TimePicker} from 'antd';
import { get, indexOf, pick, set } from "lodash-es";
import { useSelector } from 'react-redux';
import { decodeString, encodeString } from "../../../../util/misc";
import firebase from 'firebase';
import { useTranslation } from "react-i18next";



const database    = firebase.database();


const AddNewTimingModal = ({ isModalVisible, handleCancel, roomKey, weekDay, roomName }) => {

    const { db }                                     = useSelector(({ auth }) => auth);
    const { t }                                      = useTranslation();

    const [pickedStartTime, setPickedStartTime]      = useState();
    const [pickedEndTime, setPickedEndTime]          = useState();

    const [tName, setTName]                          = useState('');

    useEffect(()=>{
        if (db && isModalVisible===true && roomKey !== undefined && weekDay !== undefined && roomName !== undefined){


        }
    }, [db, isModalVisible,roomKey, weekDay, roomName])

    
    const submitNewTiming = () => {

        console.log('SUBMIT NEW TIMINGS WITH VALUES: ', {
            pickedStartTime, pickedEndTime, roomKey, weekDay, roomName, tName
        });

        if (!pickedStartTime || !pickedEndTime || !roomKey || !weekDay || !roomName || tName === ''){
            message.error('Please choose a proper timing and have all fields filled in..');
            return false;
        }

        //end time cannot be before the start time
        if (pickedStartTime.valueOf() > pickedEndTime.valueOf()){
            message.error('End Time must be later than Start Time');
            return false;
        }

        let tuid, teacherName, avatarURL;

        tuid            = db['UID'];
        teacherName     = db['PublicInfo']['UserName'];
        avatarURL       =  db['PublicInfo']['avatarURL']


        let data = {
            tuid, 
            teacherName, 
            startTime: pickedStartTime.valueOf(), 
            endTime: pickedEndTime.valueOf(), 
            avatarURL,
            timingName: encodeString(tName)
        }

        database.ref(`USERS/${db['UID']}/access_control/${roomKey}/timings/${weekDay}`).push(data)
            .then(()=>{
                message.success('New timing added for room successfully');
                handleCancel();
            });
    }
    
    return (
        <>
            <Modal
                centered
                title       =   {`New Timing: ${decodeString(roomName)} for ${weekDay}`}
                visible     =   {isModalVisible}
                onOk        =   {()=>submitNewTiming()}
                onCancel    =   {handleCancel}
                bodyStyle   =   {{paddingTop: '5px', paddingBottom: '40px'}}
                width       =   {550}
                footer      =   {[
                    <Button type="primary" onClick={()=>submitNewTiming()}>
                        Add
                    </Button>
                ]}
            >

                    <label style={{marginTop: '20px', display: 'flex', marginBottom: '5px'}}>Name</label>
                    <Input 
                        style       =   {{width: '100%'}}
                        onChange    =   {(v)=>setTName(v.target.value)} 
                        value       =   {tName}
                        placeholder =   {'e.g Morning First-Hour Check-In'}
                        name        =   "timingName" 
                        type        =   "text"
                    />

                    <label style={{marginTop: '20px', display: 'flex', marginBottom: '5px'}}>Start Time</label>
                    <TimePicker 
                        style={{width: '100%'}}
                        use12Hours 
                        format      =   "h:mm A"  
                        onChange    =   {(v)=>setPickedStartTime(v)} 
                        onSelect    =   {(v)=>setPickedStartTime(v)} 
                        value       =   {pickedStartTime}
                        placeholder =   {t("Start Time")}
                        name        =   "StartTime" 
                        placement   =   "bottomLeft"
                        allowClear  =   {false}
                    />

                    <label style={{marginTop: '20px', display: 'flex', marginBottom: '5px'}}>End Time</label>
                    <TimePicker 
                        style={{width: '100%'}}
                        use12Hours 
                        format      =   "h:mm A"  
                        onChange    =   {(v)=>setPickedEndTime(v)} 
                        onSelect    =   {(v)=>setPickedEndTime(v)} 
                        value       =   {pickedEndTime}
                        placeholder =   {t("End Time")}
                        name        =   "EndTime" 
                        placement   =   "bottomLeft"
                        allowClear  =   {false}
                    />
            
            </Modal>
        </>
    );
};

export default AddNewTimingModal;