import { useState, useEffect } from "react";
import { Modal} from 'antd';
import { useSelector } from 'react-redux';
import { decodeString } from "../../../../util/misc";

import QRCode from "react-qr-code";



const RoomLinkQRModal = ({ isModalVisible, handleCancel, link, roomName }) => {

    const { db }                                     = useSelector(({ auth }) => auth);
    const [qrCode, setQrCode]                        = useState(undefined); 


    useEffect(()=>{
        if (db && isModalVisible===true && link !== undefined ){
            setQrCode(link);
        }
    }, [db, isModalVisible,link])

    

    
    return (
        <>
            <Modal
                centered
                title       =   {`${decodeString(roomName)} Link`}
                visible     =   {isModalVisible}
                onOk        =   {handleCancel}
                onCancel    =   {handleCancel}
                bodyStyle   =   {{paddingTop: '5px', paddingBottom: '40px'}}
                width       =   {550}
                footer      =   {null}
            >
                <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>

                    <p style={{textAlign:'center'}}>Scan with your phone to open QR Scanner Access Control for {decodeString(roomName)}</p>

                    {qrCode === undefined ? <></>:                 
                        <>
                            <QRCode value={qrCode} /> 
                            <br></br>
                            {window.innerWidth > 768 ? <a target={"_blank"} rel="noreferrer" href={link}>Open Link</a> : <></>}
                        </>
                    }

                </div>
            
            </Modal>
        </>
    );
};

export default RoomLinkQRModal;