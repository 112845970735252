import React, { useCallback, useEffect, useState } from "react";
// eslint-disable-next-line
import { Col, Row, message, notification, Button, Checkbox, Tooltip,Popconfirm } from "antd";
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { get, isEmpty } from "lodash-es";
import firebase from "firebase";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { decodeString } from "../../../util/misc";

import ContentTopBar from "../../../components/ContentTopBar";
import RouteTitle from "../../../components/RouteTitle";

import UsersCard from "../../../components/UsersCard";
import AddOneUserDrawer from "../../../components/UsersCard/AddOneUserDrawer";

import Mixpanel from "../../../api/Mixpanel";
import UserProfileDrawer from "../../../components/UsersCard/UserProfileDrawer";
import EditUserDrawer from "../../../components/UsersCard/EditUserDrawer";


const database = firebase.database();


const Users = () => {

    const { t }                                                 =  useTranslation();
    const { db }                                                =  useSelector(({ auth }) => auth);

    const [isBatch, setIsBatch]                                 =  useState(false);
    const [color, setColor]                                     =  useState(undefined);
    const [loadingStudentCards, setLoadingStudentCards]         =  useState(true);
    const [showAddOneStudentDrawer, setShowAddOneStudentDrawer] =  useState(false);   
    const [loading, setLoading]                                 =  useState(false);
    const [showBulkStudentAdd, setShowBulkStudentAdd]           =  useState(false);   
    const [noSubjectAcc, setNoSubjectAcc]                       =  useState(false);

    const [allStudentsID, setAllStudentID]                      =  useState([])
    const [allStudents, setAllStudents]                         =  useState([])
    const [filterStudents, setFilterStudents]                   =  useState([])
 
    const [indeterminate, setIndeterminate]                     =  useState(false);
    const [checkAll, setCheckAll]                               =  useState(false);
    const [selectedStudent, setSelectedStudent]                 =  useState([]);

    const [studentUID, setStudentUID]                           =  useState('');
    const [stdProfileVisible, setStdProfileVisible]             =  useState(false);
    const [stdEditVisible, setStdEditVisible]                   =  useState(false);


 
    // Select student one by one
    const selectOnStudentHandler = (checked, UID) => {

        if(checked === true){

           setSelectedStudent(prevSelected=> {
                let prevCloneSelected = [...prevSelected];

                if(!prevCloneSelected.includes(UID)){
                    prevCloneSelected.push(UID)
                }
                setIndeterminate(!!prevCloneSelected.length && prevCloneSelected.length < allStudentsID.length);
                setCheckAll(prevCloneSelected.length === allStudentsID.length);

                return prevCloneSelected

           })

            setFilterStudents(prevStudents=> {
                let prevCloneStudents = [...prevStudents]
                let findSelectedStudentIndex = prevCloneStudents.findIndex(item=> item.UID === UID)

                let findSelectedStudent = prevCloneStudents.find(one => one.UID === UID);

                findSelectedStudent.props.isSelected = true;

                prevCloneStudents[findSelectedStudentIndex] = findSelectedStudent

                return prevCloneStudents
            })
        }
        else{
            setSelectedStudent(prevSelected=> {
                let prevCloneSelected = [...prevSelected];
                let newSelectedArray = prevCloneSelected.filter(item => item !== UID);
                if(newSelectedArray.length > 0 && newSelectedArray.length < allStudentsID.length){

                    setIndeterminate(true);
                    setCheckAll(false);
                }
                else{
                    setIndeterminate(false)
                    setCheckAll(newSelectedArray.length === allStudentsID.length);
                }

                return newSelectedArray

           })

            setFilterStudents(prevStudents=> {
                let prevCloneStudents = [...prevStudents]
                let findSelectedStudentIndex = prevCloneStudents.findIndex(item=> item.UID === UID)

                let findSelectedStudent = prevCloneStudents.find(one => one.UID === UID);

                findSelectedStudent.props.isSelected = false;

                prevCloneStudents[findSelectedStudentIndex] = findSelectedStudent

                return prevCloneStudents
            })
        }

    }


    // Select all students
    const checkAllStudentOnChange = (e) => {

        if(e.target.checked){
            setFilterStudents(prevStudents=> {
                let prevCloneStudents = [...prevStudents]
              
                let newAllSelectedArray = prevCloneStudents.map(item => {
                    let itemClone = {...item}
                    itemClone.props.isSelected = true;
                    return itemClone
                });
    
                return newAllSelectedArray
            })

            setSelectedStudent(e.target.checked ? allStudentsID : []);
            setIndeterminate(false);
            setCheckAll(e.target.checked);
        }
        else{
            setFilterStudents(prevStudents=> {
                let prevCloneStudents = [...prevStudents]

                let newAllSelectedArray = prevCloneStudents.map(item => {
                    let itemClone = {...item}
                    itemClone.props.isSelected = false;
                    return itemClone
                });
    
                return newAllSelectedArray
            })

            setSelectedStudent(e.target.checked ? allStudentsID : []);
            setIndeterminate(false);
            setCheckAll(e.target.checked);
        }

       
    };




    // Show student profile drawer
    const showStdProfileDrawer = useCallback((stdKey)=>{
        setStudentUID(stdKey)
        setStdProfileVisible(true);
    },[])

    // OnClose student profile drawer
    const onCloseStdProfileDrawer = useCallback(()=>{
        setStdProfileVisible(false);
    },[])

    // Show student edit drawer
    const showStdEditDrawer = useCallback((stdKey)=>{
        setStudentUID(stdKey)
        setStdEditVisible(true);
    },[])

    //OnClose student edit drawer
    const onCloseStdEditDrawer = useCallback(()=>{
        setStdEditVisible(false);
    },[])


        
    // load all students data
    useEffect(()=>{

        let userClass               = get(db, ["AddedUsers"], {});

        setLoading(true)

        if (isEmpty(userClass)){
            setNoSubjectAcc(true);
            setIsBatch(false)
            setLoading(false)
        }
        else{
            setNoSubjectAcc(false);
            setLoadingStudentCards(true);
            setSelectedStudent([]) //if active changed set empty selected student array
            setAllStudents([])
            setIndeterminate(false)
            setFilterStudents([])
            setCheckAll(false)

        
            let usersJSON       =   get(db, ['AddedUsers'], {});
            let studentArr      =   [];
            const StreamColor   =  'rgb(40,40,40)';
            setColor(StreamColor)
        
            setIsBatch(true);
            setLoading(false)
            setNoSubjectAcc(false);

            for (let suid in usersJSON){
                let thisStudentData         = {};
                thisStudentData             = usersJSON[suid];
                thisStudentData['UID']      = suid;
                thisStudentData['color']    = StreamColor
                studentArr.push(thisStudentData)
            }


            //sort this array alphabetically
            
            if (studentArr.length > 0){
                setLoadingStudentCards(false);
                setLoading(false)

                studentArr?.sort((a, b) => a.Name?.localeCompare(b.Name));

                let finalStudentsData   = []
                let studentsUID         = []

                for (let oneS of studentArr){
                
                    const newAcceptedDateString =   get(oneS,['AcceptedDate'],'Feb 9, 23 | 11:56').replace("|", '');
                    const acceptedDateTimeStamp =   new Date(newAcceptedDateString).getTime();
                
                    studentsUID.push(oneS['UID'])
                    let oneStudent = oneS;
                    oneStudent['props'] = {
                        "search-key"        : `${decodeString(oneS.Name)} - ${oneS['Phone']} - ${oneS["customID"] ? oneS["customID"] : "NO ID"})`,
                        "nameProp"          : `${decodeString(oneS.Name)}`,
                        "custom-id-prop"    : `${oneS["customID"] ? oneS["customID"] : "NO ID"}`,
                        "accept-date-prop"  : {acceptedDateTimeStamp},
                        "isSelected"        : false
                    }

                    finalStudentsData.push(oneStudent)
                }    
                
                setAllStudents(finalStudentsData)
                setFilterStudents(finalStudentsData)
                setAllStudentID(studentsUID)  

            }
            else{
                setAllStudents([])
                setFilterStudents([])
                setLoadingStudentCards(false);
                setLoading(false)
            }
        }

    }, [db]);


    return (

        <div className="gx-main-content-wrapper padding-0">
            <ContentTopBar 
                leftContent         =   {<RouteTitle 
                                            title={'Employees'} 
                                            icon={faUsers} 
                                           
                                        />} 
                middleCol={12}
                rightCol={7}
                middleContent       =   {
                                            <>
                                            
                                                <div className="gx-d-flex gx-align-items-center">
                                                    {
                                                        selectedStudent.length > 0 ? 

                                                        <>
                                                            <div className="gx-selection-card gx-mr-2 gx-d-flex gx-align-items-center gx-justify-content-between">
                                                                <div className="gx-selection" style={{width:'110px'}}>
                                                                
                                                                    <Checkbox indeterminate={indeterminate} onChange={checkAllStudentOnChange} checked={checkAll}>
                                                                        {
                                                                            selectedStudent.length === allStudentsID.length ? 'All Selected' : `${selectedStudent.length} Selected`
                                                                        }
                                                                    </Checkbox>
                                                                    
                                                                </div>

                                                                
                                                            </div> 

                                                            <Button loading={loading} 
                                                                    size="medium" 
                                                                    className='gx-mb-0 btn-modifier gx-text-white gx-btn-purple gx-mr-1' onClick={()=>{
                                                                Mixpanel.record({
                                                                    eventName: 'ALL-USER-ACCESS-CODES-OPENED',
                                                                    eventProperties: {
                                                                        value: true
                                                                    }
                                                                });
                                                                window.sessionStorage.setItem('Selected-Users-Access-Codes', selectedStudent);
                                                                window.open(`${window.location.origin}/all-user-access-codes`, '_blank');
                                                            }}>Open Selected Access Codes</Button>
                                                        
                                                            <Popconfirm
                                                                title="Are you sure?"
                                                                onConfirm={() => {
                                                                    for (let oneU of selectedStudent){
                                                                        database.ref(`USERS/${db['UID']}/AddedUsers/${oneU}`).remove();
                                                                        database.ref(`USERS/${oneU}/AcceptedByOwner/${db['UID']}`).remove();
                                                                    }
                                                                    message.success('Users deleted successfully');
                                                                }}
                                                                onCancel={() => {}}
                                                                okText="Yes"
                                                                cancelText="No"
                                                                >
                                                                <Button 
                                                                    loading={loading} 
                                                                    size="medium" 
                                                                    className='gx-mb-0 btn-modifier gx-text-white gx-btn-red gx-mr-1'
                                                                    onClick={()=>{
                                                                    }}
                                                                >
                                                                        Delete Selected
                                                                </Button>

                                                            </Popconfirm>
                                                        
                                                        </>
                                                        : 
                                                        <>
                                                            <Tooltip title="Select all users">
                                                                <div className="gx-selection-card gx-mr-3 gx-d-flex gx-align-items-center gx-justify-content-between">
                                                                    <div className="gx-selection">
                                                                        <Checkbox indeterminate={indeterminate} onChange={checkAllStudentOnChange} checked={checkAll}>
                                                                        </Checkbox>       
                                                                    </div>
                                                                </div>  
                                                            </Tooltip>
                                                                    
                                                            
                                                        </>

                                                    
                                                    }

                                                </div>
                                            
                                            
                                            </>
                                        } 
                rightContent        =   {
                    <div className="gx-d-flex gx-justify-content-end">
                    <Button
                        disabled    ={loading ? true : false}
                        onClick     ={() => {
                                                setShowAddOneStudentDrawer(true)
                                                            
                                                                }}
                                                    size        ="medium"
                                                    className   ="gx-text-white gx-mb-0 gx-btn-actionBlue"
                                                >
                                                    Add New User +
                                                </Button>
                                            </div>
                                            

                                        } 
            />

            
            <UsersCard 
                studentData             =   {filterStudents} 
                isBatch                 =   {isBatch} 
                color                   =   {color}
                loading                 =   {loadingStudentCards}
                addOneStudentHandler    =   {()=>setShowAddOneStudentDrawer(true)} 
                bulkAddStudentHandler   =   {()=>setShowBulkStudentAdd(true)}
                selectOnStudentHandler  =   {(c, v)=> selectOnStudentHandler(c, v)}
                showStdProfileDrawer    =   {showStdProfileDrawer}
                onCloseStdProfileDrawer =   {onCloseStdProfileDrawer}
            />


            <AddOneUserDrawer 
                size                =   {'large'}
                onClose             =   {()=>setShowAddOneStudentDrawer(false)}
                visible             =   {showAddOneStudentDrawer}
            />



            <UserProfileDrawer 
                name                    =   "User Profile" 
                visible                 =   {stdProfileVisible} 
                size                    =   {'large'} 
                studentUID              =   {studentUID}
                onClose                 =   {onCloseStdProfileDrawer} 
                showStdEditDrawer       =   {showStdEditDrawer}
            />

            <EditUserDrawer 
                name                    =   "Edit User Profile" 
                visible                 =   {stdEditVisible} 
                size                    =   {'large'} 
                studentUID              =   {studentUID}
                onClose                 =   {onCloseStdEditDrawer} 
                
            />

        </div>

    );
};

export default Users;
