import { useState } from "react";
import Widget from "components/Widget/index";
function getTintedColor(color, v) {
    if (color.length >6) { color= color.substring(1,color.length)}
    var rgb = parseInt(color, 16); 
    var r = Math.abs(((rgb >> 16) & 0xFF)+v); if (r>255) r=r-(r-255);
    var g = Math.abs(((rgb >> 8) & 0xFF)+v); if (g>255) g=g-(g-255);
    var b = Math.abs((rgb & 0xFF)+v); if (b>255) b=b-(b-255);
    r = Number(r < 0 || isNaN(r)) ? 0 : ((r > 255) ? 255 : r).toString(16); 
    if (r.length == 1) r = '0' + r;
    g = Number(g < 0 || isNaN(g)) ? 0 : ((g > 255) ? 255 : g).toString(16); 
    if (g.length == 1) g = '0' + g;
    b = Number(b < 0 || isNaN(b)) ? 0 : ((b > 255) ? 255 : b).toString(16); 
    if (b.length == 1) b = '0' + b;
    return "#" + r + g + b;
} 
const BuildingCard =({title , icon , onClick, color, buttonStyle, bodyStyle})=> {
    const [col, setCol]                     = useState(color);
    return (
        <Widget styleName="gx-card-full gx-dot-arrow-hover" bodyStyle={bodyStyle} backgroundColor={col} cardStyle={buttonStyle}>
            <div 
                onClick={onClick} 
                style={{backgroundColor: col, cursor: "pointer"}} 
                className="gx-media gx-align-items-center gx-flex-nowrap Bwidget-height-width-dashboard" 
                onMouseEnter={() => setCol(getTintedColor(color,-20))} onMouseLeave={() => setCol(color)} 
            >
                <div className="gx-px-3 gx-build-box-lay">
                <img style={{paddingLeft: '10px'}} alt="" src={icon} height= "35" />
                </div>
                <div className="gx-media-body gx-py-3 gx-pr-4 gx-build-box-lay-content">
                <h3 className="gx-text-truncate-with-overflow-hidden gx-mb-1 gx-text-white line-height-bangla-notInForm">{title}</h3>
                <div className="gx-dot-arrow" style={{paddingRight: '20px'}}
                    
                >
                    <div className="gx-hover-arrow" style={{background: '#FFFFFF30'}}>
                        <i className="icon icon-long-arrow-right gx-text-white" style={{color: "white"}}/>
                    </div>

                    <div className="gx-dot">
                        <i className="icon gx-text-primary"/>
                    </div>
                </div>
                </div>
            </div>
        </Widget>
    );
}

export default BuildingCard;