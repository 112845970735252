import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import logo from '../assets/images/logoLight-NoBackground.png'

import {
  hideMessage,
  showAuthLoader,
  userSignIn,
  switchLanguage
} from "../appRedux/actions";

import firebase from "firebase";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress";
import { MailOutlined, LockFilled } from "@ant-design/icons";
import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./Topbar/languageData";
import DarkLightMode from "../components/DarkLightMode";

const database    = firebase.database();

const SignIn = () => {

    const dispatch                                          = useDispatch();
    const { loader, alertMessage, showMessage, authUser }   = useSelector(({ auth }) => auth);
    const history                                           = useHistory();
    const { locale}                                         = useSelector(({ settings }) => settings);

    const [localLoading, setLocalLoading]                   = useState(false);     

    useEffect(() => {

        if (showMessage) {
            setTimeout(() => {
                dispatch(hideMessage());
            }, 100);
        }

        //if there is existing user then redirect to his dashboard
        if (authUser !== null) {
            setLocalLoading(true);
            console.log('authUser: ', authUser);
            history.push(`/main/dashboard`);
        }
        
    });



    const onFinishFailed = errorInfo => {
    };

    const onFinish = values => {
        console.log('On finish form values: ', values);
        dispatch(showAuthLoader());
        dispatch(userSignIn(values));
    };


    return (
        <div className="gx-app-login-wrap">


            <div className="gx-app-login-container sign-up-page-wrapper">
                <div className="gx-app-login-main-content sign-up-page-flex">
                <div className="gx-app-logo-content left-column">
                    <Link to="/welcome" style={{textAlign: 'center'}}> 
                        <div className="gx-app-logo">
                        <img alt="brand" src={logo} />
                        </div>
                    </Link>
                </div>
                <div className="gx-app-login-content right-column">
                    <div className="right-column-wrapper">
                    <div className="welcome-text">
                        <h3><IntlMessages id="appModule.welcome" /></h3>
                        <p><IntlMessages id="appModule.loginText" /></p>
                    </div>
                    <Form
                        initialValues={{ remember: true }}
                        name="basic"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        className="gx-signin-form gx-form-row0">


                        <label className="uk-form-label"> <IntlMessages id="appModule.email" /></label>
                        <Form.Item
                        initialValue=""
                        rules={[{ required: true, message: 'Please enter a valid email!' }]} name="email">
                        <Input type="email" placeholder="example@gmail.com" prefix={<MailOutlined />} />
                        </Form.Item>



                        <label className="uk-form-label"> <IntlMessages id="appModule.password" /></label>
                        <Form.Item className="uk-position-relative"
                        initialValue=""
                        rules={[{ required: true, message: 'Please enter your Password!' }]} name="password" >
                        <Input.Password type="password" placeholder="Password" prefix={<LockFilled />} />
                        </Form.Item>


                        <Form.Item>
                        <Button loading={localLoading} className="gx-mb-0 ant-btn-primary btn-block" htmlType="submit">
                            &nbsp; <IntlMessages id="app.userAuth.signIn" />
                        </Button>

                        </Form.Item>


                        <div className="gx-flex-row gx-justify-content-between">
                        <Link className="gx-login-form-forgot" to="/forgot-password"><IntlMessages id="app.userAuth.forgotPassword" /></Link>

                        <Link to="/signup"><IntlMessages
                            id="app.userAuth.signUp" /></Link>

                        </div>

                    </Form>
                    </div>
                </div>

                {loader ?
                    <div className="gx-loader-view">
                    <CircularProgress />
                    </div> : null}
                {showMessage ?
                    message.error(alertMessage.toString()) : null}
                </div>
            </div>
        </div>
    );
};

export default SignIn;
