import { useEffect, useState, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {  Row, message, Button, Col, Skeleton, Card, Avatar } from "antd";
import { get } from "lodash-es";
import firebase from 'firebase';
import Mixpanel from "../../../api/Mixpanel";
import { decodeString, formatDateAMPM} from "../../../util/misc";
import DashboardBody from "../../../components/SeerDashboard/DashboardBody";
import DashboardReferralCard from "../../../components/SeerDashboard/DashboardReferralCard";
import DashboardTopWidget from "../../../components/SeerDashboard/DashboardTopWidget";

const database      = firebase.database();


const MonetizeScreens = () => {

    const [loading, setLoading]                     = useState(false);
    const { db }                                    = useSelector(({ auth }) => auth);
    const [mode, setMode]                           = useState("SMS");

    
    return (
        <>  

            <div 
                className="gx-main-content-wrapper padding-1 gx-pt-3 gx-pb-2"
                style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                }}
            >
                    

                
            </div>

        </>
    );
};

export default MonetizeScreens;
