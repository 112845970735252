

import { useState, useEffect, useRef } from 'react';
import axios from "axios";
import firebase from 'firebase';
import ImgCrop from "antd-img-crop";
import { Card, Col, Row, Input, Drawer, Button, Collapse, Divider, Form, message, Upload, Checkbox, Progress, Typography, Affix , notification} from 'antd';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";
import { get, isEmpty, parseInt } from "lodash-es";
import { UploadOutlined } from '@ant-design/icons';
import { decodeString, formatDate, generateNumberKey, checkIfIdClashes, fixBangladeshPhoneNumbers } from '../../../util/misc';
import { useTranslation } from "react-i18next";
import SMS from '../../../api/SMS';

import EdutechCloudServer from '../../../EdutechCloudServer';
import Mixpanel from '../../../api/Mixpanel';
import PanelHead from '../../PanelHead';

const database    = firebase.database();

const AddOneUserDrawer = ({size, onClose, visible,  addOneStudentDefaultValue, formSubmitCallback, setAddStudentModal}) => {

    const { Panel }                                 =   Collapse;
    const { t }                                     =   useTranslation();
    const [sName, setSName]                         =   useState('Student');
    const [sPhone, setSPhone]                       =   useState('');
    const [sEmail, setSEmail]                       =   useState('');
    const [stdCustomId, setStdCustomId]             =   useState(1000);
    const { db }                                    =   useSelector(({ auth }) => auth);
    const [checkBoxData, setCheckBoxData]           =   useState([]);
    const [tArray, setTArray]                       =   useState([]);
    const [form]                                    =   Form.useForm();
    const [loading, setLoading]                     =   useState(false);
    const [studentPic, setStudentPic]               =   useState(undefined);
    const [uploadPicText, setUploadPicText]         =   useState('Click to upload');
    const [progressPercent, setProgressPercent]     =   useState(0);
    const [showProgress, setShowProgress]           =   useState(false);
    const customIdInputRef                          =   useRef(null);



    const hasNonDigitCharacters = (string) => {
        return /[^0-9]/.test(string.replace(/^ID-/, ""));
    }

    useEffect(()=> {

        if(addOneStudentDefaultValue !== undefined){
            const {name,
                phone,
                email,
                id }  = addOneStudentDefaultValue;

                setSName(name);
                setSPhone(phone);
                setSEmail(email);
                setStdCustomId(id);


                form.setFieldsValue({
                    StudentName: name,
                    StudentPhone: phone,
                    StudentEmail: email, 
                    StudentID: id
                })

        }
        else{

            let uniqueStudentJsonList = get(db, ['AddedUsers'], {});

            if(isEmpty(uniqueStudentJsonList)){
                form.setFieldsValue({
                    StudentID: '', 
                })     
            }
            else{

                let existHighestValueOfCustomID = 0;

                for(let stdUID in uniqueStudentJsonList){
                    let customID = get(uniqueStudentJsonList, [stdUID, 'customID'], '');
                    //check if contain non digit in custom id without ID-, if have non digit contain in id ignore this id
                    let hasNonDigits = hasNonDigitCharacters(customID);

                    if(hasNonDigits === false){
                        // remove all non digit from custom id
                        let afterNonDigitCustomID = customID.replace(/\D/g, "");
                        if(parseInt(existHighestValueOfCustomID) <= parseInt(afterNonDigitCustomID)){
                            existHighestValueOfCustomID = parseInt(afterNonDigitCustomID);
                        }
                    }
                }

                // increase 1 with exist highest value
                existHighestValueOfCustomID = (parseInt(existHighestValueOfCustomID) + 1);

                form.setFieldsValue({
                    StudentID: existHighestValueOfCustomID, 
                })
            }
        }
       
    },[db, visible, form, addOneStudentDefaultValue])


    

    const onFinishFailed = errorInfo => {console.log(errorInfo)};


    //submit add new user
    const onFinish = values => {

        let {StudentName, StudentPhone, StudentEmail, StudentID} = values;

        StudentPhone = StudentPhone.includes('D') ? `${fixBangladeshPhoneNumbers(StudentPhone)}D` : fixBangladeshPhoneNumbers(StudentPhone);

        addOneStudent({
            name                : StudentName,
            phone               : StudentPhone,
            email               : StudentEmail,
            id                  : `ID-${StudentID}`,
            pictureObj          : studentPic,
        }, values);

    };


    const normFile = (e) => {

        if (Array.isArray(e)) {
          return e;
        }
      
        return e?.fileList;
    };

    const addOneStudent = async ({name, phone, email, id, pictureObj}, values) => {

        setLoading(true);
        setShowProgress(true);
        setProgressPercent(1);
        const loadingKey    = 'loading-key';
        message.loading('Finding User..', loadingKey);
        EdutechCloudServer.postRequest('api-react-client-create-seer-user-account', {
            phone, name, email
        }, (response)=>{

            console.log('response: ', response);

            if (response.user){

                setProgressPercent(20);

                let studentUID  = response.user['uid'];
               
                let studentData = response.data;

                //need to check if the custom uid supplied clashes with any other account
                let {valid, clash} = checkIfCustomID_IsValid({uid: studentUID, customID: id});
                if (valid === false){
                    let h = message.error(`Your given custom ID is already in use by User ${decodeString(clash)}. Please use another!`);
                    setLoading(false);
                    setTimeout(()=>{
                        h();
                    }, 5000);
                    setProgressPercent(100);
                    setShowProgress(false);
                    if (formSubmitCallback) formSubmitCallback({'status': 'fail'});
                    return false;
                }



                //if a student photo was supplied then upload it first
                if (pictureObj){
                    setProgressPercent(30);
                    uploadStudentPhoto({
                        photo: pictureObj,
                        uid: studentUID,
                        loadKey: loadingKey,
                        callback: (avatarURL)=>{

                            setProgressPercent(60);
                            studentData['PublicInfo']['avatarURL'] = avatarURL;
                            database.ref(`USERS/${studentUID}/PublicInfo`).update({'avatarURL': avatarURL});
                            message.loading('Student found, adding to Batches..', loadingKey);
                            //loop through all batches and add this student there
            
                            let promises = [];
                            let studentData_TeacherSide = {
                                Email           : get(studentData, ['PublicInfo', 'UserEmail'], 'Null'),
                                Name            : get(studentData, ['PublicInfo', 'UserName'], 'Null'),
                                Phone           : get(studentData, ['PublicInfo', 'UserPhone'], 'Null'),
                                Type            : 'Physical',
                                UID             : studentUID,
                                avatarURL       : get(studentData, ['PublicInfo', 'avatarURL'], 'Null'),
                                AppliedDate     : formatDate(Date.now()),
                                AcceptedDate    : formatDate(Date.now()),
                                customID        : id,
                            };

                            promises.push(database.ref(`USERS/${db['UID']}/AddedUsers/${studentUID}`).update(studentData_TeacherSide));
        

                            const studentData_StudentSide =  {
                                ownerUID        : db['UID'],
                                AcceptedDate    : formatDate(Date.now()),
                                Type            : 'Physical'
                            }

                            database.ref(`USERS/${studentUID}/AcceptedByOwner/${db['UID']}/`).update(studentData_StudentSide);
        
                            setProgressPercent(80);
                            Promise.all(promises)
                                .then(()=>{
                                    setLoading(false);
                                    onClose();
                                    form.resetFields()
                                    setProgressPercent(100);
                                    setShowProgress(false);
                                    message.success('User has been added successfully!', loadingKey);
                                    setStudentPic(undefined)
                                    setUploadPicText('Click to upload')
                                    setAddStudentModal && setAddStudentModal(false);
                                    database.ref(`USERS/${db['UID']}/`).update({forceReactClientRefresh: (new Date()).getTime()});
                                    if (formSubmitCallback) formSubmitCallback({'status': 'success'});
                                })
                                .catch(e=>{
                                    setLoading(false);
                                    onClose();
                                    setShowProgress(false);
                                    message.error(e, loadingKey);
                                    if (formSubmitCallback) formSubmitCallback({'status': 'fail'});
                                })
                        }
                    })
                }
                else{

                    message.loading('User found, adding to Batches..', loadingKey);
                    //loop through all batches and add this student there
    
                    let promises = [];
                    let studentData_TeacherSide = {
                        Email           : get(studentData, ['PublicInfo', 'UserEmail'], 'Null'),
                        Name            : get(studentData, ['PublicInfo', 'UserName'], 'Null'),
                        Phone           : get(studentData, ['PublicInfo', 'UserPhone'], 'Null'),
                        Type            : 'Physical',
                        UID             : studentUID,
                        avatarURL       : get(studentData, ['PublicInfo', 'avatarURL'], 'Null'),
                        AppliedDate     : formatDate(Date.now()),
                        AcceptedDate    : formatDate(Date.now()),
                        customID        : id
                    };

                    promises.push(database.ref(`USERS/${db['UID']}/AddedUsers/${studentUID}`).update(studentData_TeacherSide));

                    const studentData_StudentSide =  {
                        ownerUID      : db['UID'],
                        AcceptedDate    : formatDate(Date.now()),
                        Type            : 'Physical',
                    }
                    database.ref(`USERS/${studentUID}/AcceptedByOwner/${db['UID']}/`).update(studentData_StudentSide);


                    setProgressPercent(65);
                    Promise.all(promises)
                        .then(()=>{
                            setLoading(false);
                            onClose();
                            form.resetFields()
                            setProgressPercent(100);
                            setShowProgress(false);
                            setAddStudentModal && setAddStudentModal(false);
                            message.success('User has been added successfully!', loadingKey);
                            setStudentPic(undefined)
                            setUploadPicText('Click to upload')
                            database.ref(`USERS/${db['UID']}/`).update({forceReactClientRefresh: (new Date()).getTime()});
                            if (formSubmitCallback) formSubmitCallback({'status': 'success'});

                            
                        })
                        .catch(e=>{
                            setLoading(false);
                            onClose();
                            message.error(e, loadingKey);
                            setShowProgress(false);
                            if (formSubmitCallback) formSubmitCallback({'status': 'fail'});
                        })
                }


                Mixpanel.record({eventName: 'USER-ADDED-BY-SEER-ADMIN', eventProperties: {studentName:name, studentEmail:email, studentPhone:phone, studentID:id}});

            }
            else{
                message.error(response.error);
                setLoading(false);
                setShowProgress(false);
                if (formSubmitCallback) formSubmitCallback({'status': 'fail'});
            }
        });
    }

    //will return obj {valid, clash}
    const checkIfCustomID_IsValid = ({uid, customID}) => {
        let valid       = true;
        let clash       = 'No Clash';
        let mapper      = {};
        //first create a mapper fn with custom-uid to UID
        let userClass = get(db, ['AddedUsers'], {});

        for (let oneSUID in userClass){
            if (userClass[oneSUID]['customID']){
                let this_customID = userClass[oneSUID]['customID'];
                mapper[this_customID] = {
                    uid: oneSUID,
                    name: userClass[oneSUID]['Name']
                }
            }
        }

        //if this students provided custom ID is already in user by another UID
        if (mapper[customID]){
            if (mapper[customID]['uid'] !== uid){
                return {valid:false, clash: mapper[customID]['name']}
            }
        }

        return {valid, clash}
    }

    const uploadStudentPhoto = async ({photo, uid,loadKey, callback}) => {

       
        message.loading('Uploading User Picture..', loadKey);
        EdutechCloudServer.postRequest('upload-avatar', {}, async (res) => {
            if (res.msg === 'success'){

                let uploadUrl                           = res.uploadUrl;
                let uploadAuthorizationToken            = res.uploadAuthorizationToken;
                let filename                            = `${uid}-avatar.png`;
            
                await axios.post( uploadUrl, photo, {
                withCredentials: true,
                headers: {
                    Authorization: uploadAuthorizationToken,
                    'Content-Type': 'b2/x-auto',
                    'X-Bz-File-Name': `${filename}`,
                    'X-Bz-Content-Sha1': 'do_not_verify',
                },
                onUploadProgress: ({ loaded, total }) => {
                    const totalProgress = parseInt((loaded / total) * 100);
                    message.loading(`Uploading Student Picture ${totalProgress}%`, loadKey);
                }
                } )
            
                let friendlyURL = `https://f002.backblazeb2.com/file/Edutech-Avatars/${filename}`


                database.ref(`USERS/${uid}/PublicInfo`).update({
                    'avatarURL': friendlyURL
                })
                .then(()=>{
                    message.loading(`User Picture uploaded successfully..`, loadKey);
                   
                    callback(friendlyURL);
                })
            }

            else{
                message.error('Image Upload Failed. Server could not provide valid upload token');
            }
        });

    }


    return (
        <>
            <Drawer
                size        =   {size}
                placement   =   "right"
                closable    =   {false}
                onClose     =   {onClose}
                visible     =   {visible}
            >
                <Form
                    form                    =   {form}
                    name                    =   "Add One User Modal Drawer"
                    onFinish                =   {onFinish}
                    onFinishFailed          =   {onFinishFailed}
                    layout                  =   'vertical'
                    className               =   "gx-force-two-cols"
                    defaultValue            =   {addOneStudentDefaultValue}
                >
                    <div className="drawer-content">
                        <Affix offsetTop={0}>
                            {/* extra div add for fix affix issue */}
                            <div> 
                                <div className="drawer-head" style={{ display: 'flex', justifyContent: 'space-between', padding: '30px 30px 5px 30px' }}>
                                    <div className="drawer-head-left" style={{ display: 'flex', justifyContent: 'space-between' }}>

                                        <CloseOutlined onClick={onClose} className="gx-mr-3" style={{ fontSize: '24px', cursor: 'pointer', marginTop: '4px' }} />

                                        <div className="drawer-title">
                                            <Typography.Title className='gx-mb-0' level={4}>Add New User</Typography.Title>
                                            <span>{t("You may use their Phone and/or Email")}</span>
                                        </div>
                                    </div>
                                    <div className="drawer-head-right">
                                        <Button size="medium" loading={loading} className="gx-btn-dark gx-mb-0" htmlType="submit">{t("Submit")}</Button>
                                    </div>
                                </div>

                                <Divider />
                            </div>
                        </Affix>


                        <div className="drawer-body" style={{ padding: '0px 0px 20px 0' }}>

                            {showProgress ? 
                            
                                <div className='gx-mr-5 gx-ml-5'>
                                    <Progress
                                        strokeColor={{
                                            '0%': '#108ee9',
                                            '100%': '#87d068',
                                        }}
                                        percent={progressPercent}
                                    />
                                </div>:
                                <></>                        
                            }


                            <Collapse bordered={false} defaultActiveKey={['1', '2', '3']} ghost={true}>
                                <Panel header={<PanelHead title={t("General")} isLineAfterText={false} titleLevel={5}/>} key="1" style={{fontSize: '18px', margin: '0px 20px', marginTop: '5px'}} >
                                    <Row justify='space-evenly'>
                                        <Col span={12} >

                                            <Form.Item 
                                                className   =   "gx-mb-0 gx-mt-0"
                                                label       =   {"User Name"} 
                                                rules       =   {[{ required: true, message: t('Please enter user name!') }]} 
                                                name        =   "StudentName"
                                                required 
                                                tooltip     =   {t("Must be provided")}

                                            >
                                                <Input 
                                                    type            =   "text" 
                                                    placeholder     =   {"John Jones"} 
                                                    name            =   "StudentName" 
                                                />
                                            </Form.Item>

                                            <Form.Item 
                                                className           =   "gx-mb-0 gx-mt-3"
                                                label               =   {"User Phone"}
                                                rules               =   {[{ required: true, message: t('Please enter user phone!') }]} 
                                                name                =   "StudentPhone"
                                                required            =   {false}
                                                tooltip             =   {t("Must be provided")}
                                                
                                            >
                                                <Input 
                                                    type            =   "text" 
                                                    placeholder     =   "1700766173" 
                                                    name            =   "UserPhone" 
                                                    prefix          =   "+880"
                                                />
                                            </Form.Item>
                                            

                                            <Form.Item 
                                                className   =   "gx-mb-0 gx-mt-3"
                                                label       =   {"User Email"}
                                                rules       =   {[{ required: false, message: t('Please enter user email!') }]} 
                                                name        =   "StudentEmail"
                                            >
                                                <Input 
                                                    type            =   "email" 
                                                    placeholder     =   "john@gmail.com" 
                                                    name            =   "StudentEmail" 
                                                />
                                            </Form.Item>


                                        </Col>

                                        <Col span={12}>

                                            <Form.Item 
                                                className   =   "gx-mb-0 gx-mt-0"
                                                label       =   {"User ID"}
                                                rules       =   {[{ required: true, message: t('Please enter a unique user ID!') }, {pattern: new RegExp(/^[0-9]+$/),message: "Enter numeric value"}]} 
                                                name        =   "StudentID"
                                                required 
                                                tooltip     =   {t("Must be provided and cannot clash with existing user id")}
                                                onClick     =   {() => {
                                                                    customIdInputRef.current.focus({
                                                                    cursor: 'all',
                                                                    });
                                                                }}

                                            >
                                                <Input 
                                                    ref             =   {customIdInputRef}
                                                    type            =   "text" 
                                                    placeholder     =   "007" 
                                                    name            =   "StudentID"
                                                    prefix          =   "ID-"
                                                    
                                                />
                                            </Form.Item>
                                            

                                            <Form.Item
                                                className           =   "gx-mb-0 gx-mt-3"
                                                name                =   "StudentPicture"
                                                label               =   {"User Picture"}
                                                valuePropName       =   "fileList"
                                                getValueFromEvent   =   {normFile}
                                            >
                                                <ImgCrop rotate>

                                                    <Upload 
                                                        name            =   "StudentPicture" 
                                                        maxCount        =   {1}
                                                        action          =   {(file)=>console.log(file)} 
                                                        showUploadList  =   {false}
                                                        customRequest   =   {(x)=>{
                                                            setStudentPic(x.file);
                                                            setUploadPicText(x.file.name);
                                                        }}
                                                        listType        =   "picture"
                                                        beforeUpload    =  {(file) => {
                                                            const isPNG = (file.type === 'image/png') || (file.type === 'image/jpg') || (file.type === 'image/jpeg');
                                                        
                                                            if (!isPNG) {
                                                                message.error(`${file.name} is not a png, jpg or jpeg file`);
                                                            }
                                                        
                                                            return isPNG || Upload.LIST_IGNORE;
                                                        }}
                                                    >
                                                        <Button block icon={<UploadOutlined />} className="gx-btn-dullLavender" type="dashed">{uploadPicText}
                                                           {
                                                            studentPic ? 
                                                            <span style={{display:'block', position:'absolute', right:'10px', top: '0px'}}>
                                                                <DeleteOutlined
                                                                    onClick={(e)=> {
                                                                                e.stopPropagation()
                                                                                setStudentPic(undefined);
                                                                                setUploadPicText('Click to upload');
                                                                                }
                                                                            } 
                                                                />
                                                            </span>
                                                            : <></>
                                                           }
                                                        
                                                        </Button>
                                                        
                                                    </Upload>

                                                </ImgCrop>
                                            </Form.Item>


                                        </Col>

                                    </Row>
                                </Panel>


                            </Collapse>
                        </div>
                    </div>
                </Form>
            </Drawer>
        </>
    );
};

export default AddOneUserDrawer;