import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import { Popover, Skeleton, Space} from "antd";
import { decodeString } from "../../util/misc";
import {get} from "lodash-es";
import firebase from "firebase";

const database = firebase.database();

const UserProfile = () => {
    
    const [name, setName]                     = useState('loading..');
    const [avatar, setAvatar]                 = useState('https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png');
    const {db}                                = useSelector(({auth}) => auth);

    const [email, setEmail]                   = useState('');
    const [phone, setPhone]                   = useState('');
    const [loading, setLoading]               = useState(true);

    const userMenuOptions = (
        <ul className="gx-user-popover">
            <li style={{fontSize: '12px'}}>{email}</li>
            <li style={{fontSize: '12px'}}>{phone}</li>
        </ul>
    );


    //will set basic variables such as user-name, proffession etc
    useEffect(()=>{
        if (db){
            setLoading(true);
            let data = db['PublicInfo'];
            if (data){
                let {avatarURL, UserName, UserEmail, UserPhone} = data;
                setAvatar(decodeString(avatarURL));
                setName(decodeString(UserName))
                setEmail(decodeString(UserEmail));
                setPhone(decodeString(UserPhone));

            }
            setLoading(false);
        }
    }, [db]);

    return (

        <div className="gx-flex-row gx-align-items-center gx-mb-3 gx-avatar-row">

            {loading === true ?  
            
                <Space>
                    <Skeleton.Avatar active={true} size={'default'} shape={'circle'} />
                    <Skeleton.Input active={true} size={'small'} />
                </Space>
                
                : 
            
                <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', marginLeft: '3%'}}>

                    <img src={avatar === 'https://i.imgur.com/XqL3xLp.png' ? 'https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png' : avatar} alt="avatar" style={{ marginRight: '10px', width:'45px',height:'45px', borderRadius: '6em' }} />
                    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>
                        <span className="gx-avatar-name">{name}</span>
                    </div>

                </span>
            }

        </div>

    )
};

export default UserProfile;
