import firebase from "firebase";

// Initialize Firebase
const config = {
    apiKey: "AIzaSyBhQCEuKiBJqrEvplVNB0kdllqFg_xMN6g",
    authDomain: "autowave-80731.firebaseapp.com",
    databaseURL: "https://autowave-80731-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "autowave-80731",
    storageBucket: "autowave-80731.appspot.com",
    messagingSenderId: "983111059241",
    appId: "1:983111059241:web:170149ab2362d115e69938",
    measurementId: "G-Q8VBQE7JW0"
};



firebase.initializeApp(config);

const auth                          = firebase.auth();
const database                      = firebase.database();


export {
  database,
  auth
};
