import React from "react";
import { Col, Row } from "antd";
import { THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import { useSelector } from "react-redux";

const ContentTopBar = ({ leftContent, middleContent, rightContent, leftCol, middleCol, rightCol }) => {
  // accept routeTitle as leftContent, changeOnSelect or SearchInput as middleContent and Button as rightContent and set middle col, right col

  const themeType = useSelector(({ settings }) => settings.themeType);

  const parentStyle={
    background: themeType === THEME_TYPE_LITE ? '#fff' : '#38424B',
    borderBottom: themeType === THEME_TYPE_LITE ? "1px solid rgba(232,232,232,0.8)" : "1px solid #495762"
  }

  if (rightContent){

      return (
        <div style={parentStyle} className="">

          <Row justify="space-between" align="middle" className="gx-row-m-0">
            <Col lg={leftCol ? leftCol :5 } md={leftCol? leftCol :5} sm={24} xs={24}>
              {leftContent}
            </Col>
            <Col lg={middleCol ? middleCol :14 } md={middleCol? middleCol :14} sm={24} xs={24}>
              {middleContent}
            </Col>
            <Col  lg={rightCol ? rightCol :5} md={rightCol ? rightCol :5} sm={24} xs={24}>
              {rightContent}
            </Col>
          </Row>

        </div>
      );
  }
  else{
    return (
        <div style={parentStyle}>

          <Row justify="space-between" align="middle" className="gx-row-m-0">
            <Col lg={5} md={5} sm={24} xs={24}>
              {leftContent}
            </Col>
            <Col lg={19} md={19} sm={24} xs={24}>
              {middleContent}
            </Col>
          </Row>

        </div>
      );
  }
};

export default ContentTopBar;
