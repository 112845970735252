



//STUDENT LIMITS FOR FREE AND FOR SUBSCRIPTIONS
export const MAX_STUDENT_FREE_LIMIT                     = 100;



//SMS RATES
export const DEFAULT_SMS_RATE_NON_MASKING               = 0.30; 
export const DEFAULT_SMS_RATE_MASKING                   = 0.60; 

export const MINIMUM_SMS_PURCHASE_AMOUNT_BDT            = 600;


//CHAT-GPT TOKEN RATES
export const perCreditCHAT_GPT_BDT_COST                 = 0.0006;


//DEV-LOGIN
export const devLoginPassword                           = 'Edutechs@321';

export const companyName                                =  'Sense Dynamics';
