import React from 'react';
import { Button, message, Upload, Modal, Spin, Progress } from 'antd';


import { useState } from 'react';
import {  useSelector } from "react-redux";
import firebase from 'firebase';
import axios from "axios";
import { FilePdfOutlined, LoadingOutlined } from '@ant-design/icons';
import EdutechCloudServer from '../../EdutechCloudServer';
import Email from '../../api/Email';
import { decodeString } from '../../util/misc';
import { useTranslation } from "react-i18next";
const { Dragger }           = Upload;
const database              = firebase.database();

const antIcon               = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

const beforeUpload = (file) => {

    const isPDF = (file.name).includes('.pdf');
  
    if (!isPDF) {
      message.error('You can only upload a PDF file!');
    }
  
    const isLt2M = file.size / 1024 / 1024 < 2;
  
    if (!isLt2M) {
      message.error('PDF must smaller than 10MB!');
    }
  
    return isPDF && isLt2M;
};


const RequestMaskingModal = ({  visible, handleCancel }) => {

    const { db }                                                    = useSelector(({ auth }) => auth);
    const [progressPercent, setProgressPercent]                     = useState(0);
    const [showProgress, setShowProgress]                           = useState(false);
    const [loading, setLoading]                                     = useState(false);
    const { t }                                                     = useTranslation();
    const uploadTradeLicense = (zippedFile) => {
        
        let hide = message.loading('Uploading..', 'upload-own-trade');
        setLoading(true);
        EdutechCloudServer.postRequest('upload-teacher-files-tab', {}, async (res) => {
            if (res.msg === 'success'){

                let uploadUrl                           = res.uploadUrl;
                let uploadAuthorizationToken            = res.uploadAuthorizationToken;
                let filename                            = `${db['UID']}-maskiing-request-trade-license.pdf`;
            
                setShowProgress(true);
                await axios.post( uploadUrl, zippedFile.file, {
                    withCredentials: true,
                    headers: {
                        Authorization: uploadAuthorizationToken,
                        'Content-Type': 'b2/x-auto',
                        'X-Bz-File-Name': `${filename}`,
                        'X-Bz-Content-Sha1': 'do_not_verify',
                    },
                    onUploadProgress: ({ loaded, total }) => {
                        const totalProgress = parseInt((loaded / total) * 100);
                        setProgressPercent(totalProgress);
                        message.loading(`Uploading ${totalProgress}%`, 'upload-own-trade');
                    }
                } )
            
                let friendlyURL = `https://f002.backblazeb2.com/file/Teacher-Files-Tab/${filename}`


                database.ref(`USERS/${db['UID']}/PublicInfo`).update({
                    'tradeLicense': friendlyURL
                })
                .then(()=>{
                    message.success(('Trade License uploaded successfully. We will get in touch with you soon!'));
                    setLoading(false);
                    setShowProgress(false);
                    Email.sendAdmin({
                        message: `User ${decodeString(db['PublicInfo']['UserName'])} ${decodeString(db['PublicInfo']['UserPhone'])} ${decodeString(db['PublicInfo']['UserEmal'])} requested for SMS Masking with Trade License: ${friendlyURL}`,
                        subject: `SMS Masking Request!`, 
                        uid: db['UID'], 
                        callback: ()=>{}
                    })
                    handleCancel();
                    hide();
                    setLoading(false);
                })
            }

            else{
                message.error('PDF Upload Failed. Server could not provide valid upload token');
                setLoading(false);
                
            }
        });



    }


    return (
        <>
            <Modal
                    visible         =   {visible}
                    title           =   {t("Request Masking")}
                    onOk            =   {()=>{}}
                    onCancel        =   {handleCancel}
                    footer={[
                        <Button key="back" onClick={handleCancel}>
                            {t("Cancel")}
                        </Button>
                    ]}
                >

                {showProgress ?         
                        <div className='gx-mr-5 gx-ml-5 gx-mb-3 gx-mt-2'>
                            <Progress
                                strokeColor={{
                                    '0%': '#108ee9',
                                    '100%': '#87d068',
                                }}
                                percent={progressPercent}
                            />
                        </div>:
                        <></>                        
                }

                {loading ? <span style={{width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', display:'flex'}}>
                    <p>{t("Uploading")}</p>
                    <Spin indicator={antIcon} /> </span> :                    
                    
                    <Dragger 
                            multiple        =   {false}
                            showUploadList  =   {false}
                            beforeUpload    =   {beforeUpload}
                            customRequest   =   {(x)=>uploadTradeLicense(x)}

                            >
                            <p className="ant-upload-drag-icon">
                                <FilePdfOutlined />
                            </p>
                            <p className="ant-upload-text">{t("Upload Trade License (PDF)")}</p>
                            <p className="ant-upload-hint">
                                {t("We will verify and get back to your given phone number in settings")}.
                            </p>
                    </Dragger>
                }



            </Modal>

        </>
    );
};

export default RequestMaskingModal;