import { Button, message, Row, Space,Table} from "antd";
import React, { useState, useEffect } from "react";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { get } from "lodash-es";
import { decodeString } from "../../../util/misc";
import EditTemplateModal from "./EditTemplateModal";
import AddBtn from '../../../components/AddBtn/index';
import AddTemplateModal from "./AddTemplateModal";

import firebase from 'firebase';
const database    = firebase.database();
// import 'antd/dist/antd.css';
const Templates = () => {

    const [selectedRows, setSelectedRows]                             = useState([]);
    const [editKey, setEditKey]                                       = useState(null);
    const [isTemplateModalVisibleEdit, setIsTemplateModalVisibleEdit] = useState(false);
    const [isTemplateModalVisibleAdd, setIsTemplateModalVisibleAdd]   = useState(false);
    const { db }                                                      = useSelector(({ auth }) => auth);
    const { t }                                                       = useTranslation();
    const [items, setItems]                                           = useState([]);
    const [pagination, setPagination]                                 = useState({
                                                                          current: 1,
                                                                          pageSize: 14,
                                                                        });


    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          setSelectedRows(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        name: record.name,
        }),
    };
    const edit=(key) =>{
        setEditKey(key);
        setIsTemplateModalVisibleEdit(!isTemplateModalVisibleEdit);
    };
    const showTemplateModalAdd = () => {
      setIsTemplateModalVisibleAdd(true);
    };
    const handleCancelTemplateAdd = () => {
      setIsTemplateModalVisibleAdd(false);
    };
  
    const handleOkTemplateAdd = () => {
      //setLoading(true);
      setIsTemplateModalVisibleAdd(false);
    };

    const handleCancelTemplateEdit = () => {
        setIsTemplateModalVisibleEdit(false);
    };
    
    const handleOkTemplateEdit = () => {
        //setLoading(true);
        setIsTemplateModalVisibleEdit(false);
    };
    
    const columns = [
  
        {
          title: t('Message Template'),
          dataIndex: 'message',
        },
        {
            title: t('Description'),
            dataIndex: 'description_',
        },
        {
          title: t('Action'),
          dataIndex: 'action',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
              <Button type="link" onClick={()=>edit(record.key)} >{t("Edit")}</Button>
            </Space>
          ),
        },
      ];
    
    useEffect(() => {
      const data = [];
      let smsTemplates = get(db, ["SMS-Templates"], {});
      for (let key in smsTemplates) {
        const templates = smsTemplates[key];
        console.log(templates.message);
        let description = templates.description ?  decodeString(templates.description) : '';
        data.push({
          key : key,
          ...templates,
          message: decodeString(templates.message),
          description_: description
        });
      }
      console.log(smsTemplates);
      setItems(data);
      
    }, [db]);

    const deleteSelected = () => {

        if (selectedRows.length === 0){
            message.info('Please select some templates first!');
            return false;
        }

        let deleteArray = [];
        //delete the selected rows
        for (let oneKey of selectedRows){
            console.log(oneKey);
            deleteArray.push(database.ref(`USERS/${db['UID']}/SMS-Templates/${oneKey}`).remove());
        }
        Promise.all(deleteArray)
            .then(()=>{
                message.success('Templates deleted successfully!');
            })
    }; 
    return (
        <>  
            <Row justify='end' className="gx-pt-2 gx-pr-3">
              <Button 
                onClick={deleteSelected} 
                className={` gx-mb-0 gx-mt-0 gx-btn-danger`}
              >
                {t('Delete')}
              </Button>
              <AddBtn 
                onClick={showTemplateModalAdd}
                text={t('Add New Template')} 
                className='gx-btn-primary'
              />
            </Row>
            <Table
                rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                }}
                className="table-striped-rows custom-fix-pagination"
                columns={columns}
                dataSource={items}
                style={{marginTop: '1em'}}
                pagination={{pageSize: 6}}
            />
            <EditTemplateModal
                key={editKey} 
                data={items}
                isModalVisible={isTemplateModalVisibleEdit}
                handleCancel={handleCancelTemplateEdit} 
                handleOk={handleOkTemplateEdit}
                onItemSelect={editKey}
            />
            <AddTemplateModal 
              key={111}
              isModalVisible={isTemplateModalVisibleAdd}
              handleCancel={handleCancelTemplateAdd}
              handleOk={handleOkTemplateAdd}
            />
        </>
    );
};

export default Templates;
