import {useEffect} from "react";
import { Modal, Button, Input, Form, message, Alert} from 'antd';
import { useSelector } from 'react-redux';
import { decodeString, encodeString } from "../../../../../util/misc";
import firebase from 'firebase';
import { useTranslation } from "react-i18next";
import {get} from "lodash-es";

const database    = firebase.database();

const { TextArea } = Input;


const EditNotice = ({ isModalVisible, handleClose }) => {
    
    
    const [form]     = Form.useForm();
    const { db }     = useSelector(({ auth }) => auth);
    const { t }      = useTranslation();

    useEffect(() => {

        let {message} = get(db, ['AccessControlSettings', 'manageNoticeBoard'], {});
        if (message){
            if (typeof message === 'string' || message instanceof String){
                form.setFieldsValue({
                    'templateMessage': decodeString(message),
                });
            }
            else{
                form.setFieldsValue({
                    'templateMessage': `This is a default message.`,
                });
            }
        }
        else{
            form.setFieldsValue({
                'templateMessage': `This is a default message.`,
            });
        }


    }, [form, isModalVisible, db])
    

    const onFinish = async (values) => {
        const {templateMessage} = values;
        if (templateMessage) {
            const data = {
                'message' : encodeString(templateMessage),
            }

            let dbAddress =  `USERS/${db['UID']}/AccessControlSettings/manageNoticeBoard`;
            await database.ref(dbAddress).update(data)
            .then(res => {
                message.success('Notice updated successfully!');
            })
            .catch(e=>{
                message.error(e)
            })
        }
        else{
            message.error('Empty template not accepted!');
        }
    };

    return (
        <>
            <Modal
                centered
                title={'Edit Notice'}
                visible={isModalVisible}
                onOk={()=>handleClose()}
                onCancel={()=>handleClose()}
                width={500}
                footer={[
                    <Button form='myFormSmsTemplatesAdd' type="primary" htmlType="submit">
                        {t("Update")}
                    </Button>
                ]}
            >
                <div className="content-insideModal-smsSetting-groups">
                    <Form
                        id="myFormSmsTemplatesAdd"
                        initialValues   =   {{ remember: true }}
                        name            =   "basic"
                        form            =   {form}
                        onFinish        =   {onFinish}
                        layout          =   "vertical"
                        className       =   "gx-force-two-cols gx-form-row0"
                    >
                                <Form.Item
                                    name="templateMessage"
                                    label='Message'
                                    rules={[{ required: true, message: 'Empty templates cannot be accepted!' }]}
                                >
                                <TextArea
                                    name="templateMessage"
                                    placeholder={t("Enter your message")}
                                    style={{
                                    height: 120,
                                    }}
                                />
                                </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

export default EditNotice;