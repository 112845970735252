import { Button, Col, Row, Card,Switch, Select, Alert} from "antd";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import firebase from 'firebase';
import { useSelector } from "react-redux";
import { get } from "lodash-es";


const database    = firebase.database();


  
const BillingHistory = () => {

    const { t }                                             = useTranslation();
    const { db }                                            = useSelector(({ auth }) => auth);



    return (
        <div className="conatiner">

        <Alert closable={true} message={`Coming Soon`} type="info" showIcon/>


        </div>
    );
};

export default BillingHistory;
