import React, { useState, useEffect } from "react";
import { Row, Button, Space, Select, Modal, Switch} from "antd";
import * as moment from 'moment';
import { useTranslation } from "react-i18next";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import { reverse } from "lodash";
const ButtonGroup = Button.Group;
const { Option } = Select;

const PrintExportPlot = ({ selectName,printVisible,plotVisible,defaultFileName,defaultValue,reverse, xAxis,lineCol , containerProps , size ,style, headers, data, columnExcelHeader}) => {
 
    const [isModalVisible   ,   setIsModalVisible]    = useState(false);
    const [dataTest ,    setDataTest]                 = useState();
    const { t }                                       = useTranslation();
    const [togglePlot,  setTogglePlot]                = useState(false);

    PrintExportPlot.defaultProps = {
        printVisible: true,
        plotVisible : true,
    };
    
    const print = (value) => {
        const unit          = "pt";
        const size          = "A4"; // Use A1, A2, A3 or A4
        const orientation   = "portrait"; // portrait or landscape
        
        var doc             = new jsPDF(orientation, unit, size);

        let dataa           = [] ;
        
        for (let key in data) {
            const student = data[key];

            dataa.push({
                ...student,
                time:   moment(student.time).format("ll"),
                scheduled: moment(student.scheduled).format("ll"),
            });
        } 
        if(reverse){
            dataa.reverse();
        }

        // for comulative purposes only
        if(togglePlot){
            let commulativeData = structuredClone(dataa);
            var result = commulativeData.map((obj, index, self) => {
                if (index === 0) return obj;
            
                const prevO = self[index - 1]
                for (const key in lineCol) {
                    if (Object.hasOwnProperty.call(lineCol, key)) {
                        const element = lineCol[key];
                        
                        obj[element?.v]+= Number.isNaN(obj[element?.v] && prevO[element?.v]) ? 0 :  prevO[element?.v];
                    }
                }
                
                return obj
            });
        }
        setDataTest(result?result :dataa); // data for plotting

        
        doc.autoTable(headers,dataa,{styles: {
            overflow: 'linebreak',
            columnWidth: 'wrap'
            },
            columnStyles: {
                0: { columnWidth: 90 },
                1: { columnWidth: 'auto'}
            }
                
        }); // Design as a table takes header and data props, 
       
        if(value    === 'pdf'){
            let filename;
            if(defaultFileName && defaultFileName !== ""){
                filename = `${defaultFileName}.pdf`; 
            }
            else{
                filename = `Table-Pdf-${(new Date()).getTime()}.pdf`;
            }
            
            doc.save(filename);
            return false;
        } // only for export pdf

        if(value    === 'xlsx'){
            let filename;

            if(defaultFileName && defaultFileName !== ""){
                filename = `${defaultFileName}`;
            }else{
                filename = `Table-Excel-${(new Date()).getTime()}`;
            }

            getExportFileBlob(headers,dataa,value,filename);
            return false;
        }   // only for export xslx

        if(value    === 'line'){
            
            setIsModalVisible(true);
            return false;
        } // for ploting 
        if(value === 'print'){
            doc.output('dataurlnewwindow');
            return false;
        }
    };

    useEffect(() => {
        print();
    },[togglePlot]);

    const getExportFileBlob=( columns, data, fileType, fileName ) =>{
    
         if (fileType === "xlsx") {
            let createXLSLFormatObj  = [];
            let xlsxHeader           = columnExcelHeader?columnExcelHeader : Object.keys(columns); // for header of excel

            createXLSLFormatObj.push(xlsxHeader);
            
            let xlsRows              = [];

            let colWidthStyle        = [];
            
            for (let index = 0; index < data?.length; index++) {
                let top = [];
                for(let i=0;i<columns?.length; i++){
                     top.push( { t:"s", v:data[index][columns[i]?.dataKey], z: "@" })
                     if(index   < 1){
                        //const max_width    = Math.max(26, data[index][columns[i].dataKey]?.length);
                        let columnWidth    = {wch: 26}
                        colWidthStyle.push(columnWidth);
                     }
                }
                xlsRows.push(top);
                
            }
        
            createXLSLFormatObj.push(...xlsRows);

            let wb                  = XLSX.utils.book_new();
            let ws1                 = XLSX.utils.json_to_sheet(createXLSLFormatObj,{skipHeader: 1});
            //let colWidthDataStyle   = [ { wch: 24 }, { wch: 26 },{ wch: 26 },{ wch: 26 },{ wch: 16 },{ wch: 16 }, { wch: 26 } ];
            ws1["!cols"]            = colWidthStyle;

            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${fileName}.xlsx`);
 
            return false;
        }
    }
    
    const plot = (value) => {
       //setIsModalVisible(true);
       print("line");
    };

    const handleChange = (value) => {
        print(value);
    };

    const onChange = (checked) => {
        setTogglePlot(checked);
    };

    return (
        < >
            <div {...containerProps}>
                
                    <Space style={{alignItems: 'normal'}}>
                        {printVisible?
                            <Button
                                onClick     =   {()=>print('print')}
                                size        =   {size?size:'small'}
                                className   =   "gx-mb-0"
                                type        =   "default"
                            >
                                {t("Print")}
                            </Button> : <></>
                        }
                        <Select 
                            style       =   {{ borderRadius: '4px'}} 
                            onChange    =   {handleChange} 
                            value       =   {selectName? selectName : t("Export As") } 
                            size        =   {size?size:'small'} 
                        >
                            <Option value="pdf">{t("Pdf")}</Option>
                            <Option value="xlsx">{t("Xslx")}</Option>
                        </Select>
                        { plotVisible?
                            <Button
                                onClick     =    {plot}
                                size        =   {size?size:'small'}
                                className   = "gx-btn-primary gx-mb-0"
                            >
                                {t("Plot")}
                            </Button> : <></>
                        }
                    </Space>
                
            </div>
            <Modal
                key={12}
                title           =   {t("Linear Chart")}
                className       =   "template-modal"
                width           =   {800}
                
                visible         =   {isModalVisible}
                onOk            =   {() => {
                    setIsModalVisible(false)
                }}
                onCancel        =   {() => setIsModalVisible(false)}
            >
                <div className="gx-px-1">
                       <span>cumulative </span><Switch checkedChildren="On" unCheckedChildren="Off"  onChange={onChange} />
                       <LineChart 
                            width={700}
                            height={300}
                            data={dataTest}
                            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend />

                            {lineCol?.map(val=>{ 
                            
                                return <Line type='monotone' dataKey={val.v} stroke={val.c} dot={false} />
                           
                            })}
            
                            <XAxis dataKey={xAxis} />
                            <YAxis />
                        </LineChart>
                </div>
            </Modal>
        </>
    );

};

export default PrintExportPlot;
