import { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import {Image, Button, Result, message, Spin, Timeline,Avatar, notification, Select, Drawer, Collapse, Row, Col, Input, Modal, Form} from "antd";
import { decodeString, fixBangladeshPhoneNumbers, formatDateAMPM, getRandomInt, getUrlVars, isValidPhoneNumber, totalCharactersUSED } from '../../../util/misc';
import firebase from "firebase";
import {get, set} from "lodash-es";
import { CameraOutlined, CheckCircleOutlined, CheckOutlined, CloseOutlined, DeleteOutlined, FormatPainterOutlined, LoadingOutlined, MenuOutlined, PlayCircleFilled, PlayCircleOutlined, ReloadOutlined, ScanOutlined, SmileOutlined, WifiOutlined } from '@ant-design/icons';
import moment from "moment";
import Mixpanel from '../../../api/Mixpanel';
import CircularProgress from '../../../components/CircularProgress';
import SMS from '../../../api/SMS';
import * as faceapi from '@vladmandic/face-api';
import { FACING_MODES } from 'react-html5-camera-photo';
import './sentinel.css';
import PanelHead from '../../../components/PanelHead';

const { TextArea }                          = Input;
const database                              = firebase.database();
const antIcon               = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
);


const faceScanInterval                      = 4000;             //how frequently the face scan will run (2 seconds)
const faceScanGracePeriod                   = 1000*60*12*60;    //the grace period between which the same face-scan will not be considered (12 hrs)

const videoID                               = 'SCANNER-VIDEO-ID';
const backVideoID                           = 'BACK-SCANNER-VIDEO-ID';




const RetailAnalyticScanner = () => {

    const [loading, setLoading]                                     = useState(true);
    const [isLoadError, setIsLoadError]                             = useState(false);
    const [errorMsg, setErrorMsg]                                   = useState('');
    const [initialDelay, setInitialDelay]                           = useState(true);
    const [form]                                                    = Form.useForm();
    const [activeClassAvailable, setActiveClassAvailable]           = useState(false);

    const activeRoomDetails                                         = useRef([]);

    const daysArr                                                   = useMemo(() => ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], []);
    const [reloadAndDoSetupAgain, setReloadAndDoSetupAgain]         = useState(0);

    const thisRoomJSON                                              = useRef(undefined);
    const [currentTime, setCurrentTime]                             = useState(new Date());

    const [isWifiEnable, setIsWifiEnable]                           = useState(true);

    const localAcceptedStudentJSON                                  = useRef({});
    const accessControlSettings                                     = useRef({});
    const [bgColorQR, setBgColorQR]                                 = useState('#343d45');
    const smsBalance                                                = useRef(0);

    const teacherDetails                                            = useRef({});
    const [defaultCheckBehave, setDefaultCheckBehave]               = useState('ALL'); // can either be 'NONE' | 'ALL';

    const [absentLaterTimeouts, setAbsentLaterTimeouts]             = useState({});

    const [cameraFacing, setCameraFacing]                           = useState(FACING_MODES.USER); //can be 'face' or 'environment'

    const [showScreenSaver, setShowScreenSaver]                     = useState(false);
    const intervalScreenSaverRef                                    = useRef(null);

    const allFacePrints                                             = useRef();
    const [modelsLoaded, setModelsLoaded]                           = useState(false);

    const faceScannerIntervalRef                                    = useRef();

    const recordBuffer                                              = useRef([]);
    const [cameraAspectRatio, setCameraAspectRatio]                 = useState(window.sessionStorage.getItem('ratio') ? window.sessionStorage.getItem('ratio') : 9/20);

    const [showSettingsDrawer, setShowSettingsDrawer]               = useState(false);
    const [uiTextColor, setUiTextColor]                             = useState(window.sessionStorage.getItem('uiTextColor') ? window.sessionStorage.getItem('uiTextColor') : 'white');
    const [uiPaddTop, setUiPaddTop]                                 = useState(window.sessionStorage.getItem('uiPaddingTop') ? window.sessionStorage.getItem('uiPaddingTop') : 0)

    //config settings form value
    const [ratio, setRatio]                                         = useState(window.sessionStorage.getItem('ratio') ? window.sessionStorage.getItem('ratio') : 9/20)
    const [uiTColor, setuiTColor]                                   = useState(window.sessionStorage.getItem('uiTextColor') ? window.sessionStorage.getItem('uiTextColor') : 'white')
    const [uiPaddingTop, setUiPaddingTop]                           = useState(window.sessionStorage.getItem('uiPaddingTop') ? window.sessionStorage.getItem('uiPaddingTop') : 0)

    const video                                                     = useRef(null);
    const backVideo                                                 = useRef(null);
    const [showPinCodeModal, setShowPinCodeModal]                   = useState(false);

    const [frontCameraSelectVal, setFrontCameraSelectVal]           = useState('');
    const [frontCameraSelectOptions, setFrontCameraSelectOptions]   = useState([]);

    const [totalCustomersEntry, setTotalCustomersEntry]             = useState(0);
    const [totalCustomersLookAtShop, setTotalCustomersLookAtShop]   = useState(0);
    const [loadVideoList, setLoadVideoList]                         = useState(0);

    const [currentFaceSubjectDetecion, setCurrentFaceSubjectDetection]  =   useState({});
    const [isDoingFaceProcessing, setIsDoingFaceProcessing]             =   useState(false);


    //loads face-scan tensor models asynchronously
    useEffect(() => {
        const loadModels = async () => {
            const MODEL_URL = process.env.PUBLIC_URL + '/models';
            Promise.all([
              faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
              faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
              faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL),
              faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
              faceapi.nets.faceLandmark68TinyNet.loadFromUri(MODEL_URL),
              faceapi.nets.ageGenderNet.loadFromUri(MODEL_URL),
              faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL)
            ]).then(()=>{
                console.log('Models have been loaded..');
                setModelsLoaded(true)
            });
        }

        loadModels();
    }, []);

    //bind listen event and do initial read for this room JSON
    useEffect(()=>{
        let urlParams   =   getUrlVars(window.location.href);
        let user_uid    =   urlParams['key'];
        let roomKey     =   urlParams['room'];


        //bind and setup room specific details and also listen events for AcceptedStudents json under each batch
        database.ref(`USERS/${user_uid}/access_control/${roomKey}`).on('value', snapshot=>{
            thisRoomJSON.current                = snapshot.val();

            //bind all the listen events for each of the batch JSON
            let allClassroomsJSONWithTimings    = {};
            let timingsJSONWithDay              = get(snapshot.val(), ['timings'], {});

            for (let dayKey in timingsJSONWithDay){
                let thisDayTimings = timingsJSONWithDay[dayKey];
                for (let oneTimingKey in thisDayTimings){
                    let {grade, subject, batch, tuid} = thisDayTimings[oneTimingKey];
                    set(allClassroomsJSONWithTimings, [batch], {grade, subject, batch, tuid});
                }
            }

            for (let batch in allClassroomsJSONWithTimings){

                let {grade, subject, tuid} = allClassroomsJSONWithTimings[batch];

                database.ref(`USERS/${tuid}/UserClass/${grade}/${subject}/Streams/${batch}/AcceptedStudents`).off();
                database.ref(`USERS/${tuid}/UserClass/${grade}/${subject}/Streams/${batch}/AcceptedStudents`).on('value', snapshot=>{
                    let oldJSON = localAcceptedStudentJSON.current;
                    set(oldJSON, [batch], snapshot.val());
                    localAcceptedStudentJSON.current = oldJSON;
                });
            }

            setReloadAndDoSetupAgain(x=>x+1);
        });

        //setup wifi is connected or not
        let connectedRef = database.ref(".info/connected");
        connectedRef.on("value", (snap) => {
            if (snap.val() === true) {
                setIsWifiEnable(true);
            } 
            else {
                setIsWifiEnable(false);
            }
        });

        //bind and listen to auto sms notification changes / other settings changes
        database.ref(`USERS/${user_uid}/AccessControlSettings/`).on('value', snapshot=>{
            accessControlSettings.current = snapshot.val();
            if (snapshot.val()){
                let defaultBehave = get(snapshot.val(), ['MultipleClassroomDefaultSelection'], 'ALL');
                setDefaultCheckBehave(defaultBehave);
            }
        });

        //bind and listen to sms balance
        database.ref(`USERS/${user_uid}/SMS-Quota/v`).on('value', snapshot=>{
            if (snapshot.val()){
                smsBalance.current = parseInt(snapshot.val())
            }
        });

        //bind and listen to sms balance
        database.ref(`USERS/${user_uid}/PublicInfo/`).on('value', snapshot=>{
            if (snapshot.val()){
                console.log(teacherDetails.current);
                teacherDetails.current = snapshot.val();
            }
        });

        //bind and listen to face-prints
        database.ref(`Face-Prints/${user_uid}/`).on('value', snapshot=>{
            if (snapshot.val()){
                let output = [];
                for (let suid in snapshot.val()){
                    let allPrintsForThisStudent = snapshot.val()[suid];
                    for (let oneP in allPrintsForThisStudent){
                        let descriptionLabeledObj = new faceapi.LabeledFaceDescriptors(allPrintsForThisStudent[oneP]['label'], [new Float32Array(allPrintsForThisStudent[oneP]['descriptors'][0])]);
                        output.push(descriptionLabeledObj);
                    }
                }
                allFacePrints.current = output;
            }
        });

    }, []);


    //will actually read the room json - find timings and set active class if applicable
    useEffect(() => {

        async function setupFN(){
            console.log('Reading Room JSON and updating active class in view..', thisRoomJSON.current);
            let urlParams = getUrlVars(window.location.href);
    
            if (!urlParams['key'] || !urlParams['room']){
                setLoading(false);
                setIsLoadError(true);
                return ()=>{}
            }

            let access_control_snapshot = thisRoomJSON.current;

            let currentTimeObj          = new Date();
            let currentDay              = daysArr[currentTimeObj.getDay()];

            let roomName                = decodeString(get(access_control_snapshot, ['room'], 'Unknown-Room'));

            if (roomName === 'Unknown-Room'){
                setErrorMsg(`Could not read room details. This is most likely due to faulty / slow internet connection issues on this phone. For debug purposes - Key=${urlParams['key']} & Room=${urlParams['room']} &  detected Room JSON is: ${thisRoomJSON.current}`);
                setIsLoadError(true);
                return false;
            }

            let timingsJSON             = get(access_control_snapshot, ['timings', currentDay], {});
            let currentToUseKey         = '';
            let timelineJSX             = [];

            let activeRoomOutput        = [];

            let defaultBehaveForChecked = defaultCheckBehave; 

            for (let oneKey in timingsJSON){

                let {tuid, grade, subject, batch, uiBatchName, uiSubject, teacherName, avatarURL, startTime, endTime} = timingsJSON[oneKey];

                let startTimeOBJ        = new Date(parseInt(startTime));
                let endTimeOBJ          = new Date(parseInt(endTime));

                let updatedStartTimeObj = new Date(currentTimeObj.setHours(startTimeOBJ.getHours()));

                updatedStartTimeObj.setMinutes(startTimeOBJ.getMinutes());

                let updatedEndTimeObj   = new Date(currentTimeObj.setHours(endTimeOBJ.getHours()));
                updatedEndTimeObj.setMinutes(endTimeOBJ.getMinutes());

                if ((updatedStartTimeObj.getTime()) < (new Date().getTime()) && (updatedEndTimeObj.getTime()) > (new Date().getTime())){
                    currentToUseKey         = oneKey;

                    let defaultChecked = false;
                    if (defaultBehaveForChecked === 'ALL'){
                        defaultChecked = true;
                    }

                    activeRoomOutput.push({
                       checked: defaultChecked,  key: currentToUseKey, tuid, grade, subject, batch, uiBatchName: decodeString(uiBatchName), uiSubject: decodeString(uiSubject), teacherName: decodeString(teacherName), avatarURL: decodeString(avatarURL), startTime, endTime, room: decodeString(roomName)
                    });

                    setActiveClassAvailable(true);
                    
                }
            }

            //on refresh / load remember what the last checks were
            activeRoomDetails.current = (activeRoomOutput);


            if (currentToUseKey === ''){
                //console.log('Could not find any active classes in current time..');
                activeRoomDetails.current = [{room: decodeString(roomName)}]
                setActiveClassAvailable(false);
            }

            setLoading(false);
            setIsLoadError(false);
        }

        setupFN();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [daysArr, reloadAndDoSetupAgain, defaultCheckBehave]);

    //every 60 seconds do a refresh and see if a new active class in present
    //also updates view current time
    useEffect(()=>{
        let t = setInterval(() => {
           // console.log('Reloading View For Every 60 Second Routine..');
            setReloadAndDoSetupAgain(x=>x+1);
        }, 60*1000);

        let z = setInterval(() => {
            //console.log('Reloading Current Time 30 Second Routine..');
            setCurrentTime(new Date())
        }, 30*1000);

        return ()=>{
            clearInterval(t)
            clearInterval(z);
        } 
    }, []);


    useEffect(() => {
        const loadingTimeout = setTimeout(() => {
            setInitialDelay(false); 
        }, 4000);

        return () => {
            clearTimeout(loadingTimeout); 
        };
    },[]);

    const listVideoDevices = async () => {
        const devices = await navigator.mediaDevices?.enumerateDevices?.();
        if (devices) {
            const video = [];
            for (const device of devices) {
                if (device.kind === 'videoinput'){
                    video.push(device);
                }
            }
            return { video };
        } else {
            throw new Error('No support for multimedia devices.');
        }
    };


    //will setup the front-camera video ref
    useEffect(()=>{
        if (video.current){
            listVideoDevices()
                .then((obj)=>{
                    let outputOptionsJSX        =   [];
                    let allAvailableVideoInputs =   obj['video'];
    
                    for (let oneVideoDevice of allAvailableVideoInputs){
                        outputOptionsJSX.push(<Select.Option value={oneVideoDevice.deviceId}>{oneVideoDevice.label}</Select.Option>)
                    }
    
                    setFrontCameraSelectOptions(outputOptionsJSX);
                    setFrontCameraSelectVal(allAvailableVideoInputs[0]['deviceId']);
    
                    navigator.mediaDevices.getUserMedia({
                        audio: false,
                        video: true,
                        deviceId: allAvailableVideoInputs[0]['deviceId']
                    })
                    .then(stream=>{
                        video.current.srcObject = stream;
                        console.log('STREAM: ', stream);
                    })
                })
        }
        // eslint-disable-next-line
    }, [loadVideoList]);


    const submitUpdates = () => {
        if (Number.isFinite(parseFloat(ratio))){
            console.log('Setting aspect ratio to: ', ratio);
            window.sessionStorage.setItem('ratio', ratio);
            setCameraAspectRatio(ratio);
        }
        if (Number.isFinite(parseInt(uiPaddingTop))){
            console.log('Setting uiPaddingTop to: ', uiPaddingTop);
            window.sessionStorage.setItem('uiPaddingTop', uiPaddingTop);
            setUiPaddTop(uiPaddingTop);
        }
        window.sessionStorage.setItem('uiTextColor', uiTColor);
        setUiTextColor(uiTColor);
        message.success('Updated successfully');
        setShowSettingsDrawer(false);
    }


    const faceDetectedFrontCamera = useCallback(async allDetections => {

        if (isDoingFaceProcessing === true){
            return false;
        }

        let newDetectionsToConsider                       = [];

        for (let oneDetection of allDetections){

            let heightOfFaceDetected    = oneDetection['detection']['_box']['_height'];
            let widthOfFaceDetected     = oneDetection['detection']['_box']['_width'];

            if (parseInt(heightOfFaceDetected) > 110 && parseInt(widthOfFaceDetected) > 80){

                if (!recordBuffer.current || recordBuffer.current.length === 0){
                    console.log('Yes this is a new Face so need to carry out work and add to past record.buffer (this is line 404)');
                    setTotalCustomersEntry(x=>x+1);
                    newDetectionsToConsider.push(new faceapi.LabeledFaceDescriptors(`VISITOR-${(new Date()).getTime()}-${getRandomInt(0,9999)}`, [oneDetection.descriptor]));
                    continue
                }

                //check to see if this user has already been detected before
                const faceMatcher                               = new faceapi.FaceMatcher(recordBuffer.current);
                if (!oneDetection) continue;
                const bestMatch                                 = faceMatcher.findBestMatch(oneDetection.descriptor);
                let detectedStringLabel                         = bestMatch.toString();

                let currentTimeStamp                            = (new Date()).getTime();

                if (!detectedStringLabel.includes('VISITOR')){
                    console.log(`Yes this is a new Face so need to carry out work and add to past record.buffer (this is line 417)`);
                    setTotalCustomersEntry(x=>x+1);
                    newDetectionsToConsider.push(new faceapi.LabeledFaceDescriptors(`VISITOR-${currentTimeStamp}-${getRandomInt(0,9999)}`, [oneDetection.descriptor]));
                }
                else{
                    //this user has already been recognized before so lets see how long ago it was
                    let tentativeTimeStampThisWasTakenAt        = detectedStringLabel.split('-')[1];
                    let millisecondsAgoWasRecognized            = currentTimeStamp - parseInt(tentativeTimeStampThisWasTakenAt);
                    
                    if (millisecondsAgoWasRecognized > faceScanGracePeriod){
                        console.log(`This is an old face but the grace period has passed so record it again as customer entry.`, millisecondsAgoWasRecognized);
                        newDetectionsToConsider.push(new faceapi.LabeledFaceDescriptors(`VISITOR-${currentTimeStamp}-${getRandomInt(0,9999)}`, [oneDetection.descriptor]));
                        setTotalCustomersEntry(x=>x+1);
                    }
                    else{
                        console.log(`Skipping this Face-Recog since has already been recognized in history  and was taken roughly ${(parseInt(currentTimeStamp) - parseInt(tentativeTimeStampThisWasTakenAt))/(1000*60)} minutes ago`);
                    }

                }
            }
        }

        recordBuffer.current = [...recordBuffer.current, ...newDetectionsToConsider];
        setIsDoingFaceProcessing(false);

    }, [isDoingFaceProcessing]);

    //face-scan setup and will set polling face-detecter and recognizer
    useEffect(()=>{

        if (modelsLoaded && initialDelay === false){
            if (faceScannerIntervalRef.current){
                clearInterval(faceScannerIntervalRef.current);
            }
    
            let q = setInterval(async () => {
                let videoElementExists      = document.getElementById(videoID);
                if (videoElementExists && modelsLoaded === true && video.current){
                    let detections                                    = await faceapi.detectAllFaces(videoID).withFaceLandmarks(videoID).withFaceDescriptors().withFaceExpressions().withAgeAndGender();
                    if (Array.isArray(detections)){
                        setCurrentFaceSubjectDetection(detections[0]);
                        setIsDoingFaceProcessing(true);
                        faceDetectedFrontCamera(detections);

                    }
                    else{
                        setCurrentFaceSubjectDetection(detections);
                        setIsDoingFaceProcessing(true);
                        faceDetectedFrontCamera([detections])
                    }
                }
            }, faceScanInterval);
    
            faceScannerIntervalRef.current = q;

            return ()=>{
                clearInterval(q);
            } 
        }


    }, [modelsLoaded, initialDelay]);


    return (
        <div style={{width: '100vw', position: 'fixed',
        top: 0,
        zIndex: 10}}>  

            {initialDelay === true ?
                <div className="gx-loader-view">
                    <CircularProgress className="size-large" />
                </div>
                :

                <div className="sign-up-page-wrapper" style={{
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    zIndex: 16
                }}>

                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', zIndex: 13, width: '90%', marginTop: '4%'}}>
                        
                        <div style={{width: '100%', display: 'flex', justifyContent: 'space-between',alignItems: 'center', marginTop: '2.5%', marginBottom: '2%'}}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
                                <Avatar size={55} src={get(teacherDetails.current, ['avatarURL'], 'https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png')} />
                                <h1 style={{margin: 0, color: uiTextColor}} className="gx-ml-2">{decodeString(get(teacherDetails.current, ['UserInstitution'], 'Default-Institution-Name'))}</h1>
                            </div>

                            <div>
                                <Button style={{color: uiTextColor, margin: 0}} size="large" type="text" icon={<PlayCircleOutlined />} onClick={()=>{
                                    setLoadVideoList(x=>x+1);
                                }}></Button>

                                <Button style={{color: uiTextColor, margin: 0}} size="large" type="text" icon={<MenuOutlined />} onClick={()=>{
                                    setShowPinCodeModal(true); 
                                    form.setFieldsValue({
                                        'templateMessage': ''
                                    });
                                }}></Button>

                            </div>
                        </div>


                        
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', left: '5%', zIndex: 13, backgroundColor: '#293037', backdropFilter: 'blur(10px)', padding: '5px', width: '90%', borderRadius: '10px', paddingLeft: '3%', paddingRight: '3%', marginTop: '4%'}}>
                        
                        <div style={{width: '100%', display: 'flex', justifyContent: 'space-between',alignItems: 'center', marginTop: '2.5%', marginBottom: '2%'}}>
                            <div>
                                <h3 style={{margin: 0, color: 'white', fontWeight: 300}}>{get((activeRoomDetails.current)[0], ['room'], '')}</h3>
                                <h2 style={{margin: 0, color: 'white', marginTop: '10px'}}>{(formatDateAMPM(currentTime)).split(' ')[1]} {(formatDateAMPM(currentTime)).split(' ')[2]}</h2>
                                <p style={{color: 'white', margin: 0, fontWeight: 300}}>{(formatDateAMPM(currentTime)).split(' ')[0]}</p>

                                <p style={{color: 'white', margin: 0, marginTop: 20, fontWeight: 300}}>Total Unique Customers In: {totalCustomersEntry}</p>
                                <p style={{color: 'white', margin: 0, fontWeight: 300}}>Total Unique Customers Gaze At Shop: {totalCustomersLookAtShop}</p>

                                <p style={{color: 'white', margin: 0, marginTop: 20, fontWeight: 300}}>Current Subject Gender: {get(currentFaceSubjectDetecion, ['gender'], 'Unknown')}</p>
                                <p style={{color: 'white', margin: 0, fontWeight: 300}}>Current Subject Apprx. Age: {parseFloat(get(currentFaceSubjectDetecion, ['age'], 0)).toFixed(2)}</p>
                                <p style={{color: 'white', margin: 0, fontWeight: 300}}>Current Subject Face Width/Height: {parseInt(get(currentFaceSubjectDetecion, ['detection', '_box', '_width'], 0))}/{parseInt(get(currentFaceSubjectDetecion, ['detection', '_box', '_height'], 0))}</p>
                                <p style={{color: 'white', margin: 0, fontWeight: 300}}>Current Subject Angle (Pitch, Roll, Yaw) {get(currentFaceSubjectDetecion, ['angle', 'pitch'], '-')} {get(currentFaceSubjectDetecion, ['angle', 'roll'], '-')} {get(currentFaceSubjectDetecion, ['angle', 'yaw'], '-')}</p>

                                <p style={{color: 'white', margin: 0, marginTop: 20, fontWeight: 300}}>Front-Camera Select Device</p>
                                <Select style={{width: '100%'}} value={frontCameraSelectVal} defaultValue="" onChange={(v)=>{
                                    setFrontCameraSelectVal(v);

                                }}>
                                    {frontCameraSelectOptions}
                                </Select>
                            </div>

                        </div>


                        
                    </div>


                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', left: '5%', zIndex: 13, backgroundColor: '#293037', backdropFilter: 'blur(10px)', padding: '5px', width: '90%', borderRadius: '10px', paddingLeft: '3%', paddingRight: '3%', marginTop: '4%'}}>
                        <p style={{color: 'white', margin: 0, fontWeight: 300}}>Front-Cam View</p>
                        <video style={{
                                width: '100%',
                                background: 'rgba(0,0,0,0)',
                                transform: 'scalex(-1)'
                            }} ref={video} autoPlay playsInline id={videoID}>
                            
                        </video>

                    </div>

                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', left: '5%', zIndex: 13, backgroundColor: '#293037', backdropFilter: 'blur(10px)', padding: '5px', width: '90%', borderRadius: '10px', paddingLeft: '3%', paddingRight: '3%', marginTop: '4%'}}>
                        <p style={{color: 'white', margin: 0, fontWeight: 300}}>Back-Cam View</p>
                        <video id={backVideoID} ref={backVideo} style={{
                                width: '100%',
                                background: 'rgba(0,0,0,0)',
                            }}>
                        </video>
                    </div>


                    <Drawer
                        size        =   {'small'}
                        placement   =   "right"
                        closable    =   {true}
                        onClose     =   {()=> setShowSettingsDrawer(false)}
                        visible     =   {showSettingsDrawer}
                        width       =   {window.screen.width}
                        title       =   "Sentinel Device Config"
                        extra={[
                            <Button onClick={()=>submitUpdates()} style={{margin:0}} type="primary">Update</Button>

                        ]
                          }
                    >

                        <div className="drawer-body" style={{ padding: '0px 0px 20px 0' }}>

                        <Collapse bordered={false} defaultActiveKey={['1', '2', '3']} ghost={true}>
                            <Collapse.Panel header={<PanelHead title={("Actions")} isLineAfterText={false} titleLevel={5}/>} key="1" style={{fontSize: '18px', margin: '0px 20px', marginTop: '5px'}} >
                                <Row justify='space-evenly'>
                                    <Col span={12} >

                                        <Button className='gx-mt-2' block style={{marginBottom: 0}}  type="primary" icon={<ReloadOutlined />} onClick={()=>window.location.reload()}>Refresh</Button>

                                        <Button className='gx-mt-2' block style={{marginBottom: 0}}  type="primary" icon={<CameraOutlined />} onClick={()=>{
                                                //flip the camera
                                                setCameraFacing(c=>{
                                                    if (c===FACING_MODES.USER){
                                                        return FACING_MODES.ENVIRONMENT
                                                    }
                                                    else{
                                                        return FACING_MODES.USER
                                                    }
                                                });

                                            }}>Flip Camera</Button>

                                        

                                    </Col>

                                    <Col span={12}>

                                    </Col>


                                </Row>
                            </Collapse.Panel>


                        </Collapse>
                        </div>



                    </Drawer>




                </div>

                
            }





            <Modal
                title={'Enter Pin-Code'}
                visible={showPinCodeModal}
                onOk={()=>setShowPinCodeModal(false)}
                onCancel={()=>setShowPinCodeModal(false)}
                style={{
                    top: '50px'
                }}
                width={500}
                footer={[
                    <Button form='myFormSmsTemplatesAdd' type="primary" htmlType="submit">
                        {("Enter")}
                    </Button>
                ]}
            >
                <div className="content-insideModal-smsSetting-groups">
                    <Form
                        id="myFormSmsTemplatesAdd"
                        initialValues   =   {{ remember: true }}
                        name            =   "basic"
                        form            =   {form}
                        onFinish        =   {(values)=>{
                            //check and open settings drawer
                            let currentCode             = '6666';
                            const {templateMessage}     = values;
                            if (templateMessage === currentCode){
                                setShowSettingsDrawer(true);
                                setShowPinCodeModal(false);
                            }
                            else{
                                message.info('Invalid Pin-Code');
                            }
                        }}
                        layout          =   "vertical"
                        className       =   "gx-force-two-cols gx-form-row0"
                    >
                                <Form.Item
                                    name="templateMessage"
                                    label='Code'
                                    rules={[{ required: true, message: 'Empty Code cannot be accepted!' }]}
                                >
                                <TextArea
                                    name="templateMessage"
                                    placeholder={("Enter your code")}
                                    style={{
                                    height: 120,
                                    }}
                                />
                                </Form.Item>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}

export default RetailAnalyticScanner