import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {Menu} from "antd";
import {Link, useHistory} from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";



const HorizontalNav = () => {

    const { authUser }                                        = useSelector(({ auth }) => auth);
    const [selectedMenu, setSelectedMenu]                     = useState([]);
    const history                                             = useHistory();

    useEffect(() => {
        console.log('HISTORY: ', history);
        let currentRoute = (history.location.pathname).replace('/', '');
        setSelectedMenu([currentRoute]);
    }, [history])
    

    return (
        <Menu
            mode                =   "horizontal"
            style               =   {{width: '35%'}} // This was 25% . I made it to 35% .
            selectedKeys        =   {selectedMenu}
        >

                {/* <Menu.Item key="welcome" title={<IntlMessages id="sidebar.home"/>}>
                    <Link className="link_text" to="/welcome">
                        <IntlMessages id="sidebar.home"/>
                    </Link>
                </Menu.Item> */}

                {/* <Menu.Item key="login" title={authUser === undefined ? <IntlMessages id="app.userAuth.login"/> : <IntlMessages id="sidebar.dashboard"/>}>
                        <Link to="/signin">
                            {authUser === undefined ? <IntlMessages id="app.userAuth.login"/> : <IntlMessages id="sidebar.dashboard"/>}
                        </Link>
                </Menu.Item> */}

                {/* <Menu.Item key="allnews" title={<IntlMessages id="sidebar.news"/>}>
                    <Link className="link_text" to="/allnews">
                        <IntlMessages id="sidebar.news"/>
                    </Link>
                </Menu.Item> */}

                {/* <Menu.Item key="careers" title={<IntlMessages id="sidebar.careers"/>}>
                    <Link className="link_text" to="/careers">
                        <IntlMessages id="sidebar.careers"/>
                    </Link>
                </Menu.Item> */}

                {/* <Menu.Item key="aboutus" title={<IntlMessages id="sidebar.aboutus"/>}>
                    <Link className="link_text" to="/aboutus">
                        <IntlMessages id="sidebar.aboutus"/>
                    </Link>
                </Menu.Item> */}

                {/* <Menu.Item key="JaagoShikkhok" title={<IntlMessages id="JaagoShikkhok"/>}>
                    <Link className="link_text" to="/jaago">
                        <span style={{color : '#ffa117'}}><IntlMessages id="JaagoShikkhok"/></span>
                    </Link>
                </Menu.Item> */}

        </Menu>

    );
};

HorizontalNav.propTypes = {};

export default HorizontalNav;

