import { useState, useEffect } from "react";
import { Layout, Popover, Tooltip, Alert } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { userSignOut } from "appRedux/actions/Auth";
import { toggleCollapsedSideNav } from "../../appRedux/actions";
import SearchBox from "../../components/SearchBox";
import Auxiliary from "util/Auxiliary";
import firebase from 'firebase';
import { THEME_TYPE_DARK, THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import { setThemeType } from "../../appRedux/actions";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import { LogoutOutlined } from "@ant-design/icons";
import SearchAutoCompleteUsers from "../../components/UsersCard/SearchAutoCompleteUsers";

const { Header } = Layout;
const database    = firebase.database();

const Topbar = () => {

    const { navStyle }                          = useSelector(({ settings }) => settings);
    const navCollapsed                          = useSelector(({ common }) => common.navCollapsed);
    const width                                 = useSelector(({ common }) => common.width);
    const [searchText, setSearchText]           = useState("");
    const dispatch                              = useDispatch();
    const { db }                                = useSelector(({ auth }) => auth);
    const [mode, setMode]                       = useState('SMS');

    const onSearch = (searchText) => {};



    const returnSearchJSX = () => {
        return (
        <>
                                                <SearchAutoCompleteUsers 
                                                        placeholder             =   "Search in app for users ... "
                                                        width                   =   {400} 
                                                        onSelect                =   {()=>{}} 
                                                        openProfileWhenClicked  =   {true}
                                                    />
                                                     {/* <Alert message={`7 days of trial left`} type="success" showIcon closable={true} className="gx-ml-3 gx-mt-3"/> */}
        </>
       )
    }

    const logOut = () => {
        database.ref(`USERS/${db['UID']}`).off();
        dispatch(userSignOut());
    }

    useEffect(() => {
        let isDarkMode = localStorage.getItem('themeType') === THEME_TYPE_DARK ? true : false
        if (isDarkMode ) {
          localStorage.setItem('themeType', THEME_TYPE_DARK)
          dispatch(setThemeType(THEME_TYPE_DARK));
        }
        else {
          localStorage.setItem('themeType', THEME_TYPE_LITE)
          dispatch(setThemeType(THEME_TYPE_LITE));
        }
      
      }, [dispatch])

    return (
        <>
            <Header>
            {navStyle === NAV_STYLE_DRAWER ||
                ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
                width < TAB_SIZE) ? (
                <div className="gx-linebar gx-mr-3">
                <i
                    className="gx-icon-btn icon icon-menu"
                    onClick={() => {
                    dispatch(toggleCollapsedSideNav(!navCollapsed));
                    }}
                />
                </div>
            ) : null}
            <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
               <></>
            </Link>

            {returnSearchJSX()}


            <ul className="gx-header-notifications gx-ml-auto">

                {mode === 'SMS' ? <></> : 
            
                    <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none">
                    <Popover
                        overlayClassName="gx-popover-horizantal"
                        placement="bottomRight"
                        content={
                        <SearchBox
                            styleName="gx-popover-search-bar"
                            placeholder="Search in app for students.."
                            onChange={onSearch}
                            value={searchText}
                        />
                        }
                        trigger="click"
                    >
                        <span className="gx-pointer gx-d-block">
                        <i className="icon icon-search-new" />
                        </span>
                    </Popover>
                    </li>
                }


                {/* <Auxiliary>
                <li className="gx-msg">
                    <Popover
                    overlayClassName="gx-popover-horizantal"
                    placement="bottomRight"
                    content={<MailNotification />}
                    trigger="click"
                    >
                    <span className="gx-pointer gx-status-pos gx-d-block">
                        <i className="icon icon-chat-new" />
                        <span className="gx-status gx-status-rtl gx-small gx-orange" />
                    </span>
                    </Popover>
                </li>
                </Auxiliary>

                <Auxiliary>
                <li className="gx-notify">
                    <Popover
                    overlayClassName="gx-popover-horizantal"
                    placement="bottomRight"
                    content={<AppNotification />}
                    trigger="click"
                    >
                    <span className="gx-pointer gx-d-block">
                        <i className="icon icon-notification" />
                    </span>
                    </Popover>
                </li>
                </Auxiliary> */}

                {/* <li className="gx-language">
                <Popover
                    overlayClassName="gx-popover-horizantal"
                    placement="bottomRight"
                    content={languageMenu()}
                    trigger="click"
                >
                    <span className="gx-pointer gx-flex-row gx-align-items-center">
                    <i className={`flag flag-24 flag-${mapLanguageLocaleToNameAndIcon(i18n.language)['icon']}`} />
                    <span className="gx-pl-2 gx-language-name">{mapLanguageLocaleToNameAndIcon(i18n.language)['name']}</span>
                    <i className="icon icon-chevron-down gx-pl-2" />
                    </span>
                </Popover>
                </li> */}

                {/* <Auxiliary>
                <li>
                    <DarkLightMode />
                </li>
                </Auxiliary> */}

                <Auxiliary>
                <li>
                    <Tooltip title="Log-Out">
                        <LogoutOutlined onClick={()=>logOut()} className="gx-mr-3" style={{ fontSize: '18px', cursor: 'pointer' }}/>

                    </Tooltip>
                </li>
                </Auxiliary>
            </ul>
            </Header>
        
        </>
    );
};

export default Topbar;
