import React, {useEffect} from "react";
import { Modal, Button, Row, Col, Input, Form, message} from 'antd';
import { useSelector } from 'react-redux';
import {  encodeString } from "../../../../util/misc";
import firebase from 'firebase';
import { useTranslation } from "react-i18next";
const database    = firebase.database();

const { TextArea } = Input;


const AddTemplateModal = ({ isModalVisible, loading, handleOk, handleCancel, onItemSelect ,data, defaultMessage }) => {
    
    
    const [form]     = Form.useForm();
    const { db }     = useSelector(({ auth }) => auth);
    const { t }      = useTranslation();

    useEffect(() => {
        if (defaultMessage){
            form.setFieldsValue({templateMessage: defaultMessage});
        }
    }, [defaultMessage, form, isModalVisible])
    

    const onFinish = async (values) => {
        const {templateMessage, description} = values;
        if (templateMessage) {
            const data = {
                message : encodeString(templateMessage),
            }

            if (description && description !== ''){
                data['description'] = encodeString(description);
            }

            let dbAddress =  `USERS/${db['UID']}/SMS-Templates/`;
            await database.ref(dbAddress).push(data)
            .then(res => {
                message.success('New Templates added successfully!');
                form.resetFields();
                handleOk();
            })
            .catch(e=>{
                message.error(e)
            })
        }
        else{
            message.error('Empty template not accepted!');
        }
    };

    return (
        <>
            <Modal
                centered
                title={t("New SMS Template")}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={500}
                footer={[
                    <Button form='myFormSmsTemplatesAdd' type="primary" htmlType="submit">
                        {t("Add")}
                    </Button>
                ]}
            >
                <div className="content-insideModal-smsSetting-groups">
                    <Form
                        id="myFormSmsTemplatesAdd"
                        initialValues   =   {{ remember: true }}
                        name            =   "basic"
                        form            =   {form}
                        onFinish        =   {onFinish}
                        layout          =   "vertical"
                        className       =   "gx-force-two-cols gx-form-row0"
                    >
                        <Row>
                        
                            <Col span={24}>
                                <Form.Item
                                    name="description"
                                    label='Description'
                                    rules={[{ required: false}]}
                                >
                                <Input
                                    name="description"
                                    placeholder={t("[Optional] Enter a description of this template")}
                                />
                                </Form.Item>
                                <Form.Item
                                    name="templateMessage"
                                    label='Message'
                                    rules={[{ required: true, message: 'Empty templates cannot be accepted!' }]}
                                >
                                <TextArea
                                    name="templateMessage"
                                    placeholder={t("Enter Your template text")}
                                    style={{
                                    height: 120,
                                    }}
                                />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

export default AddTemplateModal;