
import {useSelector} from "react-redux";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { Button, } from "antd";
import { useDispatch } from "react-redux";
import firebase from 'firebase';
import {  toggleCollapsedSideNav } from "../../appRedux/actions";
import { CloseOutlined, MenuFoldOutlined, MenuUnfoldOutlined, PlusOutlined } from "@ant-design/icons";
import Mixpanel from "../../api/Mixpanel";


const database      = firebase.database();


const SidebarLogo = ({sidebarCollapsed, setSidebarCollapsed, onClose}) => {

    const dispatch                                      = useDispatch();
    const navCollapsed                                  = useSelector(({common}) => common.navCollapsed);
    const width                                         = useSelector(({common}) => common.width);
    const { db }                                        = useSelector(({ auth }) => auth);

    let navStyle = useSelector(({settings}) => settings.navStyle);
    if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
        navStyle = NAV_STYLE_DRAWER;
    }


    return (
        <div style={{height: '74px', padding: '20px', paddingLeft: '15px', paddingRight: '15px'}}>

            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>

                {sidebarCollapsed === true ? <></> : 
                
                    <img className="sidebarFullLogo" alt="logo1" src={("/assets/images/logo-small.png")}/>
                
                }

                

                {
                    width <TAB_SIZE ?

                    <Button style={{margin: 0, marginLeft: '8px'}} type="dashed" size="medium" onClick={()=>{
                        onClose()
                    }}>
                        <CloseOutlined />
                        
                    </Button> 

                    :

                    <Button style={{margin: 0, marginLeft: '8px'}} type="dashed" size="medium" onClick={()=>{
                        setSidebarCollapsed(!sidebarCollapsed);
                        dispatch(toggleCollapsedSideNav(!sidebarCollapsed));
                    }}>
                        {!sidebarCollapsed ?  <MenuUnfoldOutlined /> :  <MenuFoldOutlined />}
                        
                    </Button> 


                }
                
                

            </div>
        </div>
    );
};

export default SidebarLogo;
